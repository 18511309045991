export enum QuestionType {
  display = 'DISPLAY',
  group = 'GROUP',

  singleChoice = 'CHOICE',

  quantity = 'QUANTITY',

  text = 'TEXT',
  string = 'STRING',

  decimal = 'DECIMAL',
  integer = 'INTEGER',
  boolean = 'BOOLEAN',

  date = 'DATE',
  time = 'TIME',
  datetime = 'DATETIME'
}
