import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const encounterBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.encounter,
  fieldGroups: [
    {
      name: ({ resource }) =>
        (resource?.content?.period?.start
          ? MedrecordMoment(resource?.content?.period?.start).format('LL') + ' - '
          : '') + resource?.content?.contactWith?.display,
      fields: [
        {
          label: 'my_doctor_type',
          fieldName: 'type',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_start_date',
          fieldName: 'period',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_end_date',
          fieldName: 'period',
          prepareData: (data) => (data?.end ? MedrecordMoment(data?.end).format('LL') : ''),
        },
        {
          label: 'my_doctor_care_provider',
          fieldName: 'contactWith',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_organization_name',
          fieldName: 'location',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_concern',
          fieldName: 'problem',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_procedure',
          fieldName: 'operation',
          prepareData: (data) => data?.display,
        },
      ],
    },
  ],
});
