import { GoogleLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';

export const provideGoogleAuthConfig = () => ({
  provide: 'SocialAuthServiceConfig',
  useValue: {
    autoLogin: false,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(
          '199723900643-1fql1dicrt6fi9u2b72t9ecrf116ngr8.apps.googleusercontent.com'
        )
      }
    ],
  } as SocialAuthServiceConfig,
});
