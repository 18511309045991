import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QuestionComment } from '../../interfaces/question-comment.interface';

@Component({
  selector: 'medrecord-question-comments',
  templateUrl: './question-comments.component.html',
  styleUrls: ['./question-comments.component.scss']
})
export class QuestionCommentsComponent {
  @Input() comment: QuestionComment = null;

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      comment: ['']
    });
  }

  send() {
    const comment = this.form.get('comment');
    this.comment = {
      comment: comment.value,
      userId: '',
      userAvatar: '',
      date: new Date()
    };

    comment.setValue('');
  }

  editComment() {
    this.form.get('comment').setValue(this.comment.comment);
    this.comment = null;
  }

  removeComment() {
    this.comment = null;
  }
}
