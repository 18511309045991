import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CareproviderResource,
  MyDoctorResourceMappingService,
  Resource,
  ResourceFieldGroup,
  ResourceType,
} from '@medrecord/managers-my-doctor';

@Component({
  selector: 'doctor-gui-medsafe-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss'],
})
export class ResourceComponent implements OnInit {
  @Input() resource: CareproviderResource;
  @Input() isGroupedResource = false;
  @Output() getData = new EventEmitter();

  isExpanded = false;
  resourceFieldGroups: ResourceFieldGroup[];
  resourceType: ResourceType;
  additionalResourceTypes: ResourceType[];
  mainResources: any[] = [];

  constructor(private mappingService: MyDoctorResourceMappingService) {}

  ngOnInit(): void {
    const block = this.mappingService.getResourceBlock(
      this.resource.key as Resource
    );
    this.resourceFieldGroups = block?.fieldGroups || [];
    this.resourceType = block?.resourceType;
    this.additionalResourceTypes = block?.additionalResourceTypes || [];
    this.mainResources = this.resource.resources.filter(
      (r) => r.resourceType === this.resourceType || this.additionalResourceTypes.includes(r.resourceType)
    );

    if (block?.isResourceFromField && block?.fieldForResources?.length) {
      this.mainResources = this.mainResources.reduce((prev, current, i) => {
        let newResources = current;
        if (Array.isArray(current?.content?.[block.fieldForResources])) {
          newResources = current?.content?.[block.fieldForResources]?.map(
            (newContent) => ({
              ...current,
              content: newContent,
              resourceId: current.resourceId + '-' + i,
            })
          );
        } else if (
          current?.content?.[block.fieldForResources] &&
          typeof current?.content?.[block.fieldForResources] === 'object'
        ) {
          newResources = [
            {
              ...current,
              content: current?.content?.[block.fieldForResources],
              resourceId: current.resourceId + '-' + i,
            },
          ];
        }

        return [...prev, ...newResources];
      }, []);
    }
  }

  triggerGetData(event: Event): void {
    event.stopPropagation();
    this.getData.emit([this.resource]);
  }
}
