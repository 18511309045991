<section class="zdb-nav-fixed">
  <zdb-topnav></zdb-topnav>
  <div class="container-fluid pl-md-0">
    <div class="zdb-bg-muted"></div>
    <div id="layoutSidenav">
      <div id="layoutSidenav_nav">
        <zdb-sidenav
          [sidenavStyle]="sidenavStyle"
          [sideNavItems]="sideNavItems"
          [sideNavSections]="sideNavSections"
        ></zdb-sidenav>
      </div>
      <div id="layoutSidenav_content">
        <main>
          <div class="container-fluid">
            <ng-content></ng-content>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
