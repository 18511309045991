import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  SideNavItem,
  ZDBRouteData,
} from 'src/app/core/navigation/navigation.model';
import { NavigationService } from 'src/app/core/navigation/navigation.service';

@Component({
  selector: 'zdb-sidenav-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
})
export class SidenavItemComponent {
  @Input() sideNavItem!: SideNavItem;
  @Input() isActive!: boolean;

  constructor(
    private navigationService: NavigationService,
    private router: Router
  ) {}
  expanded = false;
  routeData!: ZDBRouteData;

  getIsActive() {
    // Get active side bar link item
    const initialSideNavItemLink = this.sideNavItem.link.split('/')[1];
    // Check if side bar includes base route
    return this.router.url.includes(initialSideNavItemLink);
  }

  toggleSideNav(): void {
    const ua = navigator.userAgent;
    const isMobileBrowser =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      );
    if (isMobileBrowser) {
      this.navigationService.toggleSideNav();
      this.expanded = !this.expanded;
    }
  }
}
