import { ResourceType } from '../../enums/resource-type.enum';
import { ResourceBlockFunction } from '../../interfaces/resource-block-function.interface';

export const bgz2020ContactPersonBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.patient,
  isResourceFromField: true,
  fieldForResources: 'contacts',
  fieldGroups: [
    {
      name: 'my_doctor_name',
      fields: [
        {
          label: 'my_doctor_first_name',
          fieldName: 'name',
          prepareData: (data) => data.firstName,
        },
        {
          label: 'my_doctor_initials',
          fieldName: 'name',
          prepareData: (data) => data.initials,
        },
        {
          label: 'my_doctor_given_name',
          fieldName: 'name',
          prepareData: (data) => data.givenName,
        },
        {
          label: 'my_doctor_partner_last_name_prefix',
          fieldName: 'name',
          prepareData: (data) => data.partnerLastNamePrefix,
        },
        {
          label: 'my_doctor_partner_last_name',
          fieldName: 'name',
          prepareData: (data) => data.partnerLastName,
        },
        {
          label: 'my_doctor_last_name_prefix',
          fieldName: 'name',
          prepareData: (data) => data.lastNamePrefix,
        },
        {
          label: 'my_doctor_last_name',
          fieldName: 'name',
          prepareData: (data) => data.lastName || data.family || null,
        },
        {
          label: 'my_doctor_name_use',
          fieldName: 'name',
          prepareData: (data) => data?.assemblyOrder?.coding?.[0]?.display,
        },
      ],
    },
    {
      name: 'my_doctor_address',
      fields: [
        {
          label: 'my_doctor_street',
          fieldName: 'address',
          prepareData: (data) => data?.streetName,
        },
        {
          label: 'my_doctor_house_number',
          fieldName: 'address',
          prepareData: (data) => data?.houseNumber,
        },
        {
          label: 'my_doctor_postal_code',
          fieldName: 'address',
          prepareData: (data) => data?.postalCode,
        },
        {
          label: 'my_doctor_city',
          fieldName: 'address',
          prepareData: (data) => data?.city,
        },
        {
          label: 'my_doctor_country',
          fieldName: 'address',
          prepareData: (data) => data?.landGbaCode?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_address_type',
          fieldName: 'address',
          translate: true,
          prepareData: (data) => data?.use,
        },
      ],
    },
    {
      name: 'my_doctor_contact_information',
      fields: [
        {
          label: 'my_doctor_phone_number',
          fieldName: 'contactPoints',
          prepareData: (data) => {
            const phone = data?.find((t) => t.system === 'PHONE');
            return phone?.value;
          },
        },
        {
          label: 'my_doctor_phone_type',
          fieldName: 'contactPoints',
          translate: true,
          prepareData: (data) => {
            const phone = data?.find((t) => t.system === 'PHONE');
            return phone?.use ? 'common_contact_use_' + phone?.use?.toLowerCase() : '';
          },
        },
        {
          label: 'my_doctor_email',
          fieldName: 'contactPoints',
          prepareData: (data) => {
            const email = data?.find((t) => t.system === 'EMAIL');
            return email?.value;
          },
        },
        {
          label: 'my_doctor_email_type',
          fieldName: 'contactPoints',
          translate: true,
          prepareData: (data) => {
            const email = data?.find((t) => t.system === 'EMAIL');
            return email?.use ? 'common_contact_use_' + email?.use?.toLowerCase() : '';
          },
        },
        {
          label: 'my_doctor_gender',
          fieldName: 'gender',
          translate: true,
          prepareData: (data) => (data ? 'common_gender_' + data?.toLowerCase() : ''),
        },
        {
          label: 'my_doctor_role',
          fieldName: 'roles',
          prepareData: (data) =>
            data
              .map((role) => role?.coding?.[0]?.display)
              .filter(Boolean)
              .join(', '),
        },
        {
          label: 'my_doctor_relationship',
          fieldName: 'relationships',
          prepareData: (data) =>
            data
              .map((relationship) => relationship?.coding?.[0]?.display)
              .filter(Boolean)
              .join(', '),
        },
      ],
    },
  ],
});
