<ng-container *ngIf="user$ | async as user">
    <div class="row">
        <div class="col">
            <div class="row mb-3">
                <div class="col-md-3 pl-0"><strong>{{ 'userdetails.name' | translate }}</strong></div>
                <div class="col details">{{ user.name.text }}</div>
            </div>
            <div class="row mb-3" *ngIf="user.gender">
                <div class="col-md-3 pl-0"><strong>{{ 'userform.gender' | translate }}</strong></div>
                <div class="col details">{{ ('genders.' + (user.gender | lowercase)) | translate }}</div>
            </div>
            <div class="row mb-3">
                <div class="col-md-3 pl-0"><strong>{{ 'userform.date_of_birth' | translate }}</strong></div>
                <div class="col details">{{ user.birthDate }}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="row mb-3">
                <div class="col-md-3 pl-0"><strong>{{ 'userdetails.address' | translate }}</strong></div>
                <div class="col details">{{ user.streetName }} {{ user.houseNumber }}</div>
            </div>
            <div class="row mb-3">
                <div class="col-md-3 pl-0"><strong>{{ 'userform.city' | translate }}</strong></div>
                <div class="col details">{{ user.city }}</div>
            </div>
            <div class="row mb-3">
                <div class="col-md-3 pl-0"><strong>{{ 'userform.postal_code' | translate }}</strong></div>
                <div class="col details">{{ user.postalCode }}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">

            <div class="row mb-3">
                <div class="col-md-3 pl-0"><strong>{{ 'userform.email' | translate }}</strong></div>
                <div class="col details">{{ user.email }}</div>
            </div>
            <div class="row mb-3">
                <div class="col-md-3 pl-0"><strong>{{ 'userform.phone' | translate }}</strong></div>
                <div class="col details">{{ user.phone }}</div>
            </div>
        </div>
    </div>
</ng-container>
