<div class="modal-header">
  <h3 class="modal-title">{{ title }}</h3>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-body px-6" style="white-space: pre-line;">
  {{ message }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary"
   (click)="decline()">{{ btnCancelText }}</button>
  <button type="button" class="btn {{ btnStyle }}" (click)="accept()">{{ btnOkText }}</button>
</div>
