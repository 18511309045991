import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AlertModalComponent } from './alert-modal.component';

@Injectable()
export class AlertModalService {

  constructor(private modalService: NgbModal) { }

  public alert(
      title: string,
      message: string,
      btnOkText: string = 'Ok',
      dialogSize: 'sm' | 'md' = 'md'): Promise<boolean> {
    const modalRef = this.modalService.open(AlertModalComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;

    return modalRef.result;
  }

}
