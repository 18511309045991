import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'medrecord-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input() settingsModel: PageSettingsModel = {};
  @Input() pageItemsCount: string[] = ['5', '10', '15', '20'];
  
  @Output() change = new EventEmitter<{ settingsModel: PageSettingsModel }>();

  typeSettingsModel(data: PageSettingsModel): PageSettingsModel {
    return data;
  }

  getStartRange(page: number): number {
    const tempnumber = Math.max((page - 1) * this.settingsModel.pageSize + 1, 1);
    return isNaN(this.settingsModel.totalRecordsCount) ? 0 : tempnumber;
  }

  getEndRange(page: number, maxItems: number): number {
    const tempnumber = Math.min(maxItems, (page - 1) * this.settingsModel.pageSize + this.settingsModel.pageSize);
    return isNaN(tempnumber) ? 0 : tempnumber;
  }

  getTotalPages(records: number): number {
    return this.settingsModel.pageCount || Math.ceil(records / this.settingsModel.pageSize);
  }

  changeItemsPerPage(args: ChangeEventArgs): void {
    this.settingsModel = {
      ...this.settingsModel,
      pageCount: Math.ceil(this.settingsModel.totalRecordsCount / parseInt(args.itemData.value, 10)),
      currentPage: 1,
      pageSize: parseInt(args.itemData.value, 10),
    };
    this.emitChanges();
  }

  nextPage(): void {
    this.selectPage(this.settingsModel.currentPage + 1);
    this.emitChanges();
  }

  prevPage(): void {
    this.selectPage(this.settingsModel.currentPage - 1);
    this.emitChanges();
  }

  private selectPage(page: number): void {
    this.settingsModel = { ...this.settingsModel, currentPage: page };
    this.emitChanges();
  }

  private emitChanges(): void {
    this.change.emit({ settingsModel: this.settingsModel });
  }

}
