export enum CAP {
  cADD = 'cADD',
  cADL = 'cADL',
  cCARDIO = 'cCARDIO',
  cCOGNIT = 'cCOGNIT',
  cCOMMUN = 'cCOMMUN',
  cDEHYD = 'cDEHYD',
  cFALLS = 'cFALLS',
  cMOOD = 'cMOOD',
  cPACTIV = 'cPACTIV',
  cPAIN = 'cPAIN',
  cIADL = 'cIADL',
  cSOCFUNC = 'cSOCFUNC',
  cURIN = 'cURIN',
}

export enum Scale {
  sADLH = 'sADLH',
  sDIVERT = 'sDIVERT',
  sCHESS = 'sCHESS',
  sCOMM = 'sCOMM',
  sCPS = 'sCPS',
  sCPS2 = 'sCPS2',
  sIADLCH = 'sIADLCH',
  siADLSF = 'siADLSF',
  sPAIN = 'sPAIN',
  sSRMOOD = 'sSRMOOD',
  sVPR = 'sVPR',
}

export enum Algorithm {
  aAXURG = 'aAXURG',
  aSRI = 'aSRI',
  xFALLS = 'xFALLS',
}

export enum CategoryKey {
  fysical = 'fysical',
  cognitive = 'cognitive',
  mental_social = 'mental_social',
  self_determination = 'self_determination',
  stability = 'stability',
}

export enum CodeDisplay {
  gaugeChart = 'gaugeChart',
  stoplightChart = 'stoplightChart',
  textDisplay = 'textDisplay',
  longTextDisplay = 'longTextDisplay',
  ratingDisplay = 'ratingDisplay',
  default = 'default',
}
