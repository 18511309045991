import { MedrecordMoment } from '@medrecord/services-datetime';
import { Questionnaire } from '../interfaces/questionnaire.interface';
import { timeFromNumber } from './time-from-number.util';
import { QuestionType } from '../enums/question-type.enum';
import { positiveHealthIntroId } from '@app/medrecord/questionnaire-adapter/constants/positive-health-questionnaire-start-item';

/**
 * This util is the inverse of @questionnaire-response-to-answers.util.ts
 */
export const prepareQuestionnaireForSave = (questionnaire: Questionnaire) => {
  return {
    patientName: preparePatientName(questionnaire.patientName),
    items: getAnswers(
      questionnaire.questions,
      questionnaire.answers
    )
  };
};

export const preparePatientName = (patientName: any) => {
  const {firstName, initials, lastName} = patientName;
  return `${firstName || initials } ${lastName}`;
};

export const getAnswers = (questions, allAnswers) => {
  const finalAnswers = questions.map(question => {
    const linkId = question.id;
    const text = question.questionText;
    const answer = allAnswers[question.id];
    const answers = prepareAnswer(question, answer);

    const items = ( question.type === QuestionType.group )
      ? getAnswers(question.subQuestions, allAnswers)
      : [];

    if (answers === null && items.length === 0) {
      return;
    }

    return {
      linkId,
      answers,
      items,
      text
    };
  });

  return finalAnswers.filter(answer => answer !== undefined);
};

export const prepareAnswer = (question, answerRaw) => {
  const answer = answerRaw?.answer;
  const text = answerRaw?.text;

  if (answer === undefined || answer === null) {
    return null;
  }

  switch (question.type) {
    case QuestionType.display:
      return [];

    case QuestionType.text:
    case QuestionType.string:

    case QuestionType.decimal:
    case QuestionType.integer:
    case QuestionType.boolean:
      return [{
        [getAnswerFieldName(question.type)]: answer,
        text
      }];

    case QuestionType.date:
      return !answer ? null : [{
        [getAnswerFieldName(question.type)]: {
          date: MedrecordMoment(answer, 'LLL').format('YYYY-MM-DDT00:00:00.000') + 'Z',
          precision: 'DAY',
        },
        text
      }];

    case QuestionType.datetime:
      return [{
        answerDateTime: answer,
        text
      }];

    case QuestionType.quantity: {
      const {
        code = null,
        system = null,
        unit = null
      } = question.unitOfMeasureCoding || {};

      return [{
        [getAnswerFieldName(question.type)]: {
          value: answer,
          code,
          system,
          unit
        },
        text
      }];
    }

    case QuestionType.singleChoice:
      return [{
        answerCoding: answer.map(
          ({ answerCoding: { code, display, system, version } }) => ( {
            code,
            display,
            system,
            version,
            userSelected: true
          } ))[0],
        text
      }];

    case QuestionType.time: {
      return [{
        [getAnswerFieldName(question.type)]: timeFromNumber(answer),
        text
      }];
    }
  }

  return answer instanceof Array ? answer : [answer];
};

export const getAnswerFieldName = (questionType: QuestionType) => `answer${
  questionType.slice(0, 1).toUpperCase()
}${
  questionType.slice(1).toLowerCase()
}`;


export const preparePositiveHealthQuestionnaireForSave = (questionnaire: Questionnaire, questionnaireToTaskMapping: any) => {
  const questionnaires = questionnaire.questions.map((q) => {
    return {
      ...questionnaire,
      id: q.id,
      questions: q.subQuestions
        .filter((sq) => sq.id.split('|||')?.pop() === q.id)
        .map((sq) => ({ ...sq, id: sq.id.split('|||')[0] })),
      answers: Object.keys(questionnaire.answers)
        .filter((key) => key.split('|||')?.pop() === q.id)
        .reduce((prev, curr) => ({ ...prev, [curr.split('|||')[0]]: questionnaire.answers[curr] }), {})
    };
  });

  return questionnaires.filter(q => q.id !== positiveHealthIntroId).map((q) => ({
    request: { 
      patientName: preparePatientName(q.patientName),
      items: getAnswers(
        q.questions,
        q.answers
      )  
    },
    taskId: questionnaireToTaskMapping[q.id]    
  }));
};