import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  CareproviderParameters,
  CareproviderTransaction,
  Resource,
} from '@medrecord/managers-my-doctor';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { TranslateService } from '@ngx-translate/core';
import {
  FormGroupItem,
  ParameterGroup,
} from 'projects/medrecord/managers/my-doctor-manager/src/lib/models/interfaces/resource-parameters.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'doctor-gui-parameters-modal',
  templateUrl: './parameters-modal.component.html',
  styleUrls: ['./parameters-modal.component.scss'],
})
export class ParametersModalComponent implements OnInit, OnDestroy {
  @Input() transaction: CareproviderTransaction;
  @Input() dataServiceId: string;
  @Input() isOpen = false;
  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter<any>();

  readonly requiredMsg = this.translateService.instant(
    'common_validation_required'
  );

  form: FormGroup;
  parameters: CareproviderParameters[];
  parametersGroups: ParameterGroup = {};
  formModel = {};
  formGroupItems: FormGroupItem[];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.parameters = this.transaction?.parameters?.filter(
      (param: CareproviderParameters) =>
        this.transaction?.resourceKeys?.filter((resourceKey) => param.resourceKeys?.includes(resourceKey))
    );

    this.transaction?.resourceKeys?.forEach(
      (resourceKey: Resource): ParameterGroup => {
        const params = this.parameters?.filter(
          (param) => param.resourceKeys.includes(resourceKey)
        );

        if (!this.parametersGroups) {
          this.parametersGroups = {};
        }

        if (params.length) {
          this.parametersGroups[resourceKey] = params;
          return {
             [resourceKey]: params,
          };
        }
      }
    );

    this.initializeForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializeForm(): void {
    this.formModel = this.formModel;

    const group: any = {};

    Object.keys(this.parametersGroups).map((key) => {
      const resourceKeyGroup = {};
      (this.parametersGroups[key] || []).map((param) => {
        return (resourceKeyGroup[param.name] = new FormControl(
          param.defaultValue || ''
        ));
      });

      group[key] = this.formBuilder.group(resourceKeyGroup);
    });

    this.form = this.formBuilder.group(group);

    this.formGroupItems = this.getFormParametersGroup();
  }

  getFormParametersGroup(): FormGroupItem[] {
    const group = [];
    Object.keys(this.form.value).map((key: Resource) => {
      group.push({
        key,
        parameters: this.parameters.filter(
          (param) => param.resourceKeys?.includes(key)
        ),
      });
    });

    return group;
  }

  formatParameterValues(parameters: any = {}) {
    const result = {};
    Object.keys(parameters).map((key) => {
      if (parameters[key]?.date) {
        result[key] = MedrecordMoment(parameters[key]?.date).format(
          'YYYY-MM-DD'
        );
      }

      result[key] = parameters[key] || null;
    });

    return { ...parameters, ...result };
  }

  generateParametersPayload(): { key: Resource; parameters?: any }[] {
    const payload: { key: Resource; parameters?: any }[] = [];

    this.transaction?.resourceKeys.map((key) => {
      if (this.form.value?.[key]) {
        payload.push({
          key,
          parameters: this.formatParameterValues(this.form.value?.[key]),
        });
      } else {
        payload.push({ key });
      }
    });

    (this.transaction.additionalResources || []).map(key => {
      if (!this.transaction.resourceKeys.includes(key)) {
        payload.push({ key });
      }
    });

    return payload;
  }

  formSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const payload = this.generateParametersPayload();

      this.submit.emit(payload);
    }
  }
}
