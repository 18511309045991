import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CareproviderResource } from '@medrecord/managers-my-doctor';

@Component({
  selector: 'doctor-gui-medsafe-resource-group',
  templateUrl: './resource-group.component.html',
  styleUrls: ['./resource-group.component.scss'],
})
export class ResourceGroupComponent {
  @Input() resourceGroup: { group: string; resources: CareproviderResource[] };
  @Output() getData = new EventEmitter();

  isExpanded = false;

  triggerGetData(event: Event): void {
    event.stopPropagation();
    this.getData.emit(this.resourceGroup.resources);
  }
}
