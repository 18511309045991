import { questionnairesFeatureName, State } from '../questionnaires.reducer';
import { createSelector } from '@ngrx/store';

const selectQuestionnaires = (state: State) => state[questionnairesFeatureName];

export const selectIsQuestionnaireTemplateLoaded = createSelector(
  selectQuestionnaires,
  ({ questionnairesData }: State) =>
    questionnairesData?.isLoaded
);

export const selectIsQuestionnaireTemplateLoading = createSelector(
  selectQuestionnaires,
  ({ questionnairesData }: State) =>
    questionnairesData?.isLoading
);

export const selectQuestionnaireTemplateError = createSelector(
  selectQuestionnaires,
  ({ questionnairesData }: State) =>
    questionnairesData?.error
);

export const selectQuestionnaireTemplate = createSelector(
  selectQuestionnaires,
  ({ questionnairesData }: State) =>
    questionnairesData?.resource
);

export const selectQuestionnaireAnswers = createSelector(
  selectQuestionnaires,
  ({ questionnairesData }: State) =>
    questionnairesData?.answers
);

export const selectQuestionnaireTaskId = createSelector(
  selectQuestionnaires,
  ({ questionnairesData }: State) =>
    questionnairesData?.taskId
);

export const selectQuestionnaireTask = createSelector(
  selectQuestionnaires,
  ({ questionnairesData }: State) =>
    questionnairesData?.task
);

export const selectSecondQuestionnaireAnswers = createSelector(
  selectQuestionnaires,
  ({ secondQuestionnairesData }: State) =>
  secondQuestionnairesData?.answers
);