import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  CareproviderParameters,
  CareproviderTransaction,
  fetchCareproviderDataAction,
  Resource,
} from '@medrecord/managers-my-doctor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from '@medrecord/services-form-validation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medrecord-test-careprovider-modal',
  templateUrl: './test-careprovider-modal.component.html',
})
export class TestCareproviderModalComponent implements OnInit, OnDestroy {
  @Input() transaction: CareproviderTransaction;
  @Input() dataServiceId: string;
  @Input() isOpen = false;
  @Input() showParametersModal = true;
  @Output() dialogClosed = new EventEmitter();
  readonly requiredMsg = this.translateService.instant(
    'common_validation_required'
  );

  public parametersModalVisible = false;

  formModel = {};
  form: FormGroup;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private formValidatorsService: ValidationService,
    private translateService: TranslateService
  ) {}

  private initializeForm(): void {
    this.formModel = this.formModel;
    this.form = this.formBuilder.group({
      token: [null, [this.formValidatorsService.required(this.requiredMsg)]],
      userKey: [null, [this.formValidatorsService.required(this.requiredMsg)]],
      careProviderUrl: [
        null,
        [this.formValidatorsService.required(this.requiredMsg)],
      ],
    });
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  openParametersModal(): void {
    this.parametersModalVisible = true;
  }

  getParameters(): CareproviderParameters[] {
    return this.transaction?.parameters?.filter(
      (param: CareproviderParameters) =>
        !!this.transaction?.resourceKeys?.filter(resourceKey => param.resourceKeys.includes(resourceKey)).length
    );
  }

  formSubmit(): void {
    this.form.markAllAsTouched();

    if (
      this.form.valid &&
      this.getParameters()?.length &&
      this.showParametersModal
    ) {
      this.parametersModalVisible = true;
      return;
    }

    const resources = this.transaction?.resourceKeys.map(
      (key): { key: Resource } => ({ key })
    );
    this.submit(resources);
  }

  submit(resources: { key: Resource; parameters?: any }[]): void {
    if (this.form.valid) {
      const payload = {
        ...this.form.value,
        resources,
      };

      this.store.dispatch(
        fetchCareproviderDataAction({
          payload,
          dataserviceId: this.dataServiceId,
          transactionId: this.transaction.id,
        })
      );

      this.dialogClosed.emit();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
