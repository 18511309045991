import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectCurrentUser } from '@app/core/user/state/user-data.selectors';
import { IZDBUser } from '@app/core/user/user.model';
import { selectUserId } from '@medrecord/managers-auth';

@Component({
  selector: 'zdb-current-user-edit',
  templateUrl: './current-user-edit.component.html',
  styleUrls: ['./current-user-edit.component.scss']
})
export class CurrentUserEditComponent implements OnInit {

  userId$: Observable<string>;
  user$: Observable<IZDBUser>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.userId$ = this.store.select(selectUserId);
    this.user$ = this.store.select(selectCurrentUser);
  }

}
