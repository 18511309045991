import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {

  readonly emailPattern = '^ *(?!\\.)((?!.*\\.{2})[-a-zA-Z0-9._%+\\u00A0-\\u024F\\u1E00-\\u1EFF]+'
                   + '@[-a-zA-Z0-9.\\u00A0-\\u024F\\u1E00-\\u1EFF]+\\.[a-zA-Z]{2,10}) *$';

  required(message: string): ValidatorFn {
    return (formControl: FormControl) => this.applyErrorMessage(Validators.required(formControl), message);
  }

  email(message: string): ValidatorFn {
    return (formControl: FormControl) => this.applyErrorMessage(Validators.pattern(this.emailPattern)(formControl), message);
  }

  pattern(pattern: RegExp, message: string): ValidatorFn {
    return (formControl: FormControl) => this.applyErrorMessage(Validators.pattern(pattern)(formControl), message);
  }

  maxLength(maxLength: number, message: string): ValidatorFn {
    return (formControl: FormControl) => this.applyErrorMessage(Validators.maxLength(maxLength)(formControl), message);
  }

  applyErrorMessage(validationResult: ValidationErrors | null, message: string): ValidationErrors | null {
    for (const key in validationResult) {
      if (validationResult.hasOwnProperty(key)) {
        validationResult[key] = message;
      }
    }

    return validationResult;
  }
}
