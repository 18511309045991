import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const alertBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.flag,
  fieldGroups: [
    {
      name: ({ resource }) => resource?.content?.name?.coding?.[0]?.display || 'my_doctor_alert',
      fields: [
        {
          label: 'my_doctor_concern',
          fieldName: 'problem',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_date',
          fieldName: 'date',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LL') : ''),
        },
        {
          label: 'my_doctor_type',
          fieldName: 'type',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
      ],
    },
  ],
});
