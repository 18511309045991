<zdb-card>
    <div class="panel-header card-header">
        <div style="
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            ">
            <div style="display: flex; flex: 1 1 auto">
                <h2 class="card-title">{{ 'org_network.title' | translate }}</h2>
            </div>
        </div>
    </div>

    <div class="card-body" *ngIf="userId$ | async as userId">
        <ng-container class="network-item-list" *ngFor="let organization of organizations$ | async; ; let i = index">
            <div style="
                padding: 1.25rem 2rem;
                background-color: rgb(233, 236, 239);
                margin-bottom: 0.25rem;
                border-radius: 1rem;
            ">
                <div class="row align-items-center">
                    <div class="col-0" *ngIf="organization.name.includes('Foreest')">
                        <img src="assets/images/dummy/pvf-logo.png" width="46" height="37" alt="{{ organization.name }}"
                            class="rounded-circle" />
                    </div>
                    <div class="col">
                        <h4>{{ organization.name }}</h4>
                    </div>
                    <div class="col-sm-12 col-md-3 text-center">
                        <ng-container
                            *ngTemplateOutlet="organization.connectedToCurrentUser ? disconnectButton : connectButton; context: {userId: userId, organization: organization}">
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</zdb-card>

<ng-template #connectButton let-userId="userId" let-organization="organization">
    <button type="button" class="btn btn-md btn-secondary"
        (click)="onConnectToOrganization(organization.id, userId, organization.name)">{{
        'org_network.connect_user_to_org' | translate }}</button>
</ng-template>

<ng-template #disconnectButton let-userId="userId" let-organization="organization">
    <div>
        <div>{{ 'org_network.user_connected' | translate }}
            <button type="button" class="btn btn-md btn-outline-primary"
                (click)="onDisconnectFromOrganization(organization.id, userId, organization.name)">{{
                'org_network.disconnect_user_from_org' | translate }}</button>
                </div>
    </div>
</ng-template>