<ejs-dialog
  #dialogComponent
  [visible]="isOpen"
  [isModal]="true"
  (overlayClick)="close.emit()"
  width="40%"
>
  <ng-template #header>
    <span>{{ transaction?.id | translate }}</span>
  </ng-template>

  <ng-template #content>
    <div class="row mx-0 pb-3">
      <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="formSubmit()">
          <div class="row pb-3">
            <div
              class="col-12 form-group"
              *ngFor="let group of formGroupItems"
              [formGroupName]="group.key"
            >
              <div>
                <label>{{ group.key | translate }}</label>
              </div>
              <div
                class="parameters-conteiner"
                [ngClass]="parameter.type !== 'data' && 'mb-2'"
                *ngFor="let parameter of group.parameters"
              >
                <medrecord-select
                  *ngIf="parameter?.type === 'dropdown'"
                  [id]="parameter?.name"
                  [formControlName]="parameter?.name"
                  [label]="parameter?.label | translate"
                  [items]="parameter?.options"
                ></medrecord-select>
                <medrecord-datepicker
                  *ngIf="parameter.type === 'date'"
                  [id]="parameter.name"
                  [formControlName]="parameter.name"
                  [label]="parameter.label | translate"
                  class="w-100"
                  dateFormat="YYYY-MM-DD"
                ></medrecord-datepicker>
                <medrecord-input
                  *ngIf="parameter.type === 'text'"
                  [id]="parameter.name"
                  [formControlName]="parameter.name"
                  [label]="parameter.label | translate"
                  [placeholder]="parameter.placeholder | translate"
                ></medrecord-input>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex align-items-center justify-content-end">
              <button
                (click)="close.emit()"
                type="button"
                class="btn btn-outline-black mr-3"
              >
                {{ "common_cancel" | translate }}
              </button>
              <button class="btn btn-secondary">
                {{ "common_form_submit" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
