<form *ngIf="form"
      [formGroup]="form">

  <medrecord-datepicker
    [label]="'common_answer' | translate"
    [placeholder]="'common_select_date' | translate"
    formControlName="answer"
  ></medrecord-datepicker>

</form>
