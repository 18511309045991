import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const plannedCareActivityImmunizationRecommendationBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.immunizationRecommendation,
  fieldGroups: [
    {
      name: ({ resource }) => resource.content?.supportingImmunization?.display || 'my_doctor_vaccination',
      fields: [
        {
          label: 'my_doctor_status',
          fieldName: 'orderStatus',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_recommended_date',
          fieldName: 'recommendationDate',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LL') : ''),
        },
        {
          label: 'my_doctor_vaccination_date',
          fieldName: 'supportingImmunization',
          prepareData: (data, resources) => {
            const immunization = resources.find((r) => {
              const [referenceType, referenceId] = data?.reference?.split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return immunization?.content?.vaccinationDate
              ? MedrecordMoment(immunization?.content?.vaccinationDate).format('LL')
              : '';
          },
        },
        {
          label: 'my_doctor_desired_revaccination_date',
          fieldName: 'supportingImmunization',
          prepareData: (data, resources) => {
            const immunization = resources.find((r) => {
              const [referenceType, referenceId] = data?.reference?.split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return immunization?.content?.desiredRevaccinationDate
              ? MedrecordMoment(immunization?.content?.desiredRevaccinationDate).format('LL')
              : '';
          },
        },
        {
          label: 'my_doctor_dose',
          fieldName: 'supportingImmunization',
          prepareData: (data, resources) => {
            const immunization = resources.find((r) => {
              const [referenceType, referenceId] = data?.reference?.split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return immunization?.content?.dose?.value;
          },
        },
        {
          label: 'my_doctor_actor',
          fieldName: 'supportingImmunization',
          prepareData: (data, resources) => {
            const immunization = resources.find((r) => {
              const [referenceType, referenceId] = data?.reference?.split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return immunization?.content?.actor?.display;
          },
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'supportingImmunization',
          prepareData: (data, resources) => {
            const immunization = resources.find((r) => {
              const [referenceType, referenceId] = data?.reference?.split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return immunization?.content?.comment;
          },
        },
      ],
    },
  ],
});
