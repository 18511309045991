export const positiveHealthIntroId = 'positive-health-section-0';

export const positiveHealthQuestionnaireStartItem = {
  minLength: null,
  minValue: null,
  maxValue: null,
  maxDecimalPlaces: null,
  unitOfMeasure: null,
  enableBehaviour: [],
  linkId: positiveHealthIntroId,
  text: 'Start',
  type: 'GROUP',
  required: true,
  enableWhen: null,
  options: null,
  option: null,
  items: [
    {
      minLength: null,
      minValue: null,
      maxValue: null,
      maxDecimalPlaces: null,
      unitOfMeasure: null,
      enableBehaviour: [],
      linkId: 'intro-heading',
      text: 'Vul de vragenlijst Positieve Gezondheid in en krijg inzicht in je gezondheid.',
      type: 'DISPLAY',
      required: true,
      enableWhen: null,
      options: null,
      option: null,
      items: null,
    },
    {
      minLength: null,
      minValue: null,
      maxValue: null,
      maxDecimalPlaces: null,
      unitOfMeasure: null,
      enableBehaviour: [],
      linkId: 'intro-text',
      text:
        'Heb je de vragenlijst afgerond? Kijk dan in het archief bij \'mijn gezondheid\'.' +
        ' Je vindt de resultaten terug in de vorm van een spinnenweb met bijbehorende tabellen per vraag.' +
        ' Opnieuw invullen kan altijd. De laatste twee testen laten zich eenvoudig met elkaar vergelijken.' +
        ' Jij bepaalt met wie je deze gegevens deelt.',
      type: 'DISPLAY',
      required: true,
      enableWhen: null,
      options: null,
      option: null,
      items: null,
    },
  ],
};
