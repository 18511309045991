<ng-container *ngIf="questions?.items">
  <table
    [attr.data-pdfmake]="secondAnswers ? tableOptions1 : tableOptions2"
    class="positive-health-table"
  >
    <thead>
      <tr>
        <th>
          <div class="logo">
            <div
              class="color-background"
              [style.background-color]="getConfig()?.color"
            ></div>
            <div class="logo-background"></div>
            <div class="image">
              <img [src]="'assets/images/svg/' + getConfig()?.svg" alt="icon" />
            </div>
          </div>
        </th>
        <th
          [style.color]="getConfig()?.color"
          class="positive-health-question-title"
        >
          {{ getConfig()?.title }}
        </th>
        <th
          *ngIf="secondAnswers"
          [style.color]="getConfig()?.color"
          class="text-center column"
        >
          {{ secondDate }}
        </th>
        <th [style.color]="getConfig()?.color" class="text-center column">
          {{ firstDate }}
        </th>
        <th [style.color]="getConfig()?.color" class="text-center status">
          Status
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let question of questions?.items; let i = index">
        <td [style.background-color]="getConfig()?.color"></td>
        <td
          [style.background-color]="
            i % 2 === 0 ? getConfig()?.colorLight : '#f4f4f4'
          "
        >
          <span class="positive-health-question-title table-content">{{
            question.text
          }}</span>
        </td>
        <td
          [style.background-color]="
            i % 2 === 0 ? getConfig()?.colorLight : '#f4f4f4'
          "
          class="text-center column"
          *ngIf="secondAnswers"
        >
          <span class="table-content">{{
            getAnswer(secondAnswers?.items, question.linkId)
          }}</span>
        </td>
        <td
          [style.background-color]="
            i % 2 === 0 ? getConfig()?.colorLight : '#f4f4f4'
          "
          class="text-center column"
        >
          <span class="table-content">{{
            getAnswer(firstAnswers?.items, question.linkId)
          }}</span>
        </td>
        <td
          [style.background-color]="
            i % 2 === 0 ? getConfig()?.colorLight : '#f4f4f4'
          "
          class="text-center status"
        >
          <ng-template
            [ngTemplateOutlet]="statusTemplate"
            [ngTemplateOutletContext]="{
              difference: secondAnswers
                ? getAnswer(firstAnswers?.items, question.linkId) -
                  getAnswer(secondAnswers?.items, question.linkId)
                : 0,
              last: false
            }"
          ></ng-template>
        </td>
      </tr>

      <tr>
        <td [style.background-color]="getConfig()?.color"></td>
        <td
          [style.background-color]="getConfig()?.color"
          class="positive-health-question-title"
        >
          <span class="print-white-bold"> Gemiddelde </span>
        </td>
        <td
          [style.background-color]="getConfig()?.color"
          class="text-center column"
          *ngIf="secondAnswers"
        >
          <span class="print-white-bold">
            {{ getAverage(secondAnswers?.items) }}
          </span>
        </td>
        <td
          [style.background-color]="getConfig()?.color"
          class="text-center column"
        >
          <span class="print-white-bold">
            {{ getAverage(firstAnswers?.items) }}
          </span>
        </td>
        <td
          [style.background-color]="getConfig()?.color"
          class="text-center text-nowrap status"
        >
          <ng-template
            [ngTemplateOutlet]="statusTemplate"
            [ngTemplateOutletContext]="{
              difference: getTotalStatus(),
              last: true
            }"
          ></ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-template #statusTemplate let-difference="difference" let-last="last">
  <div class="d-flex align-items-center justify-content-center status-wrapper">
    <span class="status-svg d-flex align-items-center justify-content-center">
      <svg
        *ngIf="difference === 0"
        [attr.width]="svgSize - 2"
        height="4"
        viewBox="0 0 14 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.00732422"
          width="14"
          height="3"
          [attr.fill]="last ? '#FFFFFF' : '#1F98DC'"
        />
      </svg>

      <svg
        *ngIf="difference > 0"
        [class.rotate]="difference < 0"
        [attr.width]="svgSize + 3"
        [attr.height]="svgSize"
        viewBox="0 0 19 16"
        fill="none"
      >
        <path
          d="M9.5 0.00708008L18.5933 15.7571H0.406734L9.5 0.00708008Z"
          [attr.fill]="last ? '#FFFFFF' : '#5BDC1F'"
        />
      </svg>
      <svg
        *ngIf="difference < 0"
        [attr.width]="svgSize + 3"
        [attr.height]="svgSize"
        viewBox="0 0 19 16"
        fill="none"
      >
        <path
          d="M9.5 16L0.406734 0.25H18.5933L9.5 16Z"
          [attr.fill]="last ? '#FFFFFF' : '#DC1F1F'"
        />
      </svg>
    </span>
    <span
      class="status-value table-content"
      [class.print-white-bold]="last"
      [class.status-value-lg]="last"
      >{{ last ? difference.toFixed(2) : difference }}</span
    >
  </div>
</ng-template>
