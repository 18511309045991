<a
  class="nav-link"
  [routerLink]="sideNavItem.link"
  routerLinkActive="active"
  (click)="toggleSideNav()"
  [ngClass]="{ active: isActive || getIsActive(), collapsed: !expanded }"
>
  <div class="zdb-nav-item-icon" *ngIf="sideNavItem.icon">
    <fa-icon [icon]="['fas', sideNavItem.icon]"></fa-icon>
  </div>
  {{ sideNavItem.text }}
  <div class="zdb-sidenav-collapse-arrow">
    <fa-icon
      class="ml-auto"
      *ngIf="sideNavItem.submenu"
      [icon]="['fas', 'angle-down']"
    >
    </fa-icon>
  </div>
</a>
<nav
  class="zdb-sidenav-menu-nested nav"
  *ngIf="sideNavItem.submenu && expanded"
>
  <zdb-sidenav-item
    class="submenu"
    *ngFor="let submenuItem of sideNavItem.submenu"
    [sideNavItem]="submenuItem"
  >
  </zdb-sidenav-item>
</nav>
