import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const societyParticipationBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.observation,
  fieldGroups: [
    {
      name: 'my_doctor_society_participation',
      fields: [
        {
          label: 'my_doctor_work_situation',
          fieldName: 'workSituation',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_leisure_activity',
          fieldName: 'hobby',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_social_network',
          fieldName: 'socialNetwork',
          prepareData: (data) => data,
        },
      ],
    },
  ],
});
