<form *ngIf="parentForm"
      [formGroup]="parentForm"
>
  <ng-container [formArrayName]="formArrayName">
    <ng-container *ngFor="let item of items.controls; let index = index">

      <h5 *ngIf="hasRepeatedForms; else generalTitleTpl"
          class="font-weight-bold pt-3 mb-4"
      >
        {{ repeatedTitleWithIndex(index) }}

        <medrecord-svg-cross-rounded (click)="removeItem(index)"
                                     [width]="24"
                                     class="mr-2"
                                     crossType="secondary"
                                     type="mystic"
        ></medrecord-svg-cross-rounded>
      </h5>

      <ng-container [ngTemplateOutletContext]="{ index: index, formGroup: item }"
                    [ngTemplateOutlet]="formTemplate"></ng-container>

    </ng-container>

    <ng-container *ngTemplateOutlet="afterFormsList"></ng-container>
  </ng-container>
</form>

<ng-template #generalTitleTpl>
  <h5 class="font-weight-bold pt-3 mb-4">
    {{ generalTitle }}
  </h5>
</ng-template>

<ng-template #afterFormsList>
  <button (click)="addItem()"
          class="btn btn-success font-weight-bold pb-2 pl-2 pt-2 rounded-pill d-flex align-items-center"
  >
    <medrecord-svg-plus-rounded [width]="24"
                                class="mr-2"
                                plusType="success"
                                type="white"
    ></medrecord-svg-plus-rounded>

    <span class="font-weight-bold text-sm">
        {{ addMoreButtonTitle }}
    </span>
  </button>
</ng-template>