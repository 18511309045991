import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { MyDoctorManagerService } from '../services/my-doctor-manager.service';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentID } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import {
  loadCareprovidersAction,
  loadCareprovidersSuccessAction,
  loadCareprovidersFailureAction,
  loadCareproviderAction,
  loadCareproviderSuccessAction,
  loadResourcesAction,
  loadResourcesSuccessAction,
  loadResourcesFailureAction,
  fetchCareproviderDataAction,
  performAuthorizationForCareproviderAction,
  fetchCareproviderDataSuccessAction,
  fetchCareproviderDataFailureAction,
  loadLogsAction,
  loadLogsSuccessAction,
  loadLogsFailureAction,
  loadListAction,
  loadListSuccessAction,
  loadListFailureAction,
  downloadPortabilityReportAction
} from './my-doctor-manager.actions';
import { forkJoin, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MY_DOCTOR_MANAGER_CONSTANTS } from '../my-doctor-manager.tokens';
import { MyDoctorManagerConstants } from '../constants';
import { getActiveCareprovider } from './my-doctor-manager.selectors';
import { Router } from '@angular/router';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { MyDoctorRouteNames, MY_DOCTOR_ROUTE_NAMES } from '../constants/routes';
import { AttachmentsService } from '../services/attachments.service';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';
import { APP_ENVIRONMENT } from '@medrecord/core';

@Injectable()
export class MyDoctorManagerEffects {
  
  @Effect()
  loadCareproviders$ = this.actions$.pipe(
    ofType(loadCareprovidersAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ payload }, userId]) => {
      return forkJoin({
        careproviders: this.service.loadCareproviders(
          userId,
          !this.environment.production &&
            payload.startPage === 0 &&
            this.myDoctorConstants.TEST_PROVIDER_ID.includes(payload.search)
            ? { ...payload, count: payload.count - 1 }
            : { ...payload }
        ),
        count: this.service.loadCareprovidersCount(userId, payload.search),
      }).pipe(
        switchMap(({ careproviders, count }) => {
          return [
            loadCareprovidersSuccessAction({
              careproviders: [
                ...(!this.environment.production &&
                payload.startPage === 0 &&
                this.myDoctorConstants.TEST_PROVIDER_ID.includes(payload.search)
                  ? [this.myDoctorConstants.TEST_PROVIDER]
                  : []),
                ...(careproviders as any).careProviders,
              ],
              count:
                count +
                (!this.environment.production &&
                payload.startPage === 0 &&
                this.myDoctorConstants.TEST_PROVIDER_ID.includes(payload.search)
                  ? 1
                  : 0),
            }),
          ];
        }),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_careproviders_error'), error)),
          loadCareprovidersFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  loadResources$ = this.actions$.pipe(
    ofType(loadResourcesAction),
    withLatestFrom(this.store.select(selectCurrentID), this.store.select(getActiveCareprovider)),
    mergeMap(([{ resourceKeys }, userId, careprovider]) => {
      return this.service.loadResources(userId, careprovider.id, resourceKeys).pipe(
        switchMap((resources) => [loadResourcesSuccessAction({ resources: resources.data })]),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_resources_error'), error)),
          loadResourcesFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  loadCareprovider$ = this.actions$.pipe(
    ofType(loadCareproviderAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ careproviderId }, userId]) => {
      return (!this.environment.production && this.myDoctorConstants.TEST_PROVIDER_ID === careproviderId
        ? of(this.myDoctorConstants.TEST_PROVIDER)
        : this.service.loadCareprovider(userId, careproviderId)
      ).pipe(
        switchMap((careprovider) => {
          return [
            loadCareproviderSuccessAction({
              careprovider,
            }),
          ];
        }),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_careproviders_error'), error)),
          loadCareprovidersFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  loadLogs$ = this.actions$.pipe(
    ofType(loadLogsAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ careproviderId }, userId]) =>
      this.service.loadLogs(userId, careproviderId).pipe(
        switchMap((logs) => {
          const errorSubstringMap = {
            'HTTP 400': this.translateService.instant('healthcare_providers_error_400'),
            'HTTP 401': this.translateService.instant('healthcare_providers_no_information_provided_error'),
            'HTTP 500': this.translateService.instant('healthcare_providers_no_information_provided_error'),
            SocketTimeoutException: this.translateService.instant('healthcare_providers_no_information_provided_error'),
            DataFormatException: this.translateService.instant('healthcare_providers_no_information_provided_error'),
          };

          logs = logs.map((responseItem) => {
            return {
              date: MedrecordMoment(new Date(responseItem.requestTime).toISOString()).format('DD MMM YY, HH:mm'),
              items: responseItem.results.map((test) => {
                const errorMessageResultArr = Object.entries(errorSubstringMap).find(([subsctring]) => {
                  return test.errorMessage && test.errorMessage.toLowerCase().includes(subsctring.toLowerCase());
                });

                return {
                  id: test.resourceId,
                  name: this.translateService.instant(test.key),
                  count: test.countMain,
                  error: test.error,
                  errorHttpCode: test.errorHttpCode,
                  errorMessage:
                    test.errorMessage && errorMessageResultArr
                      ? errorMessageResultArr[1]
                      : this.translateService.instant('healthcare_providers_no_information_provided_error'),
                  issues: test.errorIssues,
                };
              }),
            };
          });

          return [loadLogsSuccessAction({ logs })];
        }),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_logs_error'), error)),
          loadLogsFailureAction({ error }),
        ])
      )
    )
  );

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(loadListAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ listType }, userId]) =>
      this.service.loadList(userId, listType).pipe(
        switchMap((data) => {
          return [loadListSuccessAction({ data })];
        }),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_list_error'), error)),
          loadListFailureAction({ error }),
        ])
      )
    )
  );

  @Effect()
  fetchCareproviderData$ = this.actions$.pipe(
    ofType(fetchCareproviderDataAction),
    withLatestFrom(this.store.select(selectCurrentID), this.store.select(getActiveCareprovider)),
    mergeMap(([{ payload, dataserviceId, transactionId }, userId, careprovider]) => {
      return this.service.fetchCareproviderData(userId, careprovider.id, payload).pipe(
        switchMap((resourcesResponse) => {
          if (careprovider.id !== this.myDoctorConstants.TEST_PROVIDER_ID) {
            if ((resourcesResponse as any).resources.find((r) => r.error && r.errorHttpCode === 401)) {
              if (!this.service.perforemedAuthorizationOnce) {
                return [
                  performAuthorizationForCareproviderAction({ dataserviceId, refetchPayload: payload, transactionId }),
                ];
              } else {
                this.service.perforemedAuthorizationOnce = false;
                const error = (resourcesResponse as any).resources.find((r) => r.error && r.errorHttpCode === 401);
                return [
                  addErrorToast(getErrorToastBodyUtil(this.translateService.instant('fetch_data_error'), error)),
                  fetchCareproviderDataFailureAction({ error }),
                ];
              }
            }
            this.service.perforemedAuthorizationOnce = false;
            this.router.navigate([
              this.myDoctorRouteNames.MyDoctor.MijnDoktor,
              this.myDoctorRouteNames.MyDoctor.Careprovider,
              careprovider.id,
              dataserviceId,
              transactionId,
              this.myDoctorRouteNames.MyDoctor.Fetch,
            ]);
            return [fetchCareproviderDataSuccessAction({ data: resourcesResponse })];
          } else if ((resourcesResponse as any).resources.find((r) => r.error && r.errorHttpCode === 401)) {
            const error = (resourcesResponse as any).resources.find((r) => r.error && r.errorHttpCode === 401);
            return [
              addErrorToast(getErrorToastBodyUtil(this.translateService.instant('fetch_data_error'), error)),
              fetchCareproviderDataFailureAction({ error }),
            ];
          }
          this.router.navigate([
            this.myDoctorRouteNames.MyDoctor.MijnDoktor,
            this.myDoctorRouteNames.MyDoctor.Careprovider,
            careprovider.id,
            dataserviceId,
            transactionId,
            this.myDoctorRouteNames.MyDoctor.Fetch,
          ]);
          return [fetchCareproviderDataSuccessAction({ data: resourcesResponse })];
        })
      );
    })
  );

  @Effect()
  performAuthorizationForCareprovider$ = this.actions$.pipe(
    ofType(performAuthorizationForCareproviderAction),
    withLatestFrom(this.store.select(selectCurrentID), this.store.select(getActiveCareprovider)),
    mergeMap(([{ dataserviceId, refetchPayload, transactionId }, userId, careprovider]) => {
      const successCallBack = () => {
        this.service.perforemedAuthorizationOnce = true;
        this.store.dispatch(
          addSuccessToast({
            title: 'common_success',
            content: '',
          })
        );

        this.store.dispatch(fetchCareproviderDataAction({ dataserviceId, transactionId, payload: refetchPayload }));
      };
      const failureCallBack = () => {
        this.store.dispatch(
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('fetch_data_error'), null))
        );
      };

      window.addEventListener(this.myDoctorConstants.digIdSuccess, successCallBack);
      window.addEventListener(this.myDoctorConstants.digIdFailure, failureCallBack);
      return this.service.loadDigIdUrl(userId, careprovider.id, dataserviceId).pipe(
        switchMap((data) => {
          // Medsafe app is used as middleware to authorize with medmij
          const newWin = window.open(
            `${this.environment.medmijRedirectUri}?medmijRedirectUri=${encodeURI(
              window.location.protocol + '//' + window.location.host + '/mijn-doktor/auth-back'
              )}&authEndpointUrl=${encodeURIComponent(data.authEndpointUrl)
            }`,
            'digIdCheck',
            'width=900,height=700'
          );
          const interval = setInterval(() => {
            if (newWin.closed) {
              window.removeEventListener(this.myDoctorConstants.digIdSuccess, successCallBack);
              window.removeEventListener(this.myDoctorConstants.digIdFailure, failureCallBack);
              clearInterval(interval);
            }
          }, 100);
          return [];
        }),
        catchError((error: any) => {
          this.service.perforemedAuthorizationOnce = false;
          return [
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('fetch_data_error'), error?.error || error)
            ),
            fetchCareproviderDataFailureAction({ error }),
          ];
        })
      );
    })
  );

  @Effect({ dispatch: false })
  downloadPortabilityReport$ = this.actions$.pipe(
    ofType(downloadPortabilityReportAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ startDate, endDate }, userId]) =>
      this.service.getPortabilityReport(userId, startDate, endDate).pipe(
        switchMap(({ content }) => {
          this.attachmentsService.downloadFile('data:application/xml,' + content, 'report.xml');
          return [];
        }),
        catchError((error: any) => {
          this.store.dispatch(
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('report_download_error'), error?.error || error)
            )
          );
          return [];
        })
      )
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private service: MyDoctorManagerService,
    private attachmentsService: AttachmentsService,
    private translateService: TranslateService,
    private router: Router,

    @Inject(MY_DOCTOR_ROUTE_NAMES) private myDoctorRouteNames: MyDoctorRouteNames,
    @Inject(MY_DOCTOR_MANAGER_CONSTANTS) private myDoctorConstants: MyDoctorManagerConstants,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {}
}
