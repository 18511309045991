<div class="mb-3 resource-group">
  <div
    class="title px-4 py-3 d-flex align-items-center"
    (click)="isExpanded = !isExpanded"
  >
    <div class="chevron">
      <medrecord-svg-chevron-right
        *ngIf="!isExpanded"
        icon
        width="9"
      ></medrecord-svg-chevron-right>
      <medrecord-svg-chevron-down
        *ngIf="isExpanded"
        icon
        width="14"
      ></medrecord-svg-chevron-down>
    </div>
    <span class="text-primary text-lg text-weight-600 ml-2">{{
      resourceGroup.group | translate
    }}</span>
    <button
      (click)="triggerGetData($event)"
      class="get-data-btn button btn btn-success font-weight-bold"
      type="button"
    >
      {{ "my_doctor_get_data" | translate | titlecase }}
    </button>
  </div>
  <ng-container *ngIf="isExpanded">
    <ng-container *ngFor="let resource of resourceGroup.resources">
      <doctor-gui-medsafe-resource
        [isGroupedResource]="true"
        [resource]="resource"
      ></doctor-gui-medsafe-resource>
    </ng-container>
  </ng-container>
</div>
