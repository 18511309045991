import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AlertModalService } from '@app/core/alert-modal/alert-modal.service';
import { ConfirmationDialogService } from '@app/core/confirmation-dialog/confirmation-dialog.service';
import { IOrganizationWithExtraInfo } from '@app/network/network.model';
import { OrganizationsService } from '@app/network/services/organizations-service.service';
import { selectUserId } from '@medrecord/managers-auth';
import { addErrorToast } from '@medrecord/tools-toast';


@Component({
  selector: 'zdb-organizations-network',
  templateUrl: './organizations-network.component.html',
  styleUrls: ['./organizations-network.component.scss'],
  providers: [ OrganizationsService ]
})
export class OrganizationsNetworkComponent implements OnInit {
  userId$: Observable<string>;
  userIsDoctor$: Observable<boolean>;
  organizations$: Observable<IOrganizationWithExtraInfo[]>;

  constructor(
    public orgService: OrganizationsService,
    private alertModalService: AlertModalService,
    private confirmationDialogService: ConfirmationDialogService,
    public store: Store,
    public translateService: TranslateService) {}

  ngOnInit(): void {
    this.userId$ = this.store.select(selectUserId);
    this.organizations$ = this.orgService.fetchCurrentUserOrganizations();
  }

  onConnectToOrganization(orgId: string, userId: string, orgName: string) {
    const title = this.translateService.instant('org_network.connect_confirmation_title', { orgName });
    const message = this.translateService.instant('org_network.connect_confirmation_msg', { orgName });
    const btnOkText = this.translateService.instant('org_network.connect_confirmation_btn_ok');
    const btnCancelText = this.translateService.instant('org_network.connect_confirmation_btn_cancel');
    const btnStyle = 'btn-primary-button';

    this.confirmationDialogService.confirm({
      title,
      message,
      btnOkText,
      btnCancelText,
      btnStyle,
      dialogSize: 'md',
    })
      .then(
        (confirmed) => {
          if (confirmed) {
            this.orgService.addUserToOrgganization(orgId, userId).subscribe(
              () => {
                this.organizations$ = this.orgService.fetchCurrentUserOrganizations();
                this.onConnectToOrganizationSuccessfull(orgName);
              },
              () => this.store.dispatch(addErrorToast({
                title: this.translateService.instant('auth_errors.something_went_wrong'),
                content: '',
              }))
            );
          } // no else, because the cancel button doesn't have side effects
        },
        // Modal closed, do nothing when Promise is rejected by closing
        () => {} // tslint:disable-line:no-empty
      );
  }

  onConnectToOrganizationSuccessfull(orgName: string) {
    const title = this.translateService.instant('org_network.connect_successfull_title');
    const msg = this.translateService.instant('org_network.connect_successfull_msg', { orgName });
    const btnOkText = this.translateService.instant('org_network.connect_successfull_btn_ok');
    // no then because the alert doesn't have side effects
    this.alertModalService.alert(title, msg, btnOkText, 'md');
  }

  onDisconnectFromOrganization(orgId: string, userId: string, orgName: string) {
    const title = this.translateService.instant('org_network.disconnect_confirmation_title', { orgName });
    const message = this.translateService.instant('org_network.disconnect_confirmation_msg', { orgName });
    const btnOkText = this.translateService.instant('org_network.disconnect_confirmation_btn_ok');
    const btnCancelText = this.translateService.instant('org_network.disconnect_confirmation_btn_cancel');
    const btnStyle = 'btn-danger';

    this.confirmationDialogService.confirm({
      title,
      message,
      btnOkText,
      btnCancelText,
      btnStyle,
      dialogSize: 'md',
    })
      .then(
        (confirmed) => {
          if (confirmed) {
            this.orgService.removeUserFromOrgganization(orgId, userId).subscribe(
              () => {
                this.organizations$ = this.orgService.fetchCurrentUserOrganizations();
                this.onDisconnectFromOrganizationSuccessfull(orgName);
              },
              () => this.store.dispatch(addErrorToast({
                title: this.translateService.instant('auth_errors.something_went_wrong'),
                content: '',
              }))
            );
          } // no else, because the cancel button doesn't have side effects
        },
        // Modal closed, do nothing when Promise is rejected by closing
        () => {} // tslint:disable-line:no-empty
      );
  }

  onDisconnectFromOrganizationSuccessfull(orgName: string) {
    const title = this.translateService.instant('org_network.disconnect_successfull_title');
    const msg = this.translateService.instant('org_network.disconnect_successfull_msg', { orgName });
    const btnOkText = this.translateService.instant('org_network.disconnect_successfull_btn_ok');
    // no then because the alert doesn't have side effects
    this.alertModalService.alert(title, msg, btnOkText, 'sm');
  }

}
