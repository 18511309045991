import { FormGroup } from '@angular/forms';

export class MedrecordFormComponentBase {
  form: FormGroup;
  
  /**
   * @desc This method is called when form is submitted
   * It marks all fields as touched and prevents the form submission if it's
   * invalid
   *
   * @param event - form submit event
   */
  handleFormSubmit(event: Event): void {
    event.preventDefault();
    
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.onFormSubmit(this.form.value);
    }
  }
  
  /**
   * @desc This method is called when valid form was submitted
   *
   * @param formValue
   */
  onFormSubmit(formValue: any): void {
    return formValue;
  }
}
