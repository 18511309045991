import { TranslateService } from '@ngx-translate/core';

export const getUnitsOfMeasure = ({ unitOfMeasure }, translateService: TranslateService) => {
  if (!unitOfMeasure) {
    return null;
  }

  const { code, display } = unitOfMeasure;

  if (display) {
    return display;
  }

  const translationKey = `units_of_measure_${code}`;
  const translation = translateService.instant(translationKey);

  if (translation === translationKey) {
    return code;
  }

  return translation;
};

export const getDueDate = (date: Date, effectivePeriod: any) => {
  if (!date || !effectivePeriod) {
    return null;
  }

  // TODO: add merging with effectivePeriod
  return date;
};
