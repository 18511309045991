import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medrecord-questions-list-item',
  templateUrl: './questions-list-item.component.html',
  styleUrls: ['./questions-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionsListItemComponent {
  @Input() question: string;
  @Input() completed: boolean;
  @Input() visited: boolean;
  @Input() selected: boolean;
  @Input() idk: boolean;

  constructor(private translateService: TranslateService) {
  }

  get questionItemTitle() {
    return this.question || this.translateService.instant('no_information');
  }

  get getFontSize() {
    return this.questionItemTitle.length <= 13 ? 14 : 11;
  }
}
