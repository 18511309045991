<zdb-main-layout>
  <zdb-card>
     <!-- Panel Header component -->
     <div class="card-header">
      <div
        style="
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
        "
      >
        <div style="display: flex; flex: 1 1 auto">
          <h2 class="card-title">
            {{ "patient_profile.title" | translate }}
          </h2>
        </div>
        <div style="display: flex; flex: 0 1 auto; align-self: flex-end">
          <button
            class="btn btn-lg btn btn-link"
            [routerLink]="'edit'"
          >
            <span class="badge badge-pill">
              <fa-icon [icon]="['fas', 'edit']"></fa-icon>
            </span>
            {{ "patient_profile.edit_button" | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- Panel Header component -->
    <div class="card-body">
      <ng-container *ngIf="userId$ | async as userId">
        <zdb-user-details [userId]="userId"></zdb-user-details>
      </ng-container>
    </div>
  </zdb-card>
</zdb-main-layout>
