import { QuestionType } from '../enums/question-type.enum';
import { Question } from '../interfaces/question.interface';
import { isEqual, get } from 'lodash';

export const isQuestionAnswered = (answerTexted: any, questionType: QuestionType): boolean => {
  const answer = answerTexted?.answer;
  if (questionType === QuestionType.display) {
    return true;
  }

  if (answer === undefined || answer === null) {
    return false;
  }

  switch (questionType) {
    case QuestionType.singleChoice:
      return answer?.length > 0;

    case QuestionType.date:
      return !!answer;

    case QuestionType.string:
      return answer?.length > 0;

    case QuestionType.boolean:
      return typeof answer === 'boolean';

    case QuestionType.decimal: {
      const decimal = parseInt(answer, 10);
      return !isNaN(decimal);
    }
  }

  return true;
};

export const getQuestionCompletionStatus = (question: Question, answers: any) => {
  if (!question.subQuestions) {
    const answered = question.isEnabled ? isQuestionAnswered(answers[question.id], question.type) : true;

    return {
      allAnswered: answered,
      requiredAnswered: question.required ? answered : true
    };
  }

  return question.subQuestions
    .filter(subQuestion => subQuestion.type !== QuestionType.display && subQuestion.isEnabled)
    .map(({ id, type, required }) => ( {
      answered: isQuestionAnswered(answers[id], type),
      required
    } ))
    .reduce(
      ({ allAnswered, requiredAnswered }, { answered, required }) => ( {
        allAnswered: allAnswered && answered,
        requiredAnswered: required ? requiredAnswered && answered : requiredAnswered
      } ),
      {
        allAnswered: true,
        requiredAnswered: true
      }
    );
};

export const getInitialEnabledState = (questions: Question[], answers: any) => {
  let questionsWithEnabledState = [...questions];

  let isUpdated = true;
  let maxLoopAllowed = 1000; // arbitrarily set
  while (isUpdated && maxLoopAllowed > 0) {
    isUpdated = false;
    maxLoopAllowed--;

    const flatQuestions = flatQuestionsTree(questionsWithEnabledState);

    questionsWithEnabledState = questionsWithEnabledState.map(question => {
      const questionEnabledState = getQuestionEnabledState(question, flatQuestions, answers);
      if (questionEnabledState.isIsEnabledUpdated) {
        isUpdated = true;
      }
      return questionEnabledState.question;
    });
  }

  if (maxLoopAllowed === 0) {
    console.error(
      'Questions dependency tree is too complex to be handled! ' +
      'Questions that should be visible might be hidden, and vice versa.'
    );
  }

  return questionsWithEnabledState;
};

export const flatQuestionsTree = (questions: Question[]): {[id: string]: boolean} => {
  let flat = {};
  for (const question of questions) {
    flat[question.id] = question.isEnabled;
    if (!!question.subQuestions) {
      flat = {
        ...flat,
        ...flatQuestionsTree(question.subQuestions),
      };
    }
  }

  return flat;
};

export const getSubquestions = (questions: Question[]): {[id: string]: any} => {
  let subquestions = {};
  for (const question of questions) {
    if (question.type !== QuestionType.group) {
      subquestions[question.id] = question;
    }
    if (!!question.subQuestions) {
      subquestions = {
        ...subquestions,
        ...getSubquestions(question.subQuestions),
      };
    }
  }

  return subquestions;
};

export const getQuestionEnabledState = (question: Question, questions: {[id: string]: boolean}, answers) => {
  let isUpdated = false;

  let subQuestions: Question[];
  if (question.type === QuestionType.group) {
    subQuestions = question.subQuestions.map(subQuestion => {
      const oldIsEnabledSubQuestion = subQuestion.isEnabled;
      const newIsEnabledSubQuestion = isQuestionEnabled(subQuestion, questions, answers);
      if (oldIsEnabledSubQuestion !== newIsEnabledSubQuestion) {
        isUpdated = true;
      }
      return ({
        ...subQuestion,
        isEnabled: newIsEnabledSubQuestion,
      });
    });
  } else {
    subQuestions = undefined;
  }

  const oldIsEnabled = question.isEnabled;
  const newIsEnabled = isQuestionEnabled(question, questions, answers);
  if (oldIsEnabled !== newIsEnabled) {
    isUpdated = true;
  }
  const questionEnabledState = {
    ...question,
    ...{
      subQuestions
    },
    isEnabled: newIsEnabled
  };

  return {
    question: questionEnabledState,
    isIsEnabledUpdated: isUpdated,
  };
};

export const removeAnswersForDisabledQuestions = (questions: Question[], answers: any) => {
  const cleanedAnswers = { ...answers };

  questions.forEach(question => {
    if (!question.isEnabled) {
      answers[question.id] = undefined;
    }

    if (question.type === QuestionType.group) {
      question.subQuestions.forEach(subQuestion => {
        if (!subQuestion.isEnabled) {
          answers[subQuestion.id] = undefined;
        }
      });
    }
  });

  return cleanedAnswers;
};

export const isQuestionEnabled = (question: Question, questions: {[id: string]: boolean}, answers: any) => {
  if (!question.enableWhen) {
    return true;
  }

  const conditions = [];
  for (const condition of question.enableWhen) {
    if (questions[condition.linkId] === false) {
      return false;
    }
    const { type } = condition;
    const answerKey = `answer${ type.slice(0, 1).toUpperCase() }${ type.slice(1).toLowerCase() }`;
    const currentAnswer = answers[condition.linkId]?.answer;

    conditions.push({
      answerStateSatisfied: getAnswerStateSatisfied(condition, answerKey, currentAnswer),
      shouldHaveAnswer: condition.hasAnswer,
      hasAnswer: currentAnswer !== undefined && currentAnswer !== null
    });
  }

  return conditions.reduce(
    (enabled, condition) => enabled && isConditionSatisfied(condition),
    true
  );
};

export const getAnswerStateSatisfied = (condition, answerKey, currentAnswer) => {
  if (answerKey !== 'answerChoice') {
    return condition[answerKey] === currentAnswer;
  }

  const { answerCoding } = condition;
  const currentAnswerCoding = get(currentAnswer, '[0].answerCoding', null);

  return isEqual(answerCoding, currentAnswerCoding);
};

export const isConditionSatisfied = ({ shouldHaveAnswer, hasAnswer, answerStateSatisfied }) => {
  if (shouldHaveAnswer === null) {
    return answerStateSatisfied;
  }

  if (shouldHaveAnswer) {
    if (!hasAnswer) {
      return false;
    }

    return answerStateSatisfied;
  }

  if (hasAnswer) {
    return answerStateSatisfied;
  }
  return true;
};
