import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const legalStatusBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.patient,
  fieldGroups: [
    {
      name: 'my_doctor_legal_status',
      fields: [
        {
          label: 'my_doctor_status',
          fieldName: 'legalStatus',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
      ],
    },
  ],
});
