<div [class]="isGroupedResource ? 'resource-grouped' : 'mb-3 resource'">
  <div
    [class.title]="!isGroupedResource"
    [class.title-grouped]="isGroupedResource"
    class="px-4 py-3 d-flex align-items-center"
    (click)="isExpanded = !isExpanded"
  >
    <div class="chevron">
      <medrecord-svg-chevron-right
        [type]="isGroupedResource ? 'black' : 'primary'"
        *ngIf="!isExpanded"
        icon
        width="9"
      ></medrecord-svg-chevron-right>
      <medrecord-svg-chevron-down
        [type]="isGroupedResource ? 'black' : 'primary'"
        *ngIf="isExpanded"
        icon
        width="14"
      ></medrecord-svg-chevron-down>
    </div>
    <span
      [class.text-primary]="!isGroupedResource"
      [class.text-black]="isGroupedResource"
      class="text-lg text-weight-600 ml-2"
    >
      {{ resource.key | translate }}
    </span>
    <button
      *ngIf="!isGroupedResource"
      class="get-data-btn button btn btn-success font-weight-bold"
      type="button"
      (click)="triggerGetData($event)"
    >
      {{ "my_doctor_get_data" | translate | titlecase }}
    </button>
  </div>
  <ng-container *ngIf="isExpanded">
    <ng-container *ngIf="resourceType && mainResources?.length; else noData">
      <ng-container
        *ngFor="
          let mainResourceData of mainResources;
          let index = index;
          let last = last
        "
      >
        <doctor-gui-medsafe-resource-field-group
          *ngFor="let fieldGroup of resourceFieldGroups"
          [fieldGroup]="fieldGroup"
          [resourceType]="resourceType"
          [resource]="resource"
          [resourceIndex]="index"
          [mainResourceData]="mainResourceData"
        ></doctor-gui-medsafe-resource-field-group>
      </ng-container>
    </ng-container>
    <ng-template #noData>
      <div class="row">
        <div class="col-12 my-5 d-flex justify-content-center">
          {{ "my_doctor_no_data" | translate }}
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
