import { Component } from '@angular/core';
import { QuestionnairePageComponent } from '../questionnaire-page/questionnaire-page.component';

@Component({
  selector: 'medrecord-readonly-questionnaire-page',
  templateUrl: '../questionnaire-page/questionnaire-page.component.html',
  styleUrls: ['../questionnaire-page/questionnaire-page.component.scss']
})
export class ReadonlyQuestionnairePageComponent extends QuestionnairePageComponent {
  public isReadonly = true;
}
