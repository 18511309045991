import { createAction, props } from '@ngrx/store';
import { Questionnaire } from '../interfaces/questionnaire.interface';
import { SaveModalType } from '../enums/save-modal-type.enum';

export const questionnaireLoaded = createAction(
  '[Questionnaires] Questionnaire loaded',
  props<{
    id: string;
    questionnaire: Questionnaire;
  }>()
);

export const selectQuestion = createAction(
  '[Questionnaires] Question selected',
  props<{
    questionId: string;
    questionnaireId: string;
  }>()
);

export const selectPrevQuestion = createAction(
  '[Questionnaires] Select previous question',
  props<{
    questionnaireId: string;
  }>()
);

export const selectNextQuestion = createAction(
  '[Questionnaires] Select next question',
  props<{
    questionnaireId: string;
  }>()
);

export const saveQuestionAnswer = createAction(
  '[Questionnaires] Save answer',
  props<{
    questionnaireId: string;
    questionId: string;
    answer: any;
  }>()
);

export const finishQuestionnaire = createAction(
  '[Questionnaires] Finish questionnaire',
  props<{
    questionnaireId: string;
    routeSnapshot: any;
  }>()
);

export const clearQuestionnaire = createAction(
  '[Questionnaires] Clear Questionnaire',
  props<{
    questionnaireId: string;
  }>()
);

export const showSaveModal = createAction(
  '[Questionnaires] Show Save Modal',
  props<{
    questionnaireId: string;
    doctor: string,
    title: string,
    scope: string,
    routeSnapshot: any;
    saveModalType: SaveModalType;
  }>()
);

export const setIDKForQuestion = createAction(
  '[Questionnaires] Set IDK for question',
  props<{
    questionnaireId: string;
    questionId: string;
    answer: string
  }>()
);

export const saveQuestionnaire = createAction(
  `ZDB Save Questionnaire`,
  props<{
    questionnaireId: string;
    scope: string;
    routeSnapshot?: any;
  }>()
);

export const autoSaveQuestionnaire = createAction(
  `ZDB Auto Save Questionnaire`,
  props<{
    questionnaireId: string;
    questionId: string;
    scope: string;
  }>()
);
