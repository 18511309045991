import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyDoctorRoutingModule } from './my-doctor-routing.module';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { TranslateModule } from '@ngx-translate/core';
import { MyDoctorWrapperComponent } from './containers/my-doctor-wrapper/my-doctor-wrapper.component';
import { SearchComponent } from './components/search/search.component';
import { GridModule, PageService } from '@syncfusion/ej2-angular-grids';
import { CareproviderServicesComponent } from './components/careprovider-services/careprovider-services.component';
import { MedrecordUiModule } from '@medrecord/ui';
import { ServiceResourcesComponent } from './components/service-resources/service-resources.component';
import { ResourceComponent } from './components/resource/resource.component';
import { MedrecordIconsModule } from '@medrecord/icons';
import { ResourceFieldGroupComponent } from './components/resource-field-group/resource-field-group.component';
import { MyDoctorResourceMappingService } from '@medrecord/managers-my-doctor';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { BackIconButtonComponent } from './components/back-icon-button/back-icon-button.component';
import { SvgMedmijVerifiedComponent } from './components/svg-medmij-verified/svg-medmij-verified.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import {
  myDoctorRouteNames,
  provideMyDoctorRouteNames,
} from './constants/routes';
import { LogsModalComponent } from './components/logs-modal/logs-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TestCareproviderModalComponent } from './components/test-careprovider-modal/test-careprovider-modal.component';
import { ListModalComponent } from './components/list-modal/list-modal.component';
import { FetchResourceResponseComponent } from './components/fetch-resource-response/fetch-resource-response.component';
import { MedmijAuthBackComponent } from './components/medmij-auth-back/medmij-auth-back.component';
import { ResourceGroupComponent } from './components/resource-group/resource-group.component';
import { PortabilityReportModalComponent } from './components/portability-report-modal/portability-report-modal.component';
import { ParametersModalComponent } from './components/parameters-modal/parameters-modal.component';
import { MedrecordFormsModule } from '@medrecord/components/forms';
import { ResourceFieldMultipleComponent } from './components/resource-field-multiple/resource-field-multiple.component';

const components = [
  MyDoctorWrapperComponent,
  SearchComponent,
  CareproviderServicesComponent,
  ServiceResourcesComponent,
  ResourceComponent,
  ResourceFieldGroupComponent,
  BackIconButtonComponent,
  SvgMedmijVerifiedComponent,
  PaginationComponent,
  TestCareproviderModalComponent,
  LogsModalComponent,
  ListModalComponent,
  FetchResourceResponseComponent,
  MedmijAuthBackComponent,
  ResourceGroupComponent,
  PortabilityReportModalComponent,
  ParametersModalComponent,
  ResourceFieldMultipleComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TabModule,
    GridModule,
    TranslateModule,
    MyDoctorRoutingModule,
    MedrecordUiModule,
    MedrecordIconsModule,
    DialogModule,
    DropDownListModule,
    ReactiveFormsModule,
    MedrecordFormsModule,
  ],
  declarations: [...components],
  providers: [
    PageService,
    MyDoctorResourceMappingService,
    provideMyDoctorRouteNames(myDoctorRouteNames.MyDoctor.Entry),
  ],
})
export class MyDoctorModule {}
