import {Component, EventEmitter, HostBinding, Input, Optional, Output, Self, ViewChild} from '@angular/core';
import * as Core from '@angular/core';
import {NgControl} from '@angular/forms';
import * as Forms from '@angular/forms';

@Component({
  selector: 'app-radio', // tslint:disable-line
  styleUrls: ['./radio.component.scss'],
  templateUrl: './radio.component.html'
})
export class RadioComponent implements Forms.ControlValueAccessor, Core.OnInit {
  @ViewChild('radio', {static: true})
  inputElementRef: Core.ElementRef;

  @HostBinding('class.form-control-styles') get control() {
    return this.ngControl;
  }

  @Input()
  defaultValue;

  @Input()
  labelDescription: string;

  @Input()
  description: string;

  @Input()
  hint = '';

  @Output()
  valueChanged = new EventEmitter<boolean>();

  readonly iconTypes: any = {};

  isSelected = false;

  @Input()
  disabled = false;

  private readonly controlName = `checkbox-${ Math.floor(Math.random() * 100000000 )}`;

  // tslint:disable:no-empty
  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.defaultValue !== undefined) {
      this.isSelected = this.defaultValue;
    }
  }

  get inputId() {
    return this.controlName;
  }

  select() {
    if (this.disabled) {
      return;
    }

    this.isSelected = true;

    this.valueChanged.emit(this.isSelected);
    this.onChange(this.isSelected);
    this.onTouched();
  }

  // ControlValueAccessor implementations

  writeValue(value) {
    this.isSelected = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(disabled) {
    this.disabled = disabled;
  }
}
