import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromUserData from './state/user-data.reducers';
import { UserDataEffects } from './state/user-data.effects';
import { UserService } from './user.service';

@NgModule({
  imports: [
    StoreModule.forFeature(fromUserData.userDataFeatureKey, fromUserData.reducer),
    EffectsModule.forFeature([UserDataEffects])
  ],
  providers: [
    UserService,
  ],
})
export class UserDataModule {}
