<div class="form-group">
  <medrecord-label [for]="id"
                   [hint]="hint"
                   [secondaryText]="secondaryLabel"
                   [text]="label"
                   [type]="labelType"
  ></medrecord-label>

  <div class="input-group">
    <select
      #input="ngModel"
      (blur)="checkIfTouched()"
      [(ngModel)]="value"
      [id]="id"
      [name]="control?.name"
      class="form-control"
    >
      <option *ngIf="(mandatorySelect && !value) || (!mandatorySelect)"
              [ngValue]="null"
              disabled
              selected>
        {{ placeholder }}
      </option>
      <option *ngFor="let item of items" [value]="item.value || item.id">
        {{ item.label || item.text }}
      </option>
    </select>

    <div class="input-group-append">
      <span class="input-group-text">
        <medrecord-svg-chevron-down [width]="11" type="primary"></medrecord-svg-chevron-down>
      </span>
    </div>
  </div>
</div>