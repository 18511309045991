import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { selectRoles } from '@medrecord/managers-auth';
import { map } from 'rxjs/operators';
import { Role } from '@medrecord/managers-users';

@Component({
  selector: 'zdb-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
  providers: [ ]
})
export class NetworkComponent implements OnInit {
  userIsDoctor$: Observable<boolean>;

  constructor(
    public store: Store,
    public translateService: TranslateService) {}

  ngOnInit(): void {
    this.userIsDoctor$ = this.store.select(selectRoles).pipe(map((roles) => roles && roles.includes(Role.Doctor)));
  }

}
