import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { sideNavItems, sideNavSections } from 'src/app/core/navigation/navigation-items.data';
import { NavigationService } from 'src/app/core/navigation/navigation.service';

@Component({
  selector: 'zdb-main-layout',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']

})
export class MainComponent implements OnInit {
  @Input() dark = false;
  @HostBinding('class.zdb-sidenav-toggled')
  sideNavHidden = false;
  subscription: Subscription = new Subscription();
  sideNavItems = sideNavItems;
  sideNavSections = sideNavSections;
  sidenavStyle = 'zdb-sidenav-light';
  constructor(
    public navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.dark) {
      this.sidenavStyle = 'zdb-sidenav-dark';
    }
    this.subscription.add(
      this.navigationService.isSideNavVisible$().subscribe(isVisible => {
        this.sideNavHidden = !isVisible;
        this.changeDetectorRef.markForCheck();
      })
    );
  }
}
