import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, first, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { selectUserId } from '@medrecord/managers-auth';
import { MyDoctorManagerConstants, MyDoctorManagerService, MY_DOCTOR_MANAGER_CONSTANTS } from '@medrecord/managers-my-doctor';

@Component({
  selector: 'medrecord-medmij-auth-back',
  templateUrl: './medmij-auth-back.component.html',
  styleUrls: ['./medmij-auth-back.component.scss']
})
export class MedmijAuthBackComponent implements OnInit {
  readonly windowObj = window;
  error = null;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private myDoctorManagerService: MyDoctorManagerService,

    @Inject(MY_DOCTOR_MANAGER_CONSTANTS) private myDoctorConstants: MyDoctorManagerConstants
  ) {}

  ngOnInit(): void {
    const { error, code, state } = this.activatedRoute.snapshot.queryParams;

    if (error) {
      this.myDoctorManagerService.perforemedAuthorizationOnce = false;
      if (error === 'access_denied') {
        this.error = this.translateService.instant('healthcare_providers_access_denied_error');
        return;
      }
      this.error = this.translateService.instant('healthcare_providers_auth_denied_error');
      return;
    }

    this.store
      .select(selectUserId)
      .pipe(first())
      .subscribe((userId) => {
        this.myDoctorManagerService
          .checkAuthorizationToken(userId, code, state)
          .pipe(
            catchError((err) => {
              const messageString = err.error.message;

              const substringMap = {
                revoked: this.translateService.instant('healthcare_providers_auth_denied_error'),
                invalid: this.translateService.instant('healthcare_providers_auth_denied_error'),
                access_denied: this.translateService.instant('healthcare_providers_auth_denied_error'),
                'check against ssl': this.translateService.instant(
                  'healthcare_providers_no_information_provided_error'
                ),
              };

              const result = Object.entries(substringMap).find(([substring]) => {
                return messageString.toLowerCase().includes(substring.toLowerCase());
              });

              this.myDoctorManagerService.perforemedAuthorizationOnce = false;
              this.error = result ? result[1] : messageString;
              return of(err);
            }),
            tap(() => {
              if (!this.error) {
                if (window?.opener?.dispatchEvent) {
                  window.opener.dispatchEvent(new Event(this.myDoctorConstants.digIdSuccess));
                  this.closeCurrentWindow();
                } else {
                  this.myDoctorManagerService.perforemedAuthorizationOnce = true;
                }
              }
            })
          )
          .subscribe(() => {
            return;
          });
      });
  }

  closeCurrentWindow(): void {
    window.close();
    if (this.error) {
      window.opener.dispatchEvent(new Event(this.myDoctorConstants.digIdFailure));
    }
  }
}
