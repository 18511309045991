<ng-container *ngIf="question.type === QuestionType.group; else regularQuestion">

  <h4 class="title">
    {{ question.questionText }}
    <span *ngIf="question.required" class="title-label">
      ({{ 'common_required' | translate }})
    </span>
  </h4>

  <ng-container *ngFor="
    let subQuestion of question.subQuestions | enabledQuestions;
    let index = index; trackBy: trackByFn
  ">
    <div *ngIf="showSeparator(index)" class="separator"></div>

    <medrecord-question-type-switch [answers]="answers[subQuestion.id]"
                                    [isSubQuestion]="true"
                                    [question]="subQuestion"
                                    [visited]="question.isVisited"
                                    [isReadonly]="isReadonly"
                                    [isPositiveHealth]="isPositiveHealth"
    ></medrecord-question-type-switch>
  </ng-container>
</ng-container>

<ng-template #regularQuestion>
  <medrecord-question-type-switch [answers]="answers[question.id]"
                                  [isSubQuestion]="false"
                                  [question]="question"
                                  [visited]="question.isVisited"
                                  [isReadonly]="isReadonly"
                                  [isPositiveHealth]="isPositiveHealth"
  ></medrecord-question-type-switch>
</ng-template>

