import { Component, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { IZDBUser, toZDBUser } from '@app/core/user/user.model';
import { HealthcareProviderResource } from '@app/medrecord/models/healthcare-provider-resource';
import { UserService } from '@app/core/user/user.service';
import { selectCurrentUser } from '@app/core/user/state/user-data.selectors';
import { selectUserId } from '@medrecord/managers-auth';
import { QuestionnairePageType } from '../questionnaire.enum';
import { selectQuestionnaireTask } from '@app/medrecord/questionnaire-adapter/state-management/selectors/templates.selectors';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';
import { APP_ENVIRONMENT } from '@medrecord/core';

@Component({
  selector: 'zdb-questionnaire-header',
  templateUrl: './questionnaire-header.component.html',
  styleUrls: ['./questionnaire-header.component.scss']
})
export class QuestionnaireHeaderComponent {
  title: string;
  user$: Observable<IZDBUser>;
  questionnaireTask$: Observable<HealthcareProviderResource>;
  showPdf: boolean;
  showInfopage: boolean;
  infoUrl: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private userService: UserService,
    private store: Store,
    private datepipe: DatePipe,
    private router: Router,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {
      const snapshot = this.activatedRoute.snapshot;
      const userId = snapshot.paramMap.get('userId');
      const pageType = snapshot.data.type;
      this.showPdf = snapshot.data.pdf;
      this.infoUrl = (this.environment as any) [snapshot.data.info];
      if (userId) {
        this.user$ = this.userService.find(userId).pipe(
          map(userJSON => toZDBUser(userJSON)));
      }
      else {
        this.user$ = this.store.select(selectCurrentUser);
      }
      this.questionnaireTask$ = this.store.pipe(select(selectQuestionnaireTask));
      this.title = this.getTitle(pageType);
    }

    private getTitle(type: string): string {
      switch (type) {
        case QuestionnairePageType.new:
          return this.translateService.instant('selfcheck_new_task_title');
        case QuestionnairePageType.answers:
          return this.translateService.instant('selfcheck_task_answers_title');
        case QuestionnairePageType.scores:
          return this.translateService.instant('selfcheck_task_scores_title');
        case QuestionnairePageType.pgNew:
          return this.translateService.instant('positive_health_new_task_title');
        case QuestionnairePageType.pgScores:
          return this.translateService.instant('positive_health_task_scores_title');
        default:
          return '';
      }
    }

    formatDate(date: any, format: string) {
        return this.datepipe.transform(date, format);
    }
    goBack() {
      let currentUser = null; // own UID
      this.store.select(selectUserId).subscribe(uid => currentUser = uid);
      
      const params = this.activatedRoute.snapshot.paramMap; 
      const userId = params.get('userId'); // not always own UID

      // compares own UID to UID given by the route to determine the path back.
      if (!userId || (userId === currentUser)) {
        this.router.navigate(['/zelfcheck']);
      } else {
        this.router.navigate(['/mijn-netwerk/user/' + userId]);
      }
    }
    showInfo() {
      window.location.href = this.infoUrl;
    }
}
