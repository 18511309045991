import { SideNavItems, SideNavSection } from 'src/app/core/navigation/navigation.model';

export const sideNavSections: SideNavSection[] = [
  {
    text: '',
    items: ['my-dashboard', 'selfcheck', 'my-network', 'my-data', 'my-doctor'],
  },
];

export const sideNavItems: SideNavItems = {
  'my-dashboard': {
    icon: 'lock',
    text: 'Mijn dashboard',
    link: '/home',
  },
  selfcheck: {
    icon: 'clipboard-check',
    text: 'Mijn gezondheid',
    link: '/zelfcheck',
  },
  woonsituatie: {
    icon: 'home',
    text: 'Woonsituatie',
    link: '/woonsituatie',
  },
  'my-network': {
    icon: 'address-book',
    text: 'Mijn netwerk',
    link: '/mijn-netwerk',
  },
  'my-data': {
    icon: 'file-alt',
    text: 'Mijn gegevens',
    link: '/mijn-gegevens',
  },
  'my-doctor': {
    icon: 'user-md',
    text: 'Mijn dokter',
    link: '/mijn-doktor/welcome',
  },

};
