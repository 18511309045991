import { Component, Input } from '@angular/core';

@Component({
  selector: 'zdb-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() base64Data: string;
  @Input() contentType: string;
  @Input() width = 160;
  @Input() name: string;
  @Input() extraClass: string;
}
