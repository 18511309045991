import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QuestionTypeBaseComponent } from '../../../base/question-type-base.component';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'medrecord-choice-checkbox-question',
  templateUrl: './choice-checkbox-question.component.html',
  styleUrls: ['./choice-checkbox-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChoiceCheckboxQuestionComponent extends QuestionTypeBaseComponent {

  @Input() showHelp = true;
  @Input() multiple = true;

  constructor(protected fb: FormBuilder) {
    super(fb);
  }

  initForm(): void {
    if (!this.question) {
      return;
    }

    const answersMap = this.getAnswersMap();
    const groupConfig = this.question.options.answers
      .map((answer, index) => ( {
        [index]: [answersMap[answer.label] !== undefined]
      } ))
      .reduce((group, control) => ( { ...group, ...control } ), {});

    this.form = this.fb.group(groupConfig);
  }

  getAnswersMap(): { [label: string]: any } {
    if (this.currentAnswer) {
      return this.currentAnswer.reduce((acc, { label, value }) => ( {
        ...acc,
        [label]: value
      } ), {});
    }

    return {};
  }

  getFirstSelectedIndex(): number {
    return Object.values(this.form.value).indexOf(true);
  }

  getSelectedIndexes(): number[] {
    return Object
      .values(this.form.value)
      .map((isSelected, index) => isSelected ? index : null)
      .filter(index => index !== null);
  }

  prepareAnswersForEmitting(): any {
    const selectedIndexes = this.getSelectedIndexes();
    return this.question.options.answers
      .filter((_, index) => selectedIndexes.includes(index));
  }

  onAnswerSelect(event: Event, selectedIndex: number): void {
    if (this.multiple) {
      return;
    }

    event.preventDefault();

    const formValue = Object.entries(this.form.value)
      .map(([key, value], index) => ( {
        [`${ key }`]: index !== selectedIndex
          ? false
          : value
      } ))
      .reduce((result, formControl) => ( { ...result, ...formControl } ), {});

    this.form.setValue(formValue);
  }

}
