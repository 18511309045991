<div *ngIf="label" class="label-wrapper">
  <label [for]="inputId">
    {{ label }}
    <span *ngIf="labelDescription"> ({{ labelDescription }})</span>
    <span *ngIf="hasUnitsOfMeasure">
      - {{ unitsOfMeasure }}
    </span>
  </label>
</div>

<div>
  <div
    class="slider-input-wrapper"
    [class.disabed]="disabled"
  >
    <ejs-slider
      [max]="maxValue"
      [min]="minValue"
      [step]="step"
      [id]="inputId"
      [ticks]="defaultTicks"
      [value]="value"
      (changed)="onInput($event)"
    ></ejs-slider>
    <div *ngIf="minLabel && maxLabel" class="min-max-labels">
      <span>{{ minLabel }}</span>
      <span>{{ maxLabel }}</span>
    </div>
  </div>
</div>
