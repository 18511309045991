<div class="h-100 w-100 row justify-content-center align-items-center">
  <div class="error d-flex flex-column justify-content-center align-items-center" *ngIf="error">
    <h1 class="font-weight-bold">{{ 'healthcare_providers_auth_back_error' | translate }}:</h1>
    <div class="error-text mb-4">{{ error }}</div>

    <button
      *ngIf="windowObj?.opener?.dispatchEvent"
      class="btn btn-primary font-weight-bold"
      (click)="closeCurrentWindow()"
      type="button"
    >
      {{ 'healthcare_providers_auth_back_close_window' | translate | titlecase }}
    </button>
    <p *ngIf="!windowObj?.opener?.dispatchEvent">
      {{ 'healthcare_providers_auth_back_close_window_error_text' | translate }}
    </p>
  </div>
  <div class="error d-flex flex-column justify-content-center align-items-center" *ngIf="!error">
    <h1 class="font-weight-bold">{{ 'healthcare_providers_auth_back_success' | translate }}:</h1>
    <div class="success-text mb-4">{{ 'healthcare_providers_auth_back_success_text' | translate }}</div>

    <button
      *ngIf="windowObj?.opener?.dispatchEvent"
      class="btn btn-primary font-weight-bold"
      (click)="closeCurrentWindow()"
      type="button"
    >
      {{ 'healthcare_providers_auth_back_close_window' | translate | titlecase }}
    </button>
    <p *ngIf="!windowObj?.opener?.dispatchEvent">
      {{ 'healthcare_providers_auth_back_close_window_success_text' | translate }}
    </p>
  </div>
</div>
