import { HttpClient } from '@angular/common/http';
import { MedrecordTranslationLoader, TranslationLoaderConfig } from '@medrecord/abstractions-loaders';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';

export class ZdbTranslationLoader extends MedrecordTranslationLoader {}

export function createTranslateLoader(http: HttpClient, environment: ZDBEnvironment): ZdbTranslationLoader {
  const config: TranslationLoaderConfig = {
    remote: {
      prefix: `${environment.backend}/public/translation/lang/`,
      suffix:  ''
    },
    local: {
      prefix: `./assets/i18n/`,
      suffix:  '.json'
    }
  };

  return new ZdbTranslationLoader(http, config, true);
}
