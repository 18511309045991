import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QuestionTypeBaseComponent } from '../../../base/question-type-base.component';
import { FormBuilder } from '@angular/forms';
import { ValidationService } from '@medrecord/services-form-validation';
import {TranslateService} from '@ngx-translate/core';
import { isNumber } from '../../../utils/is-number.util';

const avgLetterWidth = 8;
const defaultFractionInputWidth = 80;
const fractionInputPaddings = 20;

@Component({
  selector: 'medrecord-question-decimal',
  templateUrl: './question-decimal.component.html',
  styleUrls: ['./question-decimal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionDecimalComponent extends QuestionTypeBaseComponent {

  constructor(
    protected fb: FormBuilder,
    protected translateService: TranslateService,
    protected validatorsService: ValidationService,
  ) {
    super(fb);
  }

  get fractionInputWidth() {
    return this.question.unitOfMeasure
      ? defaultFractionInputWidth + fractionInputPaddings + avgLetterWidth * this.question.unitOfMeasure.length
      : defaultFractionInputWidth;
  }

  initForm(): void {
    this.form = this.fb.group({
      answer: [
        isNumber(this.currentAnswer) ? this.currentAnswer : null,
        [
          this.validatorsService.decimalNumber(this.translateService.instant('validation.only_number')),
          this.validatorsService.minLength(
            this.question.options.minValue,
            this.translateService.instant('validation.min', { number: this.question.options.minValue })
          ),
          this.validatorsService.maxLength(
            this.question.options.maxValue,
            this.translateService.instant('validation.max', { number: this.question.options.maxValue })
          ),
        ]
      ]
    });
  }

  getCurrentAnswerText(answer: any): string {
    if (typeof answer === 'number') {
      return `${answer} ${this.question.unitOfMeasure || ''}`.trim();
    }

    return '';
  }

}
