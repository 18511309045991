import { MedrecordNgControlComponentBase } from './medrecord-ngcontrol.component.base';
import { Component, Input, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'medrecord-input-ng-control-base',
  template: ``,
})
export class MedrecordInputNgControlComponentBase extends MedrecordNgControlComponentBase implements ControlValueAccessor {
  @ViewChild('input') input;
  
  @Input() label: string;
  @Input() secondaryLabel: string;
  @Input() placeholder = '';
  @Input() hint: string;
  
  @Input() id = uuidv4();
  
  constructor(@Self() @Optional() public control: NgControl) {
    super(control);
  }
  
  checkIfTouched(): void {
    if (this.input && this.input.touched) {
      this.onTouched();
    }
  }
}
