<nav class="zdb-sidenav accordion" id="sidenavAccordion" [ngClass]="sidenavStyle">
  <div class="zdb-sidenav-menu">
    <ng-container *ngIf="result$ | async as result">
      <a class="zdb-sidenav-avatar"  [routerLink]="result.patientIsCurrentUser ? '/mijn-gegevens' : ['/mijn-netwerk/user', patientId]">
        <div class="avatar-panel">
          <div>
            <zdb-avatar
              [base64Data]="result.selectedUser.photoBase64Data"
              [contentType]="result.selectedUser.photoContentType"
              [name]="result.selectedUser.name.text"
              [extraClass]="result.patientIsCurrentUser ? '' : 'patient'"
            ></zdb-avatar>
          </div>
          <p>
            {{ result.selectedUser.name.text }}
          </p>
        </div>
      </a>
    </ng-container>

    <ng-container *ngFor="let section of sideNavSections">
      <div class="nav-item">
        <div class="zdb-sidenav-menu-heading" *ngIf="section.text">{{ section.text }}</div>
        <ng-container *ngFor="let item of section.items">
          <zdb-sidenav-item
            [sideNavItem]="sideNavItems[item]">
          </zdb-sidenav-item>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="zdb-sidenav-footer">
    <div class="nav-item">
      <a
        class="nav-link"
        href="https://zo-dichtbij.freshdesk.com/support/home"
      >
        <div class="zdb-nav-item-icon">
          <fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
        </div>
        {{ 'sidenav.helpdesk' | translate }}
      </a>
    </div>
    <div class="nav-item">
      <button class="btn btn-link nav-link" (click)="logout()">
        <div class="zdb-nav-item-icon">
          <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
        </div>
        {{ 'auth.logout' | translate }}
      </button>
    </div>
  </div>
</nav>
