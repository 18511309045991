import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { generalRouteNames } from '@medrecord/routes-general';
import { myDoctorRouteNames } from './constants/routes';
import { WillBeSoonFeatureComponent } from '@medrecord/ui';
import { CareproviderServicesComponent } from './components/careprovider-services/careprovider-services.component';
import { SearchComponent } from './components/search/search.component';
import { ServiceResourcesComponent } from './components/service-resources/service-resources.component';
import { MyDoctorWrapperComponent } from './containers/my-doctor-wrapper/my-doctor-wrapper.component';
import { FetchResourceResponseComponent } from './components/fetch-resource-response/fetch-resource-response.component';

export const MY_DOCTOR_ROUTES = [
  {
    path: generalRouteNames.Base,
    component: MyDoctorWrapperComponent,
    children: [
      {
        path: myDoctorRouteNames.MyDoctor.Search,
        component: SearchComponent,
      },
      {
        path: myDoctorRouteNames.MyDoctor.Favourites,
        component: WillBeSoonFeatureComponent,
      },
      {
        path: myDoctorRouteNames.MyDoctor.Careprovider,
        children: [
          {
            path: myDoctorRouteNames.MyDoctor.CareproviderId,
            children: [
              {
                path: generalRouteNames.Base,
                component: CareproviderServicesComponent,
              },
              {
                path: myDoctorRouteNames.MyDoctor.Service,
                children: [
                  {
                    path: myDoctorRouteNames.MyDoctor.Transaction,
                    children: [
                      {
                        path: generalRouteNames.Base,
                        component: ServiceResourcesComponent,
                      },
                      {
                        path: myDoctorRouteNames.MyDoctor.Fetch,
                        component: FetchResourceResponseComponent,
                      },
                    ],
                  }
                ],
              },
            ],
          },
        ],
      },
      {
        path: generalRouteNames.Base,
        redirectTo: myDoctorRouteNames.MyDoctor.Search,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(MY_DOCTOR_ROUTES)],
  exports: [RouterModule],
})
export class MyDoctorRoutingModule {}
