<div class="d-flex justify-content-evenly align-items-stretch row" *ngIf="result$ | async as result">
  <div class="selfcheck col-md-6" *ngFor="let group of result.groupedOutcomes">
    <zdb-card>
      <div class="card-body">
        <div class="card-archive">
          <div class="card-archive-item">
              <div class="row">
                <div class="col"><h2 class="pb-4">{{'questionnaire_categories.' + group.label | translate}}</h2></div>
                <zdb-charts [items]="group.items" [scores]="availableScores"></zdb-charts>
                <ng-container *ngIf="!group.items.length" [ngTemplateOutlet]="noItems"></ng-container>
              </div>
          </div>
        </div>
      </div>
    </zdb-card>
  </div>
  <div class="selfcheck col-md-6">
    <zdb-card>
      <div class="card-body">
        <div class="card-archive">
          <div class="card-archive-item">
              <div class="row">
                <div class="col"><h2 class="pb-4">{{'questionnaire_categories.extra_info' | translate}}</h2></div>
                <div class="col" *ngIf="result.questionnaireTask">
                  <div>
                      * {{ 'questionnaire_scores_extra_info_date' | translate }} {{ result.questionnaireTask.completed | date: "dd-MM-yyyy" }}
                  </div>
                </div>
                <zdb-charts [items]="result.extraInfo" [scores]="availableScores"></zdb-charts>
                <ng-container *ngIf="!result.extraInfo" [ngTemplateOutlet]="noItems"></ng-container>
              </div>
          </div>
        </div>
      </div>
    </zdb-card>
  </div>
</div>

<ng-template #noItems>
  <div class="col-md-6">
    <span class="badge badge-pill badge-success d-block">{{('measurements_good') | translate}}</span>
  </div>
</ng-template>
