export enum ResourceSubType {
  generic = 'GENERIC',
  bloodGlucose = 'BLOOD_GLUCOSE',
  bloodPressure = 'BLOOD_PRESSURE',
  bodyHeight = 'BODY_HEIGHT',
  bodyTemperature = 'BODY_TEMPERATURE',
  bodyWeight = 'BODY_WEIGHT',
  heartRate = 'HEART_RATE',
  oxygenSaturation = 'OXYGEN_SATURATION',
  pulseRate = 'PULSE_RATE',
  livingSituation = 'LIVING_SITUATION',
  alcoholUse = 'ALCOHOL_USE',
  tabaccoUse = 'TOBACCO_USE',
  drugUse = 'DRUG_USE',
  treatmentDirective = 'TREATMENT_DIRECTIVE',
  advanceDirective = 'ADVANCE_DIRECTIVE',
  functionalOrMentalStatus = 'FUNCTIONAL_OR_MENTAL_STATUS',
  laboratoryTestResult = 'LABORATORY_TEST_RESULT',
  familySituation = 'FAMILY_SITUATION',
  participationInSociety = 'PARTICIPATION_IN_SOCIETY',
  helpFromOthers = 'HELP_FROM_OTHERS',
  textResult = 'TEXT_RESULT',

  gpJournalEntry = 'GP_JOURNAL_ENTRY',
}
