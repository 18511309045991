import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import { setIDKForQuestion } from '../../state/questionnaires.actions';

@Component({
  selector: 'medrecord-idk-modal',
  templateUrl: './idk-modal.component.html',
  styleUrls: ['./idk-modal.component.scss']
})
export class IdkModalComponent {
  @Input() questionId: string;
  @Input() questionnaireId: string;

  form: FormGroup;
  question: any;
  reason = '';
  answer: any;

  get isDisabled(): boolean {
    return (this.answer[0].value === 3 && !this.reason) || !this.answer;
  }

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private translateService: TranslateService,
  ) {
    this.form = this.fb.group({});

    this.question = {
      options: {
        answers: [
          {
            label: this.translateService.instant('common_answer_idk_doctor_consultation'),
            value: 0
          },
          {
            label: this.translateService.instant('common_answer_idk_make_decision'),
            value: 1
          },
          {
            label: this.translateService.instant('common_answer_idk_ask_relatives'),
            value: 2
          },
          {
            label: this.translateService.instant('common_answer_idk_other'),
            value: 3
          }
        ]
      }
    };
  }

  ok() {
    this.store.dispatch(
      setIDKForQuestion({
        questionnaireId: this.questionnaireId,
        questionId: this.questionId,
        answer: this.answer[0].value === 3 ? this.reason : this.answer[0].label
      })
    );
  }

  onAnswer(answer: any) {
    this.answer = answer;
  }

}
