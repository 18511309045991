<!-- <ng-container *ngIf="permission$ | async as editPermission"> -->
<ng-container *ngIf="careprovider$ | async as careprovider">
  <ng-container *ngIf="transaction">
    <medrecord-test-careprovider-modal
      [isOpen]="modalOpen"
      [transaction]="selectedTransaction"
      [dataServiceId]="dataserviceId"
      (dialogClosed)="modalOpen = false"
    >
    </medrecord-test-careprovider-modal>
    <doctor-gui-parameters-modal
      *ngIf="parametersModalVisible"
      [isOpen]="parametersModalVisible"
      [dataServiceId]="dataserviceId"
      [transaction]="selectedTransaction"
      (close)="parametersModalVisible = false; selectedTransaction = null"
      (submit)="onParametersSubmit($event)"
    ></doctor-gui-parameters-modal>
    <div class="row mx-0">
      <div class="col-12 mb-4 d-flex align-items-center">
        <medrecord-back-icon-button
          [tooltip]="
            ('common_back_to' | translate) + ' ' + getDisplayName(careprovider)
          "
        ></medrecord-back-icon-button>
        <h3 class="text-weight-600 text-primary mb-0">
          {{ transaction.id | translate }}
        </h3>
      </div>
    </div>
    <ng-container *ngIf="resources; else noData">
      <ng-container *ngIf="resources?.length; else noData">
        <ng-container *ngFor="let resource of resources">
          <doctor-gui-medsafe-resource
            *ngIf="!resource.group"
            [resource]="resource"
            (getData)="onDataGet($event)"
          ></doctor-gui-medsafe-resource>
          <doctor-gui-medsafe-resource-group
            *ngIf="resource.group"
            [resourceGroup]="resource"
            (getData)="onDataGet($event)"
          ></doctor-gui-medsafe-resource-group>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #noData>
      <div class="row">
        <div class="col-12 my-5 d-flex justify-content-center">
          {{ "my_doctor_no_data" | translate }}
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
<!-- </ng-container> -->
