<!-- <ng-container *ngIf="permission$ | async as editPermission"> -->
  <medrecord-portability-report-modal
    [isOpen]="portabilityModalOpen"
    (dialogClosed)="portabilityModalOpen = false"
  >
  </medrecord-portability-report-modal>
  <div class="w-100 mb-4 d-flex align-items-center justify-content-between">
    <div class="w-50 d-flex align-items-center">
      <input
        [id]="'serach'"
        (input)="onSearchChange($event.target.value)"
        [value]="searchValue"
        type="text"
        class="e-input-group shown mr-2"
        (keyup.enter)="onSearch()"
        [placeholder]="'my_doctor_search_location' | translate"
      />
      <button
        class="button btn btn-success font-weight-bold"
        (click)="onSearch()"
        type="button"
      >
        {{ 'common_search' | translate | titlecase }}
      </button>
    </div>
    <button class="button btn btn-outline-black font-weight-bold" (click)="portabilityModalOpen = true" type="button">
      {{ 'my_doctor_portability_report' | translate | titlecase }}
    </button>
  </div>

  <ejs-grid
    #grid
    [rowHeight]="80"
    class="table-card"
    [dataSource]="careproviders$ | async"
    [allowPaging]="true"
    [height]="pageSettings.totalRecordsCount ? 'auto' : '50vh'"
    [pageSettings]='{ pageSize: pageSettings.pageSize }'
  >
    <ng-template #pagerTemplate>
      <medrecord-pagination [settingsModel]="pageSettings" (change)="updateSettingsModel($event)"></medrecord-pagination>
    </ng-template>
    <e-columns>
      <e-column [minWidth]="'70%'" [maxWidth]="'100%'" field="name" [headerText]="'common_column_name' | translate">
        <ng-template #template let-data>
          <div class="d-flex align-items-center">
            <div><i class="fas fa-star" [style.color]="'var(--blue-300-color)'"></i></div>
            <div class="d-flex flex-column ml-3">
              <div class="d-flex align-items-center">
                <a class="nav-cell mr-1" (click)="onCareProviderSelect(data)"> {{ getDisplayName(data) }}</a>
                <medrecord-svg-medmij-verified></medrecord-svg-medmij-verified>
              </div>
              <div class="careprovider-info text-weight-500">
                {{ getAddress(data) }}
              </div>
            </div>
          </div>
        </ng-template>
      </e-column>
      <e-column width="350" field="name" headerText="">
        <ng-template #template let-data>
          <button
            class="button btn btn-outline-black font-weight-bold"
            (click)="selectedCareprovider = data; infoDialogOpen = true"
            type="button"
          >
            {{ 'my_doctor_more_info' | translate | titlecase }}
          </button>
        </ng-template>
      </e-column>
    </e-columns>
  </ejs-grid>

  <ejs-dialog
    #dialogComponent
    [visible]="infoDialogOpen"
    [isModal]="true"
    (overlayClick)="infoDialogOpen = false"
    width="30%"
  >
    <ng-template #header>
      <div class="d-flex align-items-center">
        <span class="font-weight-bold text-xl text-primary mr-2">
          {{ getDisplayName(selectedCareprovider) }}
        </span>
        <medrecord-svg-medmij-verified></medrecord-svg-medmij-verified>
      </div>
    </ng-template>

    <ng-template #content>
      <div class="d-flex flex-column">
        <span class="text-weight-600 text-primary">{{ 'Email' | translate }}</span>
        <span class="text-weight-500">
          <a [href]="'mailto:' + getEmail(selectedCareprovider)" class="email">{{ getEmail(selectedCareprovider) }}</a>
        </span>
      </div>
      <div class="d-flex flex-column mt-3" *ngIf="getPhone(selectedCareprovider)">
        <span class="text-weight-600 text-primary">{{ 'Phone' | translate }}</span>
        <span class="text-weight-500">
          {{ getPhone(selectedCareprovider) }}
        </span>
      </div>
      <div class="d-flex flex-column mt-3" *ngIf="getAgb(selectedCareprovider)">
        <span class="text-weight-600 text-primary">{{ 'AGB' | translate }}</span>
        <span class="text-weight-500">
          {{ getAgb(selectedCareprovider) }}
        </span>
      </div>
      <div class="d-flex flex-column mt-3" *ngIf="getSpeciality(selectedCareprovider)">
        <span class="text-weight-600 text-primary">{{ 'speciality' | translate }}</span>
        <span class="text-weight-500">
          {{ getSpeciality(selectedCareprovider) }}
        </span>
      </div>
      <div class="d-flex flex-column mt-3" *ngIf="selectedCareprovider?.addresses?.length">
        <span class="text-weight-600 text-primary">{{ 'Addresses' | translate }}</span>
        <span class="d-block text-weight-500" *ngFor="let address of selectedCareprovider?.addresses; let i = index">
          {{ getAddress(selectedCareprovider, i) }}
        </span>
      </div>
    </ng-template>

    <ng-template #footerTemplate>
      <div>
        <button (click)="infoDialogOpen = false" class="btn btn-success ml-2 font-weight-bold">
          {{ 'common_ok' | translate }}
        </button>
      </div>
    </ng-template>
  </ejs-dialog>
<!-- </ng-container> -->
