<form (submit)="handleFormSubmit($event)"
    [formGroup]="form"
    >
    <medrecord-avatar-upload [label]="'common.form.upload_avatar' | translate"
                        [uploadAvatarButtonText]="'common.form.upload_avatar' | translate"
                        [avatarSizeLimitText]="'common.form.avatar_size_limit' | translate"
                        [removeAvatarButtonText]="'common.form.remove_avatar' | translate"
                        (maxFileSizeExceeded)="handleAvatarMaxSizeError()"
                        (fileTypeNotAllowed)="handleAvatarWrongTypeError()"
                        formControlName="avatar"
    ></medrecord-avatar-upload>

    <ng-container formGroupName="name">
      <medrecord-input [hint]="'userform.first_name_hint' | translate"
                  [label]="'userform.first_name' | translate"
                  class="d-block mb-2"
                  formControlName="firstName"
      ></medrecord-input>

      <medrecord-input [hint]="'userform.last_name_hint' | translate"
                  [label]="'userform.last_name' | translate"
                  class="d-block mb-2"
                  formControlName="lastName"
      ></medrecord-input>
    </ng-container>

    <medrecord-input [hint]="'userform.email_hint' | translate"
        [label]="'userform.email' | translate"
        class="d-block mb-2"
        formControlName="email"
    ></medrecord-input>

    <medrecord-input [hint]="'userform.phone_hint' | translate"
        [label]="'userform.phone' | translate"
        class="d-block mb-2"
        formControlName="phone"
    ></medrecord-input>

    <medrecord-bricks [hint]="'userform.gender_hint' | translate"
                [label]="'userform.gender' | translate"
                [multiple]="false"
                [selectItems]="genders"
                class="d-block mb-2"
                formControlName="gender"
    ></medrecord-bricks>

    <medrecord-datepicker [hint]="'userform.date_of_birth' | translate"
                [label]="'userform.date_of_birth' | translate"
                [maxDate]="maxDate"
                [calendarViewDepth]="'Month'"
                [startCalendarView]="'Decade'"
                [dateFormat]="dateFormat"
                formControlName="birthDate"
                class="d-block mb-2"
    ></medrecord-datepicker>

    <medrecord-input [hint]="'userform.street_hint' | translate"
        [label]="'userform.street' | translate"
        class="d-block mb-2"
        formControlName="streetName"
    ></medrecord-input>

    <medrecord-input [hint]="'userform.house_number_hint' | translate"
        [label]="'userform.house_number' | translate"
        class="d-block mb-2"
        formControlName="houseNumber"
    ></medrecord-input>

    <medrecord-input [hint]="'userform.city_hint' | translate"
        [label]="'userform.city' | translate"
        class="d-block mb-2"
        formControlName="city"
    ></medrecord-input>

    <medrecord-input [hint]="'userform.postal_code_hint' | translate"
        [label]="'userform.postal_code' | translate"
        class="d-block mb-2"
        formControlName="postalCode"
    ></medrecord-input>

    <ng-container *ngIf="form.get('availableForSearch')">
        <h5 class="font-weight-bold mb-4">
        {{ 'onboarding.search_policy' | translate }}
        </h5>

        <p>
        {{ 'onboarding.search_policy_text_p1' | translate }}
        </p>
        <p>
        {{ 'onboarding.search_policy_text_p2' | translate }}
        </p>
        <p>
        {{ 'onboarding.search_policy_text_p3' | translate }}
        </p>
        <medrecord-checkbox
        [label]="'onboarding.make_profile_visible' | translate"
        formControlName="availableForSearch"
        ></medrecord-checkbox>
    </ng-container>

    <hr/>

    <div class="d-flex flex-column align-items-end">
    <button
    class="btn btn-primary-button rounded-pill mt-3 text-sm font-weight-bold pl-4 pr-4"
    type="submit"
    >
    {{ 'common.form.save' | translate }}
    </button>
    </div>

</form>
