<form *ngIf="!comment" [formGroup]="form" class="comment-form">
  <div class="e-input-group comment-input">
    <input type='text'
           id="comment"
           [placeholder]="'common_type_something' | translate"
           formControlName="comment"
    />

    <span class="e-float-line"></span>

    <label
      class="e-float-text"
      for="comment"
    >
      {{'common_comments' | translate }}
    </label>
  </div>

  <button
    (click)="send()"
    class="btn btn-secondary send-button"
  >
    {{ 'common_send' | translate }}
  </button>
</form>

<medrecord-comment (edit)="editComment()"
                   (remove)="removeComment()"
                   *ngIf="comment"
                   [comment]="comment"
>
</medrecord-comment>
