<div>
  <div class="row">
    <div class="col-12 col-xl-6" *ngFor="let outcome of stoplightChartItems">
      <div>
        <p>
          <span class="outcomeLabel">{{ "questionnaire_outcome." + outcome.code + ".name" | translate }}</span>
          <span
            class="badge badge-pill d-block"
            [ngClass]="{
              'badge-success':
              (scores[outcome.code].scale.length < 4 &&
                outcome.value == 0) ||
              (scores[outcome.code].scale.length == 4 &&
                outcome.value == 1),
              'badge-warning':
                (scores[outcome.code].scale.length > 2 &&
                 scores[outcome.code].scale.length < 4 &&
                  outcome.value == 1) ||
                  (scores[outcome.code].scale.length == 4 &&
                  outcome.value == 2) ||
                  (scores[outcome.code].scale.length == 4 &&
                  outcome.value == 3),
              'badge-danger':
                (scores[outcome.code].scale.length == 2 &&
                  outcome.value == 1) ||
                (scores[outcome.code].scale.length == 3 &&
                  outcome.value == 2) ||
                  (scores[outcome.code].scale.length == 4 &&
                    outcome.value == 0)
            }"
          >
            {{
              "questionnaire_outcome." +
                outcome.code +
                ".scale." +
                outcome.value | translate
            }}</span
          >
        </p>
      </div>
    </div>
    <div class="col-12 col-xl-6" *ngFor="let outcome of defaultItems">
      <div>
        <p>
          <span class="outcomeLabel">{{ "questionnaire_outcome." + outcome.code + ".name" | translate }}</span>
          <span class="badge-pill badge-info d-block">{{
            "questionnaire_outcome." + outcome.code + ".scale." + outcome.value
              | translate: {Default:outcome.value}
          }}</span>
        </p>
      </div>
    </div>
    <div class="col-12 col-xl-6" *ngFor="let outcome of textDisplayItems">
      <div>
        <p>
          <span class="outcomeLabel">{{ "questionnaire_outcome." + outcome.code + ".name" | translate }}</span>
          <span class="badge-pill badge-info d-block">{{ outcome.value }}</span>
        </p>
      </div>
    </div>
    <div class="col-12 col-xl-6" *ngFor="let outcome of ratingItems">
      <div>
        <p>
          <span class="outcomeLabel">{{ "questionnaire_outcome." + outcome.code + ".name" | translate }}</span>
          <span class="badge-pill badge-info d-block"><ngb-rating [max]="scores[outcome.code].range[1]" [rate]="scores[outcome.code].scale.length - outcome.value" [readonly]="true"></ngb-rating></span>
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 col-xl-6" *ngFor="let outcome of longTextDisplayItems">
    <div>
      <p>
        <span class="outcomeLabel">{{ "questionnaire_outcome." + outcome.code + ".name" | translate }}</span>
        <span class="d-block">{{ outcome.value }}</span>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-xl-6" *ngFor="let outcome of gaugeChartItems">
      <div class="zdb-gauge-chart">
        <zdb-gauge-chart
          [name]="'questionnaire_outcome.' + outcome.code + '.name' | translate"
          [bottomLabel]="
            'questionnaire_outcome.' + outcome.code + '.scale.' + outcome.value
              | translate
          "
          [needleValue]="
            (outcome.value / scores[outcome.code].scale.length) * 100
          "
          [rangeLabel]="scores[outcome.code].range"
        ></zdb-gauge-chart>
      </div>
    </div>
  </div>
</div>
