import { Action, createReducer, on } from '@ngrx/store';
import {
  clearQuestionnaire,
  questionnaireLoaded,
  saveQuestionAnswer,
  selectNextQuestion,
  selectPrevQuestion,
  selectQuestion,
  setIDKForQuestion
} from './questionnaires.actions';
import { Questionnaire } from '../interfaces/questionnaire.interface';
import {
  getInitialEnabledState,
  getQuestionCompletionStatus,
  removeAnswersForDisabledQuestions
} from '../utils/question-answers.util';

export const questionnairesFeatureName = 'questionnaires';

export interface State {
  questionnairesMap: {
    [key: string]: Questionnaire
  };
}

export const initialState = {
  questionnairesMap: {}
};

const prepareQuestionnaire = (questions, answers) => {
  let isFinished = true;
  const questionsWithEnabledState = getInitialEnabledState(questions, answers);
  const preparedQuestions = questionsWithEnabledState.map(
    question => {
      const { allAnswered: isCompleted, requiredAnswered } = getQuestionCompletionStatus(question, answers);
      const hasIdkAnswer = answers[question.id] && answers[question.id][0] && answers[question.id][0].value === 'idk';

      isFinished = isFinished && requiredAnswered;

      return {
        ...question,
        isCompleted,
        hasIdkAnswer
      };
    }
  );
  return {
    preparedQuestions,
    isFinished
  };
};

const _questionnairesReducer = createReducer<State>(
  initialState,
  on(questionnaireLoaded, (state: State, { id, questionnaire }) => {
    const { questions, answers } = questionnaire;
    const { preparedQuestions, isFinished } = prepareQuestionnaire(questions, answers);
    return ( {
      ...state,
      questionnairesMap: {
        ...state.questionnairesMap,
        [id]: {
          ...questionnaire,
          selectedQuestionIndex: 0,
          selectedQuestionIndexInOrder: 0,
          answers,
          isFinished,
          questions: preparedQuestions
        }
      }
    } );
  }),
  on(clearQuestionnaire, (state: State, { questionnaireId }) => ({
    ...state,
    questionnairesMap: {
      ...state.questionnairesMap,
      [questionnaireId]: undefined
    }
  })),
  on(selectQuestion, (state: State, { questionnaireId, questionId }) => {
    const { questions, selectedQuestionIndexInOrder: oldSelectedIndexInOrder } = state.questionnairesMap[questionnaireId];
    const questionIndex = questions.findIndex(({ id }) => id === questionId);
    const selectedQuestionIndexInOrder = questionIndex === -1 ? 0 : questionIndex;
    const preparedQuestions = questions.map(question => handleIdkAnswers(state, questionnaireId, question));
    preparedQuestions[oldSelectedIndexInOrder] = {
      ...preparedQuestions[oldSelectedIndexInOrder],
      isVisited: true
    };

    return {
      ...state,
      questionnairesMap: {
        ...state.questionnairesMap,
        [questionnaireId]: {
          ...state.questionnairesMap[questionnaireId],
          selectedQuestionIndexInOrder,
          questions: preparedQuestions
        }
      }
    };
  }),
  on(selectPrevQuestion, (state: State, { questionnaireId }) => {
    const {
      questions,
      selectedQuestionIndexInOrder: oldSelectedIndexInOrder
    } = state.questionnairesMap[questionnaireId];
    const selectedQuestionIndexInOrder = Math.max(0, oldSelectedIndexInOrder - 1);
    const preparedQuestions = questions.map(question => handleIdkAnswers(state, questionnaireId, question));
    preparedQuestions[oldSelectedIndexInOrder] = {
      ...preparedQuestions[oldSelectedIndexInOrder],
      isVisited: true
    };

    return {
      ...state,
      questionnairesMap: {
        ...state.questionnairesMap,
        [questionnaireId]: {
          ...state.questionnairesMap[questionnaireId],
          selectedQuestionIndexInOrder,
          questions: preparedQuestions
        }
      }
    };
  }),
  on(selectNextQuestion, (state: State, { questionnaireId }) => {
    const {
      questions,
      selectedQuestionIndexInOrder: oldSelectedIndexInOrder
    } = state.questionnairesMap[questionnaireId];
    const selectedQuestionIndexInOrder = Math.min(questions.length - 1, oldSelectedIndexInOrder + 1);
    const preparedQuestions = questions.map(question => handleIdkAnswers(state, questionnaireId, question));
    preparedQuestions[oldSelectedIndexInOrder] = {
      ...preparedQuestions[oldSelectedIndexInOrder],
      isVisited: true
    };

    return {
      ...state,
      questionnairesMap: {
        ...state.questionnairesMap,
        [questionnaireId]: {
          ...state.questionnairesMap[questionnaireId],
          selectedQuestionIndexInOrder,
          questions: preparedQuestions
        }
      }
    };
  }),
  on(saveQuestionAnswer, (state: State, { questionnaireId, answer, questionId }) => {
    const { questions, answers: rawAnswers } = state.questionnairesMap[questionnaireId];
    const answers = {
      ...rawAnswers,
      [questionId]: answer
    };
    const { preparedQuestions, isFinished } = prepareQuestionnaire(questions, answers);
    const cleanedAnswers = removeAnswersForDisabledQuestions(preparedQuestions, answers);

    return {
      ...state,
      questionnairesMap: {
        ...state.questionnairesMap,
        [questionnaireId]: {
          ...state.questionnairesMap[questionnaireId],
          questions: preparedQuestions,
          answers: cleanedAnswers,
          isFinished
        }
      }
    };
  }),
  on(setIDKForQuestion, (state: State, { questionnaireId, answer, questionId }) => ( {
    ...state,
    questionnairesMap: {
      ...state.questionnairesMap,
      [questionnaireId]: {
        ...state.questionnairesMap[questionnaireId],
        questions: [
          ...state.questionnairesMap[questionnaireId].questions.map(question => {
            if (question.id !== questionId) {
              return question;
            }

            return {
              ...question,
              options: {
                ...question.options,
                answers: [
                  ...question.options.answers.filter(filterNonIDK),
                  {
                    label: answer,
                    value: 'idk'
                  }
                ]
              }
            };
          }),
        ],
        answers: {
          ...state.questionnairesMap[questionnaireId].answers,
          [questionId]: [{
            label: answer,
            value: 'idk'
          }]
        }
      },
    }
  } ))
);

export function questionnairesReducer(state: State, action: Action): State {
  return _questionnairesReducer(state, action);
}

function handleIdkAnswers(state, questionnaireId, question) {
  if (!( state.questionnairesMap[questionnaireId].answers[question.id] instanceof Array )) {
    return question;
  }

  const hasIdkAnswer = state.questionnairesMap[questionnaireId].answers[question.id]
    .some(({ value }) => value === 'idk');

  return {
    ...question,
    options: {
      ...question.options,
      answers:
        hasIdkAnswer
          ? question.options.answers
          : question.options.answers.filter(({ value }) => value !== 'idk')
    }
  };
}

function filterNonIDK({ value }) {
  return value !== 'idk';
}
