import { NgModule } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { AlertModalService } from './alert-modal/alert-modal.service';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { fontAwesomeIcons } from './icons/font-awesome-icons';
import { UserDataModule } from './user/user-data.module';
import { AuthService } from './auth/auth.service';

@NgModule({
  imports: [
    UserDataModule
  ],
  providers: [ConfirmationDialogService, AlertModalService, AuthService],
  declarations: [ConfirmationDialogComponent, AlertModalComponent],
})
export class CoreModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(...fontAwesomeIcons);
  }
}
