import {Component, ElementRef, HostBinding, Input, Optional, Self, ViewChild} from '@angular/core';
import * as Core from '@angular/core';
import {NgControl} from '@angular/forms';
import * as Forms from '@angular/forms';

@Component({
  selector: 'app-input', // tslint:disable-line
  styleUrls: ['./input.component.scss'],
  templateUrl: './input.component.html'
})
export class InputComponent implements Forms.ControlValueAccessor, Core.AfterViewInit {
  @ViewChild('input')
  inputElementRef: ElementRef;

  private readonly defaultOptions = {
    type: 'text',
  };


  preparedOptions = this.defaultOptions;

  @Input() set options(options) {
    this.preparedOptions = Object.assign({}, this.defaultOptions, options);
  }

  @Input()
  disabledTo: Date;

  @Input()
  disabledAfter: Date;

  @Input()
  description: string;

  // Label props
  // Todo move label from input
  @Input()
  hint = '';

  @Input()
  label: string;

  @Input()
  labelDescription: string;
  // Label props end

  // Datetime props
  // Todo move datetime from input

  @Input()
  setDateNow: boolean;

  // Label props end

  @Input()
  inputWidth: number = null;

  @Input()
  placeholder = '';

  @HostBinding('class.form-control-styles') @Input() formControlStyles = true;

  @Input()
  isDisabled = false;

  showPasswordString = false;

  @Input()
  marginBottom = false;

  @Input()
  maxSelectableDate = null;

  @Input()
  inputType: string;

  @Input()
  dateTimePickerStartsWithYear: boolean;

  value: string;

  // tslint:disable:no-empty
  onChange: any = () => { };
  onTouched: any = () => { };
  
  valueChange(value: string): void {
    if (this.inputType) {
      // this.convertToInputType(value);
      return;
    }
    this.onChange(value.trim());
  }

/*
  convertToInputType(value: string): void {
    switch (this.inputType) {
      case inputValueType.float: {
        this.onChange(parseFloatString(value));
        break;
      }
    }
  }
*/

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit() {
    if (this.inputElementRef) {
      this.inputElementRef.nativeElement.disabled = this.isDisabled;
    }
  }

  get inputId() {
    return this.ngControl ? this.ngControl.name : null;
  }

/*
  get isPasswordInput() {
    return this.preparedOptions.type === inputControlType.password;
  }
*/

/*
  get preparedLeftIcon() {
    if (this.isPasswordInput) {
      return IconType.lock;
    }

    return this.preparedOptions.iconLeft;
  }
*/

/*
  get showError() {
    return this.ngControl ? showError(this.ngControl.errors, this.ngControl.touched) : false;
  }
*/

/*
  get showClearText() {
    return this.preparedOptions.clearAllowed && !!this.ngControl?.value;
  }
*/

/*
  get showSave() {
    return this.preparedOptions.saveCallback && !!this.ngControl?.value;
  }
*/

  get preparedInputType() {
/*
    if (this.showPasswordString) {
      return inputControlType.text;
    }
*/

    return this.preparedOptions.type;
  }

/*
  get rightIconsCount(): number {
    let count = 0;
    if (this.showError) {
      count++;
    }
    if (this.showClearText) {
      count ++;
    }
    if (this.showSave) {
      count++;
    }
    if (this.isPasswordInput) {
      count++;
    }
    if (this.preparedOptions.iconRight) {
      count++;
    }

    return count;
  }
*/

  clearText() {
    this.writeValue('');
    this.valueChange('');
  }

  setShowPasswordString(value: boolean) {
    this.showPasswordString = value;
  }

  // ControlValueAccessor implementations
  writeValue(value) {
    this.value = value;
    setTimeout(() => {
      if (this.inputElementRef) {
        this.inputElementRef.nativeElement.value = value;
      }
    });
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(disabled) {
    this.isDisabled = disabled;
  }
}
