
<div *ngIf="label"
     class="label-wrapper">
  <label [for]="inputId">{{label}} <span *ngIf="labelDescription"> ({{ labelDescription }})</span></label>
</div>

<div
 class="input-wrapper"
 [style.max-width.px]="inputWidth"
>
  <input #input *ngIf="!isDisabled"
         class="input"
         [placeholder]="placeholder"
         [max]="maxSelectableDate"
         [type]="preparedInputType"
         [id]="inputId"
         [disabled]="isDisabled"
         (input)="valueChange($event.target.value)"
         (blur)="onTouched()"
  />
  <ng-container *ngIf="isDisabled">{{value}}</ng-container>

</div>
