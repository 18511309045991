//
// Min and max value functions
//

export const getMinIntegerValue = (minValue: number, isCurrentValueNegativeFloat: boolean) => {
  if (minValue === null || minValue === undefined) {
    return -Infinity;
  }

  const minIntegerValue = Math.trunc(minValue);
  const isMinValueInteger = getFractionalPartOfNumber(minValue) === 0;
  return isCurrentValueNegativeFloat && isMinValueInteger ? minIntegerValue + 1 : minIntegerValue;
};

export const getMaxIntegerValue = (maxValue: number, isCurrentValuePositiveFloat: boolean) => {
  if (maxValue === null || maxValue === undefined) {
    return Infinity;
  }

  const maxIntegerValue = Math.trunc(maxValue);
  const isMaxValueInteger = getFractionalPartOfNumber(maxValue) === 0;
  return isCurrentValuePositiveFloat && isMaxValueInteger ? maxIntegerValue - 1 : maxIntegerValue;
};

// This function exists to avoid floating point errors
// like this: 100.24 - 100 returns 0.23999999999999488
export const getFractionalPartOfNumber = (float: number): number => {
  if (float === null || float === undefined || Math.trunc(float) === float) {
    return 0;
  }

  const fractionalPartStr = getFractionalPartOfNumberAsString(float);

  return parseFloat(`0.${ fractionalPartStr }`);
};

export const splitNumberToIntegerAndFractionStr = (float: number) => {
  if (float === null || float === undefined) {
    return { integer: null, fraction: null };
  }

  const integer = getIntegerPartOfNumberAsString(float);
  const fraction = getFractionalPartOfNumberAsString(float);

  return { integer, fraction };
};


//
// Value calculation functions
//

export const getIntegerValue = (valueStr: string) => {
  if (valueStr === null || valueStr === undefined || valueStr.length === 0) {
    return null;
  }
  return parseInt(valueStr, 10);
};

export const getFractionValue = (valueStr: string): number => {
  if (valueStr === null || valueStr === undefined || valueStr.length === 0) {
    return 0;
  }

  return parseFloat(`0.${ valueStr }`);
};

export const calculateValue = (precision, integerValue, fractionValueStr) => {
  const integer = getIntegerValue(integerValue);
  const fraction = getFractionValue(fractionValueStr);

  if (integer === null) {
    return null;
  }

  return sumFloatNumbers(precision, integer, integer < 0 ? -fraction : fraction);
};

export const getIntegerPartOfNumberAsString = (float: number): string => {
  return `${ float }`.split('.')[0] || '0';
};

export const getFractionalPartOfNumberAsString = (float: number): string => {
  return `${ float }`.split('.')[1] || '0';
};

export const sumFloatNumbers = (precision: number = 2, ...floats: number[]): number => {
  const floatsSum = floats.reduce((sum: number, float: number) => sum + float, 0);

  return parseFloat(floatsSum.toFixed(precision));
};
