import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { QuestionnairesInUseListItem } from '@app/medrecord/questionnaire-adapter/interfaces/questionnaire-in-use-list-item.interface';
import { OutcomeItem } from '@app/selfcheck/questionnaire-outomes/questionnaire-outcomes.type';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';
import { positiveHealthQuestionnaireAlias } from '../constants/positive-health-questionnaire-alias';
import { TranslateService } from '@ngx-translate/core';
import { positiveHealthQuestionnaireStartItem } from '../constants/positive-health-questionnaire-start-item';
import { positiveHealthConfigMapper } from '../constants/positive-health-config-mapper';

@Injectable({ providedIn: 'root' })
export class PositiveHealthQuestionnairesService {
  private questonnaireToTaskMapping: any;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {}

  private getLastQuestionnaireTask(
    patientId: string
  ): Observable<QuestionnairesInUseListItem[]> {
    const params = new HttpParams()
      .append('status', ['REQUESTED', 'IN_PROGRESS'].join(','))
      .append('count', '100')
      .append('start', '0');

    return this.http.get<QuestionnairesInUseListItem[]>(
      `${this.environment.backend}/patient/${patientId}/questionnaire-task`,
      { params }
    );
  }

  private getCompletedQuestionnaireTasks(
    patientId: string
  ): Observable<QuestionnairesInUseListItem[]> {
    const params = new HttpParams().append('status', 'COMPLETED');

    return this.http.get<QuestionnairesInUseListItem[]>(
      `${this.environment.backend}/patient/${patientId}/questionnaire-task`,
      { params }
    );
  }

  private createQuestionnaireTask(patientId: string): Observable<any> {
    const scheduleQuestionnaireRequest = {
      questionnaireIds: this.environment.positiveHealthQuestionnaireIds,
      subjectId: patientId,
      fulfilmentSought: {
        type: 'SINGLE',
        to: new Date().toISOString(),
      },
    };

    return this.http.post(
      `${this.environment.backend}/patient/${patientId}/questionnaire-task`,
      scheduleQuestionnaireRequest
    );
  }

  getQuestonnaireToTaskMapping(): any {
    return this.questonnaireToTaskMapping;
  }

  mapTasksToOneTask(data: any[], skipMappingUpdate: boolean = false): any {
    const { newQuestionnaire, newAnswers } = data
      .sort(
        (a, b) =>
          positiveHealthConfigMapper[a.questionnaireData.resourceId]?.order -
          positiveHealthConfigMapper[b.questionnaireData.resourceId]?.order
      )
      .reduce(
        (prev, curr) => {
          if (!skipMappingUpdate) {
            this.questonnaireToTaskMapping = {
              ...this.questonnaireToTaskMapping,
              [curr.questionnaireData.resourceId]: curr.taskId,
            };
          }

          prev = {
            newQuestionnaire: {
              ...curr.questionnaireData,
              description: this.translateService.instant(
                'questionnaire_positive_health_description'
              ),
              name: positiveHealthQuestionnaireAlias,
              resourceId: positiveHealthQuestionnaireAlias,
              title: 'Positieve Gezondheid',
              items: [
                ...(prev?.newQuestionnaire?.items
                  ? prev.newQuestionnaire.items
                  : []),
                {
                  ...curr.questionnaireData.items?.[0],
                  items: curr.questionnaireData.items.map((item) => ({
                    ...item,
                    linkId:
                      item.linkId + '|||' + curr.questionnaireData.resourceId,
                  })),
                  text: curr.questionnaireData.title
                    .split(' - ')
                    .splice(1)
                    ?.join(' - '),
                  type: 'GROUP',
                  linkId: curr.questionnaireData.resourceId,
                  minValue: null,
                  maxValue: null,
                },
              ],
            },
            newAnswers: {
              ...curr.answers,
              items: [
                ...(prev?.newAnswers?.items ? prev.newAnswers.items : []),
                {
                  ...curr.answers.items?.[0],
                  items: curr.answers.items.map((item) => ({
                    ...item,
                    linkId:
                      item.linkId + '|||' + curr.questionnaireData.resourceId,
                  })),
                  linkId: curr.questionnaireData.resourceId,
                  text: null,
                  answers: null,
                },
              ],
            },
          };

          return prev;
        },
        { newQuestionnaire: null, newAnswers: null }
      );
    // .sort(
    //   (a, b) =>
    //     a.newQuestionnaire
    // );

    newQuestionnaire.items = [
      positiveHealthQuestionnaireStartItem,
      ...newQuestionnaire.items,
    ];

    const newTask = {
      ...data?.[0]?.task,
      questionnaireId: positiveHealthQuestionnaireAlias,
      taskId: 'positive-health-task',
    };

    return {
      questionnaireData: newQuestionnaire,
      answers: newAnswers,
      task: newTask,
      taskId: newTask.taskId,
    };
  }

  getQuestionnaireTask(
    patientId: string,
    taskIds: string[] = []
  ): Observable<QuestionnairesInUseListItem[]> {
    return (
      taskIds?.length
        ? this.getCompletedQuestionnaireTasks(patientId)
        : this.getLastQuestionnaireTask(patientId)
    ).pipe(
      mergeMap((tasks) => {
        if (!!tasks && tasks.length > 0) {
          const filteredTasks = tasks.filter(
            (value) =>
              this.environment.positiveHealthQuestionnaireIds.includes(
                value.questionnaireId
              ) &&
              (!taskIds?.length ||
                (taskIds?.length && taskIds.includes(value.taskId)))
          );

          if (!!filteredTasks?.length || taskIds?.length) {
            return of(filteredTasks);
          }
        }
        return this.createQuestionnaireTask(patientId).pipe(
          mergeMap(() =>
            this.getLastQuestionnaireTask(patientId).pipe(
              mergeMap((freshTasks) => {
                const filteredFreshTasks = freshTasks.filter((value) =>
                  this.environment.positiveHealthQuestionnaireIds.includes(
                    value.questionnaireId
                  )
                );
                if (!!filteredFreshTasks?.length) {
                  return of(filteredFreshTasks);
                }
                console.error('Cannot fetch newly created questionnaire task!');
                return of({} as any);
              })
            )
          )
        );
      })
    );
  }

  loadQuestionnaireScoreByName(
    patientId: string,
    names: string[],
    count: string
  ): Observable<OutcomeItem[]> {
    const params = new HttpParams()
      .append('questionnaires', names.join(','))
      .append('count', count);

    return this.http.get<any>(
      `${this.environment.backend}/patient/${patientId}/questionnaire/scores/name`,
      { params }
    );
  }
}
