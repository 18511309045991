<h1>[title]="'common_select_an_option' | translate"</h1>

<div class="modal-content">

  <p>{{ 'common_select_reason_for_non_answering' | translate }}</p>

  <medrecord-choice-checkbox-question
    (answer)="onAnswer($event)"
    [multiple]="false"
    [question]="question"
    [showHelp]="false"
  ></medrecord-choice-checkbox-question>

  <medrecord-input
     *ngIf="answer && answer[0] && answer[0].value === 3"
     [(ngModel)]="reason"
     [placeholder]="'common_your_reason' | translate"
  ></medrecord-input>

  <div class="buttons-wrap">
    <button class="btn btn-outline-secondary">
      {{ 'common_cancel' | translate }}
    </button>

    <button class="btn btn-primary-button button" (click)="ok()">
      {{ 'common_ok' | translate }}
    </button>
  </div>
</div>
