import { Component } from '@angular/core';
import { MedrecordIcon } from '@medrecord/icons';

@Component({
  selector: 'medrecord-svg-medmij-verified',
  templateUrl: './svg-medmij-verified.component.svg',
})
export class SvgMedmijVerifiedComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
}
