import { questionnairesFeatureName, State } from './questionnaires.reducer';
import { createSelector } from '@ngrx/store';

const selectQuestionnaires = (state: State) => state[questionnairesFeatureName];

export const selectAccessToken = createSelector(
  selectQuestionnaires,
  (state: State) => state.questionnairesMap
);

export const selectQuestionnaireExists = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => !!state.questionnairesMap[id]
);

export const selectQuestionnaire = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id]
);


export const selectQuestionnaireTitle = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].title
);

export const selectQuestionnaireDoctor = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].doctor
);

export const selectQuestionnaireDueDate = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].dueDate
);

export const selectQuestionnaireTags = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].tags
);

export const selectQuestionnaireDescription = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].description
);

export const selectQuestionnaireCopyright = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].copyright
);

export const selectQuestionnaireOwnerLink = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].ownerLink
);

export const selectQuestionnaireOwnerName = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].ownerName
);

export const selectQuestionnaireCommentsEnabled = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].commentsEnabled
);

export const selectFinishModalDataForQuestionnaire = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => {
    const { doctor, title, saveModalType, scope } = state.questionnairesMap[id];

    return { doctor, title, saveModalType, scope };
  }
);

export const selectQuestionnaireQuestions = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].questions
);

export const selectQuestionnaireSelectedQuestionIndex = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].selectedQuestionIndexInOrder
);

export const isFirstQuestionSelected = createSelector(
  selectQuestionnaires,
  (state: State, id: string) =>
    state.questionnairesMap[id].selectedQuestionIndexInOrder === 0
);


export const isLastQuestionSelected = createSelector(
  selectQuestionnaires,
  (state: State, id: string) =>
    state.questionnairesMap[id].selectedQuestionIndexInOrder === state.questionnairesMap[id].questions.length - 1
);

export const selectQuestionnaireSelectedQuestion = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => {
    const { questions, selectedQuestionIndexInOrder } = state.questionnairesMap[id];

    return questions[selectedQuestionIndexInOrder];
  }
);

export const selectQuestionnaireSelectedQuestionAnswer = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => {
    const { answers, questions, selectedQuestionIndexInOrder } = state.questionnairesMap[id];
    const questionId = questions[selectedQuestionIndexInOrder].id;

    return answers[questionId];
  }
);

export const selectIsQuestionnaireFinished = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].isFinished
);

export const selectAnswers = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].answers
);

export const selectScope = createSelector(
  selectQuestionnaires,
  (state: State, id: string) => state.questionnairesMap[id].scope
);
