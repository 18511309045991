import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import { Question } from '../../interfaces/question.interface';
import { saveQuestionAnswer } from '../../state/questionnaires.actions';
import { Store } from '@ngrx/store';
import { QuestionType } from '../../enums/question-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IdkModalComponent } from '../idk-modal/idk-modal.component';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';
import { positiveHealthQuestionnaireAlias } from '@app/medrecord/questionnaire-adapter/constants/positive-health-questionnaire-alias';

@Component({
  selector: 'medrecord-question-type-switch',
  templateUrl: './question-type-switch.component.html',
  styleUrls: ['./question-type-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionTypeSwitchComponent {
  @Input() isPositiveHealth = false;
  @Input() isSubQuestion = false;
  @Input() question: Question;
  @Input() answers: any;
  @Input() visited: boolean;
  @Input() isReadonly: boolean;

  QuestionType = QuestionType;

  constructor(
    private store: Store,
    private modalService: NgbModal,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {
  }

  onAnswer(answer: any): void {
    this.store.dispatch(saveQuestionAnswer({
      questionnaireId: !this.isPositiveHealth ? this.environment.interraiQuestionnaireId : positiveHealthQuestionnaireAlias,
      questionId: this.question.id,
      answer,
    }));

  }

  showIDK() {
    const modalInstance = this.modalService.open(IdkModalComponent)
      .componentInstance as IdkModalComponent;

    modalInstance.questionId = this.question.id;
    modalInstance.questionnaireId = !this.isPositiveHealth ? this.environment.interraiQuestionnaireId : positiveHealthQuestionnaireAlias;
  }

}
