import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QuestionTypeBaseComponent } from '../../../base/question-type-base.component';
import { FormBuilder } from '@angular/forms';
import { timeFromNumber } from '../../../utils/time-from-number.util';
import { ValidationService } from '@medrecord/services-form-validation';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'medrecord-question-time',
  templateUrl: './question-time.component.html',
  styleUrls: ['./question-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionTimeComponent extends QuestionTypeBaseComponent {

  minValue = 0;
  maxValue = 23.59;

  maxFractionsValue = 0.59; // max minutes value

  constructor(
    protected fb: FormBuilder,
    protected translateService: TranslateService,
    protected validatorsService: ValidationService,
  ) {
    super(fb);
  }

  initForm(): void {
    this.form = this.fb.group({
      answer: [
        this.currentAnswer || null,
        [
          this.validatorsService.decimalNumber(this.translateService.instant('validation.only_number')),
          this.validatorsService.minLength(
            this.minValue,
            this.translateService.instant('validation.min', { number: this.minValue })
          ),
          this.validatorsService.maxLength(
            this.maxValue,
            this.translateService.instant('validation.max', { number: this.maxValue })
          ),
        ]
      ]
    });
  }

  getCurrentAnswerText(answer: any): string {
    if (typeof answer === 'number') {
      const answerStr = timeFromNumber(answer);
      return `${answerStr} ${this.question.unitOfMeasure || ''}`.trim();
    }

    return '';
  }

}
