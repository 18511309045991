import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const careTeamBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.careTeam,
  isResourceFromField: true,
  fieldForResources: 'participants',
  fields: [
    {
      label: 'my_doctor_last_name',
      fieldName: 'reference',
      prepareData: (data, allItems) => {
        const practitioner = allItems.find((item) => `${item.resourceType}/${item.resourceId}` === data.reference);
        return practitioner?.content?.name?.family;
      },
    },
  ],
  fieldGroups: [
    {
      name: ({ resource }) => resource.content?.healthcareProviderReference?.display || 'my_doctor_healthcare',
      fields: [
        {
          label: 'my_doctor_last_name',
          fieldName: 'reference',
          prepareData: (data, allItems) => {
            const practitioner = allItems.find((item) => `${item.resourceType}/${item.resourceId}` === data.reference);
            return practitioner?.content?.name?.family;
          },
        },
        {
          label: 'my_doctor_specialist',
          fieldName: 'specialtyText',
          prepareData: (data, allItems) => {
            const practitionerRole = allItems.find(
              (item) => `${item.resourceType}/${item.resourceId}` === data.reference
            );
            const { code, display } = practitionerRole?.content?.specialties?.[0]?.coding?.[0];
            return `${code} - ${display}`;
          },
        },
        {
          label: 'my_doctor_practitioner_role',
          fieldName: 'roleCode',
          translate: true,
          prepareData: (data) => (data ? `my_doctor_practitioner_role_${data.toLowerCase()}` : ''),
        },
        {
          name: 'my_doctor_address',
          fieldAsSubgroup: true,
          fields: [
            {
              label: 'my_doctor_street',
              fieldName: 'reference',
              prepareData: (data, allItems) => {
                const practitioner = allItems.find(
                  (item) => `${item.resourceType}/${item.resourceId}` === data.reference
                );
                return practitioner?.content?.addressList?.[0]?.streetName;
              },
            },
            {
              label: 'my_doctor_house_number',
              fieldName: 'reference',
              prepareData: (data, allItems) => {
                const practitioner = allItems.find(
                  (item) => `${item.resourceType}/${item.resourceId}` === data.reference
                );
                return practitioner?.content?.addressList?.[0]?.houseNumber;
              },
            },
            {
              label: 'my_doctor_city',
              fieldName: 'reference',
              prepareData: (data, allItems) => {
                const practitioner = allItems.find(
                  (item) => `${item.resourceType}/${item.resourceId}` === data.reference
                );
                return practitioner?.content?.addressList?.[0]?.city;
              },
            },
            {
              label: 'my_doctor_country',
              fieldName: 'reference',
              prepareData: (data, allItems) => {
                const practitioner = allItems.find(
                  (item) => `${item.resourceType}/${item.resourceId}` === data.reference
                );
                return practitioner?.content?.addressList?.[0]?.country;
              },
            },
            {
              label: 'my_doctor_address_type',
              fieldName: 'reference',
              prepareData: (data, allItems) => {
                const practitioner = allItems.find(
                  (item) => `${item.resourceType}/${item.resourceId}` === data.reference
                );
                return practitioner?.content?.addressList?.[0]?.use;
              },
            },
          ],
        },
        {
          name: 'my_doctor_contact_information',
          fieldAsSubgroup: true,
          fields: [
            {
              label: 'my_doctor_phone_number',
              fieldName: 'reference',
              prepareData: (data, allItems) => {
                const practitioner = allItems.find(
                  (item) => `${item.resourceType}/${item.resourceId}` === data.reference
                );
                const phone = practitioner?.content?.contactList?.find(({ system }) => system === 'PHONE');
                return phone?.value;
              },
            },
            {
              label: 'my_doctor_telecom_type',
              fieldName: 'reference',
              prepareData: (data, allItems) => {
                const practitioner = allItems.find(
                  (item) => `${item.resourceType}/${item.resourceId}` === data.reference
                );
                const phone = practitioner?.content?.contactList?.find(({ system }) => system === 'PHONE');
                return phone?.telecomType?.coding?.[0]?.display;
              },
            },
            {
              label: 'my_doctor_phone_type',
              fieldName: 'reference',
              prepareData: (data, allItems) => {
                const practitioner = allItems.find(
                  (item) => `${item.resourceType}/${item.resourceId}` === data.reference
                );
                const phone = practitioner?.content?.contactList?.find(({ system }) => system === 'PHONE');
                return phone?.use;
              },
            },
            {
              label: 'my_doctor_email',
              fieldName: 'reference',
              prepareData: (data, allItems) => {
                const practitioner = allItems.find(
                  (item) => `${item.resourceType}/${item.resourceId}` === data.reference
                );
                const email = practitioner?.content?.contactList?.find(({ system }) => system === 'EMAIL');
                return email?.value;
              },
            },
            {
              label: 'my_doctor_email_type',
              fieldName: 'reference',
              prepareData: (data, allItems) => {
                const practitioner = allItems.find(
                  (item) => `${item.resourceType}/${item.resourceId}` === data.reference
                );
                const email = practitioner?.content?.contactList?.find(({ system }) => system === 'EMAIL');
                return email?.use;
              },
            },
          ],
        },
      ],
    },
  ],
});
