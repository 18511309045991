<label [class]="'text-' + type"
       [for]="for"
       class="text-md font-weight-bold">
  {{ text }}
  <span *ngIf="secondaryText" class="font-weight-normal">
    {{ secondaryText }}
  </span>

  <medrecord-hint *ngIf="hint" [hint]="hint"></medrecord-hint>
</label>
