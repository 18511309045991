import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {selectUserId} from '@medrecord/managers-auth';

@Component({
  selector: 'zdb-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss']
})
export class PatientProfileComponent implements OnInit {

  userId$: Observable<string>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    // FIXME: retrieve the patient, not the currently logged in user
    this.userId$ = this.store.select(selectUserId);
  }

}
