import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const languageProficiencyBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.patient,
  isResourceFromField: true,
  fieldForResources: 'communications',
  fieldGroups: [
    {
      name: ({ resource }) => resource.content?.language?.coding?.[0]?.display || 'my_doctor_language',
      fields: [
        {
          label: 'my_doctor_language_understanding',
          fieldName: 'languageProficiencyList',
          prepareData: (data) => {
            const item = data.find(({ type }) => type.code === 'RSP') || {};
            return item?.level?.display;
          },
        },
        {
          label: 'my_doctor_language_speaking',
          fieldName: 'languageProficiencyList',
          prepareData: (data) => {
            const item = data.find(({ type }) => type.code === 'ESP') || {};
            return item?.level?.display;
          },
        },
        {
          label: 'my_doctor_language_reading',
          fieldName: 'languageProficiencyList',
          prepareData: (data) => {
            const item = data.find(({ type }) => type.code === 'RWR') || {};
            return item?.level?.display;
          },
        },
      ],
    },
  ],
});
