import { MedrecordMoment } from '@medrecord/services-datetime';
import { PullTransaction } from '../models/enums/pull-transaction.enum';
import { ResourceGroup } from '../models/enums/resource-group.enum';
import { Resource } from '../models/enums/resource.enum';

export const pullTransactionMappings = [
  {
    transactionId: PullTransaction.bgz,
    resourceGroups: {
      [ResourceGroup.treatmentDirectives]: [
        Resource.treatmentDirective,
        Resource.advanceDirective,
      ],
      [ResourceGroup.socialHistory]: [
        Resource.livingSituation,
        Resource.drugUse,
        Resource.alcoholUse,
        Resource.tobaccoUse,
        Resource.nutritionAdvice,
      ],
      [ResourceGroup.medication]: [
        Resource.medicationUse,
        Resource.medicationAgreement,
        Resource.administrationAgreement,
      ],
      [ResourceGroup.vitalSigns]: [
        Resource.bloodPressure,
        Resource.bodyWeight,
        Resource.bodyHeight,
      ],
      [ResourceGroup.plannedCareActivities]: [
        Resource.plannedCareActivityProcedureRequest,
        Resource.plannedCareActivityImmunizationRecommendation,
        Resource.plannedCareActivityAppointment,
        Resource.plannedCareActivityDeviceRequest,
      ],
    },
    resourceKeys: [
      ResourceGroup.treatmentDirectives,
      Resource.contactPerson,
      Resource.functionalOrMentalStatus,
      Resource.concern,
      ResourceGroup.socialHistory,
      Resource.alert,
      Resource.allergyIntolerance,
      ResourceGroup.medication,
      Resource.medicalAid,
      Resource.vaccination,
      ResourceGroup.vitalSigns,
      Resource.laboratoryTestResult,
      Resource.procedure,
      Resource.encounter,
      ResourceGroup.plannedCareActivities,
      Resource.healthProfessional,
      Resource.patient,
    ],
  },
  {
    transactionId: PullTransaction.bgz2020,
    resourceGroups: {
      [ResourceGroup.treatmentDirectives]: [
        Resource.bgzTreatmentDirective2020,
        Resource.bgzAdvanceDirective2020,
      ],
      [ResourceGroup.socialHistory]: [
        Resource.bgzLivingSituation2020,
        Resource.bgzDrugUse2020,
        Resource.bgzAlcoholUse2020,
        Resource.bgzTobaccoUse2020,
        Resource.bgzNutritionAdvice2020,
      ],
      [ResourceGroup.medication]: [
        Resource.bgzMedicationUse2020,
        Resource.bgzMedicationAgreement2020,
        Resource.bgzAdministrationAgreement2020,
      ],
      [ResourceGroup.vitalSigns]: [
        Resource.bgzBloodPressure2020,
        Resource.bgzBodyWeight2020,
        Resource.bgzBodyHeight2020,
      ],
      [ResourceGroup.plannedCareActivities]: [
        Resource.bgzPlannedCareActivityProcedureRequest2020,
        Resource.bgzPlannedCareActivityImmunizationRecommendation2020,
        Resource.bgzPlannedCareActivityAppointment2020,
        Resource.bgzPlannedCareActivityDeviceRequest2020,
      ],
    },
    resourceKeys: [
      ResourceGroup.treatmentDirectives,
      Resource.bgzContactPerson2020,
      Resource.bgzFunctionalOrMentalStatus2020,
      Resource.bgzConcern2020,
      ResourceGroup.socialHistory,
      Resource.bgzAlert2020,
      Resource.bgzAllergyIntolerance2020,
      ResourceGroup.medication,
      Resource.bgzMedicalAid2020,
      Resource.bgzVaccination2020,
      ResourceGroup.vitalSigns,
      Resource.bgzLaboratoryTestResult2020,
      Resource.bgzProcedure2020,
      Resource.bgzEncounter2020,
      ResourceGroup.plannedCareActivities,
      Resource.bgzHealthProfessional2020,
      Resource.bgzPatient2020,
    ],
  },
  {
    transactionId: PullTransaction.gp,
    resourceKeys: [
      Resource.gpEpisode,
      Resource.gpMedicationIntolerance,
      Resource.gpSoap,
      Resource.gpPractitioner,
      Resource.gpPatient,
    ],
  },
  {
    transactionId: PullTransaction.gp2020,
    resourceKeys: [
      Resource.gpEpisode2020,
      Resource.gpMedicationIntolerance2020,
      Resource.gpSoap2020,
      Resource.gpPractitioner2020,
      Resource.gpPatient2020,
      Resource.gpEncounter2020,
      Resource.gpCurrentMedication2020,
      Resource.gpLaboratoryResults2020,
    ],
    parameters: [
      // Laboratory results and current medication parameters
      {
        resourceKeys: [Resource.gpLaboratoryResults2020, Resource.gpCurrentMedication2020],
        type: 'date',
        name: 'start-date',
        label: 'my_doctor_start_date',
        placeholder: 'my_doctor_start_date',
      },
    ],
  },
  {
    transactionId: PullTransaction.pdfA2020,
    resourceKeys: [Resource.pdfMetadataDocref, Resource.pdfMetadataManifest],
    additionalResources: [Resource.pdfMetadataDocref],
    parameters: [
      // Metadata document reference parameters
      {
        resourceKeys: [Resource.pdfMetadataDocref, Resource.pdfMetadataManifest],
        type: 'dropdown',
        options: [
          { id: 'CURRENT', text: 'CURRENT' },
          { id: 'SUPERSEDED', text: 'SUPERSEDED' },
          { id: 'ENTEREDINERROR', text: 'ENTEREDINERROR' },
        ],
        defaultValue: 'CURRENT',
        defaultValues: ['CURRENT', 'SUPERSEDED'],
        name: 'status',
        label: 'my_doctor_status',
        placeholder: 'my_doctor_status',
      },
      {
        resourceKeys: [Resource.pdfMetadataDocref, Resource.pdfMetadataManifest],
        type: 'date',
        name: 'start-date',
        label: 'my_doctor_start_date',
        placeholder: 'my_doctor_start_date',
        defaultValue: MedrecordMoment(new Date().setMonth(new Date().getMonth() - 6)).format('YYYY-MM-DD'),
      },
      {
        resourceKeys: [Resource.pdfMetadataDocref, Resource.pdfMetadataManifest],
        type: 'date',
        name: 'end-date',
        label: 'my_doctor_end_date',
        placeholder: 'my_doctor_end_date',
        defaultValue: MedrecordMoment(new Date()).format('YYYY-MM-DD'),
      },
    ],
  },
  // BgLZ data service
  {
    transactionId: PullTransaction.bglz2020,
    resourceGroups: {
      [ResourceGroup.treatmentDirectives]: [
        Resource.bglzTreatmentDirective2020,
        Resource.bglzAdvanceDirective2020,
      ],
    },
    resourceKeys: [
      Resource.bglzPatient2020,
      ResourceGroup.treatmentDirectives,
      Resource.bglzContactPerson2020,
      Resource.bglzProblem2020,
      Resource.bglzAllergyIntolerance2020,
      Resource.bglzLaboratoryTestResult2020,
      Resource.bglzProcedure2020,
      Resource.bglzGoalsAndInterventions2020,
      Resource.bglzCaresettingCareteam2020,
    ],
  },
  // Bg GGZ data service
  {
    transactionId: PullTransaction.ggz2020,
    resourceGroups: {
      [ResourceGroup.treatmentDirectives]: [
        Resource.ggzTreatmentDirective2020,
        Resource.ggzAdvanceDirective2020,
        Resource.ggzLegalStatus2020,
      ],
      [ResourceGroup.substanceUse]: [
        Resource.ggzAlcoholUse2020,
        Resource.ggzDrugUse2020,
        Resource.ggzTobaccoUse2020,
      ],
      [ResourceGroup.socialHistory]: [
        Resource.ggzLivingSituation2020,
        Resource.ggzFamilySituation2020,
        Resource.ggzLanguageProficiency2020,
        Resource.ggzParticipationInSociety2020,
        Resource.ggzHelpFromOthers2020,
      ],
      [ResourceGroup.results]: [
        Resource.ggzLaboratoryTestResult2020,
        Resource.ggzGeneralMeasurement2020,
        Resource.ggzProcedure2020,
        Resource.ggzTextResult2020,
      ],
    },
    resourceKeys: [
      Resource.ggzPatient2020,
      Resource.ggzPayer2020,
      ResourceGroup.treatmentDirectives,
      Resource.ggzContactPerson2020,
      Resource.ggzFuncOrMentalStatus2020,
      Resource.ggzProblem2020,
      ResourceGroup.substanceUse,
      ResourceGroup.socialHistory,
      ResourceGroup.results,
      Resource.ggzCareTeam2020,
    ],
  },
];
