import { createAction, props } from '@ngrx/store';
import { Careprovider } from '../models/interfaces/careprovider.interface';
import { CareproviderLoadPayload } from '../models/interfaces/careprovider-load-payload.interface';
import { CareproviderResource } from '../models/interfaces/careprovider-resource.interface';
import { FetchResourcesPayload } from '../models/interfaces/fetch-resources-payload.interface';
import { FetchListType } from '../models/enums/fetch-list-type.enum';

export const loadCareprovidersAction = createAction(
  '[My Doctor Manager Actions] Load Careproviders',
  props<{ payload: CareproviderLoadPayload }>()
);

export const loadCareprovidersSuccessAction = createAction(
  '[My Doctor Manager Actions] Load Careproviders Success',
  props<{ careproviders: Careprovider[]; count: number }>()
);

export const loadCareprovidersFailureAction = createAction(
  '[My Doctor Manager Actions] Load Careproviders Failure',
  props<{ error: any }>()
);

export const loadCareproviderAction = createAction(
  '[My Doctor Manager Actions] Load Careprovider',
  props<{ careproviderId: string }>()
);

export const loadCareproviderSuccessAction = createAction(
  '[My Doctor Manager Actions] Load Careprovider Success',
  props<{ careprovider: Careprovider }>()
);

export const clearActiveCareproviderAction = createAction('[My Doctor Manager Actions] Clear Active Careprovider');

export const loadResourcesAction = createAction(
  '[My Doctor Manager Actions] Load Resources',
  props<{ resourceKeys: string[] }>()
);

export const loadResourcesSuccessAction = createAction(
  '[My Doctor Manager Actions] Load Resources Success',
  props<{ resources: CareproviderResource[] }>()
);

export const loadResourcesFailureAction = createAction(
  '[My Doctor Manager Actions] Load Resources Failure',
  props<{ error: any }>()
);

export const clearResourcesAction = createAction('[My Doctor Manager Actions] Clear Resources');

export const fetchCareproviderDataAction = createAction(
  '[My Doctor Manager Actions] Fetch Careprovider Data',
  props<{ payload: FetchResourcesPayload; dataserviceId: string; transactionId: string }>()
);

export const fetchCareproviderDataSuccessAction = createAction(
  '[My Doctor Manager Actions] Fetch Careprovider Data Success',
  props<{ data: any }>()
);

export const fetchCareproviderDataFailureAction = createAction(
  '[My Doctor Manager Actions] Fetch Careprovider Data Failure',
  props<{ error: any }>()
);

export const performAuthorizationForCareproviderAction = createAction(
  '[My Doctor Manager Actions] Perform Authorization For Careprovider',
  props<{ dataserviceId: string; refetchPayload: FetchResourcesPayload; transactionId: string }>()
);

export const fetchResourceDataAction = createAction(
  '[My Doctor Manager Actions] Fetch Resource Data',
  props<{ payload: FetchResourcesPayload }>()
);

export const fetchResourceDataSuccessAction = createAction(
  '[My Doctor Manager Actions] Fetch Resource Data Success',
  props<{ data: any }>()
);

export const fetchResourceDataSuccessActionFailure = createAction(
  '[My Doctor Manager Actions] Fetch Resource Data Failure',
  props<{ error: any }>()
);

export const loadLogsAction = createAction(
  '[My Doctor Manager Actions] Load Logs',
  props<{ careproviderId: string }>()
);

export const loadLogsSuccessAction = createAction(
  '[My Doctor Manager Actions] Load Logs Success',
  props<{ logs: any }>()
);

export const loadLogsFailureAction = createAction(
  '[My Doctor Manager Actions] Load Logs Failure',
  props<{ error: any }>()
);

export const loadListAction = createAction(
  '[My Doctor Manager Actions] Load List',
  props<{ listType: FetchListType }>()
);

export const loadListSuccessAction = createAction(
  '[My Doctor Manager Actions] Load List Success',
  props<{ data: any }>()
);

export const loadListFailureAction = createAction(
  '[My Doctor Manager Actions] Load List Failure',
  props<{ error: any }>()
);

export const downloadPortabilityReportAction = createAction(
  '[My Doctor Manager Actions] Download MedMij Maintenance Report',
  props<{ startDate: string; endDate: string }>()
);