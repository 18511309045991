<div *ngIf="label"
     class="label-wrapper">
  <label [for]="inputId">{{label}} <span *ngIf="labelDescription"> ({{ labelDescription }})</span></label>
</div>

<div class="inputs-row">
  <div class="numeric-input-wrapper"
       [class.has-units-of-measure]="hasUnitsOfMeasure && !showFractions"
       [class.disabed]="disabled"
       [style.width.px]="integerInputWidth"
  >
    <input type="text" *ngIf="!disabled"
           class="input"
           tabindex="1"
           placeholder="--"
           [id]="inputId"
           [disabled]="disabled"
           [min]="minIntegerValue"
           [max]="maxIntegerValue"
           (input)="onIntegerInput($event.target?.value)"
           (blur)="onTouched()"
           mask="09*"
           #integerInput
    >
    <ng-container *ngIf="disabled">{{integerValue}}</ng-container>

    <div class="numeric-buttons" *ngIf="!disabled">
      <button class="up"
              tabindex="-1"
              [disabled]="disabled"
              (click)="incrementIntegerInput($event)">
        <div class="chevron-up"></div>
<!--        <med-icon icon="chevron-up"></med-icon>-->
      </button>
      <button class="down"
              tabindex="-1"
              [disabled]="disabled"
              (click)="decrementIntegerInput($event)">
        <div class="chevron-down"></div>
<!--        <med-icon icon="chevron-down"></med-icon>-->
      </button>
    </div>

    <div class="units-of-measure" *ngIf="hasUnitsOfMeasure && !showFractions">
      {{ unitsOfMeasure }}
    </div>
  </div>

  <div class="comma" *ngIf="showFractions">
    {{ comma }}
  </div>

  <div class="numeric-input-wrapper"
       [style.width.px]="fractionInputWidth"
       [class.has-units-of-measure]="hasUnitsOfMeasure && showFractions"
       [class.disabed]="disabled"
       *ngIf="showFractions">

    <input type="text" *ngIf="!disabled"
           class="input"
           tabindex="1"
           [disabled]="disabled"
           [min]="minFractionValue"
           [maxLength]="defaultFractionDigitsNumber"
           (input)="onFractionInput($event.target?.value)"
           (blur)="onTouched()"
           mask="0*"
           #fractionInput
    >
    <ng-container *ngIf="disabled">{{fractionValue}}</ng-container>

    <div class="units-of-measure" *ngIf="hasUnitsOfMeasure">
      {{ unitsOfMeasure }}
    </div>
  </div>
</div>
