<h4 *ngIf="!isSubQuestion; else subQuestionTitle" class="title">
  {{ question.questionText }}
  <span *ngIf="question.required" class="title-label">
    ({{ 'common_required' | translate }})
  </span>
</h4>

<div [class.subquestion]="isSubQuestion"
     [ngSwitch]="question.type"
     class="question-form-wrapper"
>

  <div *ngSwitchCase="QuestionType.display"></div>

  <medrecord-choice-radio-question (answer)="onAnswer($event)"
                                   *ngSwitchCase="QuestionType.singleChoice"
                                   [currentAnswer]="answers"
                                   [question]="question"
                                   [isReadonly]="isReadonly"
  ></medrecord-choice-radio-question>

  <medrecord-boolean-question (answer)="onAnswer($event)"
                              *ngSwitchCase="QuestionType.boolean"
                              [currentAnswer]="answers"
                              [question]="question"
                              [isReadonly]="isReadonly"
  ></medrecord-boolean-question>

  <medrecord-string-question (answer)="onAnswer($event)"
                             *ngSwitchCase="QuestionType.string"
                             [currentAnswer]="answers"
                             [question]="question"
                             [isReadonly]="isReadonly"
  ></medrecord-string-question>

  <medrecord-text-question (answer)="onAnswer($event)"
                           *ngSwitchCase="QuestionType.text"
                           [currentAnswer]="answers"
                           [question]="question"
                           [isReadonly]="isReadonly"
  ></medrecord-text-question>

  <medrecord-question-date (answer)="onAnswer($event)"
                           *ngSwitchCase="QuestionType.date"
                           [currentAnswer]="answers"
                           [question]="question"
                           [isReadonly]="isReadonly"
  ></medrecord-question-date>

  <medrecord-question-datetime (answer)="onAnswer($event)"
                               *ngSwitchCase="QuestionType.datetime"
                               [currentAnswer]="answers"
                               [question]="question"
                               [isReadonly]="isReadonly"
  ></medrecord-question-datetime>

  <medrecord-question-time (answer)="onAnswer($event)"
                           *ngSwitchCase="QuestionType.time"
                           [currentAnswer]="answers"
                           [question]="question"
                           [isReadonly]="isReadonly"
  ></medrecord-question-time>

  <medrecord-question-decimal (answer)="onAnswer($event)"
                              *ngSwitchCase="QuestionType.decimal"
                              [currentAnswer]="answers"
                              [question]="question"
                              [isReadonly]="isReadonly"
  ></medrecord-question-decimal>

  <medrecord-question-integer (answer)="onAnswer($event)"
                              *ngSwitchCase="QuestionType.integer"
                              [currentAnswer]="answers"
                              [isPositiveHealth]="isPositiveHealth"
                              [question]="question"
                              [isReadonly]="isReadonly"
  ></medrecord-question-integer>

  <medrecord-question-integer (answer)="onAnswer($event)"
                              *ngSwitchCase="QuestionType.quantity"
                              [currentAnswer]="answers"
                              [question]="question"
                              [isReadonly]="isReadonly"
  ></medrecord-question-integer>

  <div *ngSwitchDefault
       class="question-not-supported">
    {{ 'common_question_not_supported' | translate }} ({{ question.type }})
  </div>

  <button
    (click)="showIDK()"
    *ngIf="question.idkAllowed"
    class="btn btn-secondary idk-button"
  >
    {{ answers && answers[0] && answers[0].value === 'idk' ? 'common_select_another_reason' : 'common_idk' | translate }}
  </button>
</div>

<ng-template #subQuestionTitle>
  <h5 class="title" [class.unanswered]="visited && !!answers === false">
    {{ question.questionText }}
    <span *ngIf="question.required" class="title-label">
      ({{ 'common_required' | translate }})
    </span>
  </h5>
</ng-template>
