const bodilyFunctions = {
  order: 1,
  title: 'Lichaamsfuncties',
  color: '#DC1F36',
  colorLight: '#DCC9CC',
  svg: 'bodily-functions.svg',
};

const mentalWellBeing = {
  order: 2,
  title: 'Mentaal welbevinden',
  color: '#1FA4DE',
  colorLight: '#C9D7DD',
  svg: 'mental-well-being.svg',
};

const meaning = {
  order: 3,
  title: 'Zingeving',
  color: '#832E88',
  colorLight: '#D3CBD4',
  svg: 'meaning.svg',
};

const qualityOfLife = {
  order: 4,
  title: 'Kwaliteit van leven',
  color: '#F59C0E',
  colorLight: '#DFD6C7',
  svg: 'quality-of-life.svg',
};

const participation = {
  order: 5,
  title: 'Meedoen',
  color: '#EE7210',
  colorLight: '#DED2C8',
  svg: 'participation.svg',
};

const dailyFunction = {
  order: 6,
  title: 'Dagelijks functioneren',
  color: '#96C125',
  colorLight: '#D5DACA',
  svg: 'daily-function.svg',
};

export const positiveHealthConfigMapper = {
  4210: bodilyFunctions,
  4209: mentalWellBeing,
  4211: meaning,
  4213: qualityOfLife,
  4208: participation,
  4212: dailyFunction,

  2081: bodilyFunctions,
  2080: mentalWellBeing,
  2082: meaning,
  2084: qualityOfLife,
  2079: participation,
  2083: dailyFunction,
};
