import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { IOrganization } from './../network.model';
import { IOrganizationWithExtraInfo } from '@app/network/network.model';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { selectUserId } from '@medrecord/managers-auth';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  constructor(
    private http: HttpClient,
    private store: Store,

    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) { }

  loadOrganizations(): Observable<IOrganization[]> {
    return this.http.get<IOrganization[]>(
      `${this.environment.backend}/network/organization`
    );
  }

  getUserNetwork(userId: string): Observable<any> {
    return this.http.get<any>(`${this.environment.backend}/person/${ userId }/network`);
  }

  fetchCurrentUserOrganizations(): Observable<IOrganizationWithExtraInfo[]> {
    return this.loadOrganizations().pipe(
      withLatestFrom(this.store.pipe(select(selectUserId))),
      mergeMap(
        ([organizations, userId]) => this.getUserNetwork(userId).pipe(
          map((userNetwork) => {
            return organizations.map(org => ({
              ...org,
              connectedToCurrentUser: !!userNetwork.filter(
                item => item.resourceType === 'Organization' && org.id === item.id
              ).length
            }));
        }))
      )
    );
  }

  addUserToOrgganization(orgId: string, userId: string): Observable<any> {
    return this.http.post<any>(
      `${this.environment.backend}/person/${ userId }/network/organization`,
      {
        id: orgId,
        permission: 'READ_ACCOUNT'
      });
  }

  removeUserFromOrgganization(orgId: string, userId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.environment.backend}/person/${ userId }/network/organization/${ orgId }`
    );
  }

}
