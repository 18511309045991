import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const allergyIntoleranceBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.allergyIntolerance,
  fieldGroups: [
    {
      name: ({ resource }) => resource.content?.code?.coding?.[0]?.display || 'my_doctor_allergy',
      fields: [
        {
          label: 'my_doctor_producing_fabric',
          fieldName: 'code',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_allergy_category',
          fieldName: 'category',
          translate: true,
          prepareData: (data) => data?.category?.toLowerCase(),
        },
        {
          label: 'my_doctor_allergy_status',
          fieldName: 'clinicalStatus',
          translate: true,
          prepareData: (data) => data?.toLowerCase(),
        },
        {
          label: 'my_doctor_start_date',
          fieldName: 'onset',
          prepareData: (data) => (data?.date ? MedrecordMoment(data?.date).format('LL') : ''),
        },
        {
          label: 'my_doctor_allergy_criticality',
          fieldName: 'criticality',
          translate: true,
          prepareData: (data) => data?.toLowerCase(),
        },
        {
          label: 'my_doctor_last_occurence',
          fieldName: 'lastOccurrence',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LT') : ''),
        },
        {
          label: 'my_doctor_explanation',
          fieldName: 'note',
          prepareData: (data) => data.map((n) => n.text).join('\n'),
        },
        {
          name: 'my_doctor_reactions',
          fieldAsSubgroup: true,
          fields: [
            {
              label: 'my_doctor_symptom',
              fieldName: 'reactionList',
              prepareData: (data) => data?.[0]?.manifestation?.[0]?.coding?.[0]?.display,
            },
            {
              label: 'my_doctor_description',
              fieldName: 'reactionList',
              prepareData: (data) => data?.[0]?.description,
            },
            {
              label: 'my_doctor_severity',
              fieldName: 'reactionList',
              prepareData: (data) => data?.[0]?.severity,
            },
            {
              label: 'my_doctor_expose_route',
              fieldName: 'reactionList',
              prepareData: (data) => data?.[0]?.exposureRoute?.coding?.[0]?.display,
            },
            {
              label: 'my_doctor_start_date',
              fieldName: 'reactionList',
              prepareData: (data) => (data?.[0]?.onset ? MedrecordMoment(data?.[0]?.onset).format('LL') : ''),
            },
          ],
        },
      ],
    },
  ],
});
