<zdb-main-layout>
    <zdb-card>
        <div class="card-header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-10 col-xl-7">
                    <div class="d-flex justify-content-between">
                        <h3 class="font-weight-bold">
                            {{ 'patient_edit.title' | translate }}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <ng-container *ngIf="{ userId: userId$ | async, user: user$ | async } as userDetails">
                <zdb-user-form [userId]="userDetails.userId" [user]="userDetails.user"></zdb-user-form>
            </ng-container>
        </div>
    </zdb-card>
</zdb-main-layout>
