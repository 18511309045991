import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import * as UserDataActions from '@app/core/user/state/user-data.actions';
import {loadUserDataSuccess} from '@app/core/user/state/user-data.actions';
import {selectCurrentUser} from '@app/core/user/state/user-data.selectors';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {first, map, switchMap, take} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CurrentUserGuard implements CanActivate {

  constructor(private store: Store,
              private actions$: Actions) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(selectCurrentUser).pipe(
      take(1),
      switchMap(value => {
        if (!!value) {
          return of(true);
        }

        this.store.dispatch(UserDataActions.loadUserData());

        return this.actions$.pipe(
          ofType(loadUserDataSuccess),
          first(),
          map(() => {
            return true;
          })
        );
      }));
  }
}
