import { Questionnaire } from '../interfaces/questionnaire.interface';
import { SaveModalType } from '../enums/save-modal-type.enum';
import { toAnswers } from './questionnaire-response-to-answers.util';
import { getDueDate } from './questions-helper.util';
import { getQuestions } from './questions-parsing.util';
import { TranslateService } from '@ngx-translate/core';

export const mapResourceToQuestionnaire = (
  resource: any,
  questionnaireScope,
  saveModalType: SaveModalType,
  translateService: TranslateService,
  answers: { items: any[] },
  patientName: any = {}
): { id: string, questionnaire: Questionnaire } => {
  return {
    id: resource.id,
    questionnaire: {
      saveModalType,
      scope: questionnaireScope,

      title: resource.title,
      doctor: '',

      dueDate: getDueDate(resource.date, resource.effectivePeriod),
      tags: [resource.version, resource.status],
      description: resource.description,

      copyright: resource.copyright,
      ownerLink: resource.url,
      ownerName: resource.url,

      commentsEnabled: false,
      questions: getQuestions(resource.items, translateService),

      answers: toAnswers(answers),

      patientName
    }
  };

};
