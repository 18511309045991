import * as UserDataActions from './user-data.actions';
import { Action, createReducer, on } from '@ngrx/store';

import { IZDBUser } from '@app/core/user/user.model';

export const userDataFeatureKey = 'user-metadata';

export interface UserDataState {
  current_user: IZDBUser;
  patient: IZDBUser;
  error: any;
}

export const initialState: UserDataState = {
  current_user: null,
  patient: null,
  error: null
};

const userDataReducer = createReducer<UserDataState>(
  initialState,
  on(UserDataActions.loadUserData, (state) => ({
    ...state,
    error: null,
  })),
  on(UserDataActions.loadUserDataSuccess, (state, { current_user, patient }) => {
    return {
      ...state,
      current_user,
      patient
    };
  }),
  on(UserDataActions.loadUserDataFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UserDataActions.emptyUserData, () => ({ ...initialState }))
);

export function reducer(state: UserDataState, action: Action): UserDataState {
  return userDataReducer(state, action);
}
