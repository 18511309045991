import { Injectable } from '@angular/core';
import { ActivatedRoute, ChildActivationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ZDBRouteData } from 'src/app/core/navigation/navigation.model';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private sideNavVisible$ = new BehaviorSubject(true);
  private zdbRouteData$ = new BehaviorSubject({} as ZDBRouteData);

  private zdbCurrentURL$ = new BehaviorSubject('');

  constructor(public route: ActivatedRoute, public router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof ChildActivationEnd))
      .subscribe(event => {
        let snapshot = (event as ChildActivationEnd).snapshot;
        while (snapshot.firstChild !== null) {
          snapshot = snapshot.firstChild;
        }
        this.zdbCurrentURL$.next(router.url);
      });
  }

  isSideNavVisible$(): Observable<boolean> {
    return this.sideNavVisible$;
  }

  toggleSideNav(visibility?: boolean): void {
    if (typeof visibility !== 'undefined') {
      this.sideNavVisible$.next(visibility);
    } else {
      this.sideNavVisible$.next(!this.sideNavVisible$.value);
    }
  }

  routeData$(): Observable<ZDBRouteData> {
    return this.zdbRouteData$;
}

  currentURL$(): Observable<string> {
    return this.zdbCurrentURL$;
  }
}
