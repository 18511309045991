import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectUserId } from '@medrecord/managers-auth';
import { selectCurrentUser } from '@app/core/user/state/user-data.selectors';
import { IZDBUser } from '@app/core/user/user.model';

@Component({
  selector: 'zdb-selfcheck',
  templateUrl: './selfcheck.component.html',
  styleUrls: ['./selfcheck.component.scss']
})
export class SelfcheckComponent implements OnInit {
  userId$: Observable<string>;
  user$: Observable<IZDBUser>;

  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.userId$ = this.store.select(selectUserId);
    this.user$ = this.store.select(selectCurrentUser);
  }

  startSelfCheck() {
    this.router.navigate(['zelfcheck', 'start']);
  }

  startPositiveHealth(userId: string) {
    this.router.navigate(['zelfcheck', 'positieve-gezondheid', userId]);
  }
}
