import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as UserDataActions from './user-data.actions';
import { UserService } from '../user.service';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

import { toZDBUser } from '@app/core/user/user.model';
import { logoutFinished, selectUserId } from '@medrecord/managers-auth';

@Injectable()
export class UserDataEffects {
  loadUserData = createEffect(() => this.actions$.pipe(
    ofType(UserDataActions.loadUserData),
    withLatestFrom(this.store.select(selectUserId)),
    switchMap(([, userId]) => {
      if (userId !== null) {
        return this.userService.find(userId).pipe(
          map(userJSON => {
            return UserDataActions.loadUserDataSuccess({
              current_user: toZDBUser(userJSON),
              // TODO: load patient
              patient: toZDBUser(userJSON)
            });
          }),
          catchError((error) => {
            if (error.status === 404) {
              return of(UserDataActions.loadUserDataNoProfile());
            }
            return of(UserDataActions.loadUserDataFailure({ error }));
          })
        );
      }
      // No user logged in
      return of(UserDataActions.loadUserDataNoUser());
    }
  )));

  emptyUserDataOnLogout$ = createEffect(() => this.actions$.pipe(
    ofType(logoutFinished),
    map(() => {
      return UserDataActions.emptyUserData();
    })
  ));

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store
  ) {}
}
