<zdb-card
  *ngIf="user$ | async as user"
  [ngStyle]="{
    backgroundImage: 'url(assets/images/cards/selfcheck-archive-empty.png)'
  }"
>
  <div class="panel-header card-header">
    <div
      style="display: flex; flex-flow: row wrap; justify-content: space-between"
    >
      <div style="display: flex; flex: 1 1 auto">
        <h2 class="card-title">
          <ng-container *ngIf="!isPositiveHealth; else positiveHealthTitle"
            >{{ "questionnaire_archive.title" | translate }}
            <span class="badge badge-primary">{{ user.name.text }}</span>
          </ng-container>
          <ng-template #positiveHealthTitle>
            Archief Positieve Gezondheid
          </ng-template>
        </h2>
      </div>
      <div style="display: flex; flex: 0 1 auto; align-self: flex-end"></div>
    </div>
  </div>

  <div class="card-body">
    <ng-container
      *ngIf="
        showInProgress && inProgressQuestionnaireTasks$
          | async as inProgressQuestionnaireTasks
      "
    >
      <p
        class="description card-text"
        *ngIf="inProgressQuestionnaireTasks.length; else noInProgressTasks"
      >
        {{ "questionnaire_archive.in_progress_intro" | translate }}
      </p>
      <ng-container
        class="card-archive"
        *ngFor="let questionnaireTask of inProgressQuestionnaireTasks"
      >
        <div class="card-archive-item">
          <div class="row align-items-center">
            <div class="col">
              <a
                [routerLink]="[
                  '/zelfcheck/user',
                  userId,
                  questionnaireTask.taskId
                ]"
              >
                {{
                  "questionnaire_archive.see_in_progress_answers" | translate
                }}
              </a>
            </div>
          </div>
        </div>
      </ng-container>
      <hr *ngIf="inProgressQuestionnaireTasks.length" />
    </ng-container>

    <ng-container *ngIf="!isPositiveHealth">
      <p
        class="description card-text"
        *ngIf="
          completedQuestionnaireTasks && completedQuestionnaireTasks.length;
          else noCompletedTasks
        "
      >
        {{ "questionnaire_archive.completed_intro" | translate }}
      </p>
    </ng-container>
    <ng-container
      class="card-archive"
      *ngFor="
        let questionnaireTask of pagedCompletedQuestionnaireTasks;
        let index = index
      "
    >
      <div class="card-archive-item">
        <div class="row align-items-center">
          <div class="col-12 d-flex align-items-center mb-2">
            <span class="text-lg mr-2">
              {{ questionnaireTask.completed | date: "dd-MM-yyyy" }}
            </span>
            <button
              *ngIf="questionnaireTask.completed"
              class="btn btn-lg btn-outline-primary ml-2 see-answers-btn"
              [class.see-answers-btn-positive-health]="isPositiveHealth"
              [routerLink]="
                !isPositiveHealth
                  ? ['/zelfcheck/user', userId, questionnaireTask.taskId]
                  : ['/zelfcheck/spinnenweb', userId]
              "
              [queryParams]="isPositiveHealth ? getTaskIds(index) : {}"
            >
              {{ "questionnaire_archive.see_complete_answers" | translate }}
            </button>
            <div class="ml-2" style="flex: 1 0 auto">
              <button
                class="btn btn-lg btn-secondary w-100"
                *ngIf="!isPositiveHealth && questionnaireTask.completed"
                [routerLink]="[
                  '/zelfcheck/user',
                  userId,
                  questionnaireTask.taskId,
                  'scores'
                ]"
              >
                {{ "questionnaire_archive.see_results" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <br />
    <h2 class="d-flex justify-content-center">
      <ngb-pagination
        [(page)]="currentPage"
        [pageSize]="isPositiveHealth ? 3 : 2"
        [maxSize]="3"
        [collectionSize]="completedQuestionnaireTasks.length"
      >
      </ngb-pagination>
    </h2>
  </div>
</zdb-card>

<ng-template #noInProgressTasks>
  <div class="card-text">
    {{ "questionnaire_archive.no_in_progress_selfchecks" | translate }}
  </div>
  <hr />
</ng-template>

<ng-template #noCompletedTasks>
  <div class="card-text">
    {{ "questionnaire_archive.no_completed_selfchecks" | translate }}
  </div>
</ng-template>
