import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medrecord-time-left',
  templateUrl: './time-left.component.html',
  styleUrls: ['./time-left.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeLeftComponent {
  timeLeft: any;
  interval: any;

  date: Date;

  constructor(
    private cd: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
  }

  @Input()
  set dueDate(date: Date) {
    this.date = date;

    this.clearInterval();

    this.timeLeft = this.getTimeLeft();
    this.interval = setInterval(() => {
      this.timeLeft = this.getTimeLeft();
      this.cd.detectChanges();
    }, 1000);
  }

  clearInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  getTimeLeft(): string {
    const now = new Date();

    if (now >= this.date) {
      return this.translateService.instant('common_time_over');
    }

    let years = this.date.getUTCFullYear() - now.getUTCFullYear();
    let months = this.date.getUTCMonth() - now.getUTCMonth();
    let days = this.date.getUTCDate() - now.getUTCDate();

    let hours = this.date.getUTCHours() - now.getUTCHours();
    let minutes = this.date.getUTCMinutes() - now.getUTCMinutes();
    let seconds = this.date.getUTCSeconds() - now.getUTCSeconds();

    if (seconds < 0) {
      minutes -= 1;
      seconds += 60;
    }

    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }

    if (hours < 0) {
      days -= 1;
      hours += 24;
    }

    if (days < 0) {
      months -= 1;
      days += 30;
    }

    if (months < 0) {
      years -= 1;
      months += 12;
    }

    const yearStr = this.translateService.instant(
      `common_year${ years > 1 ? 's' : '' }`
    );

    const monthStr = this.translateService.instant(
      `common_month${ months > 1 ? 's' : '' }`
    );

    const dayStr = this.translateService.instant(
      `common_day${ days > 1 ? 's' : '' }`
    );

    const YYYY = years ? `${ years } ${ yearStr } ` : '';
    const MM = months ? `${ months } ${ monthStr } ` : '';
    const DD = days ? `${ days } ${ dayStr } ` : '';

    const hh = hours ? `${ hours }`.padStart(2, '0') : '00';
    const mm = minutes ? `${ minutes }`.padStart(2, '0') : '00';
    const ss = seconds ? `${ seconds }`.padStart(2, '0') : '00';

    return this.translateService.instant('common_time_left', {
      time: `${ YYYY }${ MM }${ DD }${ hh }:${ mm }:${ ss }`
    });
  }

}
