import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QuestionTypeBaseComponent } from '../../../base/question-type-base.component';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'medrecord-choice-radio-question',
  templateUrl: './choice-radio-question.component.html',
  styleUrls: ['./choice-radio-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChoiceRadioQuestionComponent extends QuestionTypeBaseComponent {

  @Input() showHelp = true;

  answerOptionsList: any[];

  constructor(
    protected fb: FormBuilder
  ) {
    super(fb);
  }

  initForm(): void {
    if (!this.question) {
      return;
    }

    this.initAnswersOptionsList();

    this.form = this.fb.group(
      this.getGroupConfig()
    );
  }

  initAnswersOptionsList() {
    this.answerOptionsList = [
      ...this.question.options.answers
    ];
  }

  getGroupConfig() {
    const answersMap = this.getAnswersMap();
    const config = this.answerOptionsList
      .map((answer, index) => ( {
        [index]: [
          answersMap[answer.label] !== undefined && answersMap[answer.label] !== null
        ]
      } ));

    return config
      .reduce((group, control) => ( { ...group, ...control } ), {});
  }

  getAnswersMap(): { [label: string]: any } {
    return this.currentAnswer
      ? this.currentAnswer.reduce((acc, { label, value }) => ( {
        ...acc,
        [label]: value
      } ), {})
      : {};
  }

  getFirstSelectedIndex(): number {
    return Object.values(this.form.value).indexOf(true);
  }

  getSelectedIndex(): number {
    return Object
      .values(this.form.value)
      .findIndex(isSelected => isSelected);
  }

  prepareAnswersForEmitting(formValue: any): any {
    console.log('prepareAnswersForEmitting', formValue);
    const selectedIndex = this.getSelectedIndex();
    const answer = this.answerOptionsList[selectedIndex];

    return answer ? [answer] : undefined;
  }

  onAnswerSelect(value: boolean, selectedIndex: number): void {
    console.log('onAnswerSelect', value);

    const formValue = Object.keys(this.form.value)
      .map((key, index) => ( {
        [`${ key }`]: index === selectedIndex
      } ))
      .reduce((result, formControl) => ( { ...result, ...formControl } ), {});

    this.form.setValue(formValue);
  }

  getCurrentAnswerText(answer: any): string {
    return answer ? answer[0].label : '';
  }

}
