import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const documentReferenceBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.documentReference,
  additionalResourceTypes: [ResourceType.patient],
  fieldGroups: [
    {
      name: 'my_doctor_document',
      fields: [
        {
          label: 'my_doctor_status',
          fieldName: 'status',
          prepareData: (status) => status,
        },
        {
          label: 'my_doctor_type',
          fieldName: 'type',
          prepareData: (data) => data?.coding[0]?.display,
        },
        {
          label: 'my_doctor_class',
          fieldName: 'classField',
          prepareData: (data) => data?.coding[0]?.display,
        },
        {
          label: 'my_doctor_subject',
          fieldName: 'subject',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_indexed_date',
          fieldName: 'indexedDate',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LLL') : ''),
        },
        {
          label: 'my_doctor_author',
          fieldName: 'author',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_title',
          fieldName: 'title',
          prepareData: (data) => data
        },
        {
          label: 'my_doctor_content_type',
          fieldName: 'contentType',
          prepareData: (data) => data
        },
        {
          label: 'my_doctor_language',
          fieldName: 'language',
          prepareData: (data) => data
        },
        {
          label: 'my_doctor_security_label',
          fieldName: 'securityLabel',
          prepareData: (data) => data?.coding[0]?.display
        },
        {
          label: 'my_doctor_location',
          fieldName: 'location',
          prepareData: (data) => data
        },
        {
          label: 'my_doctor_download_pdf',
          fieldName: 'location',
          prepareData: (data) => data,
          showAsDownload: true,
        },
      ],
    },
  ],
});
