<ejs-dialog #dialogComponent [visible]="isOpen" [isModal]="true" (overlayClick)="dialogClosed.emit()" width="60%">
  <ng-template #header>
    <span>{{ 'healthcare_providers_logs' | translate }}</span>
  </ng-template>

  <ng-template #content>
    <div class="row mx-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12 content" *ngIf="logs$ | async as logs">
            <ng-container *ngIf="logs?.length; else noLogs">
              <div class="log pb-4" *ngFor="let log of logs">
                <span class="date">{{ log.date }}</span>

                <p *ngFor="let item of log.items">
                  <span>{{ item.name }} ({{ item.count }}): </span>
                  <span class="error" *ngIf="item.error">
                    {{ 'healthcare_providers_error' | translate | titlecase }} [{{ item.errorHttpCode }}] ({{
                      item.errorMessage
                    }})
                    <br />

                    <ng-container *ngIf="item.issues && item.issues.length">
                      {{ 'healthcare_providers_issues' | translate | titlecase }}:
                      <br />

                      <span *ngFor="let issue of item.issues">
                        -
                        <span>
                          {{ 'healthcare_providers_error_code' | translate | titlecase }}: {{ issue.code }}.
                        </span>
                        <span>
                          {{ 'healthcare_providers_severity' | translate | titlecase }}: {{ issue.severity }}.
                        </span>
                        <span *ngIf="issue.diagnostics">
                          {{ 'healthcare_providers_diagnostics' | translate | titlecase }}:
                          {{ issue.diagnostics }}</span
                        >
                      </span>
                    </ng-container>
                  </span>
                  <span *ngIf="!item.error" class="success">{{ 'common_ok' | translate | uppercase }}</span>
                </p>
              </div>
            </ng-container>
            <ng-template #noLogs>
              <div class="d-flex justify-content-center align-items-center h-100">
                {{ 'healthcare_providers_no_logs' | translate | titlecase }}
              </div>
            </ng-template>
          </div>

          <div class="col-12 d-flex justify-content-end mt-3">
            <button class="btn btn-secondary" (click)="dialogClosed.emit()">
              {{ 'common_ok' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
