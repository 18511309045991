<zdb-main-layout *ngIf="user$ | async as user">
  <div class="col">
    <div class="row">
      <zdb-card
        class="col-md-6"
        [ngStyle]="{
          backgroundImage: 'url(assets/images/cards/selfcheck-todo.png)'
        }"
      >
        <div class="card-header">
          <h2 class="card-title">Hallo {{ user.name.firstName }}!</h2>
        </div>
        <div class="card-body">
          <p class="card-text">
            Hoe vitaal ben je? Vul de<br />
            zelfcheck regelmatig in.
          </p>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-lg btn-primary-button"
            (click)="startSelfCheck()"
          >
            Start zelfcheck
          </button>
        </div>
      </zdb-card>

      <ng-container *ngIf="userId$ | async as userId">
        <zdb-questionnaire-archive
          class="col-md-6 px-0 px-md-3"
          [userId]="userId"
        ></zdb-questionnaire-archive>
      </ng-container>
      <ng-container *ngIf="userId$ | async as userId">
        <zdb-card class="col-md-6">
          <div class="card-header">
            <h2 class="card-title">Positieve Gezondheid</h2>
          </div>
          <div class="card-body">
            <p class="card-text w-100" style="height: 150px">
              <img
                src="assets/images/cards/selfcheck-spiderweb.png"
                alt="spinnenweb"
                [ngStyle]="{
                  height: '200px',
                  float: 'right',
                  marginTop: '-25px'
                }"
              />
              Vul de vragenlijst Positieve <br />
              Gezondheid in en krijg inzicht in <br />
              je gezondheid
            </p>
          </div>
          <div class="card-footer">
            <button
              class="btn btn-lg btn-primary-button"
              (click)="startPositiveHealth(userId)"
            >
              Start positieve Gezondheid
            </button>
          </div>
        </zdb-card>
      </ng-container>
      <ng-container *ngIf="userId$ | async as userId">
        <zdb-questionnaire-archive
          class="col-md-6 px-0 px-md-3"
          [userId]="userId"
          [isPositiveHealth]="true"
        ></zdb-questionnaire-archive>
      </ng-container>
    </div>
  </div>
</zdb-main-layout>
