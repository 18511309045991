<ng-container *ngIf="description">

  {{ visibleDescription }}

  <ng-container *ngIf="canDescriptionBeCollapsed">
    <a (click)="showMore($event)"
              *ngIf="isCollapsed"
    > {{ 'common_show_more' | translate }}</a>

    <a (click)="showLess($event)"
              *ngIf="!isCollapsed"
    > {{ 'common_show_less' | translate }}</a>

  </ng-container>

</ng-container>
