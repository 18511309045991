import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationDialogComponent } from './confirmation-dialog.component';

interface Options {
  title: string;
  message: string;
  btnOkText: string;
  btnCancelText: string;
  btnStyle: string;
  dialogSize: string;
}

@Injectable()
export class ConfirmationDialogService {
  constructor(private modalService: NgbModal) {}

  public confirm(
    options: Options = {
      title: '',
      message: '',
      btnOkText: 'Ok',
      btnCancelText: 'Cancel',
      btnStyle: 'btn-secondary',
      dialogSize: 'md',
    }
  ): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {
      size: options.dialogSize,
    });
    modalRef.componentInstance.title = options.title;
    modalRef.componentInstance.message = options.message;
    modalRef.componentInstance.btnOkText = options.btnOkText;
    modalRef.componentInstance.btnCancelText = options.btnCancelText;
    modalRef.componentInstance.btnStyle = options.btnStyle;

    return modalRef.result;
  }
}
