import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const problemBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.condition,
  fieldGroups: [
    {
      name: ({ resource }) => resource.content?.name?.coding?.[0]?.display || 'my_doctor_problem',
      fields: [
        {
          label: 'my_doctor_type',
          fieldName: 'type',
          prepareData: (data) => data?.coding?.[0]?.display ?? '',
        },
        {
          label: 'my_doctor_name',
          fieldName: 'name',
          prepareData: (data) =>
            data?.coding?.[0]?.display || data?.coding?.[0]?.code
              ? `${data?.coding?.[0]?.code} ${data?.coding?.[0]?.display}`
              : '',
        },
        {
          label: 'my_doctor_start_date',
          fieldName: 'problemPeriod',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_status',
          fieldName: 'status',
          prepareData: (data) => data ?? '',
        },
      ],
    },
  ],
});
