import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const practitionerBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.practitioner,
  fieldGroups: [
    {
      name: 'my_doctor_practitioner_title',
      fields: [
        {
          name: 'my_doctor_name',
          fieldAsSubgroup: true,
          fields: [
            {
              label: 'my_doctor_first_name',
              fieldName: 'name',
              prepareData: (data) => data.firstName,
            },
            {
              label: 'my_doctor_initials',
              fieldName: 'name',
              prepareData: (data) => data.initials,
            },
            {
              label: 'my_doctor_given_name',
              fieldName: 'name',
              prepareData: (data) => data.givenName,
            },
            {
              label: 'my_doctor_partner_last_name_prefix',
              fieldName: 'name',
              prepareData: (data) => data.partnerLastNamePrefix,
            },
            {
              label: 'my_doctor_partner_last_name',
              fieldName: 'name',
              prepareData: (data) => data.partnerLastName,
            },
            {
              label: 'my_doctor_last_name_prefix',
              fieldName: 'name',
              prepareData: (data) => data.lastNamePrefix,
            },
            {
              label: 'my_doctor_last_name',
              fieldName: 'name',
              prepareData: (data) => data.lastName,
            },
          ],
        },
        {
          name: 'my_doctor_contact_information',
          fieldAsSubgroup: true,
          fields: [
            {
              label: 'my_doctor_specialism',
              fieldName: 'specialties',
              useResource: ResourceType.practitionerRole,
              prepareData: (data) => data?.[0]?.coding?.[0]?.code,
            },
            {
              label: 'my_doctor_phone_number',
              fieldName: 'telecoms',
              useResource: ResourceType.practitionerRole,
              prepareData: (data, _, resource) =>
                (data?.length ? data : resource?.content?.contactList)?.find((t) => t.system === 'PHONE')?.value,
            },
            {
              label: 'my_doctor_phone_type',
              fieldName: 'telecoms',
              useResource: ResourceType.practitionerRole,
              translate: true,
              prepareData: (data, _, resource) => {
                const phone = (data?.length ? data : resource?.content?.contactList)?.find((t) => t.system === 'PHONE');
                return phone?.use ? 'common_contact_use_' + phone?.use?.toLowerCase() : '';
              },
            },
            {
              label: 'my_doctor_email',
              fieldName: 'telecoms',
              useResource: ResourceType.practitionerRole,
              prepareData: (data, _, resource) =>
                (data?.length ? data : resource?.content?.contactList)?.find((t) => t.system === 'EMAIL')?.value,
            },
            {
              label: 'my_doctor_email_type',
              fieldName: 'telecoms',
              useResource: ResourceType.practitionerRole,
              translate: true,
              prepareData: (data, _, resource) => {
                const email = (data?.length ? data : resource?.content?.contactList)?.find((t) => t.system === 'EMAIL');
                return email?.use ? 'common_contact_use_' + email?.use?.toLowerCase() : '';
              },
            },
            {
              label: 'my_doctor_gender',
              fieldName: 'gender',
              translate: true,
              prepareData: (data) => (data ? 'common_gender_' + data?.toLowerCase() : ''),
            },
            {
              label: 'my_doctor_identification',
              fieldName: 'identifiers',
              prepareData: (data, resources) => {
                const practitionerRole = resources.find((v) => v.resourceType === ResourceType.practitionerRole);
                return [practitionerRole?.content?.practitionerReference?.display, data?.[0]?.value]
                  .filter(Boolean)
                  .join(', ');
              },
            },
          ],
        },
        {
          name: 'my_doctor_address',
          fieldAsSubgroup: true,
          fields: [
            {
              label: 'my_doctor_street',
              fieldName: 'addressList',
              prepareData: (data) => data?.[0]?.streetName,
            },
            {
              label: 'my_doctor_house_number',
              fieldName: 'addressList',
              prepareData: (data) => data?.[0]?.houseNumber,
            },
            {
              label: 'my_doctor_postal_code',
              fieldName: 'addressList',
              prepareData: (data) => data?.[0]?.postalCode,
            },
            {
              label: 'my_doctor_city',
              fieldName: 'addressList',
              prepareData: (data) => data?.[0]?.city,
            },
            {
              label: 'my_doctor_country',
              fieldName: 'addressList',
              prepareData: (data) => data?.[0]?.landGbaCode?.coding?.[0]?.display,
            },
            {
              label: 'my_doctor_address_type',
              fieldName: 'addressList',
              translate: true,
              prepareData: (data) => data?.[0]?.use,
            },
          ],
        },
      ],
    },
    {
      name: 'my_doctor_careprovider_title',
      fields: [
        {
          label: 'my_doctor_organization_name',
          fieldName: 'name',
          useResource: ResourceType.organization,
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_organization_type',
          fieldName: 'types',
          useResource: ResourceType.organization,
          prepareData: (data) => data?.[0]?.coding[0]?.display,
        },
        {
          label: 'my_doctor_street',
          fieldName: 'addresses',
          useResource: ResourceType.organization,
          prepareData: (data) => data?.[0]?.streetName,
        },
        {
          label: 'my_doctor_house_number',
          fieldName: 'addresses',
          useResource: ResourceType.organization,
          prepareData: (data) => data?.[0]?.houseNumber,
        },
        {
          label: 'my_doctor_postal_code',
          fieldName: 'addresses',
          useResource: ResourceType.organization,
          prepareData: (data) => data?.[0]?.postalCode,
        },
        {
          label: 'my_doctor_city',
          fieldName: 'addresses',
          useResource: ResourceType.organization,
          prepareData: (data) => data?.[0]?.city,
        },
        {
          label: 'my_doctor_country',
          fieldName: 'addresses',
          useResource: ResourceType.organization,
          prepareData: (data) => data?.[0]?.landGbaCode?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_address_type',
          fieldName: 'addresses',
          useResource: ResourceType.organization,
          translate: true,
          prepareData: (data) => data?.[0]?.use,
        },
        {
          label: 'my_doctor_identification',
          fieldName: 'identifiers',
          useResource: ResourceType.organization,
          prepareData: (data) => data?.[0]?.value,
        },
      ],
    },
  ],
});
