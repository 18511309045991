<div class="input-wrapper">
  <input type="radio"
         [value]="isSelected"
         [id]="inputId"
         [disabled]="disabled"
         #radio
  />
  <div class="checked-icon"
       (click)="select()"
       *ngIf="isSelected; else notSelected"></div>
</div>

<div class="label-wrapper">
  <label [for]="inputId" (click)="select()">
    <ng-content></ng-content>
  </label>
  <span *ngIf="labelDescription" class="label-desc">({{ labelDescription }})</span>
</div>

<div *ngIf="description" class="description">
  {{ description }}
</div>

<ng-template #notSelected>
  <div class="unchecked-icon"
       (click)="select()"></div>
</ng-template>
