import {Action, createReducer} from '@ngrx/store';
import {questionnairesReducers} from '@app/medrecord/questionnaire-adapter/state-management/reducers/questionnaires.reducers';

export const questionnairesFeatureName = 'bmd_questionnaires';

export interface State {

  questionnairesData: {
    isLoading: boolean;
    isLoaded: boolean;
    error: string;
    taskId: string;
    task: any;
    resource: any;
    answers: any;
  };

  secondQuestionnairesData: {
    taskId: string;
    task: any;
    resource: any;
    answers: any;
  };

}

export const initialState: State = {
  questionnairesData: {
    isLoading: false,
    isLoaded: false,
    error: null,
    taskId: null,
    task: null,
    resource: null,
    answers: null,
  },

  secondQuestionnairesData: {
    taskId: null,
    task: null,
    resource: null,
    answers: null,
  }
};

const _questionnairesReducer = createReducer<State>(
  initialState,
  ...questionnairesReducers
);

export function questionnairesReducer(state: State, action: Action): State {
  return _questionnairesReducer(state, action);
}
