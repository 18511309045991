<zdb-main-layout>
  <div class="row">
    <div class="col">
      <zdb-header description="Korte uitleg" title="Help mij op weg">
      </zdb-header>
    </div>
  </div>

  <zdb-card>
    <div class="card-body">
      <ul class="list-group list-group-flush">
        <zdb-help-item *ngFor="let helpItem of helpItems" [helpItem]="helpItem">
        </zdb-help-item>
      </ul>
    </div>
  </zdb-card>
</zdb-main-layout>
