<ejs-dialog #dialogComponent [visible]="isOpen" [isModal]="true" (overlayClick)="dialogClosed.emit()" width="30%">
  <ng-template #header>
    <span>{{ 'my_doctor_portability_report' | translate }}</span>
  </ng-template>

  <ng-template #content>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-12">
              <medrecord-ui-datepicker-precision
                [hint]="'common_start_date' | translate"
                [label]="'common_start_date' | translate"
                [calendarViewDepth]="'Month'"
                [startCalendarView]="'Decade'"
                [precision]="'DAY'"
                formControlName="startDate"
              ></medrecord-ui-datepicker-precision>
            </div>

            <div class="col-12 mt-2">
              <medrecord-ui-datepicker-precision
                [hint]="'common_end_date' | translate"
                [label]="'common_end_date' | translate"
                [calendarViewDepth]="'Month'"
                [startCalendarView]="'Decade'"
                [precision]="'DAY'"
                formControlName="endDate"
              ></medrecord-ui-datepicker-precision>
            </div>

            <div class="col-12 mt-3 text-right">
              <button class="btn btn-success font-weight-bold">
                {{ 'common_download' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</ejs-dialog>