import { setDateWithPrecision } from '@medrecord/services-datetime';
import { getAnswerFieldName } from './questionnaire-prepare-for-save.util';
import { numberFromTime } from './time-from-number.util';
import { QuestionType } from '../enums/question-type.enum';
import { isNumber } from './is-number.util';

/**
 * This util is the inverse of @questionnaire-prepare-for-save.util.ts
 */
export const toAnswers = (response: { items: any[] }) => {
  return !response ? {} : getAnswers(response.items);
};

export const getAnswers = (items: {
  linkId: string, answers: any[], items: any[], text: string,
}[]) => {
  let result = {};

  items.forEach(item => {
    if (!!item.answers && item.answers.length > 0) {
      const preparedAnswer = prepareAnswer(item.answers[0]);
      if (!!preparedAnswer) {
        result[item.linkId] = preparedAnswer;
      } else {
        console.warn('While resuming work on the questionnaire, ignored answer:', item.answers[0]);
      }
    }

    if (!!item.items) {
      result = {
        ...result,
        ... getAnswers(item.items),
      };
    }
  });

  return result;
};

export const prepareAnswer = (answer) => {
  const preparedAnswer: { text?: string; answer?: any; } = {};

  preparedAnswer.text = answer.text;

  const simpleQuestionTypes = [
    QuestionType.text,
    QuestionType.string,
    QuestionType.boolean,
  ];
  for (const simpleQuestionType of simpleQuestionTypes) {
    const simpleAttribute = getAnswerFieldName(simpleQuestionType);

    if (!!answer[simpleAttribute]) {
      preparedAnswer.answer = answer[simpleAttribute];
      return preparedAnswer;
    }
  }

  const numberQuestionTypes = [
    QuestionType.decimal,
    QuestionType.integer,
  ];
  for (const numberQuestionType of numberQuestionTypes) {
    const numberAttribute = getAnswerFieldName(numberQuestionType);
    const attributeVal = answer[numberAttribute];
    if (isNumber(attributeVal)) {
      preparedAnswer.answer = attributeVal;
      return preparedAnswer;
    }
  }

  let attribute = getAnswerFieldName(QuestionType.date);
  if (!!answer[attribute]) {
    preparedAnswer.answer = setDateWithPrecision(answer[attribute]);
    return preparedAnswer;
  }

  attribute = 'answerDateTime'; // QuestionType.datetime
  if (!!answer[attribute]) {
    preparedAnswer.answer = answer[attribute];
    return preparedAnswer;
  }

  attribute = getAnswerFieldName(QuestionType.quantity);
  if (!!answer[attribute]) {
    preparedAnswer.answer = answer[attribute].value;
    return preparedAnswer;
  }

  attribute = 'answerCoding'; // QuestionType.singleChoice
  if (!!answer[attribute]) {
    const answerCoding = answer[attribute];
    preparedAnswer.answer = [{
      answerCoding: {
        code: answerCoding.code,
        display: answerCoding.display,
        system: answerCoding.system,
        version: answerCoding.version,
      },
      label: answerCoding.display,
      value: answerCoding.code,
    }];
    return preparedAnswer;
  }

  attribute = getAnswerFieldName(QuestionType.time);
  if (!!answer[attribute]) {
    preparedAnswer.answer = numberFromTime(answer[attribute]);
    return preparedAnswer;
  }

  return null;
};
