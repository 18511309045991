import { ZDBEnvironment } from '@app/models/zdb-environment.interface';
import { Role } from '@medrecord/managers-users';

export const environment: ZDBEnvironment = {
  production: false,
  auth: 'https://zdb-test-auth.medvision360.org',
  oauth: 'https://staging.login.zodichtbij.nl',
  backend: 'https://zdb-test-backend.medvision360.org',
  middlewareBackend: 'https://zdb-test-middleware.medvision360.org',
  client: 'zdb',
  clientId: '2d6c9591-ec11-4ed6-9ba2-18c1b3086',
  interraiQuestionnaireId: '12',
  positiveHealthQuestionnaireIds: [
    '2079',
    '2080',
    '2081',
    '2082',
    '2083',
    '2084',
  ],
  googleAuthDisabled: true,
  disableRoleSelection: true,
  disableRemember: true,
  defaultSignupRole: Role.Patient,
  defaultLanguage: 'nl',
  adminPanelUrl: 'https://staging.zv.zodichtbij.nl/admin',
  medmijRedirectUri: 'https://medsafe.medvision360.org',
  zelfcheckResultInfoUrl: 'https://zodichtbij.nl/uitleg/toelichting-zelfcheck-resultaten/',
};
