import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const functionalOrMentalStatusBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.observation,
  fieldGroups: [
    {
      name: ({ resource }) =>
        (resource.content.statusDate ? MedrecordMoment(resource.content.statusDate).format('LL') + ' - ' : '') +
        resource.content.statusName?.coding?.[0]?.display,
      fields: [
        {
          label: 'my_doctor_status_name',
          fieldName: 'statusName',
          prepareData: (data) => [data?.coding?.[0]?.code, data?.coding?.[0]?.display].filter(Boolean).join(' - '),
        },
        {
          label: 'my_doctor_status_value',
          fieldName: 'statusValue',
          prepareData: (data) => [data?.coding?.[0]?.code, data?.coding?.[0]?.display].filter(Boolean).join(' - '),
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_medical_device',
          fieldName: 'medicalDevice',
          prepareData: (data) => data?.display,
        },
      ],
    },
  ],
});
