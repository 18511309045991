import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Careprovider,
  CareproviderTransaction,
  fetchCareproviderDataAction,
  FetchListType,
  getActiveCareprovider,
  loadCareproviderAction,
  loadListAction,
  loadListFailureAction,
  loadListSuccessAction,
  loadLogsAction,
  MyDoctorManagerConstants,
  MY_DOCTOR_MANAGER_CONSTANTS,
  Resource,
  TransactionMode,
} from '@medrecord/managers-my-doctor';
import { MyDoctorRouteParams } from '../../constants/routes';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctor-gui-medsafe-careprovider-services',
  templateUrl: './careprovider-services.component.html',
  styleUrls: ['./careprovider-services.component.scss'],
})
export class CareproviderServicesComponent implements OnInit {
  readonly testCareproviderId = this.myDoctorConstants.TEST_PROVIDER_ID;
  readonly fetchListType = FetchListType;

  careprovider$: Observable<Careprovider> = this.store.select(
    getActiveCareprovider
  );
  transactions: {
    dataserviceId: string;
    transaction: CareproviderTransaction;
  }[] = [];
  count$: Observable<number>;

  modalOpen = false;
  logsModalOpen = false;
  listModalOpen = false;
  activeListType: FetchListType = null;
  activeListStatus: string = null;
  selectedTransaction: CareproviderTransaction = null;
  selectedDataServiceId: string = null;
  routeCareproviderId: string = null;

  // permission$: Observable<Permission> = this.store.select(getSelectedUserPermission);

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private actions$: Actions,
    @Inject(MY_DOCTOR_MANAGER_CONSTANTS)
    private myDoctorConstants: MyDoctorManagerConstants
  ) {}

  ngOnInit(): void {
    this.loadCareprovider();

    this.actions$
      .pipe(ofType(loadListSuccessAction, loadListFailureAction))
      .subscribe((data) => {
        if (!(data as any).error && (data as any)?.data.status) {
          this.activeListStatus = (data as any).data.status;
          this.listModalOpen = true;
        } else {
          this.listModalOpen = false;
          this.activeListStatus = null;
          this.activeListType = null;
        }
      });

    this.careprovider$.subscribe((careprovider) => {
      this.transactions =
        careprovider?.dataServices?.reduce((prev, curr) => {
          return [
            ...prev,
            ...curr.transactions
              .filter((t) => t.mode === TransactionMode.pull)
              .map((t) => {
                const parameters = (
                  this.myDoctorConstants.pullTransactionMappings.find(
                    (pullTransation) => pullTransation.transactionId === t.id
                  )?.parameters || []
                );

                return {
                  dataserviceId: curr.id,
                  transaction: { ...t, parameters },
                };
              }),
          ];
        }, []) || [];
    });
  }

  isDataServiceSupported(dataServiceId): boolean {
    return this.myDoctorConstants.supportedDataServiceIds.includes(
      dataServiceId
    );
  }

  private loadCareprovider(): void {
    this.store.dispatch(
      loadCareproviderAction({
        careproviderId: this.activatedRoute.snapshot.paramMap.get(
          MyDoctorRouteParams.careproviderId
        ),
      })
    );
  }

  loadTransactionData(
    resourceKeys: { key: Resource; parameters?: any }[]
  ): void {
    this.store.dispatch(
      fetchCareproviderDataAction({
        payload: {
          resources: resourceKeys.map(({ key, parameters }) => ({
            key,
            parameters,
          })),
        },
        dataserviceId: this.selectedDataServiceId,
        transactionId: this.selectedTransaction.id,
      })
    );
  }

  getData(
    transaction: CareproviderTransaction,
    dataserviceId: string,
    careprovider: Careprovider
  ): void {
    this.selectedTransaction = transaction;
    this.selectedDataServiceId = dataserviceId;
    if (careprovider.id === this.myDoctorConstants.TEST_PROVIDER_ID) {
      this.modalOpen = true;
    } else {
      this.loadTransactionData(
        transaction.resourceKeys.map((key) => ({ key }))
      );
    }
  }

  loadList(type: FetchListType): void {
    this.activeListType = type;
    this.store.dispatch(loadListAction({ listType: type }));
  }

  getDisplayName(careprovider: Careprovider): string {
    return careprovider?.displayName
      ? careprovider?.displayName
      : careprovider?.id
      ? careprovider?.id
      : careprovider?.email;
  }

  getLogsAndOpenModal(careproviderId: string): void {
    this.store.dispatch(loadLogsAction({ careproviderId }));
    this.logsModalOpen = true;
  }
}
