import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HealthcareProviderResource } from '@app/medrecord/models/healthcare-provider-resource';
import { QuestionnairesInUseListItem } from '@app/medrecord/questionnaire-adapter/interfaces/questionnaire-in-use-list-item.interface';
import { OutcomeItem } from '@app/selfcheck/questionnaire-outomes/questionnaire-outcomes.type';
import { Observable, of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';

@Injectable({ providedIn: 'root' })
export class QuestionnairesService {
  constructor(
    private http: HttpClient,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {
  }

  getQuestionnaireTask(patientId: string): Observable<QuestionnairesInUseListItem> {
    return this.getLastQuestionnaireTask(patientId).pipe(
      mergeMap(tasks => {
        if (!!tasks && tasks.length > 0) {
          const task = tasks.find(value => value.questionnaireId === this.environment.interraiQuestionnaireId);
          if (!!task) {
            return of(task);
          }
        }

        return this.createQuestionnaireTask(patientId).pipe(
          mergeMap(() => this.getLastQuestionnaireTask(patientId).pipe(
            mergeMap(freshTasks => {
              const task = freshTasks.find(value => value.questionnaireId === this.environment.interraiQuestionnaireId);
              if (!!task) {
                return of(task);
              }
              console.error('Cannot fetch newly created questionnaire task!');
              return of({} as any);
            })
          ))
        );
      })
    );
  }

  private createQuestionnaireTask(patientId: string): Observable<any> {

    const scheduleQuestionnaireRequest = {
      questionnaireIds: [this.environment.interraiQuestionnaireId],
      subjectId: patientId,
      fulfilmentSought: {
        type: 'SINGLE',
        to: new Date().toISOString(),
      }
    };

    return this.http.post(`${ this.environment.backend }/patient/${ patientId }/questionnaire-task`,
      scheduleQuestionnaireRequest);
  }

  private getLastQuestionnaireTask(patientId: string): Observable<QuestionnairesInUseListItem[]> {

    const params = new HttpParams()
      .append('status', ['REQUESTED', 'IN_PROGRESS'].join(','))
      .append('count', '100')
      .append('start', '0');

    return this.http.get<QuestionnairesInUseListItem[]>(`${ this.environment.backend }/patient/${ patientId }/questionnaire-task`,
      { params });
  }

  loadQuestionnaire(patientId: string, id: string): Observable<HealthcareProviderResource> {
    return this.http.get<HealthcareProviderResource>(`${ this.environment.backend }/patient/${ patientId }/questionnaire/${ id }`);
  }

  saveQuestionnaireResponse(patientId: string, taskId: string, questionnaireResponse: any): Observable<any> {
    return this.http.post(`${ this.environment.backend }/patient/${ patientId }/questionnaire-task/${ taskId }`,
      questionnaireResponse);
  }

  autoSaveQuestionnaireResponse(patientId: string, taskId: string, questionnaireResponse: any): Observable<any> {

    return this.http.post(`${ this.environment.backend }/patient/${ patientId }/questionnaire-task/${ taskId }/pause`,
      questionnaireResponse);
  }

  getQuestionnaireTaskResponse(patientId: string, taskId: string): Observable<HealthcareProviderResource> {

    return this.http.get<HealthcareProviderResource>(
      `${ this.environment.backend }/patient/${ patientId }/questionnaire-task/${ taskId }/response`);
  }

  loadQuestionnaireTasks(patientId: string, params: any = {}): Observable<HealthcareProviderResource> {

    return this.http.get<HealthcareProviderResource>(`${ this.environment.backend }/patient/${ patientId }/questionnaire-task`, { params });
  }

  loadCompletedQuestionnaireTasks(patientId: string): Observable<HealthcareProviderResource> {
    const params = {status: 'COMPLETED'};
    return this.loadQuestionnaireTasks(patientId, params);
  }

  loadInProgressQuestionnaireTasks(patientId: string): Observable<HealthcareProviderResource> {
    const params = {status: 'IN_PROGRESS'};
    return this.loadQuestionnaireTasks(patientId, params);
  }

  loadQuestionnaireTask(patientId: string, taskId: string): Observable<HealthcareProviderResource> {
    return this.http.get<HealthcareProviderResource>(`${ this.environment.backend }/patient/${ patientId }/questionnaire-task/${ taskId }`);
  }

  loadQuestionnaireTaskScore(patientId: string, taskId: string): Observable<OutcomeItem[]> {
    return this.http.get<any>(`${ this.environment.backend }/patient/${ patientId }/questionnaire-task/${ taskId }/score`)
      .pipe(
        switchMap((scores: []) => {
          const result: OutcomeItem[] = scores.map((score: {
            code: string,
            codeDescription: string,
            result: number,
            resultDescription: string,
            value: number,
          }) => {
            return {
              code: score.code,
              codeDescription: score.codeDescription,
              value: score.result,
              valueDescription: score.resultDescription,
              displayType: '',
            };
          });
          return of(result);
        })
      );
  }

}
