import { HealthcareProviderResource } from '@app/medrecord/models/healthcare-provider-resource';
import { featurePrefix } from '@app/medrecord/questionnaire-adapter/state-management/actions/feature-prefix';
import { createAction, props } from '@ngrx/store';

export const loadNewQuestionnaire = createAction(
  `${featurePrefix} Load New Questionnaire`
);

export const loadQuestionnaire = createAction(
  `${featurePrefix} Load Questionnaire`,
  props<{
    userId: string;
    taskId: string;
  }>()
);

export const loadQuestionnaireSuccess = createAction(
  `${featurePrefix} Load Questionnaire Success`,
  props<{
    questionnaireData: HealthcareProviderResource;
    answers: any;
    taskId: string;
    task: any;
  }>()
);

export const loadQuestionnaireFailure = createAction(
  `${featurePrefix} Load Questionnaire Failure`,
  props<{
    error: any;
  }>()
);

export const saveQuestionnaireTask = createAction(
  `${featurePrefix} Save Questionnaire Task`,
  props<{
    questionnaireId: string;
    routeSnapshot: any;
  }>()
);

export const saveQuestionnaireTaskSuccess = createAction(
  `${featurePrefix} Save Questionnaire Task Success`,
  props<{
    questionnaireId: string;
  }>()
);

export const autoSaveQuestionnaireTaskSuccess = createAction(
  `${featurePrefix} Auto Save Questionnaire Task Success`,
  props<{
    taskId: string;
    questionnaireId: string;
  }>()
);

export const saveQuestionnaireTaskFailure = createAction(
  `${featurePrefix} Save Questionnaire Task Failure`,
  props<{
    questionnaireId: string;
    error: any;
  }>()
);

export const loadPositiveHealthQuestionnaire = createAction(
  `${featurePrefix} Load Positive Health Questionnaire`
);

export const loadPositiveHealthQuestionnaires = createAction(
  `${featurePrefix} Load Positive Health Questionnaires`,
  props<{
    firstTaskIds: string[];
    secondTaskIds: string[];
    userId: string;
  }>()
);

export const loadPositiveHealthQuestionnairesSuccess = createAction(
  `${featurePrefix} Load Positive Health Questionnaires Success`,
  props<{
    firstQuestionnaire: {
      questionnaireData: HealthcareProviderResource;
      answers: any;
      taskId: string;
      task: any;
    };
    secondQuestionnaire: {
      questionnaireData: HealthcareProviderResource;
      answers: any;
      taskId: string;
      task: any;
    };
  }>()
);