import { MedrecordMoment } from '@medrecord/services-datetime';
import { mapDataUriToImageModel } from '@medrecord/tools-utils';

export enum AddressType {
  POSTAL = 'POSTAL',
  PHYSICAL = 'PHYSICAL',
  BOTH = 'BOTH',
  NULL = 'NULL',
}

export enum AddressUse {
  HOME = 'HOME',
  WORK = 'WORK',
  TEMP = 'TEMP',
  OLD = 'OLD',
}
enum PostalAddressUse {
  BAD = 'BAD',
  CONF = 'CONF',
  HP = 'HP',
  HV = 'HV',
  DIR = 'DIR',
  PUB = 'PUB',
  PHYS = 'PHYS',
  PST = 'PST',
}

export enum ContactListSystem {
  PHONE = 'PHONE',
  FAX = 'FAX',
  EMAIL = 'EMAIL',
  PAGER = 'PAGER',
  URL = 'URL',
  SMS = 'SMS',
  OTHER = 'OTHER',
}
export enum ContactListUse {
  HOME = 'HOME',
  WORK = 'WORK',
  TEMP = 'TEMP',
  OLD = 'OLD',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

enum NameUse {
  USUAL = 'USUAL',
  OFFICIAL = 'OFFICIAL',
  TEMP = 'TEMP',
  NICKNAME = 'NICKNAME',
  ANONYMOUS = 'ANONYMOUS',
  OLD = 'OLD',
  MAIDEN = 'MAIDEN',
  NULL = 'NULL',
}

export interface IAddress {
  city: string;
  country: string;
  district?: string;
  line?: string;
  postalCode: string;
  state?: string;
  streetName: string;
  text?: string;
  type: AddressType;
  use: AddressUse;
  houseNumber: string;
  buildingNumberSuffix?: string;
  unitID?: string;
  additionalLocator?: string;
  landGbaCode?: {
    coding: [
      {
        code: string,
        display: string,
        system: string,
        userSelected: boolean,
        version: string
      }
    ],
    text: string
  };
  landIsoCode?: {
    coding: [
      {
        code: string,
        display: string,
        system: string,
        userSelected: boolean,
        version: string
      }
    ],
    text: string
  };
  postalAddressUse?: PostalAddressUse;
  officialAddress?: boolean;
}

export interface IContact {
  rank: number;
  system: ContactListSystem;
  use: ContactListUse;
  value: string;
}


export interface INameDetails {
  prefix?: string;
  suffix?: string;
  family?: string;
  assemblyOrder?: {
    coding: [
      {
        code: string,
        display: string,
        system: string,
        userSelected: boolean,
        version: string
      }
    ],
    text: string
  };
  firstName: string;
  initials?: string;
  givenName?: string;
  lastNamePrefix?: string;
  lastName: string;
  partnerLastNamePrefix?: string;
  partnerLastName?: string;
  text?: string;
  use?: NameUse;
}

export interface IUser {
  addressList: IAddress[];
  birthDate: string;
  contactList: IContact[];
  gender: Gender;
  name: INameDetails;
  photoBase64Data?: string;
  photoContentType?: string;
  availableForSearch?: boolean;
}

interface IZDBNameDetails {
  firstName: string;
  lastName: string;
  text?: string;
}

export interface IZDBUser {
  name: IZDBNameDetails;
  gender: Gender;
  birthDate: string;
  streetName: string;
  houseNumber: string;
  city: string;
  postalCode: string;
  email: string;
  phone: string;
  photoBase64Data?: string;
  photoContentType?: string;
  avatarSrc: string;
  availableForSearch?: boolean;
}

const getAvatarSrc = (user: IUser) => {
  if (user.photoBase64Data && user.photoContentType) {
    return `data:${user.photoContentType};base64,${user.photoBase64Data}`;
  }
  return '';
};

export const toZDBUser = (user: IUser) => {
  const {
    name,
    gender,
    birthDate,
    addressList,
    contactList,
    photoBase64Data,
    photoContentType,
    availableForSearch
  } = user;
  const email = contactList.filter(contact => contact.system === ContactListSystem.EMAIL)?.[0]?.value || '';
  const phone = contactList.filter(contact => contact.system === ContactListSystem.PHONE)?.[0]?.value || '';
  const address = addressList.filter(addressItem => addressItem.type === AddressType.POSTAL)?.[0] || addressList?.[0] || null;
  return {
    name: {
      firstName: name.firstName, lastName: name.lastName, text: name.text
    },
    gender,
    birthDate: dateToZDBFormat(birthDate),
    email,
    phone,
    streetName: address?.streetName,
    houseNumber: address?.houseNumber,
    city: address.city,
    postalCode: address.postalCode,
    photoBase64Data,
    photoContentType,
    avatarSrc: getAvatarSrc(user),
    availableForSearch
  };
};


export const formDataToUser = (formData: any) => {
  const contactList = [];
  if (formData?.phone) {
    contactList.push({
      system: ContactListSystem.PHONE,
      use: ContactListUse.HOME,
      value: formData.phone,
      rank: 1
    });
  }
  if (formData?.email) {
    contactList.push({
      system: ContactListSystem.EMAIL,
      use: ContactListUse.HOME,
      value: formData.email,
      rank: formData?.phone ? 2 : 1
    });
  }
  const addressList = [];
  if (formData?.streetName || formData?.houseNumber || formData?.city || formData?.postalCode) {
    addressList.push({
      type: AddressType.POSTAL,
      use: AddressUse.HOME,
      country: 'Nederland',
      streetName: formData?.streetName,
      houseNumber: formData?.houseNumber,
      city: formData?.city,
      postalCode: formData?.postalCode,
    });
  }
  const user = {
    name: formData?.name,
    gender: formData?.gender,
    birthDate: formData?.birthDate && dateToBEFormat(formData.birthDate),
    // default photo is empty string, to be able to process a remove avatar action
    photoBase64Data: '',
    photoContentType: '',
    ...mapDataUriToImageModel(formData?.avatar),
    contactList,
    addressList
  };
  if (formData?.availableForSearch !== undefined) {
    user['availableForSearch'] = formData.availableForSearch;
  }
  return user;
};

// FIXME: Move date related methods to a date utils
export const ZDB_DATE_FORMAT = 'L';

const dateToZDBFormat = (backendValue: string, format: string = ZDB_DATE_FORMAT) => {
  return MedrecordMoment(backendValue).format(format);
};

export const dateToBEFormat = (formValue: string, format: string = ZDB_DATE_FORMAT) => {
  // Format the value as a date only, without time specification
  return MedrecordMoment(formValue, format, true).format('YYYY-MM-DD');
};
