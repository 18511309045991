<div class="form-group">
  <label class="checkbox d-flex align-items-start">
    <input #input="ngModel"
           (blur)="checkIfTouched()"
           [(ngModel)]="value"
           [name]="control?.name"
           type="checkbox"
    >
    <div class="checkmark d-flex align-items-center justify-content-center">
      <medrecord-svg-check [type]="'white'"
                           [width]="12"
                           class="checkmark-icon"
      ></medrecord-svg-check>
    </div>
    <span #labelContent><ng-content></ng-content></span>
    <span *ngIf="labelContent.childNodes.length === 0">{{ label }}</span>
  </label>

  <ng-container *ngIf="hasErrors">
    <span *ngFor="let error of errors" class="text text-warning">
      {{ error }}
    </span>
  </ng-container>
</div>
