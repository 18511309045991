import { Component, Input, OnInit } from '@angular/core';
import { Color } from '@app/shared/types/color.type';
@Component({
  selector: 'zdb-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent implements OnInit {
  public canvasWidth = 220;
  public centralLabel = '';

  @Input()
  public needleValue = 65;

  @Input()
  public name = 'Gauge chart';

  @Input()
  public bottomLabel = '65';

  @Input()
  public rangeLabel = ['Laag', 'Hoog'];

  public options: object;

  ngOnInit(): void {
    const defaultOptions = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: [Color.greenYellow, Color.warningOrange, Color.red],
      arcDelimiters: [35, 65],
      needleStartValue: 100,
    };
    this.options = Object.assign(defaultOptions, { rangeLabel: this.rangeLabel.map(String) });
  }

}
