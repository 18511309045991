import {Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { saveQuestionnaire } from '../../state/questionnaires.actions';

@Component({
  selector: 'medrecord-questionnaire-finished-modal',
  templateUrl: './questionnaire-finished-modal.component.html',
  styleUrls: ['./questionnaire-finished-modal.component.scss']
})
export class QuestionnaireFinishedModalComponent {
  @Input() data: any;

  form: FormGroup;

  @ViewChild('modalContent', {static: true}) modalContent: ElementRef;

  @HostListener('document:click', ['$event'])
  click(event: Event) {
    if (this.element.nativeElement.contains(event.target) && !this.modalContent.nativeElement.contains(event.target)) {
      this.activeModal.close();
    }
  }

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private store: Store,
    private activeModal: NgbActiveModal,
    private element: ElementRef,
  ) {
    this.form = this.fb.group({
      email: [null],
      share: [null]
    });
  }

  prepareData(data: any) {
    return {
      ...data,
      doctor: data.doctor ? data.doctor : this.translateService.instant('healthcare_providers_practitioner')
    };
  }

  send() {
    const { questionnaireId, scope } = this.data;

    this.store.dispatch(saveQuestionnaire({
      questionnaireId,
      scope,
      routeSnapshot: this.data.routeSnapshot
    }));

    this.activeModal.close();
  }
}
