<h5 *ngIf="showHelp" class="question-help">
  {{ 'common_select_one_of_answers' | translate }}
</h5>

<form *ngIf="form"
      [formGroup]="form"
>
  <app-radio (valueChanged)="onAnswerSelect($event, index)"
             *ngFor="let answer of answerOptionsList; let index = index"
             [formControlName]="index"
  >
    {{ answer.label }}
  </app-radio>
</form>
