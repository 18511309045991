import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Careprovider,
  CareproviderResource,
  getActiveCareprovider,
  getFetchedResourceData,
  loadCareproviderAction,
} from '@medrecord/managers-my-doctor';
import { MyDoctorRouteParams } from '../../constants/routes';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'medrecord-fetch-resource-response',
  templateUrl: './fetch-resource-response.component.html',
  styleUrls: ['./fetch-resource-response.component.scss']
})
export class FetchResourceResponseComponent implements OnInit {
  careprovider$: Observable<Careprovider>;
  resources$: Observable<CareproviderResource[]>;

  constructor(private store: Store, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.getFetchedCareproviderData();
  }

  private getFetchedCareproviderData(): void {
    this.careprovider$ = this.store.select(getActiveCareprovider);
    this.resources$ = this.store.select(getFetchedResourceData);

    this.store.dispatch(
      loadCareproviderAction({
        careproviderId: this.activatedRoute.snapshot.paramMap.get(MyDoctorRouteParams.careproviderId),
      })
    );
  }

  hasError(resources: CareproviderResource[]): boolean {
    return !!resources.find(r => r.error);
  }

  redirect(): void {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  getDisplayName(careprovider: Careprovider): string {
    return careprovider?.displayName
      ? careprovider?.displayName
      : careprovider?.id
      ? careprovider?.id
      : careprovider?.email;
  }
}
