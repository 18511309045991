<zdb-card>
    <div class="panel-header card-header">
        <div
        style="
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        "
        >
            <div style="display: flex; flex: 1 1 auto">
                <h2 class="card-title">{{ 'patients_network.title' | translate }}</h2>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="form-container">
          <form [formGroup]="form" class="mt-4">
              <div class="pt-3">
                <medrecord-ui-map-field controlName="searchTerm"
                                        [label]="'patients_network.search_label' | translate"
                                        [fieldType]="mapFieldType.Input"
                                        [parentForm]="form"
                                        [placeholder]="'patients_network.search_placeholder' | translate"
                ></medrecord-ui-map-field>
              </div>
          </form>
          <div class="col">
            <span class="ml-3" *ngIf="relUsersService.loading$ | async">{{  'patients_network.loading' | translate }}</span>
          </div>
        </div>

        <ng-container
        class="network-item-list"
        *ngFor="let user of users$ | async; ; let i = index"
        >
            <div
                style="
                padding: 0.25rem 1.25rem;
                background-color: $gray-200;
                margin-bottom: 0.25rem;
                border-radius: 1rem;
                "
            >
                <div class="row align-items-center">
                    <div class="col-0">
                        <zdb-avatar
                            [base64Data]="user.photoBase64Data"
                            [contentType]="user.photoContentType"
                            [name]="user.name && user.name.text"
                            [extraClass]="'avatar-s'"
                        ></zdb-avatar>
                    </div>
                    <div class="col">
                        <a [routerLink]="['user', user.userId]">{{ user.name && user.name.text }}</a>
                    </div>
                    <div class="col text-md-left text-sm-right">
                        <a [routerLink]="['user', user.userId]">{{ 'patients_network.general_archive' | translate }}</a>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="d-flex justify-content-between p-2">
          <ngb-pagination
              [collectionSize]="(totalUsers$ | async)!" [(page)]="relUsersService.page" [pageSize]="relUsersService.pageSize">
          </ngb-pagination>
      </div>
    </div>
</zdb-card>
