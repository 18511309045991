import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MyDoctorManagerEffects } from './my-doctor-manager.effects';
import { myDoctorManagerFeatureKey, reducer } from './my-doctor-manager.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(myDoctorManagerFeatureKey, reducer),
    EffectsModule.forFeature([ MyDoctorManagerEffects ])
  ]
})
export class MyDoctorManagerStateModule {}