import {
  loadNewQuestionnaire,
  loadPositiveHealthQuestionnaire,
  loadPositiveHealthQuestionnaires,
  loadPositiveHealthQuestionnairesSuccess,
  loadQuestionnaire,
  loadQuestionnaireFailure,
  loadQuestionnaireSuccess,
} from '@app/medrecord/questionnaire-adapter/state-management/actions/questionnaire.actions';
import { State } from '@app/medrecord/questionnaire-adapter/state-management/questionnaires.reducer';
import { on } from '@ngrx/store';

export const questionnairesReducers = [
  on(
    loadNewQuestionnaire,
    loadPositiveHealthQuestionnaire,
    loadPositiveHealthQuestionnaires,
    (state: State) => ({
      ...state,
      questionnairesData: {
        ...state.questionnairesData,
        isLoading: true,
        isLoaded: false,
        error: null,
        resource: null,
        answers: null,
        taskId: null,
        task: null,
      },
      secondQuestionnairesData: {
        ...state.secondQuestionnairesData,
        resource: null,
        answers: null,
        taskId: null,
        task: null,
      },
    })
  ),
  on(loadQuestionnaire, (state: State) => ({
    ...state,
    questionnairesData: {
      ...state.questionnairesData,
      isLoading: true,
      isLoaded: false,
      error: null,
      resource: null,
      answers: null,
      taskId: null,
      task: null,
    },
  })),
  on(
    loadQuestionnaireSuccess,
    (state: State, { questionnaireData, answers, taskId, task }) => ({
      ...state,
      questionnairesData: {
        ...state.questionnairesData,
        isLoading: false,
        isLoaded: true,
        error: null,
        resource: questionnaireData,
        answers,
        taskId,
        task,
      },
    })
  ),
  on(
    loadPositiveHealthQuestionnairesSuccess,
    (state: State, { firstQuestionnaire, secondQuestionnaire }) => ({
      ...state,
      questionnairesData: {
        ...state.questionnairesData,
        isLoading: false,
        isLoaded: true,
        error: null,
        resource: firstQuestionnaire.questionnaireData,
        answers: firstQuestionnaire.answers,
        taskId: firstQuestionnaire.taskId,
        task: firstQuestionnaire.task,
      },
      secondQuestionnairesData: {
        ...state.secondQuestionnairesData,
        ...(secondQuestionnaire
          ? {
              resource: secondQuestionnaire.questionnaireData,
              answers: secondQuestionnaire.answers,
              taskId: secondQuestionnaire.taskId,
              task: secondQuestionnaire.task,
            }
          : {}),
      },
    })
  ),
  on(loadQuestionnaireFailure, (state: State, { error }) => ({
    ...state,
    questionnairesData: {
      ...state.questionnairesData,
      isLoading: false,
      isLoaded: false,
      error,
      resource: null,
      answers: null,
      taskId: null,
      task: null,
    },
    secondQuestionnairesData: {
      ...state.secondQuestionnairesData,
      resource: null,
      answers: null,
      taskId: null,
      task: null,
    },
  })),
];
