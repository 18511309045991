import { Component, Inject, Input } from '@angular/core';
import { Question } from '../../interfaces/question.interface';
import { Store } from '@ngrx/store';
import { selectQuestion } from '../../state/questionnaires.actions';
import { trackByQuestion } from '../../utils/track-by.util';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';
import { positiveHealthQuestionnaireAlias } from '@app/medrecord/questionnaire-adapter/constants/positive-health-questionnaire-alias';

@Component({
  selector: 'medrecord-questions-list',
  templateUrl: './questions-list.component.html',
  styleUrls: ['./questions-list.component.scss']
})
export class QuestionsListComponent {
  @Input() isPositiveHealth = false;
  @Input() questions: Question[] = [];
  @Input() selectedQuestionId: string;
  trackByFn = trackByQuestion;

  constructor(
    private store: Store,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {
  }

  questionSelected(question: Question): void {
    this.store.dispatch(selectQuestion({
      questionId: question.id,
      questionnaireId: !this.isPositiveHealth ? this.environment.interraiQuestionnaireId : positiveHealthQuestionnaireAlias
    }));
    window.setTimeout(this.scrollToTop, 25);
  }

  private scrollToTop() {
    window.scrollTo({
      top: 400,
      left: 0,
      behavior: 'smooth',
    });
  }
}
