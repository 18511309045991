import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { SideNavItems, SideNavSection } from '@app/core/navigation/navigation.model';
import { NavigationService } from '@app/core/navigation/navigation.service';
import { selectCurrentUser } from '@app/core/user/state/user-data.selectors';
import { IZDBUser, toZDBUser } from '@app/core/user/user.model';
import { UserService } from '@app/core/user/user.service';
import { logout, selectUserId } from '@medrecord/managers-auth';

@Component({
  selector: 'zdb-sidenav',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input() sidenavStyle!: string;
  @Input() sideNavItems!: SideNavItems;
  @Input() sideNavSections!: SideNavSection[];

  subscription: Subscription = new Subscription();
  routeDataSubscription!: Subscription;
  result$: Observable<{ patientIsCurrentUser; selectedUser }>;
  patientId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    public navigationService: NavigationService,
    private store: Store
  ) {}

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.paramMap;
    this.patientId = params.get('userId');
    const patientIsCurrentUser$ = this.store
      .select(selectUserId)
      .pipe(
        map((currentUserId) => (!this.patientId || (currentUserId === this.patientId))
      )
    );
    this.result$ = combineLatest([
      patientIsCurrentUser$,
      patientIsCurrentUser$.pipe(switchMap((patientIsCurrentUser) => (
          patientIsCurrentUser
            ? this.store.select(selectCurrentUser)
            : this.findUser(this.patientId)
        )
      ))]).pipe(
        map(([patientIsCurrentUser, selectedUser]) => ({
          patientIsCurrentUser,
          selectedUser,
        }))
      );
  }

  logout() {
    this.store.dispatch(logout());
  }

  private findUser(userId: string): Observable<IZDBUser> {
    return this.userService
      .find(userId)
      .pipe(map((userJSON) => toZDBUser(userJSON)));
  }
}
