import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { selectCurrentUser } from '@app/core/user/state/user-data.selectors';
import { IZDBUser, toZDBUser } from '@app/core/user/user.model';
import { UserService } from '@app/core/user/user.service';
import { PositiveHealthQuestionnairesService } from '@app/medrecord/questionnaire-adapter/services/positive-health-questionnaires.service';
import {
  selectQuestionnaireTemplate,
  selectQuestionnaireAnswers,
  selectSecondQuestionnaireAnswers,
} from '@app/medrecord/questionnaire-adapter/state-management/selectors/templates.selectors';
import { selectUserId } from '@medrecord/managers-auth';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'zdb-positive-health',
  templateUrl: './positive-health.component.html',
  styleUrls: ['./positive-health.component.scss'],
})
export class PositiveHealthComponent implements OnInit {
  showPreviousResults = false;
  data = null;
  user$: Observable<IZDBUser>;
  windowWidth = null;

  resource$: Observable<any> = this.store.pipe(
    select(selectQuestionnaireTemplate)
  );
  firstAnswers$: Observable<any> = this.store.pipe(
    select(selectQuestionnaireAnswers)
  );
  secondAnswers$: Observable<any> = this.store.pipe(
    select(selectSecondQuestionnaireAnswers)
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private store: Store,
    private router: Router,
    private positiveHealthQuestionnaireService: PositiveHealthQuestionnairesService,
    
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;

    const params = this.activatedRoute.snapshot.paramMap;
    const { count, firstIndex, secondIndex } =
      this.activatedRoute.snapshot.queryParams;
    const userId = params.get('userId');

    this.positiveHealthQuestionnaireService
      .loadQuestionnaireScoreByName(
        userId,
        new Array(6)
          .fill('positive-health-')
          .map((value, i) => value + (i + 1)),
        count
      )
      .subscribe(
        (v) =>
          (this.data = (v as any).map((questionnaireScore) => {
            return {
              ...questionnaireScore,
              scores: [
                questionnaireScore.scores[Number.parseInt(firstIndex, 10)],
                ...(secondIndex && secondIndex !== ''
                  ? [
                      questionnaireScore.scores[
                        Number.parseInt(secondIndex, 10)
                      ],
                    ]
                  : []),
              ],
            };
          }))
      );

    if (userId) {
      this.user$ = this.userService
        .find(userId)
        .pipe(map((userJSON) => toZDBUser(userJSON)));
    } else {
      this.user$ = this.store.select(selectCurrentUser);
    }
  }

  getResultDate(index: number, forPDF: boolean = false): string {
    if (
      !this.data ||
      (index === 0 && this.data?.[0]?.scores?.length === 0) ||
      (index === 1 && this.data?.[0]?.scores?.length <= 1)
    ) {
      return 'Nog geen gegevens';
    }
    if (forPDF) {
      return MedrecordMoment(this.data?.[0]?.scores?.[index]?.scoreDate).format(
        'DD-MM-yyyy'
      );
    }
    return MedrecordMoment(this.data?.[0]?.scores?.[index]?.scoreDate).format(
      'DD-MM-yyyy'
    );
  }

  getQuestionsWithoutFirstQuestion(questions) {
    return questions.slice(1);
  }

  getAnswersByLinkId(linkId, answers) {
    return answers?.find((answer) => answer.linkId === linkId);
  }

  goBack() {
    let currentUser = null; // own UID
    this.store.select(selectUserId).subscribe(uid => currentUser = uid);
    
    const params = this.activatedRoute.snapshot.paramMap; 
    const userId = params.get('userId'); // not always own UID

    // compares own UID to UID given by the route to determine the path back.
    if (!userId || (userId === currentUser)) {
      this.router.navigate(['/zelfcheck']);
    } else {
      this.router.navigate(['/mijn-netwerk/user/' + userId]);
    }
  }
}
