import { Component, Input } from '@angular/core';
import { MedrecordInputNgControlComponentBase } from '../../base/medrecord-input-ng-control-component-base.component';
import { SelectItem } from '@medrecord/core';
import { Colors } from '@medrecord/services-colors';

@Component({
  selector: 'medrecord-select',
  templateUrl: './medrecord-select.component.html',
  styleUrls: ['./medrecord-select.component.scss'],
})
export class MedrecordSelectComponent extends MedrecordInputNgControlComponentBase {
  @Input() items: SelectItem[];
  @Input() labelType: Colors = 'boulder';
  @Input() mandatorySelect = false;
}
