import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageSize, Node } from 'pdfmake/interfaces';
const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'zdb-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class PrintComponent {
  @Input() printRoot: any;
  @Input() title: string;

  private logoUrl: string;
  private radioUncheckedSvg: string;
  private radioCheckedSvg: string;
  private bodilyFunctionSvg: string;
  private mentalWellBeingSvg: string;
  private meaningSvg: string;
  private qualityOfLifeSvg: string;
  private participationSvg: string;
  private dailyFunctionSvg: string;

  private defaultStyles: any = {
    h1: { fontSize: 22, bold: true, marginBottom: 5 },
    h2: { fontSize: 20, bold: true, marginTop: 15, marginBottom: 10 },
    h3: { fontSize: 18, bold: true, marginTop: 12, marginBottom: 8 },
    h4: { fontSize: 14, bold: true, marginTop: 12, marginBottom: 8 },
    h5: { fontSize: 12, bold: true, marginTop: 12, marginBottom: 8 },
    h6: { fontSize: 12, bold: true, marginBottom: 5 },
    p: { fontSize: 10, margin: [0, 4, 0, 6] },
    div: { fontSize: 10 },
    button: { nonPrintable: true },
  };
  private cssStyles: any = {
    outcomeLabel: { alignment: 'left', fontSize: 14 },
    badge: { alignment: 'left' },
    'badge-primary': { background: '#00acc8', color: '#ffffff' },
    'badge-info': { background: '#17a2b8', color: '#ffffff' },
    'badge-success': { background: '#AFCB5D', color: '#ffffff' },
    'badge-warning': { background: '#ffc107', color: '#ffffff' },
    'badge-danger': { background: '#ff0000', color: '#ffffff' },
    'gauge-chart__label': { fontSize: 10, alignment: 'center' },
    'gauge-chart': { alignment: 'center', marginTop: 15 },
  };
  private customStyles: any = {
    'ZDB-PRINT': { nonPrintable: true },
    '.sr-only': { nonPrintable: true },
    '.question-help': { nonPrintable: true },
    'medrecord-question-form h4 .title-label': { nonPrintable: true },
    'medrecord-question-type-switch h5 .title-label': { nonPrintable: true },
    '.d-block': { dBlock: true },
    '.outcomeLabel': { fontSize: 12 },
    '.selfcheck .col-md-6': { selfcheckColumn: true },
    '.col-12 .col-xl-6': { selfcheckColumn: true },
    '.selfcheck .row .col': { selfcheckColumn: true },
    'span .badge-pill': this.handleBadgeRoundedRectangle.bind(this),
    'div .gauge-chart': this.handleSmallerGaugeChart.bind(this),
    'NGB-RATING': this.handleRating,
    '.unchecked-icon': this.handleUncheckedRadio.bind(this),
    '.checked-icon': this.handleCheckedRadio.bind(this),
    '.copyright': { alignment: 'right', marginTop: 20 },
    // format inputs in two columns
    'app-radio .input-wrapper': { columnWidth: '5%', selfcheckColumn: true },
    'app-radio .label-wrapper': {
      fontSize: 10,
      columnWidth: '*',
      selfcheckColumn: true,
    },
    'app-input .input-wrapper': {
      fontSize: 10,
      bold: true,
      columnWidth: '*',
      selfcheckColumn: true,
    },
    'app-input label': { fontSize: 10, color: '#1B7989' },
    'app-input .label-wrapper': { columnWidth: '10%', selfcheckColumn: true },
    'app-numeric-input .inputs-row': {
      fontSize: 10,
      bold: true,
      columnWidth: '*',
      selfcheckColumn: true,
    },
    'app-numeric-input label': { fontSize: 10, color: '#1B7989' },
    'app-numeric-input .label-wrapper': {
      columnWidth: '10%',
      selfcheckColumn: true,
    },
    'medrecord-input .input-group': {
      fontSize: 10,
      bold: true,
      columnWidth: '*',
      selfcheckColumn: true,
    },
    'medrecord-input label': { fontSize: 10, color: '#1B7989' },
    'medrecord-input medrecord-label': {
      columnWidth: '10%',
      selfcheckColumn: true,
    },
    '=medrecord-question-type-switch': this.handleKeepTogether.bind(this),
    '=app-radio': this.handleRadioInput.bind(this),
    // positive health
    '=zdb-spiderweb-graph': this.handleSvg.bind(this),
    '=ejs-checkbox': { nonPrintable: true },
    '.result-before': this.handleResultText.bind(this),
    '.result-after': this.handleResultText.bind(this),
    '.before .color-box': { background: '#D35400' },
    '.after .color-box': { background: '#3A97D3' },
    '.color-box': this.handleColorBox.bind(this),
    'zdb-positive-health-answers-table .image':
      this.handlePositiveHealthSvg.bind(this),
    'zdb-positive-health-answers-table .table-content':
      this.handleTableCell.bind(this),
    'zdb-positive-health-answers-table .status-svg':
      this.handleStatusSvg.bind(this),
    'zdb-positive-health-answers-table .status-wrapper':
      this.handleStatusWrapper.bind(this),
    '.print-white-bold': {
      bold: true,
      color: '#ffffff',
    },
    'zdb-positive-health-answers-table': { unbreakable: true },
    '.positive-health-answers-table': { marginBottom: 20 },
  };

  constructor(private http: HttpClient) {
    const logoUrlStr = '/assets/images/zdb-logo-with-text.png';
    this.getBase64ImageFromURL(logoUrlStr).then((result) => {
      this.logoUrl = result;
    });
    this.http
      .get('/assets/images/svg/radio.svg', { responseType: 'text' })
      .subscribe((logo) => {
        this.radioUncheckedSvg = logo.replace(
          /width="([0-9][0-9]*)" height="([0-9][0-9]*)"/,
          'width="10" height="10"'
        );
      });
    this.http
      .get('/assets/images/svg/radio-checked.svg', { responseType: 'text' })
      .subscribe((logo) => {
        this.radioCheckedSvg = logo.replace(
          /width="([0-9][0-9]*)" height="([0-9][0-9]*)"/,
          'width="10" height="10"'
        );
      });
    this.http
      .get('/assets/images/svg/bodily-functions.svg', { responseType: 'text' })
      .subscribe((svg) => {
        this.bodilyFunctionSvg = svg;
      });
    this.http
      .get('/assets/images/svg/mental-well-being.svg', { responseType: 'text' })
      .subscribe((svg) => {
        this.mentalWellBeingSvg = svg;
      });
    this.http
      .get('/assets/images/svg/meaning.svg', { responseType: 'text' })
      .subscribe((svg) => {
        this.meaningSvg = svg;
      });
    this.http
      .get('/assets/images/svg/quality-of-life.svg', { responseType: 'text' })
      .subscribe((svg) => {
        this.qualityOfLifeSvg = svg;
      });
    this.http
      .get('/assets/images/svg/participation.svg', { responseType: 'text' })
      .subscribe((svg) => {
        this.participationSvg = svg;
      });
    this.http
      .get('/assets/images/svg/daily-function.svg', { responseType: 'text' })
      .subscribe((svg) => {
        this.dailyFunctionSvg = svg;
      });
  }

  public downloadAsPDF() {
    let pdfElem = this.printRoot;
    if (typeof pdfElem === 'string') {
      pdfElem = document.getElementById(pdfElem);
    }
    let html = htmlToPdfmake(pdfElem.innerHTML, {
      customTag: this.customTag.bind(this),
      defaultStyles: this.defaultStyles,
      tableAutoSize: true,
    });
    const htmlStr: string = JSON.stringify(html);
    html = JSON.parse(
      htmlStr.replace(/("marginTop":)"([-0-9][0-9]*)px"/g, '$1$2')
    );
    const pageSize: PageSize = 'A4';
    const documentDefinition = {
      content: html,
      pageSize,
      pageBreakBefore: this.pageBreakBefore,
      styles: this.cssStyles,
      header: this.pageHeader.bind(this),
      footer: this.pageFooter.bind(this),
    };
    const filename =
      this.title
        .replace(/[^a-z0-9]/gi, '_')
        .toLowerCase()
        .replace(/__*/g, '_') + '.pdf';
    pdfMake.createPdf(documentDefinition).download(filename);
  }

  pageBreakBefore(
    currentNode: Node,
    followingNodesOnPage: Node[],
    nodesOnNextPage: Node[],
    previousNodesOnPage: Node[]
  ): boolean {
    if (followingNodesOnPage || nodesOnNextPage || previousNodesOnPage) {
      return (
        (currentNode.columns && currentNode.pageNumbers.length > 1) ||
        (currentNode.style &&
          (currentNode.style as string[]).indexOf(
            'positive-health-answers-title'
          ) > -1)
      );
    }
    return false;
  }

  pageHeader(currentPage: number) {
    if (currentPage === 1) {
      return {
        alignment: 'right',
        image: this.logoUrl,
        height: 28,
        width: 136,
        marginTop: 20,
        marginRight: 20,
      };
    }
  }

  pageFooter(currentPage: number) {
    return {
      columns: [
        { text: this.title, width: '80%' },
        { text: '' + currentPage, alignment: 'right' },
      ],
      margin: [50, 0, 50, 0],
    };
  }

  customTag(params: any): any {
    let ret = params.ret;
    const element = params.element;
    // const parents = params.parents;
    // Note: Tried using getComputedStyle to determine if element has display:none, or opacity 0,
    //       so it can be excluded. Because html2pdfmake will take any element, hidden or not.
    //       But this only works in Firefox. In Chome for detached elements it does not work. So abandonned.
    //       Instead, using this.customStyles, set relevant elements to non-printable.
    // const computedStyle = window.getComputedStyle(element);
    const nonPrintable = element.classList.contains('non-printable');
    if (nonPrintable) {
      return {}; // Elements marked with class non-printable
    }
    this.applyCustomStyles(ret, element);
    if (ret.nonPrintable) {
      return {}; // Elements marked with customStyles as non-printable.
    }
    if (ret.stack && ret.stack.length > 1) {
      this.handleTwoColumnLayout(ret);
    }
    if (
      ret.nodeName === 'P' &&
      ret.text.length &&
      ret.text.length === 2 &&
      ret.text[1].dBlock
    ) {
      // Split in two paragraphs, needed d-block marked sub-SPAN, that needs to become a new paragraph.
      const oldtext = ret.text;
      const par1 = ret;
      const blockpar = JSON.parse(JSON.stringify(par1));
      par1.text = oldtext[0];
      par1.margin = [0, 4, 0, 0];
      // Handle case for handleBadgeRoundedRectangle that replaced text with SVG
      if (oldtext[1].svg) {
        blockpar.stack = [oldtext[1]];
      } else {
        blockpar.text = oldtext[1];
      }
      blockpar.margin = [0, 0, 0, 10];
      ret = { stack: [par1, blockpar] };
    }

    return ret;
  }

  private applyCustomStyles(ret: any, element: any): void {
    if (!element.classList) {
      return;
    }
    for (const classKeys in this.customStyles) {
      if (this.customStyles.hasOwnProperty(classKeys)) {
        let found = true;
        const classList = classKeys.split(' ');
        for (const classKey of classList) {
          if (classKey.indexOf('.') === 0) {
            const className = classKey.substring(1);
            if (!element.classList.contains(className)) {
              found = false;
              break;
            }
          } else if (!this.hasLocalNameThisOrParent(element, classKey)) {
            found = false;
            break;
          }
        }
        if (found) {
          const styles = this.customStyles[classKeys];
          if (typeof styles === 'function') {
            styles(ret, element);
          } else {
            for (const property in styles) {
              if (styles.hasOwnProperty(property)) {
                ret[property] = styles[property];
              }
            }
          }
        }
      }
    }
  }

  private handleTwoColumnLayout(ret: any): void {
    // Add two-column layout for col-md-6
    let idx = 0;
    let prevChildIdx = -1;
    const newStack = [];
    for (idx = 0; idx < ret.stack.length; idx++) {
      const child = ret.stack[idx];
      if (child.selfcheckColumn && prevChildIdx === -1) {
        prevChildIdx = idx;
      } else if (child.selfcheckColumn && prevChildIdx === idx - 1) {
        // we have two columns, join them
        prevChildIdx = -1;
        const colWidth1 = ret.stack[idx - 1].columnWidth
          ? ret.stack[idx - 1].columnWidth
          : '49%';
        const colWidth2 = child.columnWidth ? child.columnWidth : '49%';
        const cols: any = {
          columns: [
            { width: colWidth1, stack: [ret.stack[idx - 1]] },
            { width: colWidth2, stack: [child] },
          ],
          columnGap: 10,
        };
        const firstTextChild1 = this.getFirstTextChild(ret.stack[idx - 1]);
        const firstTextChild2 = this.getFirstTextChild(child);
        const marginTop1 = this.getMarginTop(firstTextChild1);
        const marginTop2 = this.getMarginTop(firstTextChild2);
        const marginTop = marginTop1 > marginTop2 ? marginTop1 : marginTop2;
        if (marginTop !== 0) {
          cols.marginTop = marginTop;
        }
        newStack.push(cols);
      } else if (prevChildIdx === -1) {
        newStack.push(child);
      } else {
        // There was some intermediate child, no columns
        if (child.selfcheckColumn) {
          prevChildIdx = idx;
        } else {
          prevChildIdx = -1;
        }
        newStack.push(ret.stack[idx - 1]);
        newStack.push(child);
      }
    }
    if (prevChildIdx !== -1) {
      newStack.push(ret.stack[prevChildIdx]);
    }
    ret.stack = newStack;
  }

  private handleKeepTogether(ret: any): void {
    if (ret.stack) {
      ret.columns = [{ width: '*', stack: [ret.stack] }];
      delete ret.stack;
    }
  }

  private handleRating(ret: any, element: any): void {
    if (element.getAttribute('aria-valuetext')) {
      ret.text = element.getAttribute('aria-valuetext');
    } else {
      ret.text = element.innerText
        .replace(/[★☆]/g, '')
        .replace(/\(\*\)/g, '*')
        .replace(/\( \)/g, 'o');
    }
  }

  private handleBadgeRoundedRectangle(ret: any, element: any): void {
    let text = '';
    if (ret.text.length && ret.text[0].text) {
      text = ret.text[0].text;
    } else if (!ret.text.length) {
      text = ret.text;
    }
    // TODO: for stars, need to handle construct with more sub-sub-texts, pull out all stars.
    let fill = '#ffffff';
    let textColor = '#000000';
    for (const className of element.classList) {
      if (className.indexOf('badge-') === 0 && this.cssStyles[className]) {
        fill = this.cssStyles[className].background;
        textColor = this.cssStyles[className].color;
      }
    }
    const svg =
      '<svg width="115" height="14" viewBox="0 0 115 14" xmlns="http://www.w3.org/2000/svg">' +
      '<rect x="0" y="0" width="115" height="14" rx="7" fill="' +
      fill +
      '"/>' +
      '<text x="10" y="11" style="font: 10px sans-serif" fill="' +
      textColor +
      '">' +
      text +
      '</text>' +
      '</svg>';
    if (ret.text.length && ret.text.length === 1 && ret.text[0].text) {
      delete ret.text;
      ret.svg = svg;
    } else if (ret.text.length && ret.text[0].text) {
      delete ret.text[0].text;
      ret.text[0].svg = svg;
    } else if (!ret.text.length) {
      delete ret.text;
      ret.svg = svg;
    }
  }

  private handleSmallerGaugeChart(ret: any, element: any): void {
    if (!element) {
      return;
    } // use element, so compiler is happy
    ret.stack[0].fontSize = 12;
    if (
      typeof ret.stack[0].text === 'string' &&
      ret.stack[0].text.length <= 22
    ) {
      // Multi line estimatation, as real height of element is not available.
      // Add extra line, so it aligns with cells with a two-line label.
      ret.stack[0].stack = ['   ', ret.stack[0].text];
      delete ret.stack[0].text;
    }
    const svgParent = ret.stack[1].stack[0];
    svgParent.alignment = 'center';
    svgParent.svg = svgParent.svg.replace(
      /width="([0-9][0-9]*)" height="([0-9][0-9]*)"/,
      'width="130" height="84" viewBox="0 0 $1 $2"'
    );
  }

  private handleUncheckedRadio(ret: any): void {
    delete ret.text;
    ret.svg = this.radioUncheckedSvg;
  }

  private handleCheckedRadio(ret: any): void {
    delete ret.text;
    ret.svg = this.radioCheckedSvg;
  }

  private handleRadioInput(ret: any, element: any): void {
    if (element.innerHTML && element.innerHTML.indexOf('unchecked-icon') > 0) {
      delete ret.stack;
      ret.text = '';
    }
  }

  private handleSvg(ret: any): void {
    ret.marginTop = 20;
    ret.stack[0].width = 400;
    ret.stack[0].height = 400;
  }

  private handleColorBox(ret: any): void {
    ret.text = ['      '];
  }

  private handleTableCell(ret: any): void {
    ret.fillColor = '#ffffff';
    if (ret?.text?.[0]?.fillColor) {
      ret.text[0].fillColor = '#ffffff';
    }
    ret.style = [
      ...(ret.style ? [...ret.style, 'cell-print'] : ['cell-print']),
    ];
  }

  private handleStatusSvg(ret: any): void {
    if (!ret.stack[0]?.svg.includes('height="4"')) {
      ret.stack[0].svg = ret.stack[0]?.svg.replace(
        /width="([0-9][0-9]*)" height="([0-9][0-9]*)"/,
        'width="10" height="7" viewBox="0 0 $1 $2"'
      );
    } else {
      ret.stack[0].svg = ret.stack[0]?.svg.replace(
        /width="([0-9][0-9]*)" height="([0-9][0-9]*)"/,
        'width="10" height="3" viewBox="0 0 $1 $2"'
      );
    }
  }

  private handleStatusWrapper(ret: any): void {
    ret.marginTop = 4;
    ret.stack[1].marginTop = -9;
    ret.stack[1].marginLeft = 12;
  }

  private handlePositiveHealthSvg(ret: any): void {
    const imgPath = ret.stack[0].image?.split('/').slice(-1)?.[0];
    let img = null;
    if (imgPath === 'bodily-functions.svg') {
      img = this.bodilyFunctionSvg;
    }
    if (imgPath === 'mental-well-being.svg') {
      img = this.mentalWellBeingSvg;
    }
    if (imgPath === 'meaning.svg') {
      img = this.meaningSvg;
    }
    if (imgPath === 'quality-of-life.svg') {
      img = this.qualityOfLifeSvg;
    }
    if (imgPath === 'participation.svg') {
      img = this.participationSvg;
    }
    if (imgPath === 'daily-function.svg') {
      img = this.dailyFunctionSvg;
    }

    ret.stack[0] = {
      nodeName: 'DIV',
      svg: img,
    };
  }

  private handleResultText(ret: any): void {
    if (
      ret.stack[1].style.includes('result-before') &&
      !ret.stack[1].style.includes('show-result')
    ) {
      delete ret.stack;
      ret.text = [];
    } else {
      ret.marginTop = 16;
      ret.marginLeft = 32;
    }
  }

  private getFirstTextChild(ret: any): void {
    if (ret.text) {
      return ret;
    } else if (ret.stack) {
      return this.getFirstTextChild(ret.stack[0]);
    }
    return ret;
  }

  private hasLocalNameThisOrParent(element: any, name: string): boolean {
    const noParent = name.indexOf('=') === 0;
    if (noParent) {
      name = name.substring(1);
    }
    if (
      element.localName &&
      element.localName.toUpperCase() === name.toUpperCase()
    ) {
      return true;
    }
    if (!noParent && element.parentElement) {
      return this.hasLocalNameThisOrParent(element.parentElement, name);
    }
    return false;
  }

  private getMarginTop(ret: any): number {
    if (ret.marginTop) {
      return ret.marginTop;
    } else if (ret.margin) {
      return ret.margin[1];
    }
    return 0;
  }

  private getBase64ImageFromURL(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }
}
