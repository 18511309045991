import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const pdfaBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.binary,
  fieldGroups: [
    {
      name: 'my_doctor_pdfa_reference',
      fields: [
        {
          label: 'my_doctor_content_type',
          fieldName: 'contentType',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_source',
          fieldName: 'pdfa',
          prepareData: (_data, _resources, mainResourceData) => mainResourceData.fullUrl,
        },
        {
          label: 'my_doctor_pdfa',
          fieldName: 'pdfa',
          showAsPdfADownload: true,
          prepareData: (base64, _resources, mainResourceData) => {
            return `data:${mainResourceData.content?.contentType};base64,${base64}`;
          },
        },
      ],
    },
  ],
});
