
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'zdb-avatar-initials',
  templateUrl: './avatar-initials.component.html',
  styleUrls: ['./avatar-initials.component.scss'],
})
export class AvatarInitialsComponent implements OnInit {

  @Input() public name: string;
  @Input() public extraClass: string;

  public initials: string;
  public circleColor: string;

  private colors = [
    '#E16736', // red
    '#AFCB5D', // green
    '#BA9702', // yellow
    '#175563', // blue
  ];

  ngOnInit() {

    this.initials = this.name.split(' ').map((n) => n.trim()[0]).join('').substr(0, 2).toUpperCase();

    // FIXME get index based on initials
    // const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
    this.circleColor = this.colors[3];

  }
}
