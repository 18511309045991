import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';

import { IUser } from './user.model';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';

@Injectable({ providedIn: 'root' })
export class UserService {
  public resourceUrl = `${ this.environment.backend }/person`;

  constructor(
    private http: HttpClient,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment,
  ) {}

  // FIXME: remove the not used methods
  create(user: IUser): Observable<IUser> {
    return this.http.post<IUser>(this.resourceUrl, user);
  }

  update(userId: string, user: IUser): Observable<IUser> {
    return this.http.patch<IUser>(`${this.resourceUrl}/${ userId }`, user);
  }

  find(userId: string): Observable<IUser> {
    return this.http.get<IUser>(`${this.resourceUrl}/${ userId }`);
  }

  delete(userId: string): Observable<{}> {
    return this.http.delete(`${this.resourceUrl}/${ userId }`);
  }
}
