import { Component, Input } from '@angular/core';
import { MedrecordInputNgControlComponentBase } from '../../base/medrecord-input-ng-control-component-base.component';
import { Colors } from '@medrecord/services-colors';
import { SelectItem } from '@medrecord/core';

@Component({
  selector: 'medrecord-bricks',
  templateUrl: './medrecord-bricks.component.html',
  styleUrls: ['./medrecord-bricks.component.scss']
})
export class MedrecordBricksComponent extends MedrecordInputNgControlComponentBase {
  @Input() labelType: Colors = 'boulder';

  @Input() selectItems: SelectItem[] = [];

  @Input() multiple: boolean;

  isItemSelected(item: SelectItem): boolean {
    if (!this.value) {
      return false;
    }

    if (this.multiple) {
      return !!this.value.find(value => value === item.value);
    }

    return this.value === item.value;
  }

  handleItemSelect(item: SelectItem): void {
    this.isItemSelected(item) ? this.unselectItem(item) : this.selectItem(item);
  }

  selectItem(item: SelectItem): void {
    this.value = this.multiple
      ? [...(this.value || []), item.value]
      : item.value;
  }

  unselectItem(item: SelectItem): void {
    this.value = this.multiple
      ? this.value.filter(value => value !== item.value)
      : null;
  }

}
