import { Component, Input, OnInit } from '@angular/core';
import {
  CareproviderResource,
  CareproviderResourceData,
  MyDoctorTableInterface,
  ResourceField,
  ResourceFieldGroup,
} from '@medrecord/managers-my-doctor';
import { AttachmentsService } from 'projects/medrecord/managers/my-doctor-manager/src/lib/services/attachments.service';

@Component({
  selector: 'medrecord-resource-field-multiple',
  templateUrl: './resource-field-multiple.component.html',
  styleUrls: ['./resource-field-multiple.component.scss'],
})
export class ResourceFieldMultipleComponent implements OnInit {
  @Input() fieldGroup: ResourceFieldGroup;
  @Input() resource: CareproviderResource;
  @Input() mainResourceData: CareproviderResourceData;

  public data = [];
  public expanded: number[] = [];

  ngOnInit(): void {
    this.prepareData();
  }

  prepareData(): void {
    this.data = this.fieldGroup.getDataSource(this.mainResourceData);
  }

  prepareFieldGroupName(data: any, index: number): string {
    return typeof this.fieldGroup.name === 'string'
      ? this.fieldGroup?.name
      : this.fieldGroup?.name({ index, resource: data });
  }

  isFieldGroupEmpty(data: any): boolean {
    return this.fieldGroup.fields.reduce((prev, field) => {
      if (!prev) {
        return prev;
      }
      return !!this.isFieldEmpty(data, field);
    }, true);
  }

  isFieldEmpty(data: any, field): boolean {
    if ((field as ResourceFieldGroup)?.fieldAsSubgroup) {
      return (field as ResourceFieldGroup)?.fields?.reduce((prev, f) => {
        if (!prev) {
          return prev;
        }
        return !!this.isFieldEmpty(data, f);
      }, true);
    } else {
      const preparedData = this.prepareDataForField(data, field);
      return (
        preparedData === undefined ||
        preparedData === null ||
        preparedData === '' ||
        (field.showAsTable &&
          !(preparedData as MyDoctorTableInterface)?.rows?.length)
      );
    }
  }

  castPreparedDataToString(data: any): string {
    return data as string;
  }

  prepareDataForField(
    data,
    field: ResourceField
  ): string | MyDoctorTableInterface {
    if (!data[field.fieldName]) {
      return null;
    }
    return field.prepareData(
      data[field.fieldName],
      this.resource.resources,
      this.mainResourceData
    );
  }

  expand(index: number): void {
    const exists = this.expanded.indexOf(index);
    if (exists > -1) {
      this.expanded.splice(exists, 1);
    } else {
      this.expanded.push(index);
    }
  }

  isExpanded(i: number): boolean {
    return this.expanded.includes(i);
  }

  onPdfaButtonClick(base64Data): void {
    this.attachmentService.openFile(base64Data, 'Document');
  }

  constructor(private attachmentService: AttachmentsService) {}
}
