import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const bodyWeightBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.observation,
  fieldGroups: [
    {
      name: ({ resource }) =>
        [
          resource.content?.effective?.date ? MedrecordMoment(resource.content?.effective?.date).format('LL') : null,
          [resource.content?.weight?.value, resource.content?.weight?.unit].filter(Boolean).join(''),
        ]
          .filter(Boolean)
          .join(' - ') || 'my_doctor_measurement',
      fields: [
        {
          label: 'my_doctor_clothing',
          fieldName: 'clothing',
          translate: true,
          prepareData: (data) => (data ? 'measurements_body_weight_clothing_' + data?.toLowerCase() : null),
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_care_provider',
          fieldName: 'performer',
          prepareData: (data) => data?.display,
        },
      ],
    },
  ],
});
