import { Component, Input, OnInit } from '@angular/core';
import { CodeDisplay } from '@app/selfcheck/questionnaire-outomes/questionnnaire-outomes.enum';

@Component({
  selector: 'zdb-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent implements OnInit {
  @Input()
  public items: Array<any> = [];

  @Input()
  public scores: Array<any> = [];

  public defaultItems: Array<any> = [];
  public stoplightChartItems: Array<any> = [];
  public gaugeChartItems: Array<any> = [];
  public textDisplayItems: Array<any> = [];
  public longTextDisplayItems: Array<any> = [];
  public ratingItems: Array<any> = [];

  ngOnInit(): void {
    this.items.forEach((item) => this.pushItem(item));
  }

  private pushItem(item): void {
    switch (item.displayType) {
      case CodeDisplay.stoplightChart:
        this.stoplightChartItems.push(item);
        break;
      case CodeDisplay.gaugeChart:
        this.gaugeChartItems.push(item);
        break;
      case CodeDisplay.textDisplay:
        this.textDisplayItems.push(item);
        break;
      case CodeDisplay.longTextDisplay:
        this.longTextDisplayItems.push(item);
        break;
      case CodeDisplay.ratingDisplay:
        this.ratingItems.push(item);
        break;
      default:
        this.defaultItems.push(item);
        break;
    }
  }
}
