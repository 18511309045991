<div class="form-group">
  <medrecord-label [for]="id"
                   [hint]="hint"
                   [secondaryText]="secondaryLabel"
                   [text]="label"
                   [type]="labelType"
  ></medrecord-label>

  <div [class.warning]="hasErrors" [class]="formDisabled ? 'input-group disabled' : 'input-group'">
    <div *ngIf="iconsLeft.length" class="input-group-prepend">
      <span class="input-group-text">
        <ng-container *ngFor="let icon of iconsLeft">
          <ng-container *ngTemplateOutlet="icon" [attr.height]="18"></ng-container>
        </ng-container>
      </span>
    </div>

    <input *ngIf="!formDisabled"
      #input="ngModel"
      (blur)="checkIfTouched()"
      [(ngModel)]="value"
      [id]="id"
      [name]="control?.name"
      [placeholder]="placeholder"
      [type]="type"
      [disabled]="disabled"
      class="form-control"
    />
    <ng-container *ngIf="formDisabled">{{value}}</ng-container>

    <div *ngIf="iconsRight.length || hasErrors" class="input-group-append">
      <span *ngIf="hasErrors" class="input-group-text">
        <medrecord-svg-warning type="warning"></medrecord-svg-warning>
      </span>

      <ng-container *ngIf="iconsRight.length">
        <span *ngFor="let icon of iconsRight" class="input-group-text">
          <ng-container *ngTemplateOutlet="icon"></ng-container>
        </span>
      </ng-container>
    </div>
  </div>

  <ng-content></ng-content>

  <ng-container *ngIf="hasErrors">
    <span *ngFor="let error of errors" class="text text-warning">
      {{ error }}
    </span>
  </ng-container>
</div>