import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HelpItem } from './help.model';

@Component({
  selector: 'zdb-help-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './help-item.component.html',
  styleUrls: ['./help-item.component.scss']
})
export class HelpItemComponent {
  @Input() helpItem: HelpItem;
}
