import { NgModule } from '@angular/core';
import { QuestionnairesRoutingModule } from './questionnaires-routing.module';
import { CommonModule } from '@angular/common';
import { QuestionnairePageComponent } from './containers/questionnaire-page/questionnaire-page.component';
import { QuestionnaireComponent } from './containers/questionnaire/questionnaire.component';
import { QuestionsListComponent } from './components/questions-list/questions-list.component';
import { QuestionsListItemComponent } from './components/questions-list-item/questions-list-item.component';
import { QuestionFormComponent } from './containers/question-form/question-form.component';
import { ChoiceCheckboxQuestionComponent } from './components/question-types/choice-chcekbox-question/choice-checkbox-question.component';
import { QuestionCommentsComponent } from './components/question-comments/question-comments.component';
import { TextQuestionComponent } from './components/question-types/text-question/text-question.component';
import { QuestionDateComponent } from './components/question-types/date-question/question-date.component';
import { QuestionDecimalComponent } from './components/question-types/decimal-question/question-decimal.component';
import { TranslateModule } from '@ngx-translate/core';
import { TimeLeftComponent } from './components/time-left/time-left.component';
import { StoreModule } from '@ngrx/store';
import { questionnairesFeatureName, questionnairesReducer } from './state/questionnaires.reducer';
import { TagsListComponent } from './components/tags-list/tags-list.component';
import { RouterModule } from '@angular/router';
import { QuestionTypeBaseComponent } from './base/question-type-base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollapsibleDescriptionComponent } from './components/collapsible-description/collapsible-description.component';
import { QuestionnaireFinishedModalComponent } from './components/questionnaire-finished-modal/questionnaire-finished-modal.component';
import { QuestionTypeSwitchComponent } from './components/question-type-switch/question-type-switch.component';
import { CommentComponent } from './components/comment/comment.component';
import { IdkModalComponent } from './components/idk-modal/idk-modal.component';
import { StringQuestionComponent } from './components/question-types/string-question/string-question.component';
import { BooleanQuestionComponent } from './components/question-types/boolean-question/boolean-question.component';
import { QuestionDateTimeComponent } from './components/question-types/datetime-question/question-date-time.component';
import { QuestionIntegerComponent } from './components/question-types/integer-question/question-integer.component';
import { QuestionTimeComponent } from './components/question-types/time-question/question-time.component';
import { ChoiceRadioQuestionComponent } from './components/question-types/choice-radio-question/choice-radio-question.component';
import { EnabledQuestionsPipe } from './pipes/enabled-questions.pipe';
import { QuestionnaireEffects } from './state/questionnaire.effects';
import { EffectsModule } from '@ngrx/effects';
import { ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { MedrecordIconsModule } from '@medrecord/icons';
import { MedrecordDatetimeModule } from '@medrecord/services-datetime';
import { MedrecordFormsModule } from '@medrecord/components/forms';
import { FormValidationModule } from '@medrecord/services-form-validation';
import { ReadonlyQuestionnairePageComponent } from './containers/readonly-questionnaire-page/readonly-questionnaire-page.component';

@NgModule({
  imports: [
    CommonModule,
    QuestionnairesRoutingModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MedrecordDatetimeModule,
    StoreModule.forFeature(questionnairesFeatureName, questionnairesReducer),
    EffectsModule.forFeature([
      QuestionnaireEffects
    ]),
    ButtonModule,
    RadioButtonModule,
    MedrecordIconsModule,
    MedrecordFormsModule,
    FormValidationModule,
  ],
  declarations: [
    QuestionnairePageComponent,
    QuestionnaireComponent,
    QuestionsListComponent,
    QuestionsListItemComponent,
    QuestionFormComponent,
    ChoiceCheckboxQuestionComponent,
    QuestionCommentsComponent,
    TextQuestionComponent,
    QuestionDateComponent,
    QuestionDecimalComponent,
    TimeLeftComponent,
    TagsListComponent,
    QuestionTypeBaseComponent,
    CollapsibleDescriptionComponent,
    QuestionnaireFinishedModalComponent,
    QuestionTypeSwitchComponent,
    CommentComponent,
    IdkModalComponent,
    StringQuestionComponent,
    BooleanQuestionComponent,
    QuestionDateTimeComponent,
    QuestionIntegerComponent,
    QuestionTimeComponent,
    ChoiceRadioQuestionComponent,
    EnabledQuestionsPipe,
    ReadonlyQuestionnairePageComponent
  ],
  exports: [
    QuestionnairePageComponent,
    TagsListComponent,
    CollapsibleDescriptionComponent,
    QuestionnaireComponent,
    ReadonlyQuestionnairePageComponent
  ],
  providers: [
    QuestionnaireEffects
  ],
})
export class QuestionnairesModule {}
