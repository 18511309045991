import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Careprovider,
  CareproviderResource,
  CareproviderTransaction,
  clearResourcesAction,
  fetchCareproviderDataAction,
  getActiveCareprovider,
  getCareproviderResources,
  loadCareproviderAction,
  loadResourcesAction,
  MyDoctorManagerConstants,
  MY_DOCTOR_MANAGER_CONSTANTS,
  Resource,
  ResourceGroup,
} from '@medrecord/managers-my-doctor';
import { MyDoctorRouteParams } from '../../constants/routes';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctor-gui-medsafe-service-resources',
  templateUrl: './service-resources.component.html',
  styleUrls: ['./service-resources.component.scss'],
})
export class ServiceResourcesComponent implements OnInit {
  // readonly editPermissions = [Permission.MainAccount];
  readonly dataserviceId = this.activatedRoute.snapshot.paramMap.get(
    MyDoctorRouteParams.serviceId
  );
  careprovider$: Observable<Careprovider> = this.store.select(
    getActiveCareprovider
  );
  resources$: Observable<CareproviderResource[]> = this.store.select(
    getCareproviderResources
  );
  resources: CareproviderResource[] = null;
  transaction: CareproviderTransaction;
  resourceKeys: string[] = [];
  // permission$: Observable<Permission> = this.store.select(getSelectedUserPermission);
  pullMapping = null;
  modalOpen = false;
  selectedTransaction: CareproviderTransaction = null;
  parametersModalVisible = false;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    @Inject(MY_DOCTOR_MANAGER_CONSTANTS)
    private myDoctorConstants: MyDoctorManagerConstants
  ) {}

  ngOnInit(): void {
    this.careprovider$.subscribe((careprovider) => {
      if (!careprovider) {
        this.loadCareprovider();
      } else {
        this.transaction = careprovider.dataServices
          .find((service) => service.id === this.dataserviceId)
          ?.transactions?.find(
            (t) =>
              t.id ===
              this.activatedRoute.snapshot.paramMap.get(
                MyDoctorRouteParams.transactionId
              )
          );

        this.pullMapping = this.myDoctorConstants.pullTransactionMappings.find(
          (mapping) => mapping.transactionId === this.transaction?.id
        );

        this.transaction = {
          ...this.transaction,
          additionalResources: this.pullMapping?.additionalResources,
          parameters: (this.pullMapping?.parameters || []).filter((p) =>
            this.transaction.resourceKeys?.filter((resourceKey) => p.resourcesKeys?.includes(resourceKey))
          ),
        };

        this.resourceKeys = (this.pullMapping?.resourceKeys || [])
          ?.filter(
            (key) =>
              Object.values(ResourceGroup).includes(
                key as unknown as ResourceGroup
              ) || this.transaction.resourceKeys.includes(key)
          )
          .reduce((prev, curr) => {
            if (
              Object.values(ResourceGroup).includes(
                curr as unknown as ResourceGroup
              )
            ) {
              return [
                ...prev,
                ...(this.pullMapping.resourceGroups?.[curr] ?? []),
              ];
            }
            return [...prev, curr];
          }, []);

        if (this.resourceKeys?.length) {
          this.loadResources(this.resourceKeys);
        } else {
          this.store.dispatch(clearResourcesAction());
        }
      }
    });

    this.resources$.subscribe((resources) => {
      if (resources) {
        this.resources = this.formatResources(resources);
      }
    });
  }

  private loadCareprovider(): void {
    this.store.dispatch(
      loadCareproviderAction({
        careproviderId: this.activatedRoute.snapshot.paramMap.get(
          MyDoctorRouteParams.careproviderId
        ),
      })
    );
  }

  private loadResources(keys: string[]): void {
    this.store.dispatch(loadResourcesAction({ resourceKeys: keys }));
  }

  formatResources(resources): any[] {
    return this.resourceKeys
      .map((resourceKey) => ({
        key: resourceKey,
        resources: [],
        ...(resources?.find(({ key }) => key === resourceKey) || {}),
      }))
      .reduce((prev, curr) => {
        const resourceGroup = (
          Object.keys(this.pullMapping?.resourceGroups || {}) || []
        )?.find((group) =>
          this.pullMapping.resourceGroups[group].includes(curr?.key)
        );

        if (resourceGroup) {
          const index = prev.findIndex((r) => r?.group === resourceGroup);
          if (index === -1) {
            return [
              ...prev,
              { key: resourceGroup, group: resourceGroup, resources: [curr] },
            ];
          }

          prev[index] = {
            ...prev[index],
            resources: [...prev[index].resources, curr],
          };
          return [...prev];
        }

        if (this.transaction.additionalResources && !(this.transaction.additionalResources || []).includes(curr.key)) {
          let additionalResources = [];
          this.transaction.additionalResources.map((resourceKey) => {
            const foundResources = resources.find(({ key }) => key === resourceKey)?.resources || [];
            additionalResources = [...additionalResources, ...foundResources];
          });

          return [
            ...prev,
            {
              ...curr,
              resources: [...(curr?.resources || []), ...additionalResources],
            },
          ];
        }

        return [...prev, curr];
      }, []);
  }

  getDisplayName(careprovider: Careprovider): string {
    return careprovider?.displayName
      ? careprovider?.displayName
      : careprovider?.id
      ? careprovider?.id
      : careprovider?.email;
  }

  fetchCareproviderData(
    resourceKeys: { key: Resource; parameters?: any }[]
  ): void {
    this.store.dispatch(
      fetchCareproviderDataAction({
        // Resource keys should have `parameters` next to the `keys` property
        payload: { resources: resourceKeys },
        dataserviceId: this.dataserviceId,
        transactionId: this.transaction.id,
      })
    );
    this.modalOpen = false;
    this.parametersModalVisible = false;
    this.selectedTransaction = null;
  }

  onDataGet(resources: CareproviderResource[]): void {
    this.careprovider$.subscribe((careprovider) => {
      const resourceKeys: { key: Resource }[] = resources.map(({ key }) => ({
        key: key as Resource,
      }));

      this.selectedTransaction = {
        ...this.transaction,
        resourceKeys: resourceKeys.map(({ key }) => key),
      };

      if (careprovider.id === this.myDoctorConstants.TEST_PROVIDER_ID) {
        this.modalOpen = true;
      } else {
        if (
          this.transaction?.parameters &&
          this.transaction?.parameters.length
        ) {
          this.parametersModalVisible = true;
        } else {
          this.fetchCareproviderData(resourceKeys);
        }
      }
    });
  }

  onParametersSubmit(event): void {
    this.fetchCareproviderData(event);
  }
}
