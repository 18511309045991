import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { loadNewQuestionnaire } from '@app/medrecord/questionnaire-adapter/state-management/actions/questionnaire.actions';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class NewQuestionnaireResolver implements Resolve<void> {
  constructor(private store: Store) {
  }

  resolve(): Observable<any> | Promise<any> | any {
    return this.store.dispatch(loadNewQuestionnaire());
  }
}
