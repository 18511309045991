export enum ResourceType {
  deviceRequest = 'DeviceRequest',
  device = 'Device',
  specimen = 'Specimen',
  nutritionOrder = 'NutritionOrder',
  medication = 'Medication',
  medicationStatement = 'MedicationStatement',
  medicationRequest = 'MedicationRequest',
  medicationDispense = 'MedicationDispense',
  deviceUseStatement = 'DeviceUseStatement',
  immunization = 'Immunization',
  organization = 'Organization',
  practitioner = 'Practitioner',
  practitionerRole = 'PractitionerRole',
  immunizationRecommendation = 'ImmunizationRecommendation',
  condition = 'Condition',
  appointment = 'Appointment',
  procedureRequest = 'ProcedureRequest',
  encounter = 'Encounter',
  patient = 'Patient',
  questionnairePatient = 'questonnairePatient',
  relatedPerson = 'RelatedPerson',
  gpPatient = 'GpPatient',
  procedure = 'Procedure',
  coverage = 'Coverage',
  contact = 'Contact',
  consent = 'Consent',
  flag = 'Flag',
  careTeam = 'CareTeam',
  allergyIntolerance = 'AllergyIntolerance',
  episodeOfCare = 'EpisodeOfCare',
  composition = 'Composition',
  observation = 'Observation',
  task = 'Task',
  questionnaire = 'Questionnaire',
  goal = 'Goal',
  carePlan = 'CarePlan',
  documentReference = 'DocumentReference',
  documentManifest = 'DocumentManifest',
  diagnosticReport = 'DiagnosticReport',
  binary = 'Binary',
}
