<div class="comment">
    <div class="avatar rounded-circle">
      <medrecord-svg-person *ngIf="!comment.userAvatar"></medrecord-svg-person>

      <img *ngIf="comment.userAvatar"
           alt="" draggable="false" src=""/>
    </div>

  <div class="content-wrap">
    <div class="content">
      {{ comment.comment }}

      <medrecord-svg-pen-rounded
        (click)="edit.emit()"
        [type]="'primary'"
        [circleBgType]="'athens'"
        [circleBorderType]="'mystic'"
        class="icon"
      ></medrecord-svg-pen-rounded>

      <medrecord-svg-cross-rounded
        (click)="remove.emit()"
        [type]="'athens'"
        class="icon"
      ></medrecord-svg-cross-rounded>
    </div>

    <div class="date">
      {{ formattedDate }}
    </div>
  </div>

</div>
