<zdb-main-layout>
  <ng-container *ngIf="{
  isLoading: isLoading$ | async,
  isLoaded: isLoaded$ | async,
  error: error$ | async
} as state">

    <div id="printRoot" *ngIf="state.isLoaded">
      <router-outlet name="questionnaire-header"></router-outlet>
      <router-outlet></router-outlet>
    </div>

    <ng-container *ngIf="state.isLoading">
      <div class="is-loading">{{ 'common_questionnaire_loading' | translate }}</div>
    </ng-container>

    <ng-container *ngIf="state.error">
      {{ state.error | json }}
    </ng-container>

  </ng-container>

</zdb-main-layout>
