import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'zdb-user-questionnaires',
  templateUrl: './user-questionnaires.component.html',
  styleUrls: ['./user-questionnaires.component.scss']
})
export class UserQuestionnairesComponent implements OnInit {
  userId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router  
  ) {}
    

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.paramMap.get('userId');
  }

  goBack() {
    this.router.navigate(['/mijn-netwerk']);
  }
}
