export enum PullTransaction {
  bgz = 'bgz-pull',
  bgz2020 = 'bgz-2020-pull',
  gp = 'gp-data-pull',
  gp2020 = 'gp-data-2020-pull',
  pdf = 'pdf-pull',
  pdfA2020 = 'pdf-a-pull-2020',
  pdfAMetadata2020 = 'pdf-a-metadata-pull',
  bglz2020 = 'bglz-2020-pull',
  ggz2020 = 'ggz-2020-pull',
}
