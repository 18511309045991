import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const laboratoryResultBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.observation,
  fieldGroups: [
    {
      name: ({ resource }) => resource?.content?.context?.display || 'my_doctor_laboratory_result',
      fields: [
        {
          label: 'my_doctor_start_date',
          fieldName: 'effective',
          prepareData: (data) => (data?.date ? MedrecordMoment(data?.start).format('LLL') : ''),
        },
        {
          label: 'my_doctor_nhg_code',
          fieldName: 'nhgCode',
          prepareData: (data) => data?.display || '',
        },
        {
          label: 'my_doctor_rate',
          fieldName: 'rate',
          prepareData: (data) => `${data?.value || ''}${data?.unit || ''}`,
        },
        {
          label: 'my_doctor_body_site',
          fieldName: 'bodySite',
          prepareData: (data) => data?.text || '',
        },
        {
          label: 'my_doctor_temperature',
          fieldName: 'temperature',
          prepareData: (data) => `${data?.value || ''} ${data?.unit || ''}`,
        },
        {
          label: 'my_doctor_systolic',
          fieldName: 'systolic',
          prepareData: (data) => `${data?.value || ''} ${data?.unit || ''}`,
        },
        {
          label: 'my_doctor_glucose',
          fieldName: 'glucose',
          prepareData: (data) => `${data?.value || ''} ${data?.unit || ''}`,
        },
        {
          label: 'my_doctor_diastolic',
          fieldName: 'diastolic',
          prepareData: (data) => `${data?.value || ''} ${data?.unit || ''}`,
        },
        {
          label: 'my_doctor_testResult',
          fieldName: 'testResult',
          prepareData: (data) => data?.text || '',
        },
      ],
    },
  ],
});
