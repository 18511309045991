import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControlDirective,
  NgControl,
  ValidationErrors,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-slider-input', // tslint:disable-line
  templateUrl: './slider-input.component.html',
  styleUrls: ['./slider-input.component.scss'],
})
export class SliderInputComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  readonly defaultTicks = {
    placement: 'Before',
    largeStep: 1,
    showSmallTicks: false,
  };

  // Text options
  @Input() label: string;
  @Input() labelDescription: string;
  @Input() hint: string;
  @Input() unitsOfMeasure: string;
  @Input() comma = ',';
  @Input() minLabel: string;
  @Input() maxLabel: string;

  // Behavior options
  @Input() step: number;
  @Input() minValue: number;
  @Input() maxValue: number;

  // State options
  @Input() disabled: boolean;
  @Input() externalValidationErrors: ValidationErrors | null;

  inputsInitialized$ = new BehaviorSubject<boolean>(false);

  private _value;

  get value() {
    return this._value;
  }

  @Input()
  set value(value) {
    this._value = value;
    this.onChange(this._value);
  }

  get inputId() {
    return this.ngControl ? this.ngControl.name : null;
  }

  get hasUnitsOfMeasure(): boolean {
    return (
      this.unitsOfMeasure !== null &&
      this.unitsOfMeasure !== undefined &&
      this.unitsOfMeasure.length > 0
    );
  }

  get errors(): ValidationErrors | null {
    return this.ngControl
      ? { ...this.ngControl.errors, ...this.externalValidationErrors }
      : null;
  }

  // tslint:disable:no-empty
  onChange: any = (): any => {};

  onTouched: any = (): any => {};

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.inputsInitialized$.next(true);
    this.onInput({
      value:
        (this.ngControl as FormControlDirective).form.value ||
        Math.floor((this.minValue + this.maxValue) / 2),
    });
  }

  ngOnDestroy(): void {
    this.inputsInitialized$.complete();
  }

  writeValue(value: any): void {
    this.value = value;

    this.inputsInitialized$
      .pipe(
        filter((initialized) => initialized),
        first()
      )
      .subscribe(() => {
        this.value = value;
      });
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled) {
    this.disabled = disabled;
  }

  onInput(value: any): void {
    this.onTouched();

    this.value = value.value;
  }
}
