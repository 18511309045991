import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const livingSituationBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.observation,
  fieldGroups: [
    {
      name: ({ resource }) => resource?.content?.homeType?.coding?.[0]?.display || 'my_doctor_home',
      fields: [
        {
          label: 'my_doctor_start_date',
          fieldName: 'effective',
          prepareData: (data) => (data?.date ? MedrecordMoment(data?.date).format('LL') : ''),
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
      ],
    },
  ],
});
