import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuestionComment } from '../../interfaces/question-comment.interface';
import { MedrecordMoment } from '@medrecord/services-datetime';

@Component({
  selector: 'medrecord-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent {

  @Input() comment: QuestionComment;

  @Output() edit = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();

  get formattedDate() {
    return MedrecordMoment(this.comment.date)
      .format('LLL');
  }

}
