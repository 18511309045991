<div *ngIf="!isFieldGroupEmpty()" [class.pl-5]="!fieldGroup.fieldAsSubgroup">
  <div
    [class]="fieldGroup.fieldAsSubgroup ? 'pb-3' : 'py-3'"
    class="title pr-4 d-flex align-items-center"
    (click)="isExpanded = !isExpanded"
  >
    <div class="chevron">
      <medrecord-svg-chevron-right
        [type]="'black'"
        *ngIf="!isExpanded"
        icon
        width="7"
      ></medrecord-svg-chevron-right>
      <medrecord-svg-chevron-down
        [type]="'black'"
        *ngIf="isExpanded"
        icon
        width="12"
      ></medrecord-svg-chevron-down>
    </div>
    <span class="text-black text-lg text-weight-600 ml-2">{{
      prepareFieldGroupName() | translate
    }}</span>
  </div>

  <ng-container *ngIf="isExpanded">
    <div class="row ml-2" [class.mr-2]="fieldGroup.fieldAsSubgroup">
      <ng-container *ngFor="let field of fieldGroup.fields">
        <ng-container *ngIf="!field.multiple; else multipleBlock">
          <ng-container *ngIf="!field.fieldAsSubgroup; else subgroupBlock">
            <ng-container *ngIf="!field.showAsTable; else showAsTable">
              <div class="col-3 my-0 py-0" *ngIf="!isFieldEmpty(field)">
                <p class="text-boulder text-weight-600 mb-2">
                  {{ field.label | translate }}
                </p>
              </div>
              <ng-container *ngIf="!field.showAsDownload; else showAsDownload">
                <ng-container
                  *ngIf="!field.showAsPdfADownload; else showAsPdfADownload"
                >
                  <div class="col-9 my-0 py-0" *ngIf="!isFieldEmpty(field)">
                    <p
                      class="mb-2"
                      [class]="field.cssClass"
                      [innerHTML]="
                        field.translate
                          ? (castPreparedDataToString(prepareDataForField(field))
                            | translate)
                          : prepareDataForField(field)
                      "
                    ></p>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-template #showAsTable>
              <div class="col-12 my-0 py-0 mb-3" *ngIf="!isFieldEmpty(field)">
                <table class="w-100">
                  <tr>
                    <th
                      class="text-primary table-header table-cell p-2"
                      *ngFor="let column of prepareDataForField(field)?.columns"
                    >
                      {{ column | translate }}
                    </th>
                  </tr>
                  <tr *ngFor="let row of prepareDataForField(field)?.rows">
                    <td class="p-2 table-cell" *ngFor="let data of row">
                      {{ data }}
                    </td>
                  </tr>
                </table>
              </div>
            </ng-template>
            <ng-template #showAsDownload>
              <div class="col-9 my-0 py-0">
                <a
                  type="button"
                  class="btn-sm btn-outline-black text-weight-600"
                  [href]="prepareDataForField(field)"
                  target="_blank"
                >
                  {{ "my_doctor_download_pdf" | translate }}
                </a>
              </div>
            </ng-template>
            <ng-template #showAsPdfADownload>
              <div class="col-9 my-0 py-0">
                <a
                  type="button"
                  class="btn-sm btn-outline-black text-weight-600"
                  (click)="onPdfaButtonClick(prepareDataForField(field))"
                >
                  {{ "Download PDF/A" | translate }}
                </a>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-template #subgroupBlock>
          <div class="col-12">
            <doctor-gui-medsafe-resource-field-group
              *ngIf="!isFieldEmpty(field)"
              [fieldGroup]="field"
              [resourceType]="resourceType"
              [resource]="resource"
              [resourceIndex]="resourceIndex"
              [mainResourceData]="mainResourceData"
            ></doctor-gui-medsafe-resource-field-group>
          </div>
        </ng-template>
        <ng-template #multipleBlock>
          <div class="col-12">
            <medrecord-resource-field-multiple
              [fieldGroup]="field"
              [mainResourceData]="mainResourceData"
              [resource]="resource"
            ></medrecord-resource-field-multiple>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</div>
