<div class="d-flex align-items-center pagination-wrapper">
  <div class="items-select d-flex align-items-center">
    <span class="mx-3">{{ 'common_page_items_label' | translate }}</span>
    <ejs-dropdownlist
      [dataSource]="pageItemsCount"
      placeholder="Items"
      [value]="settingsModel.pageSize.toString() || pageItemsCount[0]"
      (change)="changeItemsPerPage($event)"
    ></ejs-dropdownlist>
  </div>

  <div class="ml-5">
    <button
      class="btn btn-white-300 mx-2 text-white-300"
      (click)="prevPage()"
      [disabled]="settingsModel.currentPage === 1"
    >
      <i class="fas fa-chevron-left text-primary"></i>
    </button>
    <span *ngIf="settingsModel.totalRecordsCount">
      {{ getStartRange(settingsModel.currentPage) }} -
      {{ getEndRange(settingsModel.currentPage, settingsModel.totalRecordsCount) }} {{ 'common_of' | translate }}
      {{ settingsModel.totalRecordsCount }}
    </span>
    <span *ngIf="!settingsModel.totalRecordsCount">
      {{ 'common_no_results' | translate }}
    </span>
    <button
      class="btn btn-white-300 mx-2 text-white-300"
      (click)="nextPage()"
      [disabled]="settingsModel.currentPage === getTotalPages(settingsModel.totalRecordsCount)"
    >
      <i class="fas fa-chevron-right text-primary"></i>
    </button>
  </div>
</div>
