import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectUserId } from '@medrecord/managers-auth';

@Component({
  selector: 'zdb-current-user-profile',
  templateUrl: './current-user-profile.component.html',
  styleUrls: ['./current-user-profile.component.scss']
})
export class CurrentUserProfileComponent implements OnInit {

  userId$: Observable<string>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.userId$ = this.store.select(selectUserId);
  }

}
