import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';

import { APP_ENVIRONMENT } from '@medrecord/core';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';

@Injectable()
export class AuthService {
  public resourceTokenUrl = `${ this.environment.auth }/token`;

  constructor(
    private http: HttpClient,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment,
  ) {}

  getTempToken(accessToken: string): Observable<{}> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${ accessToken }`
    });
    return this.http.get(`${this.resourceTokenUrl}/getauthtoken`, { headers });
  }
}
