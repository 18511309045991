<ng-container *ngIf="{
  questions: questions$ | async,
  selectedQuestion: selectedQuestion$ | async,
  selectedQuestionAnswer: selectedQuestionAnswer$ | async,
  isQuestionnaireFinished: isQuestionnaireFinished$ | async,
  commentsEnabled: commentsEnabled$ | async,
  answers: answers$ | async,
  isFirstQuestionSelected: isFirstQuestionSelected$ | async,
  isLastQuestionSelected: isLastQuestionSelected$ | async
} as state">
  <div class="row non-printable">
    <div class="col-md-8 mb-4">
      <div class="zdb-card zdb-shadow" [ngStyle]="state.isFirstQuestionSelected && { backgroundImage: 'url(assets/images/cards/selfcheck-todo.png)' }">
        <div class="card-body">

          <medrecord-question-form *ngIf="state.selectedQuestion" [answers]="state.answers"
            [question]="state.selectedQuestion" [isReadonly]="isReadonly" [isPositiveHealth]="isPositiveHealth"></medrecord-question-form>
        </div>

        <div class="comments-section">
          <medrecord-question-comments *ngIf="state.commentsEnabled"></medrecord-question-comments>
        </div>

        <div class="card-footer ">
          <div class="buttons-wrap">
            <button (click)="selectPrevQuestion()" class="btn btn-lg btn-outline-primary" [hidden]="state.isFirstQuestionSelected">
              {{ 'common_previous' | translate }}
            </button>
            <button (click)="selectNextQuestion()" class="btn btn-lg btn-secondary" [hidden]="state.isLastQuestionSelected">
              {{ 'common_next' | translate }}
            </button>
            <button *ngIf="!isReadonly" (click)="finish()" class="btn  btn-lg btn-primary-button" [hidden]="!(state.isLastQuestionSelected && state.isQuestionnaireFinished)">
              {{ 'common_questionnaire_send' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="zdb-card zdb-shadow" style="background-color: #fff;">
        <div class="card-header">
          <h3 class="card-title">{{ 'common_questionnaire_progress' | translate }}</h3>
        </div>
        <div class="card-body" style="padding:0">
          <medrecord-questions-list [isPositiveHealth]="isPositiveHealth" [questions]="state.questions" [selectedQuestionId]="state.selectedQuestion.id">
          </medrecord-questions-list>
        </div>
        <div class="card-footer ">
          <div class="buttons-wrap justify-content-center">
            <button *ngIf="!isReadonly" (click)="finish()" class="btn  btn-lg btn-primary-button btn-block" [disabled]="!state.isQuestionnaireFinished">
              {{ 'common_questionnaire_send' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isReadonly && state.questions">
  <div class="row printable" style="display: none" *ngFor="let question of state.questions">
    <div class="col-md-8 mb-4">
      <div class="zdb-card zdb-shadow">
        <div class="card-body">

          <medrecord-question-form [answers]="state.answers"
            [question]="question" [isReadonly]="isReadonly"></medrecord-question-form>
        </div>
      </div>
    </div>
  </div>
  </ng-container>

</ng-container>
