import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { selectQuestionnaireTask, selectQuestionnaireTemplate } from '@app/medrecord/questionnaire-adapter/state-management/selectors/templates.selectors';
import { QuestionnairesService } from '@app/medrecord/questionnaire-adapter/services/questionnaires.service';
import { QuestionnaireOutcomesService } from '@app/selfcheck/questionnaire-outomes/questionnaire-outcomes.service';
import {
  OutcomeItem,
  Score,
} from '@app/selfcheck/questionnaire-outomes/questionnaire-outcomes.type';
import { selectQuestionnaire } from 'projects/questionnaires/src/public-api';

@Component({
  selector: 'zdb-questionnaire-task-scores',
  templateUrl: './questionnaire-task-scores.component.html',
  styleUrls: ['./questionnaire-task-scores.component.scss'],
})
export class QuestionnaireTaskScoresComponent implements OnInit {
  result$: Observable<{ extraInfo; groupedOutcomes }>;
  availableScores: Array<Score> = [];
  @Input() userId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private questionnaireService: QuestionnairesService,
    private questionnaireOutcomeService: QuestionnaireOutcomesService,
    private translateService: TranslateService,
    private store: Store
  ) {}

  ngOnInit() {
    const params = this.activatedRoute.snapshot.paramMap;
    const userId = params.get('userId');
    const taskId = params.get('taskId');

    this.result$ = combineLatest([
      // load extra info
      this.store.pipe(
        select(selectQuestionnaireTemplate),
        concatMap((template: any) =>
          this.store.pipe(select(selectQuestionnaire, template.resourceId))
        ),
        map((questionnaire) => {
          return this.questionnaireOutcomeService.getExtraInfo(questionnaire);
        })
      ),
      // load task scores
      this.questionnaireService
        .loadQuestionnaireTaskScore(userId, taskId)
        .pipe(
          map((outcomes) =>
            this.questionnaireOutcomeService.groupOutcomes(
              outcomes as Array<OutcomeItem>
            )
          )
        ),
      // load task metadata
      this.store.pipe(select(selectQuestionnaireTask)),
    ]).pipe(
      map(([extraInfo, groupedOutcomes, questionnaireTask]) => ({
        extraInfo,
        groupedOutcomes,
        questionnaireTask,
      }))
    );

    this.availableScores = this.translateService.instant(
      `questionnaire_outcome`
    );
  }
}
