import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { Resource } from '../enums/resource.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const documentManifestBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.documentManifest,
  additionalResources: [Resource.pdfMetadataDocref],
  fieldGroups: [
    {
      name: 'my_doctor_document',
      fields: [
        {
          label: 'my_doctor_status',
          fieldName: 'status',
          prepareData: (status) => status,
        },
        {
          label: 'my_doctor_type',
          fieldName: 'type',
          prepareData: (data) => data?.coding[0]?.display,
        },
        {
          label: 'my_doctor_subject',
          fieldName: 'subject',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_indexed_date',
          fieldName: 'createdDate',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LLL') : ''),
        },
        {
          label: 'my_doctor_author',
          fieldName: 'author',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_source',
          fieldName: 'source',
          prepareData: (data) => data,
        },
        {
          name: 'my_doctor_content',
          fieldAsSubgroup: true,
          multiple: true,
          getDataSource: (data) => data?.content?.content,
          fields: [
            {
              label: 'my_doctor_status',
              fieldName: 'reference',
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                return reference?.content?.status;
              },
            },
            {
              label: 'my_doctor_type',
              fieldName: 'reference',
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                return reference?.content?.type?.coding[0]?.display;
              },
            },
            {
              label: 'my_doctor_class',
              fieldName: 'reference',
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                return reference?.content?.classField?.coding[0]?.display;
              },
            },
            {
              label: 'my_doctor_subject',
              fieldName: 'reference',
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                return reference?.content?.subject?.display;
              },
            },
            {
              label: 'my_doctor_indexed_date',
              fieldName: 'reference',
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                const date = reference?.content?.indexedDate;
                return date ? MedrecordMoment(date).format('LLL') : '';
              },
            },
            {
              label: 'my_doctor_author',
              fieldName: 'reference',
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                return reference?.content?.author?.display;
              },
            },
            {
              label: 'my_doctor_title',
              fieldName: 'reference',
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                return reference?.content?.title;
              },
            },
            {
              label: 'my_doctor_content_type',
              fieldName: 'reference',
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                return reference?.content?.contentType;
              },
            },
            {
              label: 'my_doctor_language',
              fieldName: 'reference',
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                return reference?.content?.language;
              },
            },
            {
              label: 'my_doctor_security_label',
              fieldName: 'reference',
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                return reference?.content?.securityLabel?.coding[0]?.display;
              },
            },
            {
              label: 'my_doctor_location',
              fieldName: 'reference',
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                return reference?.content?.location;
              },
            },
            {
              label: 'my_doctor_download_pdf',
              fieldName: 'reference',
              showAsDownload: true,
              prepareData: (referenceKey, allResources) => {
                const reference = allResources.find(({ resourceId }) => resourceId === referenceKey.split('/')[1]);
                return reference?.content?.location;
              },
            },
          ],
        },
      ],
    },
  ],
});
