import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { Observable } from 'rxjs';
import { CareproviderLoadPayload } from '../models/interfaces/careprovider-load-payload.interface';
import { Careprovider } from '../models/interfaces/careprovider.interface';
import { CareproviderResource } from '../models/interfaces/careprovider-resource.interface';
import { FetchResourcesPayload } from '../models/interfaces/fetch-resources-payload.interface';
import { FetchListType } from '../models/enums/fetch-list-type.enum';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';

@Injectable()
export class MyDoctorManagerService {
  constructor(
    private http: HttpClient,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {}

  public perforemedAuthorizationOnce = false;

  loadCareproviders(userId: string, payload: CareproviderLoadPayload): Observable<Careprovider[]> {
    let params = new HttpParams();

    Object.keys(payload).forEach((key) => {
      params = params.set(key, payload[key]);
    });

    return this.http.get<Careprovider[]>(this.environment.backend + '/extdata/' + userId + '/careprovider', { params });
  }

  loadCareprovidersCount(userId: string, search: string): Observable<number> {
    let params = new HttpParams();
    params = params.set('search', search);

    return this.http.get<number>(this.environment.backend + '/extdata/' + userId + '/careprovider/count', { params });
  }

  loadCareprovider(userId: string, careproviderId: string): Observable<Careprovider> {
    return this.http.get<Careprovider>(this.environment.backend + '/extdata/' + userId + '/careprovider/info/' + careproviderId);
  }

  loadResources(userId: string, careproviderId: string, resourceKeys: string[]): Observable<any> {
    let params = new HttpParams();

    params = params.set('resourceKeys', resourceKeys.join(','));

    return this.http.get<any>(this.environment.backend + '/extdata/' + userId + '/careprovider/' + careproviderId, {
      params,
    });
  }

  loadLogs(userId: string, careproviderId: string): Observable<any[]> {
    return this.http.get<any[]>(
      this.environment.backend + '/extdata/' + userId + '/careprovider/' + careproviderId + '/log'
    );
  }

  loadList(userId: string, type: FetchListType): Observable<any[]> {
    return this.http.post<any[]>(this.environment.backend + '/extdata/' + userId + '/list/' + type, { body: {} });
  }

  fetchCareproviderData(
    userId: string,
    careproviderId: string,
    payload: FetchResourcesPayload
  ): Observable<CareproviderResource[]> {
    return this.http.post<CareproviderResource[]>(
      this.environment.backend + '/extdata/' + userId + '/careprovider/' + careproviderId,
      {
        ...payload,
      }
    );
  }

  loadDigIdUrl(userId: string, careproviderId: string, dataServiceId: string): Observable<{ authEndpointUrl: string }> {
    return this.http.post<{ authEndpointUrl: string }>(
      this.environment.backend +
        '/extdata/' +
        userId +
        '/careprovider/' +
        careproviderId +
        '/auth/' +
        dataServiceId +
        '/url',
      {
        redirectUrl: `${this.environment.medmijRedirectUri}/external-services/healthcare-providers/auth-back`,
      }
    );
  }

  checkAuthorizationToken(userId: string, authorizationCode: string, state: string): Observable<any> {
    const params = new HttpParams().append(
      'errors_notification_models',
      JSON.stringify([
        {
          hideError: true,
        },
      ])
    );

    return this.http.post(
      `${this.environment.backend}/extdata/${userId}/careprovider/auth/token`,
      {
        authorizationCode,
        redirectUrl: `${this.environment.medmijRedirectUri}/external-services/healthcare-providers/auth-back`,
        state,
      },
      { params }
    );
  }

  getPortabilityReport(userId: string, startDate: string, endDate: string): Observable<any> {
    return this.http.post(`${this.environment.backend}/extdata/${userId}/medmij/portability`, {
      startDate,
      endDate,
    });
  }
}
