import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { questionnaireScope } from '@app/medrecord/questionnaire-adapter/constants/questionnaire-scope';
import { QuestionnairesService } from '@app/medrecord/questionnaire-adapter/services/questionnaires.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  Questionnaire,
  selectQuestionnaire,
} from '../../../../../projects/questionnaires/src/public-api';
import * as questionnaireLibActions from '../../../../../projects/questionnaires/src/public-api';
import {
  preparePositiveHealthQuestionnaireForSave,
  prepareQuestionnaireForSave,
} from '../../../../../projects/questionnaires/src/lib/utils/questionnaire-prepare-for-save.util';
import {
  saveQuestionnaireTask,
  saveQuestionnaireTaskFailure,
  saveQuestionnaireTaskSuccess,
  autoSaveQuestionnaireTaskSuccess,
} from './questionnaires.actions';
import { selectQuestionnaireTaskId } from '@app/medrecord/questionnaire-adapter/state-management/selectors/templates.selectors';
import * as questionnaireActions from './questionnaires.actions';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { combineLatest, forkJoin, of } from 'rxjs';
import { selectUserId } from '@medrecord/managers-auth';
import { PositiveHealthQuestionnairesService } from '../services/positive-health-questionnaires.service';
import { QuestionnairesInUseListItem } from '../interfaces/questionnaire-in-use-list-item.interface';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';
import { APP_ENVIRONMENT } from '@medrecord/core';

@Injectable()
export class QuestionnaireEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private questionnairesService: QuestionnairesService,
    private positiveHealthQuestionnaireService: PositiveHealthQuestionnairesService,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {}

  loadNewQuestionnaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(questionnaireActions.loadNewQuestionnaire),
      withLatestFrom(this.store.pipe(select(selectUserId))),
      mergeMap(([, userId]) =>
        this.questionnairesService
          .getQuestionnaireTask(userId)
          .pipe(map((task) => ({ userId, task })))
      ),
      mergeMap(({ userId, task }) =>
        this.loadQuestionnaireData(userId, task.taskId)
      )
    )
  );

  loadQuestionnaireTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(questionnaireActions.loadQuestionnaire),
      mergeMap((action) =>
        this.loadQuestionnaireData(action.userId, action.taskId)
      )
    )
  );

  handleSaveQuestionnaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(questionnaireLibActions.saveQuestionnaire),
      filter(({ scope }) => scope === questionnaireScope),
      map(({ questionnaireId, routeSnapshot }) =>
        saveQuestionnaireTask({
          questionnaireId,
          routeSnapshot,
        })
      )
    )
  );

  saveQuestionnaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(questionnaireActions.saveQuestionnaireTask),
      withLatestFrom(this.store.select(selectUserId)),
      concatMap(([action, patientId]) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(selectQuestionnaire, action.questionnaireId)
            ),
            this.store.pipe(
              select(selectQuestionnaireTaskId, action.questionnaireId)
            )
          ),
          map(([, questionnaire, taskId]) => [
            action,
            patientId,
            questionnaire,
            taskId,
          ])
        )
      ),
      concatMap(([action, patientId, questionnaire, taskId]) => {
        // const taskId = (action as {questionnaireId: string, routeSnapshot: any}).routeSnapshot.params?.taskId; //todo

        if (taskId !== 'positive-health-task') {
          const questionnaireResponse = prepareQuestionnaireForSave(
            questionnaire as Questionnaire
          );

          return this.questionnairesService
            .saveQuestionnaireResponse(
              patientId as string,
              taskId as string,
              questionnaireResponse
            )
            .pipe(
              map(() =>
                saveQuestionnaireTaskSuccess({
                  questionnaireId: (action as any).questionnaireId,
                })
              ),
              catchError((error) =>
                of(
                  saveQuestionnaireTaskFailure({
                    questionnaireId: (action as any).questionnaireId,
                    error,
                  })
                )
              )
            );
        } else {
          const questionnaireResponse =
            preparePositiveHealthQuestionnaireForSave(
              questionnaire as Questionnaire,
              this.positiveHealthQuestionnaireService.getQuestonnaireToTaskMapping()
            ).map((qr) =>
              this.questionnairesService.saveQuestionnaireResponse(
                patientId as string,
                qr.taskId as string,
                qr.request
              )
            );

          return combineLatest(questionnaireResponse).pipe(
            map(() => {
              return saveQuestionnaireTaskSuccess({
                questionnaireId: (action as any).questionnaireId,
              });
            }),
            catchError((error) => {
              return of(
                saveQuestionnaireTaskFailure({
                  questionnaireId: (action as any).questionnaireId,
                  error,
                })
              );
            })
          );
        }
      })
    )
  );

  afterSavedQuestionnaireTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(questionnaireActions.saveQuestionnaireTaskSuccess),
      mergeMap(() => {
        this.router.navigate(['zelfcheck']);
        return [
          // loadQuestionnairesInUse(),
          // loadQuestionnaireScores(),
        ];
      })
    )
  );

  handleAutoSaveQuestionnaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(questionnaireLibActions.autoSaveQuestionnaire),
      withLatestFrom(this.store.select(selectUserId)),
      concatMap(([action, patientId]) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(selectQuestionnaire, action.questionnaireId)
            ),
            this.store.pipe(
              select(selectQuestionnaireTaskId, action.questionnaireId)
            )
          ),
          concatMap(([, questionnaire, taskId]) => {
            if (taskId !== 'positive-health-task') {
              const questionnaireResponse = prepareQuestionnaireForSave(
                questionnaire as Questionnaire
              );

              return this.questionnairesService
                .autoSaveQuestionnaireResponse(
                  patientId as string,
                  taskId as string,
                  questionnaireResponse
                )
                .pipe(
                  map(() =>
                    autoSaveQuestionnaireTaskSuccess({
                      taskId: taskId as string,
                      questionnaireId: (questionnaire as any).id,
                    })
                  ),
                  catchError((error) =>
                    of(
                      saveQuestionnaireTaskFailure({
                        questionnaireId: (questionnaire as any).id,
                        error,
                      })
                    )
                  )
                );
            } else {
              const questionnaireResponse =
                preparePositiveHealthQuestionnaireForSave(
                  questionnaire as Questionnaire,
                  this.positiveHealthQuestionnaireService.getQuestonnaireToTaskMapping()
                )
                  .filter(
                    (qr) =>
                      qr.taskId ===
                      this.positiveHealthQuestionnaireService.getQuestonnaireToTaskMapping()?.[
                        action.questionId?.split('|||')?.pop()
                      ]
                  )
                  .map((qr) =>
                    this.questionnairesService.autoSaveQuestionnaireResponse(
                      patientId as string,
                      qr.taskId as string,
                      qr.request
                    )
                  );

              return combineLatest(questionnaireResponse).pipe(
                map(() =>
                  autoSaveQuestionnaireTaskSuccess({
                    taskId: taskId as string,
                    questionnaireId: (questionnaire as any).id,
                  })
                ),
                catchError((error) =>
                  of(
                    saveQuestionnaireTaskFailure({
                      questionnaireId: (questionnaire as any).id,
                      error,
                    })
                  )
                )
              );
            }
          })
        )
      )
    )
  );

  autoSavedQuestionnaireTask$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(questionnaireActions.autoSaveQuestionnaireTaskSuccess),
        tap((action) => {
          console.log(
            'Questionnaire auto-saved! (taskId:' + action.taskId + ')'
          );
        })
      ),
    { dispatch: false }
  );

  loadPositiveHealthQuestionnaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(questionnaireActions.loadPositiveHealthQuestionnaire),
      withLatestFrom(this.store.pipe(select(selectUserId))),
      mergeMap(([, userId]) =>
        this.positiveHealthQuestionnaireService
          .getQuestionnaireTask(userId)
          .pipe(map((tasks) => ({ userId, tasks })))
      ),
      mergeMap(({ userId, tasks }) =>
        this.loadPositiveHealthQuestionnaireData(userId, tasks)
      )
    )
  );

  loadPositiveHealthQuestionnaires$ = createEffect(() =>
    this.actions$.pipe(
      ofType(questionnaireActions.loadPositiveHealthQuestionnaires),
      withLatestFrom(this.store.pipe(select(selectUserId))),
      mergeMap(([{ firstTaskIds, secondTaskIds, userId }, currentUserId]) =>
        this.positiveHealthQuestionnaireService
          .getQuestionnaireTask(!userId ? currentUserId: userId, [...firstTaskIds, ...secondTaskIds])
          .pipe(
            map((tasks) => ({ userId: !userId ? currentUserId: userId, tasks, firstTaskIds, secondTaskIds }))
          )
      ),
      mergeMap(({ userId, tasks, firstTaskIds, secondTaskIds }) => {
        const { firstTasks, secondTasks } = tasks.reduce(
          (prev, curr) => ({
            firstTasks: firstTaskIds.includes(curr.taskId)
              ? [...prev.firstTasks, curr]
              : prev.firstTasks,
            secondTasks: secondTaskIds.includes(curr.taskId)
              ? [...prev.secondTasks, curr]
              : prev.secondTasks,
          }),
          { firstTasks: [], secondTasks: [] }
        );

        return this.loadPositiveHealthQuestionnaireData(
          userId,
          firstTasks,
          secondTasks
        );
      })
    )
  );

  loadQuestionnaireData = (userId: string, taskId: string) => {
    const apiCalls = [
      this.questionnairesService.loadQuestionnaire(
        userId,
        this.environment.interraiQuestionnaireId
      ),
      this.questionnairesService.loadQuestionnaireTask(userId, taskId),
      this.questionnairesService.getQuestionnaireTaskResponse(userId, taskId),
    ];

    return combineLatest(apiCalls).pipe(
      map(([questionnaireData, taskData, responseData]) => {
        return questionnaireActions.loadQuestionnaireSuccess({
          questionnaireData,
          answers: responseData,
          taskId,
          task: taskData,
        });
      }),
      catchError((error) =>
        of(
          questionnaireActions.loadQuestionnaireFailure({
            error,
          })
        )
      )
    );
  }

  loadPositiveHealthQuestionnaireData = (
    userId: string,
    tasks: QuestionnairesInUseListItem[],
    secondTasks: QuestionnairesInUseListItem[] = []
  ) => {
    const apiCalls1 = tasks.reduce(
      (prev, task) => [
        ...prev,
        ...[
          this.questionnairesService.loadQuestionnaire(
            userId,
            task.questionnaireId
          ),
          this.questionnairesService.loadQuestionnaireTask(userId, task.taskId),
          this.questionnairesService.getQuestionnaireTaskResponse(
            userId,
            task.taskId
          ),
        ],
      ],
      []
    );

    const apiCalls2 =
      secondTasks?.reduce(
        (prev, task) => [
          ...prev,
          ...[
            this.questionnairesService.loadQuestionnaire(
              userId,
              task.questionnaireId
            ),
            this.questionnairesService.loadQuestionnaireTask(
              userId,
              task.taskId
            ),
            this.questionnairesService.getQuestionnaireTaskResponse(
              userId,
              task.taskId
            ),
          ],
        ],
        []
      ) || [];

    return forkJoin({
      firstCalls: combineLatest(apiCalls1),
      secondCalls: apiCalls2?.length ? combineLatest(apiCalls2) : of([]),
    }).pipe(
      map(({ firstCalls, secondCalls }) => {
        const firstData = this.reducePositiveHealthResponses(firstCalls, tasks);
        const secondData = secondCalls?.length
          ? this.reducePositiveHealthResponses(secondCalls, secondTasks)
          : null;

        const firstQuestionnaire =
          this.positiveHealthQuestionnaireService.mapTasksToOneTask(firstData);

        const secondQuestionnaire = secondData
          ? this.positiveHealthQuestionnaireService.mapTasksToOneTask(
              secondData,
              true
            )
          : null;

        return questionnaireActions.loadPositiveHealthQuestionnairesSuccess({
          firstQuestionnaire,
          secondQuestionnaire,
        });
      }),
      catchError((error) =>
        of(
          questionnaireActions.loadQuestionnaireFailure({
            error,
          })
        )
      )
    );
  }

  reducePositiveHealthResponses = (calls, tasks) => {
    let newGroup = false;
    const data = calls.reduce((prev, curr, i) => {
      if (i % 3 === 0) {
        newGroup = true;
      } else {
        newGroup = false;
      }

      if (!newGroup) {
        prev[Math.floor(i / 3)] = {
          ...(i % 3 === 1 ? { task: curr } : { answers: curr }),
          ...prev[Math.floor(i / 3)],
        };
      } else {
        (prev as Array<any>).push({
          taskId: tasks[Math.floor(i / 3)].taskId,
          questionnaireData: curr,
        });
      }
      return prev;
    }, []) as any[];

    return data;
  }
}
