import { Component, Input } from '@angular/core';
import {
  CareproviderResource,
  CareproviderResourceData,
  MyDoctorTableInterface,
  ResourceField,
  ResourceFieldGroup,
  ResourceType,
} from '@medrecord/managers-my-doctor';
import { AttachmentsService } from 'projects/medrecord/managers/my-doctor-manager/src/lib/services/attachments.service';

@Component({
  selector: 'doctor-gui-medsafe-resource-field-group',
  templateUrl: './resource-field-group.component.html',
  styleUrls: ['./resource-field-group.component.scss'],
})
export class ResourceFieldGroupComponent {
  @Input() fieldGroup: ResourceFieldGroup;
  @Input() resource: CareproviderResource;
  @Input() mainResourceData: CareproviderResourceData;
  @Input() resourceType: ResourceType;
  @Input() resourceIndex: number;

  isExpanded = false;

  prepareFieldGroupName(): string {
    return typeof this.fieldGroup.name === 'string'
      ? this.fieldGroup.name
      : this.fieldGroup.name({
          index: this.resourceIndex,
          resource: this.mainResourceData,
        });
  }

  isFieldGroupEmpty(): boolean {
    return this.fieldGroup.fields.reduce((prev, field) => {
      if (!prev) {
        return prev;
      }
      return !!this.isFieldEmpty(field);
    }, true);
  }

  isFieldEmpty(field): boolean {
    if ((field as ResourceFieldGroup)?.fieldAsSubgroup) {
      return (field as ResourceFieldGroup)?.fields?.reduce((prev, f) => {
        if (!prev) {
          return prev;
        }
        return !!this.isFieldEmpty(f);
      }, true);
    } else {
      const preparedData = this.prepareDataForField(field);
      return (
        preparedData === undefined ||
        preparedData === null ||
        preparedData === '' ||
        (field.showAsTable &&
          !(preparedData as MyDoctorTableInterface)?.rows?.length)
      );
    }
  }

  castPreparedDataToString(data: any): string {
    return data as string;
  }

  prepareDataForField(field: ResourceField): string | MyDoctorTableInterface {
    let rawData = field.useResource
      ? this.resource.resources.find(
          (r) => r.resourceType === field.useResource
        )
      : this.mainResourceData;

    if (field.fieldName) {
      rawData = rawData?.content?.[field.fieldName];
    }

    if (
      rawData === undefined ||
      rawData === null ||
      (typeof rawData === 'string' && rawData === '')
    ) {
      return null;
    }
    return field.prepareData(
      rawData,
      this.resource.resources,
      this.mainResourceData
    );
  }

  onPdfaButtonClick(base64Data): void {
    this.attachmentService.openFile(base64Data, 'Document');
  }

  constructor(private attachmentService: AttachmentsService) {}
}
