<div [class.selected]="selected"
     class="questions-list-item d-flex align-items-center"
>
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" fill="white" r="11.5" stroke="#E5EBED"/>
    <path *ngIf="completed || idk" d="M7 12L10.3333 15L17 9" stroke="#02C386" stroke-width="2"/>
    <path *ngIf="visited && !completed && !idk"
          d="M10.9934 13.841C10.9934 13.3903 11.093 13.0177 11.2924 12.723C11.5004 12.4283 11.7994 12.1033 12.1894 11.748C12.4754 11.488 12.6834 11.2713 12.8134 11.098C12.952 10.916 13.0214 10.7123 13.0214 10.487C13.0214 10.1663 12.8914 9.915 12.6314 9.733C12.38 9.54233 12.042 9.447 11.6174 9.447C11.21 9.447 10.846 9.53367 10.5254 9.707C10.2134 9.87167 9.94903 10.1057 9.73236 10.409L8.15936 9.486C8.52336 8.93133 9.01303 8.50233 9.62836 8.199C10.2524 7.89567 10.989 7.744 11.8384 7.744C12.835 7.744 13.6324 7.95633 14.2304 8.381C14.837 8.80567 15.1404 9.395 15.1404 10.149C15.1404 10.5043 15.0797 10.8163 14.9584 11.085C14.8457 11.3537 14.7027 11.5833 14.5294 11.774C14.3647 11.956 14.148 12.1683 13.8794 12.411C13.5587 12.697 13.3247 12.9397 13.1774 13.139C13.03 13.3297 12.9564 13.5637 12.9564 13.841H10.9934ZM11.9814 17.104C11.626 17.104 11.327 16.9913 11.0844 16.766C10.8504 16.532 10.7334 16.2503 10.7334 15.921C10.7334 15.5917 10.8504 15.3187 11.0844 15.102C11.3184 14.8767 11.6174 14.764 11.9814 14.764C12.3454 14.764 12.6444 14.8767 12.8784 15.102C13.1124 15.3187 13.2294 15.5917 13.2294 15.921C13.2294 16.2503 13.108 16.532 12.8654 16.766C12.6314 16.9913 12.3367 17.104 11.9814 17.104Z"
          fill="#FC9E00"/>
  </svg>

  <span [style.fontSize.px]="getFontSize">
     {{ questionItemTitle }}
  </span>

</div>
