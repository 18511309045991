import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zdb-spiderweb-graph',
  templateUrl: './spiderweb-graph.component.html',
  styleUrls: ['./spiderweb-graph.component.scss'],
})
export class SpiderwebGraphComponent implements OnInit {
  @Input() showPreviousResults = false;
  @Input() data = null;
  readonly centerPoint = [163.476, 188.811];
  svgSize: string | number = 550;

  readonly points = {
    'positive-health-1': [163.476, 111.317], // bodily function
    'positive-health-2': [233.916, 149.619], // mental well-being
    'positive-health-3': [233.916, 226.173], // meaning
    'positive-health-4': [163.476, 264.481], // quality of life
    'positive-health-5': [93.0361, 226.173], // participation
    'positive-health-6': [93.0361, 149.612], // daily function
  };

  pathString = '';
  previousPathString = '';

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setSvgSize();
  }

  ngOnInit(): void {
    this.setSvgSize();

    this.calculatePathString(this.data);
    this.calculatePathString(this.data, false);
  }

  calculatePathString(data, current = true) {
    if (!data?.length) {
      if (!current) {
        this.previousPathString = '';
      }
      if (current) {
        this.pathString = '';
      }
      return;
    }

    let path = 'M';
    let firstPoint;
    let hasAllScores = true;
    data?.forEach((questionnaire, index) => {
      if (
        (!current && questionnaire.scores?.length <= 1) ||
        (current && questionnaire.scores?.length === 0)
      ) {
        hasAllScores = false;
      } else {
        const value = parseFloat(
          questionnaire.scores[current ? 0 : 1]?.scores?.[0]?.value
        );
        const point =
          value?.toFixed(3) === '0.00'
            ? this.centerPoint
            : this.getPointAtLevel(
                value,
                this.points[questionnaire.questionnaireName]
              );
        if (index === 0) {
          firstPoint = [...point];
        }
        path += `${point[0]} ${point[1]}L`;
      }
    });

    if (!hasAllScores) {
      path = '';
    } else {
      path += `${firstPoint[0]} ${firstPoint[1]}Z`;
    }

    if (!current) {
      this.previousPathString = path;
    } else if (current) {
      this.pathString = path;
    }
  }

  getPointAtLevel(level: number, point: number[]) {
    return [
      (
        (1 - level / 10) * this.centerPoint[0] +
        (level / 10) * point[0]
      )?.toFixed(3),
      (
        (1 - level / 10) * this.centerPoint[1] +
        (level / 10) * point[1]
      )?.toFixed(3),
    ];
  }

  private setSvgSize() {
    this.svgSize = window.innerWidth < 768 ? '100%' : 550;
  }
}
