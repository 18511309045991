import { myDoctorManagerFeatureKey, MyDoctorManagerState } from './my-doctor-manager.reducer';
import { createSelector } from '@ngrx/store';
import { Careprovider } from '../models/interfaces/careprovider.interface';
import { CareproviderResource } from '../models/interfaces/careprovider-resource.interface';

export const getMyDoctorManagerState: (state: MyDoctorManagerState) => any = (state: MyDoctorManagerState) =>
  state[myDoctorManagerFeatureKey];

export const getCareproviders = createSelector(
  getMyDoctorManagerState,
  (state: MyDoctorManagerState): Careprovider[] => state.careproviders
);

export const getCareprovidersCount = createSelector(
  getMyDoctorManagerState,
  (state: MyDoctorManagerState): number => state.count
);

export const getActiveCareprovider = createSelector(
  getMyDoctorManagerState,
  (state: MyDoctorManagerState): Careprovider => state.activeCareprovider
);

export const getCareproviderResources = createSelector(
  getMyDoctorManagerState,
  (state: MyDoctorManagerState): CareproviderResource[] => state.resources
);

export const getFetchedResourceData = createSelector(
  getMyDoctorManagerState,
  (state: MyDoctorManagerState): CareproviderResource[] => state.fetchDataResponse
);

export const getLogs = createSelector(getMyDoctorManagerState, (state: MyDoctorManagerState): any[] => state.logs);
