import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getLogs } from '@medrecord/managers-my-doctor';

@Component({
  selector: 'medrecord-logs-modal',
  templateUrl: './logs-modal.component.html',
  styleUrls: ['./logs-modal.component.scss']
})
export class LogsModalComponent {
  @Input() careproviderId: string;
  @Input() isOpen = false;
  @Output() dialogClosed = new EventEmitter();

  logs$: Observable<any[]> = this.store.select(getLogs);

  constructor(private store: Store) { }
}
