import { Component, HostListener, Input, OnInit } from '@angular/core';
import { positiveHealthConfigMapper } from '@app/medrecord/questionnaire-adapter/constants/positive-health-config-mapper';

@Component({
  selector: 'zdb-positive-health-answers-table',
  templateUrl: './positive-health-answers-table.component.html',
  styleUrls: ['./positive-health-answers-table.component.scss'],
})
export class PositiveHealthAnswersTable implements OnInit {
  @Input() questions: any = null;
  @Input() firstAnswers: any = null;
  @Input() secondAnswers: any = null;
  @Input() firstDate: string = null;
  @Input() secondDate: string = null;
  svgSize = 16;
  tableOptions1 = `{'widths':['auto','*','auto','auto','auto'], 'layout':'noBorders'}`;
  tableOptions2 = `{'widths':['auto','*','auto','auto'], 'layout':'noBorders'}`;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setSvgSize();
  }

  ngOnInit(): void {
    this.setSvgSize();
  }

  private setSvgSize() {
    this.svgSize =
      window.innerWidth < 600 ? 12 : window.innerWidth < 768 ? 14 : 16;
  }

  getAnswer(answers, linkId) {
    return answers.find((answer) => answer.linkId === linkId)?.answers?.[0]
      ?.answerInteger;
  }

  getAverage(answers) {
    return (
      answers.reduce((prev, curr) => {
        return prev + curr?.answers?.[0]?.answerInteger;
      }, 0) / answers?.length
    )?.toFixed(2);
  }

  getConfig() {
    return positiveHealthConfigMapper[this.questions.linkId];
  }

  getTotalStatus() {
    if (!this.secondAnswers) {
      return 0;
    }

    return this.firstAnswers?.items
      .reduce(
        (prev, item, index) => {
          return [
            prev[0] + item?.answers?.[0]?.answerInteger,
            prev[1] +
              this.secondAnswers.items[index]?.answers?.[0]?.answerInteger,
          ];
        },
        [0, 0]
      )
      .map((value) => value / this.firstAnswers.items.length)
      .reduce((prev, curr, index) => (index === 0 ? curr : prev - curr), 0);
  }
}
