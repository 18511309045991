import { MedrecordMoment } from '@medrecord/services-datetime';
import { Resource } from '../models/enums/resource.enum';
import { TransactionMode } from '../models/enums/transaction-mode.enum';
import { Careprovider } from '../models/interfaces/careprovider.interface';

export const TEST_PROVIDER_ID = 'test@medrecord';

export const TEST_PROVIDER: Careprovider = {
  id: TEST_PROVIDER_ID,
  addresses: [
    {
      address: 'Marconistraat 16 \r\n 3029 AK Rotterdam',
      city: 'Rotterdam',
      country: null,
      firstLine: null,
      geolocation: null,
      postalCode: '3029 AK',
      state: null,
      streetName: 'Marconistraat',
      streetNumber: '16',
      type: 'Practice',
    },
  ],
  agb: null,
  displayName: 'Medrecord Test',
  email: TEST_PROVIDER_ID,
  hrn: null,
  oin: null,
  phone: '',
  speciality: '',
  ura: null,
  dataServices: [
    {
      id: '28',
      name: 'Huisartsgegevens',
      transactions: [
        {
          id: 'gp-data-2020-pull',
          code: 'HGB',
          version: '1.1',
          mode: TransactionMode.pull,
          informationStandard: 'gp-data',
          resourceKeys: [
            Resource.gpEpisode2020,
            Resource.gpMedicationIntolerance2020,
            Resource.gpLaboratoryResults2020,
            Resource.gpSoap2020,
            Resource.gpPractitioner2020,
            Resource.gpPatient2020,
            Resource.gpEncounter2020,
            Resource.gpCurrentMedication2020,
          ],
          parameters: [
            // Laboratory results and current medication parameters
            {
              resourceKeys: [Resource.gpLaboratoryResults2020, Resource.gpCurrentMedication2020],
              type: 'date',
              name: 'start-date',
              label: 'my_doctor_start_date',
              placeholder: 'my_doctor_start_date',
            },
          ],
        },
      ],
    },
    {
      id: '48',
      name: 'Basisgegevens zorg',
      transactions: [
        {
          id: 'bgz-2020-pull',
          code: 'HGB',
          version: '1.1',
          mode: TransactionMode.pull,
          informationStandard: 'bgz',
          resourceKeys: [
            Resource.bgzPatient2020,
            Resource.bgzPayer2020,
            Resource.bgzTreatmentDirective2020,
            Resource.bgzAdvanceDirective2020,
            Resource.bgzContactPerson2020,
            Resource.bgzFunctionalOrMentalStatus2020,
            Resource.bgzConcern2020,
            Resource.bgzLivingSituation2020,
            Resource.bgzDrugUse2020,
            Resource.bgzAlcoholUse2020,
            Resource.bgzTobaccoUse2020,
            Resource.bgzNutritionAdvice2020,
            Resource.bgzAlert2020,
            Resource.bgzAllergyIntolerance2020,
            Resource.bgzMedicationUse2020,
            Resource.bgzMedicationAgreement2020,
            Resource.bgzAdministrationAgreement2020,
            Resource.bgzMedicalAid2020,
            Resource.bgzVaccination2020,
            Resource.bgzBloodPressure2020,
            Resource.bgzBodyWeight2020,
            Resource.bgzBodyHeight2020,
            Resource.bgzLaboratoryTestResult2020,
            Resource.bgzProcedure2020,
            Resource.bgzEncounter2020,
            Resource.bgzPlannedCareActivityProcedureRequest2020,
            Resource.bgzPlannedCareActivityImmunizationRecommendation2020,
            Resource.bgzPlannedCareActivityAppointment2020,
            Resource.bgzPlannedCareActivityDeviceRequest2020,
            Resource.bgzHealthProfessional2020,
          ],
        },
      ],
    },
    {
      id: 'unknown-3',
      name: 'PDF-A',
      transactions: [
        {
          id: 'pdf-a-pull-2020',
          code: 'HGB',
          version: '1.1',
          mode: TransactionMode.pull,
          informationStandard: 'pdf-a',
          resourceKeys: [Resource.pdfMetadataDocref, Resource.pdfMetadataManifest],
          additionalResources: [Resource.pdfMetadataDocref],
          parameters: [
            // Document reference id parameters
            {
              resourceKeys: [Resource.pdfMetadataDocref, Resource.pdfMetadataManifest],
              type: 'dropdown',
              options: [
                { id: 'CURRENT', text: 'Current' },
                { id: 'SUPERSEDED', text: 'Superseded' },
                { id: 'ENTEREDINERROR', text: 'ENTEREDINERROR' },
              ],
              defaultValue: 'CURRENT',
              name: 'status',
              label: 'my_doctor_status',
              placeholder: 'my_doctor_status',
            },
            {
              resourceKeys: [Resource.pdfMetadataDocref, Resource.pdfMetadataManifest],
              type: 'date',
              name: 'start-date',
              label: 'my_doctor_start_date',
              placeholder: 'my_doctor_start_date',
              defaultValue: MedrecordMoment(new Date().setMonth(new Date().getMonth() - 6)).format('YYYY-MM-DD'),
            },
            {
              resourceKeys: [Resource.pdfMetadataDocref, Resource.pdfMetadataManifest],
              type: 'date',
              name: 'end-date',
              label: 'my_doctor_end_date',
              placeholder: 'my_doctor_end_date',
              defaultValue: MedrecordMoment(new Date()).format('YYYY-MM-DD'),
            },
          ],
        },
      ],
    },
    {
      id: 'bglz2020',
      name: 'BGLZ',
      transactions: [
        {
          id: 'bglz-2020-pull',
          code: 'HGB',
          version: '1.1',
          mode: TransactionMode.pull,
          informationStandard: 'bglz',
          resourceKeys: [
            Resource.bglzPatient2020,
            Resource.bglzTreatmentDirective2020,
            Resource.bglzAdvanceDirective2020,
            Resource.bglzContactPerson2020,
            Resource.bglzProblem2020,
            Resource.bglzAllergyIntolerance2020,
            Resource.bglzLaboratoryTestResult2020,
            Resource.bglzProcedure2020,
            Resource.bglzGoalsAndInterventions2020,
            Resource.bglzCaresettingCareteam2020,
          ],
        },
      ],
    },
    {
      id: 'ggz2020',
      name: 'Bg GGZ',
      transactions: [
        {
          id: 'ggz-2020-pull',
          code: 'HGB',
          version: '1.1',
          mode: TransactionMode.pull,
          informationStandard: 'ggz',
          resourceKeys: [
            Resource.ggzPatient2020,
            Resource.ggzPayer2020,
            Resource.ggzTreatmentDirective2020,
            Resource.ggzAdvanceDirective2020,
            Resource.ggzLegalStatus2020,
            Resource.ggzContactPerson2020,
            Resource.ggzFuncOrMentalStatus2020,
            Resource.ggzProblem2020,
            Resource.ggzAlcoholUse2020,
            Resource.ggzDrugUse2020,
            Resource.ggzTobaccoUse2020,
            Resource.ggzLivingSituation2020,
            Resource.ggzFamilySituation2020,
            Resource.ggzLanguageProficiency2020,
            Resource.ggzParticipationInSociety2020,
            Resource.ggzHelpFromOthers2020,
            Resource.ggzLaboratoryTestResult2020,
            Resource.ggzGeneralMeasurement2020,
            Resource.ggzProcedure2020,
            Resource.ggzTextResult2020,
            Resource.ggzCareTeam2020,
          ],
        },
      ],
    },
  ],
};
