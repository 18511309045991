import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userDataFeatureKey, UserDataState } from './user-data.reducers';

export const getUserDataState = createFeatureSelector<UserDataState>(userDataFeatureKey);

export const selectCurrentUser = createSelector(
  getUserDataState,
  (state: UserDataState) => state.current_user
);

export const selectPatient = createSelector(
  getUserDataState,
  (state: UserDataState) => state.patient
);
