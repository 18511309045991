<div id="spiderweb">
  <zdb-card>
    <div class="card-header" *ngIf="user$ | async as user">
      <div class="float-lg-right mb-2">
        <button (click)="goBack()" class="btn btn-lg btn-secondary">
          {{ "common_back" | translate }}
        </button>
      </div>
      <div class="mr-2">
        <zdb-print
          printRoot="spiderweb"
          [title]="
            'Positieve Gezondheid ' +
            user.name.text +
            ', ' +
            getResultDate(0, true)
          "
        ></zdb-print>
      </div>
      <h2 class="card-title" *ngIf="user">
        {{ "positive_health_task_scores_title" | translate }}
        <span class="badge badge-primary">{{ user.name.text }}</span>
      </h2>
      <h3 class="card-title">
        {{ getResultDate(0) }}
      </h3>
    </div>
  </zdb-card>
  <zdb-card>
    <div *ngIf="data" class="card-body" style="overflow-x: scroll;">
      <div
        class="d-flex flex-wrap align-items-center"
        [class.flex-column]="windowWidth < 1268"
      >
        <zdb-spiderweb-graph
          [showPreviousResults]="showPreviousResults"
          [data]="data"
        ></zdb-spiderweb-graph>
        <div
          class="d-flex align-items-center"
          [class.ml-5]="windowWidth >= 1268"
          [class.pl-5]="windowWidth >= 1268"
          [class.mt-4]="windowWidth < 1268"
        >
          <div class="d-flex flex-column">
            <div>
              <ejs-checkbox
                label="Toon vorige resultaten"
                [checked]="showPreviousResults"
                (change)="showPreviousResults = !showPreviousResults"
              ></ejs-checkbox>
            </div>
            <div
              [class.show-result]="showPreviousResults"
              class="result-before d-flex align-items-center mt-3"
            >
              <div
                [ngStyle]="{ backgroundColor: '#D35400' }"
                class="before color-box mr-3"
              ></div>
              <span>
                Vorige resultaten <br />
                ({{ getResultDate(1) }})
              </span>
            </div>
            <div class="result-after d-flex align-items-center mt-3">
              <div
                [ngStyle]="{ backgroundColor: '#71CDDF' }"
                class="after color-box mr-3"
              ></div>
              <span>
                Huidige resultaten <br />
                ({{ getResultDate(0) }})
              </span>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="resource$ | async as resource">
        <ng-container *ngIf="firstAnswers$ | async as firstAnswers">
          <ng-container
            *ngIf="
              secondAnswers$ | async as secondAnswers;
              else withoutSecondAnswers
            "
          >
            <h3 class="my-5 positive-health-answers-title">Uitslag per categorie</h3>

            <div
              *ngFor="
                let questions of getQuestionsWithoutFirstQuestion(
                  resource.items
                )
              "
              class="pb-4 mb-5 positive-health-answers-table"
              style="width: 100%;"
            >
              <zdb-positive-health-answers-table
                [questions]="questions"
                [firstAnswers]="
                  getAnswersByLinkId(questions.linkId, firstAnswers.items)
                "
                [secondAnswers]="
                  getAnswersByLinkId(questions.linkId, secondAnswers.items)
                "
                [firstDate]="getResultDate(0)"
                [secondDate]="getResultDate(1)"
              ></zdb-positive-health-answers-table>
            </div>
          </ng-container>
          <ng-template #withoutSecondAnswers>
            <h3 class="my-5 positive-health-answers-title">Uitslag per categorie</h3>

            <div
              *ngFor="
                let questions of getQuestionsWithoutFirstQuestion(
                  resource.items
                )
              "
              class="pb-4 mb-5 positive-health-answers-table"
            >
              <zdb-positive-health-answers-table
                [questions]="questions"
                [firstAnswers]="
                  getAnswersByLinkId(questions.linkId, firstAnswers.items)
                "
                [firstDate]="getResultDate(0)"
                [secondDate]="getResultDate(1)"
              ></zdb-positive-health-answers-table>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </zdb-card>
</div>
