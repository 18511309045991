import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { downloadPortabilityReportAction } from '@medrecord/managers-my-doctor';
import { ValidationService } from '@medrecord/services-form-validation';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medrecord-portability-report-modal',
  templateUrl: './portability-report-modal.component.html',
  styleUrls: ['./portability-report-modal.component.scss']
})
export class PortabilityReportModalComponent implements OnInit {
  @Input() isOpen = false;
  @Output() dialogClosed = new EventEmitter();

  form: FormGroup;
  requiredErrorMsg = this.translateService.instant('validation.required');

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private validationService: ValidationService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.form = this.formBuilder.group({
      startDate: [null, this.validationService.required(this.requiredErrorMsg)],
      endDate: [null, this.validationService.required(this.requiredErrorMsg)],
    });
  }

  onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.store.dispatch(
        downloadPortabilityReportAction({
          startDate: this.form.value.startDate?.date,
          endDate: this.form.value.endDate?.date,
        })
      );

      this.dialogClosed.emit();
    }
  }

}
