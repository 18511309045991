import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-hint',
  templateUrl: './medrecord-hint.component.html',
  styleUrls: ['./medrecord-hint.component.scss']
})
export class MedrecordHintComponent {
  @Input() hint: string;
  @Input() placement: 'top' | 'right' | 'bottom' | 'left' = 'bottom';
}