import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {NewQuestionnaireResolver} from '@app/medrecord/questionnaire-adapter/resolvers/new-questionnaire-resolver.service';
import {QuestionnaireResolver} from '@app/medrecord/questionnaire-adapter/resolvers/questionnaire-resolver.service';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {QuestionnairesModule} from '../../../../projects/questionnaires/src/lib/questionnaires.module';
import { questionnairesFeatureName, questionnairesReducer } from './state-management/questionnaires.reducer';
import { QuestionnaireEffects } from './state-management/questionnaire.effects';
import { QuestionnairesService } from './services/questionnaires.service';
import { PositiveHealthQuestionnaireResolver } from './resolvers/positive-health-questionnaire-resolver.service';
import { PositiveHealthQuestionnairesService } from './services/positive-health-questionnaires.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    QuestionnairesModule,
    StoreModule.forFeature(questionnairesFeatureName, questionnairesReducer),
    EffectsModule.forFeature([
      QuestionnaireEffects
    ]),
  ],
  declarations: [
  ],
  providers: [
    NewQuestionnaireResolver,
    QuestionnaireResolver,
    PositiveHealthQuestionnaireResolver,
    QuestionnairesService,
    PositiveHealthQuestionnairesService
  ]
})
export class QuestionnaireAdapterModule {
}
