import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const bloodPressureBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.observation,
  fieldGroups: [
    {
      name: ({ resource }) =>
        [
          [resource.content?.systolic?.value, resource.content?.systolic?.unit].filter(Boolean).join(''),
          [resource.content?.diastolic?.value, resource.content?.diastolic?.unit].filter(Boolean).join(''),
        ]
          .filter(Boolean)
          .join('/') || 'my_doctor_measurement',
      fields: [
        {
          label: 'my_doctor_date',
          fieldName: 'effective',
          prepareData: (data) => (data?.date ? MedrecordMoment(data?.date).format('LLL') : ''),
        },
        {
          label: 'my_doctor_systolic',
          fieldName: 'systolic',
          prepareData: (data) => [data?.value, data?.unit].filter(Boolean).join(''),
        },
        {
          label: 'my_doctor_diastolic',
          fieldName: 'diastolic',
          prepareData: (data) => [data?.value, data?.unit].filter(Boolean).join(''),
        },
        {
          label: 'my_doctor_position',
          fieldName: 'position',
          translate: true,
          prepareData: (data) => (data ? 'measurements_blood_pressure_position_' + data?.toLowerCase() : null),
        },
        {
          label: 'my_doctor_cuff_type',
          fieldName: 'cuffType',
          translate: true,
          prepareData: (data) => (data ? 'measurements_blood_pressure_cuff_type_' + data?.toLowerCase() : null),
        },
        {
          label: 'my_doctor_body_site',
          fieldName: 'bodySite',
          translate: true,
          prepareData: (data) => (data ? 'measurements_blood_pressure_body_site_' + data?.toLowerCase() : null),
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_care_provider',
          fieldName: 'performer',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_diastolic_endpoint',
          fieldName: 'diastolicEndpoint',
          translate: true,
          prepareData: (data) => (data ? 'measurements_blood_pressure_diastolic_endpoint_' + data?.toLowerCase() : null),
        },
        {
          label: 'my_doctor_method',
          fieldName: 'method',
          translate: true,
          prepareData: (data) => (data ? 'measurements_blood_pressure_method_' + data?.toLowerCase() : null),
        },
        {
          label: 'my_doctor_average_blood_pressure',
          fieldName: 'averageBloodPressure',
          prepareData: (data) => [data?.value, data?.unit].filter(Boolean).join(''),
        },
      ],
    },
  ],
});
