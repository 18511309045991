import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const helpFromOthersBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.carePlan,
  isResourceFromField: true,
  fieldForResources: 'activities',
  fieldGroups: [
    {
      name: ({ resource }) => {
        return resource?.content?.typeOfHelp?.coding?.[0]?.display || 'my_doctor_help';
      },
      fields: [
        {
          label: 'my_doctor_frequency',
          fieldName: 'frequency',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_nature',
          fieldName: 'nature',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_performer',
          fieldName: 'performer',
          prepareData: (data) => data?.display,
        },
      ],
    },
  ],
});
