<ng-container *ngIf="questionnaireExists$ | async; else questionnaireNotFound">

  <div *ngIf="{
    title: title$ | async,
    doctor: doctor$ | async,
    dueDate: dueDate$ | async,
    description: description$ | async,
    tags: tags$ | async,
    copyright: copyright$ | async,
    ownerLink: ownerLink$ | async,
    ownerName: ownerName$ | async
  } as state">

  <div class="zdb-card bg-white mb-4 zdb-shadow">
    <div class="card-header">
      <h2 class="card-title">{{ state.title }}</h2>
    </div>

    <div class="card-body">
      <!--  <medrecord-tags-list [tags]="state.tags"></medrecord-tags-list>-->

      <div class="doctor text-uppercase text-bold">{{ state.doctor }}</div>

      <div *ngIf="state.dueDate" class="d-flex time-left-panel align-items-center">
        <b class="description">{{ 'common_finish_questionnaire_until' | translate }}</b>
        <span class="final-date">{{ getFormattedDate(state.dueDate) }}</span>
        <medrecord-time-left [dueDate]="state.dueDate"></medrecord-time-left>
      </div>

      <medrecord-collapsible-description *ngIf="!isPositiveHealth" [description]="state.description"></medrecord-collapsible-description>
      <div class="description" *ngIf="isPositiveHealth">{{ state.description }}</div>
    </div>
    <!-- Panel Header component -->
  </div>

    <medrecord-questionnaire [isReadonly]="isReadonly"></medrecord-questionnaire>

    <div [innerHTML]="state.copyright" class="copyright"></div>
    <a (click)="openOwnerPage($event, state.ownerLink)">
      {{ state.ownerName }}
    </a>
  </div>

</ng-container>

<ng-template #questionnaireNotFound>
  <div class="container d-flex align-items-center justify-space-between">
    <h4 class="not-found">{{ 'Questionnaire not found' | translate }}</h4>
  </div>
</ng-template>
