import { Component, Inject, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { IZDBUser, toZDBUser } from '@app/core/user/user.model';
import { UserService } from '@app/core/user/user.service';
import { QuestionnairesService } from '@app/medrecord/questionnaire-adapter/services/questionnaires.service';
import { map, shareReplay } from 'rxjs/operators';
import { HealthcareProviderResource } from '@app/medrecord/models/healthcare-provider-resource';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';

@Component({
  selector: 'zdb-questionnaire-archive',
  templateUrl: './questionnaire-archive.component.html',
  styleUrls: ['./questionnaire-archive.component.scss'],
})
export class QuestionnaireArchiveComponent implements OnInit {
  subscription: Subscription;
  // FIXME: Add QuestionnaireTask interface
  completedQuestionnaireTasks$: Observable<HealthcareProviderResource>;
  completedQuestionnaireTasks: any[] = [];
  pagedCompletedQuestionnaireTasks: any[] = [];
  _currentPage = 1;
  inProgressQuestionnaireTasks$: Observable<HealthcareProviderResource>;
  user$: Observable<IZDBUser>;
  @Input() userId: string;
  @Input() showInProgress = false;
  @Input() isPositiveHealth = false;

  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(pageNumber: number) {
    this._currentPage = pageNumber;
    this.showPage(this._currentPage);
  }

  constructor(
    private userService: UserService,
    private questionnaireService: QuestionnairesService,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {}

  ngOnInit() {
    this.user$ = this.userService
      .find(this.userId)
      .pipe(map((userJSON) => toZDBUser(userJSON)));
    this.completedQuestionnaireTasks$ = this.questionnaireService
      .loadCompletedQuestionnaireTasks(this.userId)
      .pipe(shareReplay())
      .pipe(
        map((tasks) => {
          let preparedTasks = (tasks as any)
            .filter(
              (task) =>
                (this.isPositiveHealth &&
                  this.environment.positiveHealthQuestionnaireIds.includes(
                    task.questionnaireId
                  )) ||
                (!this.isPositiveHealth &&
                  task.questionnaireId ===
                    this.environment.interraiQuestionnaireId)
            )
            .sort(
              (a, b) =>
                new Date(b.completed).getTime() -
                new Date(a.completed).getTime()
            );

          if (this.isPositiveHealth) {
            preparedTasks = preparedTasks.reduce((prev, curr, index) => {
              if (index % 6 === 0) {
                const newTask = {
                  ...curr,
                  taskIds: [curr.taskId],
                };

                return [...prev, newTask];
              }
              prev[Math.floor(index / 6)] = {
                ...prev[Math.floor(index / 6)],
                taskIds: [...prev[Math.floor(index / 6)].taskIds, curr.taskId],
              };

              return [...prev];
            }, []);
          }

          return preparedTasks;
        })
      );
    this.completedQuestionnaireTasks$.subscribe((result: any) => {
      this.completedQuestionnaireTasks = result;
      this.showPage(this.currentPage);
    });
    if (this.showInProgress) {
      this.inProgressQuestionnaireTasks$ = this.questionnaireService
        .loadInProgressQuestionnaireTasks(this.userId)
        .pipe(shareReplay())
        .pipe(
          map((tasks) =>
            (tasks as any)
              .filter(
                (task) =>
                  task.questionnaireId ===
                  this.environment.interraiQuestionnaireId
              )
              .sort(
                (a, b) =>
                  new Date(b.completed).getTime() -
                  new Date(a.completed).getTime()
              )
          )
        );
    }
  }

  showPage(pageNumber: number): void {
    const count = this.isPositiveHealth ? 3 : 2;
    this.pagedCompletedQuestionnaireTasks = [];
    for (let i = 0; i < count; i++) {
      if (
        (pageNumber - 1) * count + i <
        this.completedQuestionnaireTasks.length
      ) {
        this.pagedCompletedQuestionnaireTasks[i] =
          this.completedQuestionnaireTasks[(pageNumber - 1) * count + i];
      }
    }
  }

  getTaskIds(index) {
    const count = this.isPositiveHealth ? 3 : 2;
    const firstOnly =
      this.completedQuestionnaireTasks.length ===
      (this.currentPage - 1) * count + index + 1;

    return {
      firstTaskIds:
        this.completedQuestionnaireTasks[
          (this.currentPage - 1) * count + index
        ].taskIds?.join(','),
      secondTaskIds: firstOnly
        ? ''
        : this.completedQuestionnaireTasks[
            (this.currentPage - 1) * count + index + 1
          ].taskIds?.join(','),
      count: firstOnly
        ? (this.currentPage - 1) * count + index + 1
        : (this.currentPage - 1) * count + index + 2,
      firstIndex: (this.currentPage - 1) * count + index,
      secondIndex: firstOnly ? '' : (this.currentPage - 1) * count + index + 1,
    };
  }
}
