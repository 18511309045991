import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-collapsible-description',
  templateUrl: './collapsible-description.component.html',
  styleUrls: ['./collapsible-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsibleDescriptionComponent {

  @Input() collapsedDescriptionLength = 249;
  @Input() description = '';

  isCollapsed = true;

  get visibleDescription(): string {
    return this.isCollapsed
      ? this.cutDescription
      : this.description;
  }

  get cutDescription(): string {
    return this.canDescriptionBeCollapsed
      ? `${ this.description.slice(0, this.collapsedDescriptionLength).trim() }...`
      : this.description;
  }

  get canDescriptionBeCollapsed(): boolean {
    return this.description && this.description.length > this.collapsedDescriptionLength;
  }

  showMore(e: any): void {
    e.preventDefault();

    this.isCollapsed = false;
  }

  showLess(e: any): void {
    e.preventDefault();

    this.isCollapsed = true;
  }

}
