import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IUser } from 'src/app/core/user/user.model';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { selectUserId } from '@medrecord/managers-auth';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';

interface IState {
  searchTerm: string;
  page: number;
  pageSize: number;
}

function matches(user: IUser, terms: string) {
  return terms.split(' ').every(
    term => user.name.firstName.toLowerCase().includes(term.toLowerCase())
    || user.name.lastName.toLowerCase().includes(term.toLowerCase())
  );
}

@Injectable()
export class RelatedUsersService {
  private fetchRelatedUsers$ = new Subject<void>();
  private filterRelatedUsers$ = new Subject<void>();

  private _initial_state: IState = {
    searchTerm: '',
    page: 1,
    pageSize: 10
  };
  // FIXME: use clone of the intial state
  private _state: IState = this._initial_state;
  private _allUsers$ = new BehaviorSubject<IUser[]>([]);
  private _curPageUsers$ = new BehaviorSubject<IUser[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private store: Store,

    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {
    // FIXME: should we add a loading indicator?
    this.fetchRelatedUsers$.pipe(
      tap(() => this._loading$.next(true)),
      withLatestFrom(this.store.select(selectUserId)),
      switchMap(([, userId]) => this._getRelatedUsers$(userId)),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      // reset pagination when performing a new search
      Object.assign(this._state, this._initial_state);
      this._allUsers$.next(result);
      this._total$.next(result.length);
      this.filterRelatedUsers$.next();
    });

    this.fetch();

    this.filterRelatedUsers$.pipe(
      tap(() => this._loading$.next(true)),
      switchMap(() => this._allUsers$)
    ).subscribe(allUsers => {
      const { searchTerm, page, pageSize } = this._state;
      const searchTermUsers = allUsers.filter(user => matches(user, searchTerm));
      const curPageUsers = searchTermUsers.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
      this._curPageUsers$.next(curPageUsers);
      this._total$.next(searchTermUsers.length);
      this._loading$.next(false);
    });
  }

  private _setState = (patch: Partial<IState>) => {
    Object.assign(this._state, patch);
    // When the state changes, filter the results
    this.filterRelatedUsers$.next();
  }

  // FIXME: rethink wether the pagination should be part of this service
  get page() { return this._state.page; }
  set page(page: number) { this._setState({page}); }

  get pageSize() { return this._state.pageSize; }
  set pageSize(pageSize: number) { this._setState({pageSize}); }

  get searchTerm() { return this._state.searchTerm; }
  set searchTerm(searchTerm: string) { this._setState({searchTerm}); }

  get users$() { return this._curPageUsers$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }

  private _getRelatedUsers$(userId: string): Observable<IUser[]> {
    // TODO: add INetworkElem
    return this.http.get<any[]>(
      `${this.environment.backend}/person/${userId}/network/other`
    ).pipe(map(res => res.filter(x => x.resourceType === 'Patient')));
  }

  public fetch() {
    this.fetchRelatedUsers$.next();
  }
}
