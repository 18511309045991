import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectAnswers,
  selectIsQuestionnaireFinished,
  selectQuestionnaireCommentsEnabled,
  isLastQuestionSelected,
  selectQuestionnaireQuestions,
  selectQuestionnaireSelectedQuestion,
  selectQuestionnaireSelectedQuestionAnswer,
  isFirstQuestionSelected,
} from '../../state/questionnaires.selectors';
import { ActivatedRoute } from '@angular/router';
import {
  finishQuestionnaire,
  selectNextQuestion,
  selectPrevQuestion,
} from '../../state/questionnaires.actions';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { positiveHealthQuestionnaireAlias } from '@app/medrecord/questionnaire-adapter/constants/positive-health-questionnaire-alias';

@Component({
  selector: 'medrecord-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireComponent {
  public isPositiveHealth = false;
  @Input() isReadonly: boolean;
  questions$;
  selectedQuestion$;
  selectedQuestionAnswer$;
  isQuestionnaireFinished$;
  commentsEnabled$;
  answers$;
  isFirstQuestionSelected$;
  isLastQuestionSelected$;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {
    this.isPositiveHealth = this.route.snapshot.data.isPositiveHealth;

    this.questions$ = this.store.select(
      selectQuestionnaireQuestions,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );

    this.selectedQuestion$ = this.store.select(
      selectQuestionnaireSelectedQuestion,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );

    this.selectedQuestionAnswer$ = this.store.select(
      selectQuestionnaireSelectedQuestionAnswer,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );

    this.isQuestionnaireFinished$ = this.store.select(
      selectIsQuestionnaireFinished,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );

    this.commentsEnabled$ = this.store.select(
      selectQuestionnaireCommentsEnabled,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );

    this.answers$ = this.store.select(
      selectAnswers,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );

    this.isFirstQuestionSelected$ = this.store.select(
      isFirstQuestionSelected,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );

    this.isLastQuestionSelected$ = this.store.select(
      isLastQuestionSelected,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );
  }

  selectPrevQuestion(): void {
    this.store.dispatch(
      selectPrevQuestion({
        questionnaireId: !this.isPositiveHealth
          ? this.environment.interraiQuestionnaireId
          : positiveHealthQuestionnaireAlias,
      })
    );
    window.setTimeout(this.scrollToTop, 25);
  }

  selectNextQuestion(): void {
    this.store.dispatch(
      selectNextQuestion({
        questionnaireId: !this.isPositiveHealth
          ? this.environment.interraiQuestionnaireId
          : positiveHealthQuestionnaireAlias,
      })
    );
    window.setTimeout(this.scrollToTop, 25);
  }

  private scrollToTop() {
    window.scrollTo({
      top: 400,
      left: 0,
      behavior: 'smooth',
    });
  }

  finish(): void {
    const { data, params } = this.route.snapshot;

    this.store.dispatch(
      finishQuestionnaire({
        questionnaireId: !this.isPositiveHealth
          ? this.environment.interraiQuestionnaireId
          : positiveHealthQuestionnaireAlias,
        routeSnapshot: { data, params },
      })
    );
  }
}
