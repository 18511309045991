import { Component } from '@angular/core';
import { HelpItem } from './help-item/help.model';

@Component({
  selector: 'zdb-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
  helpItems: HelpItem[] = [
    {
      question: 'Wie heeft deze site gebouwd?',
      answer: `Deze website is een initiatief van de stichting Zo-Dichtbij. De
      implementatie is verricht door West IT Solutions.`
    },
    {
      question: 'Wie heeft toegang tot mijn gegevens?',
      answer: `Alleen de mantelzorgers die u aan jouw team toevoegt hebben
      inzage in uw gegevens. Daarnaast kunt u specifieke zorgverleners toegang
      geven tot uw data.`
    }
  ];

}
