import { Component, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MyDoctorRouteNames, MY_DOCTOR_ROUTE_NAMES } from '../../constants/routes';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'doctor-gui-medsafe-my-doctor-wrapper',
  templateUrl: './my-doctor-wrapper.component.html',
  styleUrls: ['./my-doctor-wrapper.component.scss'],
})
export class MyDoctorWrapperComponent implements AfterViewInit {
  @ViewChild('myDoctorTabs') tabInstance: TabComponent;

  constructor(
    private router: Router,
    @Inject(MY_DOCTOR_ROUTE_NAMES) public myDoctorRouteNames: MyDoctorRouteNames) {}

  ngAfterViewInit(): void {
    const route = this.router.url.split('/');
    route.length = 5;
    if (this.tabInstance && route[route.length - 1] === this.myDoctorRouteNames.MyDoctor.Favourites) {
      this.tabInstance.selectedItem = 1;
      this.tabInstance.dataBind();
    }
  }

  tabSelected({ selectedItem }): void {
    const route = this.router.url.split('/');
    route.length = 5;
    route[route.length - 1] = selectedItem.dataset.id;

    this.router.navigateByUrl(route.join('/'));
  }
}
