import {
  Algorithm,
  CategoryKey,
  CAP,
  Scale,
} from '@app/selfcheck/questionnaire-outomes/questionnnaire-outomes.enum';
import {
  CategoryGroup,
  Codes,
} from '@app/selfcheck/questionnaire-outomes/questionnaire-outcomes.type';

export const questionnaireCategories: CategoryGroup = {
  [CategoryKey.fysical]: {
    entries: [
      CAP.cADD,
      CAP.cCARDIO,
      CAP.cDEHYD,
      CAP.cFALLS,
      Algorithm.xFALLS,
      CAP.cPAIN,
      CAP.cURIN,
      Scale.sPAIN,
    ],
  },
  [CategoryKey.cognitive]: {
    entries: [Scale.sCPS2, Scale.sCOMM, CAP.cCOGNIT, CAP.cCOMMUN],
  },
  [CategoryKey.mental_social]: {
    entries: [
      CAP.cMOOD,
      CAP.cSOCFUNC,
      Scale.sSRMOOD
    ],
  },
  [CategoryKey.self_determination]: {
    entries: [
      CAP.cADL,
      Scale.sADLH,
      Scale.sIADLCH,
      CAP.cPACTIV,
      CAP.cIADL,
      Algorithm.aSRI,
    ],
  },
  [CategoryKey.stability]: {
    entries: [
      Scale.sCHESS,
      Scale.sDIVERT,
    ],
  },
};

export const unavailableCodes: Codes[] = [
  Algorithm.aAXURG,
  Scale.sCPS,
  Scale.siADLSF,
  Scale.sVPR,
];
