import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { trackByTag } from '../../utils/track-by.util';

@Component({
  selector: 'medrecord-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsListComponent {
  @Input() tags: string[];

  trackByFn = trackByTag;
}
