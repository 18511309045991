import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const medicationAgreementBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.medicationRequest,
  fieldGroups: [
    {
      name: ({ resource }) => resource?.content?.agreedMedicine?.display || 'my_doctor_medication',
      fields: [
        {
          label: 'my_doctor_identification',
          fieldName: 'identifier',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_appointment_date',
          fieldName: 'medicationAppointmentDateTime',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LLL') : ''),
        },
        {
          label: 'my_doctor_reason_medication_agreement',
          fieldName: 'reasonMedicationAgreement',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_start_date',
          fieldName: 'periodOfUse',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_end_date',
          fieldName: 'periodOfUse',
          prepareData: (data) => (data?.end ? MedrecordMoment(data?.end).format('LL') : ''),
        },
        {
          label: 'my_doctor_duration',
          fieldName: 'duration',
          prepareData: (data) => [data?.value, data?.unit].filter(Boolean).join(' '),
        },
        {
          label: 'my_doctor_care_provider',
          fieldName: 'provider',
          prepareData: (data, resources) => {
            const practitionerRole = resources.find((r) => {
              const [referenceType, referenceId] = data?.reference?.split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return [
              practitionerRole?.content?.practitionerReference?.display,
              practitionerRole?.content?.organizationReference?.display,
            ]
              .filter(Boolean)
              .join(', ');
          },
        },
        {
          label: 'my_doctor_dosage',
          fieldName: 'dosage',
          prepareData: (data) => data?.text,
        },
        
        {
          label: 'my_doctor_administration_route',
          fieldName: 'dosage',
          prepareData: (data) => data?.[0]?.route?.coding?.[0]?.display,
        },
      ]
    },
  ],
});
