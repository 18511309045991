import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import * as UserDataActions from '@app/core/user/state/user-data.actions';
import { formDataToUser, Gender, IZDBUser, ZDB_DATE_FORMAT } from '@app/core/user/user.model';
import { UserService } from '@app/core/user/user.service';
import { MedrecordFormComponentBase, ValidationService } from '@medrecord/components/forms';
import { SelectItem } from '@medrecord/core';
import { zdbRouteNames } from '@zdb/routes';
import { selectRoles } from '@medrecord/managers-auth';
import { Role } from '@medrecord/managers-users';
import { addErrorToast } from '@medrecord/tools-toast';

@Component({
  selector: 'zdb-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
  export class UserFormComponent extends MedrecordFormComponentBase implements OnChanges, OnDestroy {
    @Input() userId: string;
    @Input() user: IZDBUser;
    subscription: Subscription;
    maxDate = new Date();
    dateFormat = ZDB_DATE_FORMAT;

    genders: SelectItem[] = [
      { label: this.translateService.instant('genders.male'), value: Gender.MALE },
      { label: this.translateService.instant('genders.female'), value: Gender.FEMALE },
      { label: this.translateService.instant('genders.other'), value: Gender.OTHER },
      { label: this.translateService.instant('genders.unknown'), value: Gender.UNKNOWN }
    ];

    constructor(private fb: FormBuilder,
                private validationService: ValidationService,
                private router: Router,
                private store: Store,
                private translateService: TranslateService,
                private userService: UserService
    ) {
      super();

      const requiredErrorMsg = this.translateService.instant('validation.required');
      const emailErrorMsg = this.translateService.instant('validation.email');

      this.form = this.fb.group({
        name: this.fb.group({
          firstName: [null, this.validationService.required(requiredErrorMsg)],
          lastName: [null, this.validationService.required(requiredErrorMsg)]
        }),
        email: [
          null,
          [
            this.validationService.required(requiredErrorMsg),
            this.validationService.email(emailErrorMsg)
          ],
        ],
        phone: [null, this.validationService.required(requiredErrorMsg)],
        gender: [null, this.validationService.required(requiredErrorMsg)],
        birthDate: [null, this.validationService.required(requiredErrorMsg)],
        streetName: [null, this.validationService.required(requiredErrorMsg)],
        houseNumber: [null, this.validationService.required(requiredErrorMsg)],
        city: [null, this.validationService.required(requiredErrorMsg)],
        postalCode: [null, this.validationService.required(requiredErrorMsg)],
        avatar: [null]
      });
      this.subscription = this.store.select(selectRoles).subscribe((roles) => {
        if (roles && roles.includes(Role.Doctor)) {
          // will only be displayed for doctors
          this.form.addControl('availableForSearch', new FormControl() );
        }
      });
    }

    ngOnChanges(): void {
      if (this.user) {
        this.form.patchValue({
          name: {
            firstName: this.user.name.firstName, lastName: this.user.name.lastName
          },
          email: this.user.email,
          phone: this.user.phone,
          gender: this.user.gender,
          birthDate: this.user.birthDate,
          streetName: this.user.streetName,
          houseNumber: this.user.houseNumber,
          city: this.user.city,
          postalCode: this.user.postalCode,
          avatar: this.user.avatarSrc,
        });
        if (this.form.get('availableForSearch')) {
          this.form.patchValue({availableForSearch: this.user.availableForSearch});
        }
      }
    }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }

    onFormSubmit(formData: any): void {
      this.userService.update(this.userId, formDataToUser(formData)).subscribe(
        () => {
          this.store.dispatch(UserDataActions.loadUserData());
          this.router.navigate([zdbRouteNames.userProfile]);
        });
    }

    handleAvatarMaxSizeError(): void {
      this.store.dispatch(addErrorToast({
        title: this.translateService.instant('errors.file_max_size_exceeded'),
        content: this.translateService.instant('common.form.avatar_size_limit')
      }));
    }

    handleAvatarWrongTypeError(): void {
      this.store.dispatch(addErrorToast({
        title: this.translateService.instant('errors.file_type_not_allowed'),
        content: this.translateService.instant('errors.avatar_allowed_types')
      }));
    }
  }
