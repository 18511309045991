<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-body px-4" style="white-space: pre-line;">
  {{ message }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="accept()">{{ btnOkText }}</button>
</div>
