import { Component, Input } from '@angular/core';
import { Question } from '../../interfaces/question.interface';
import { QuestionType } from '../../enums/question-type.enum';
import { trackByQuestion } from '../../utils/track-by.util';

@Component({
  selector: 'medrecord-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
})
export class QuestionFormComponent {
  @Input() isPositiveHealth = false;
  @Input() question: Question;
  @Input() answers: any;
  @Input() commentsEnabled: boolean;
  @Input() isReadonly: boolean;

  QuestionType = QuestionType;

  trackByFn = trackByQuestion;

  showSeparator(index: number) {
    return index === 0 ? this.question.questionText : true;
  }
}
