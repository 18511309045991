import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@medrecord/tools-storage';
import {Language, StorageKeys} from '@medrecord/core';
import { environment } from '../../../environments/environment';

export const provideDefaultLang = () => (
  {
    provide: APP_INITIALIZER,
    useFactory: (
      service: TranslateService,
      storage: StorageService<StorageKeys>
    ) => async () => {
      let defaultLanguage: Language = Language.NL;
      if (environment.defaultLanguage) {
          Object.keys(Language).filter(k => Language[k as Language] === environment.defaultLanguage)
                .map(k => defaultLanguage = Language[k as Language]);
      }
      // stored language is being used for login and signup effects
      storage.setItem(StorageKeys.Language, defaultLanguage);
      service.setDefaultLang(defaultLanguage);
      await service.use(defaultLanguage).toPromise();
    },
    multi: true,
    deps: [TranslateService, StorageService]
  }
);
