import { Pipe, PipeTransform } from '@angular/core';
import { Question } from '../interfaces/question.interface';

@Pipe({
  name: 'enabledQuestions',
  pure: false
})
export class EnabledQuestionsPipe implements PipeTransform {
  transform(questions: Question[]): Question[] {
    if (!questions) {
      return questions;
    }

    return questions.filter(({ isEnabled }) => isEnabled);
  }
}
