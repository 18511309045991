<zdb-main-layout>
  <div class="row">
    <div class="col">
      <div class="card-deck">
        <zdb-card>
          <div class="card-body mt-5">
            <div class="medsafe-logo-container">
              <img src="https://medrecord-logos.s3.eu-north-1.amazonaws.com/top-menu-logo.svg" alt="medsafe_logo" />
            </div>
            <p class="card-text mt-3">
              Samen met de gecertificeerde Persoonlijk Gezondheids Omgeving (PGO) MedSafe bieden wij een
              verbinding met jouw huisarts en/of ziekenhuis om een beter inzicht in alle gegevens te krijgen.
              Om deze gegevens op te halen moet je inloggen met DigID.
              Zorg dat je je medische gegevens alleen deelt met de mensen die je vertrouwt.
            </p>
          </div>
          <div class="card-footer text-center mt-5 mb-5">
            <a
              class="btn btn-lg btn-primary-button"
              routerLink="/mijn-doktor"
            >
              Doorgaan
            </a>
          </div>
        </zdb-card>
      </div>
    </div>
  </div>
</zdb-main-layout>
