import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const vaccinationBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.immunization,
  fieldGroups: [
    {
      name: ({ resource }) =>
        (resource?.content?.vaccinationDate
          ? MedrecordMoment(resource?.content?.vaccinationDate).format('LL') + ' - '
          : '') + resource?.content?.productCode?.coding?.[0].display,
      fields: [
        {
          label: 'my_doctor_dose',
          fieldName: 'dose',
          prepareData: (data) => data?.value,
        },
        {
          label: 'my_doctor_desired_revaccination_date',
          fieldName: 'desiredRevaccinationDate',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LL') : ''),
        },
        {
          label: 'my_doctor_actor',
          fieldName: 'actor',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
      ],
    },
  ],
});
