import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyDoctorManagerStateModule } from './state-management/my-doctor-manager-state.module';
import { MyDoctorManagerService } from './services/my-doctor-manager.service';
import { provideMyDoctorManagerConstants } from './constants';
import {
  myDoctorRouteNames,
  provideMyDoctorRouteNames,
} from './constants/routes';
import { AttachmentsService } from './services/attachments.service';

@NgModule({
  imports: [CommonModule, MyDoctorManagerStateModule],
  providers: [
    MyDoctorManagerService,
    AttachmentsService,
    provideMyDoctorManagerConstants,
    provideMyDoctorRouteNames(myDoctorRouteNames.MyDoctor.Entry),
  ],
})
export class MyDoctorManagerModule {}
