import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as questionnaireActions from '../state/questionnaires.actions';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { selectFinishModalDataForQuestionnaire, selectScope } from './questionnaires.selectors';
import { QuestionnaireFinishedModalComponent } from '../components/questionnaire-finished-modal/questionnaire-finished-modal.component';
import { of } from 'rxjs';
import { SaveModalType } from '../enums/save-modal-type.enum';

@Injectable()
export class QuestionnaireEffects {

  finishQuestionnaire$ = createEffect(() =>
      this.actions$.pipe(
        ofType(questionnaireActions.finishQuestionnaire),
        mergeMap(({ questionnaireId, routeSnapshot }) => of(questionnaireId)
          .pipe(
            withLatestFrom(
              this.store.pipe(select(selectFinishModalDataForQuestionnaire, questionnaireId))
            ),
            map(([, dataForModal]) => ({ questionnaireId, routeSnapshot, ...dataForModal })),
            map(modalData => questionnaireActions.showSaveModal({ ...modalData }))
          )
        )
      ),
  );

  showSaveModal$ = createEffect(() =>
      this.actions$.pipe(
        ofType(questionnaireActions.showSaveModal),
        filter(({ saveModalType }) => saveModalType === SaveModalType.default),
        tap(data => {
          const modalInstance = this.modalService.open(QuestionnaireFinishedModalComponent)
            .componentInstance as QuestionnaireFinishedModalComponent;

          modalInstance.data = data;
        })
      ),
    { dispatch: false }
  );

  saveQuestionAnswer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(questionnaireActions.saveQuestionAnswer),
      mergeMap((data) => of(data)
        .pipe(
          withLatestFrom(this.store.pipe(select(selectScope, data.questionnaireId))),
          map(([, scope]) => questionnaireActions.autoSaveQuestionnaire({
            questionnaireId: data.questionnaireId,
            questionId: data.questionId,
            scope,
          })) 
        )
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private modalService: NgbModal
  ) {
  }

}
