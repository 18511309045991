import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const episodeBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.episodeOfCare,
  fieldGroups: [
    {
      name: ({ resource }) =>
        (resource.content.period?.start ? MedrecordMoment(resource.content.period?.start).format('LL') + ' - ' : '') +
        resource.content.title,
      fields: [
        {
          label: 'my_doctor_attention_flag',
          translate: true,
          cssClass: 'red',
          prepareData: (data, resources) => {
            const flag = resources.find((r) => {
              if (r.resourceType === ResourceType.flag) {
                const [referenceType, referenceId] = r?.content?.problem?.reference?.split('/');
                return referenceType === ResourceType.episodeOfCare && referenceId === data.resourceId;
              }
              return false;
            });

            return flag ? 'common_yes' : null;
          },
        },
        {
          label: 'my_doctor_start_date',
          fieldName: 'period',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_end_date',
          fieldName: 'period',
          prepareData: (data) => (data?.end ? MedrecordMoment(data?.end).format('LL') : ''),
        },
        {
          label: 'my_doctor_first_contact_date',
          fieldName: 'dateFirstEncounter',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LL') : ''),
        },
        {
          label: 'my_doctor_last_contact_date',
          fieldName: 'dateLastEncounter',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LL') : ''),
        },
        {
          label: 'ICPC',
          fieldName: 'diagnosisConditions',
          prepareData: (data, resources) => {
            if (!data) {
              return null;
            }

            const [referenceType, referenceId] = [...(data ? data : [])]?.pop()?.reference?.split('/');
            return resources.find(
              (r) =>
                r.resourceType === ResourceType.condition &&
                referenceType === ResourceType.condition &&
                referenceId === r.resourceId
            )?.content?.name?.coding?.[0]?.code;
          },
        },
        {
          label: 'my_doctor_icpc_description',
          fieldName: 'diagnosisConditions',
          prepareData: (data, resources) => {
            if (!data) {
              return null;
            }

            const [referenceType, referenceId] = [...(data ? data : [])]?.pop()?.reference?.split('/');
            return resources.find(
              (r) =>
                r.resourceType === ResourceType.condition &&
                referenceType === ResourceType.condition &&
                referenceId === r.resourceId
            )?.content?.name?.coding?.[0]?.display;
          },
        },
        {
          label: 'my_doctor_icpc_description',
          prepareData: (data, resources) => {
            const encounters = resources.filter((r) => {
              if (r.resourceType === ResourceType.encounter) {
                const episodesOfCare = r?.content?.episodesOfCare;

                return (
                  episodesOfCare?.find?.((e) => e.reference === `${ResourceType.episodeOfCare}/${data.resourceId}`) ||
                  null
                );
              }
              return false;
            });

            if (encounters.length) {
              const arr = encounters
                .map((encounter) => {
                  const encounterName = encounter.content?.identifier?.value;

                  return encounterName?.length
                    ? `${encounterName.charAt(0).toUpperCase()}${encounterName.slice(1)}`
                    : null;
                })
                .filter(Boolean);

              arr.sort((itemA, itemB) => {
                const [numA] = itemA.match(/\d+/);
                const [numB] = itemB.match(/\d+/);

                return +numA - +numB;
              });

              return arr.join(', ');
            }
          },
        },
      ],
    },
  ],
});
