<div *ngFor="let item of data; let i = index">
  <div
    [class]="fieldGroup.fieldAsSubgroup ? 'pb-3' : 'py-3'"
    class="title pr-4 d-flex align-items-center"
    (click)="expand(i)"
  >
    <div class="chevron">
      <medrecord-svg-chevron-right
        [type]="'black'"
        *ngIf="!isExpanded(i)"
        icon
        width="7"
      ></medrecord-svg-chevron-right>
      <medrecord-svg-chevron-down
        [type]="'black'"
        *ngIf="isExpanded(i)"
        icon
        width="12"
      ></medrecord-svg-chevron-down>
    </div>
    <span class="text-boulder text-weight-600 ml-2"
      >{{ prepareFieldGroupName(item, i) | translate }} - {{ i + 1 }}</span
    >
  </div>

  <ng-container *ngIf="isExpanded(i)">
    <div class="row ml-2" [class.mr-2]="fieldGroup.fieldAsSubgroup">
      <ng-container *ngFor="let field of fieldGroup.fields">
        <div class="col-3 my-0 py-0" *ngIf="!isFieldEmpty(item, field)">
          <p class="text-boulder text-weight-600 mb-2">
            {{ field.label | translate }}
          </p>
        </div>
        <ng-container *ngIf="!field.showAsDownload; else showAsDownload">
          <ng-container
            *ngIf="!field.showAsPdfADownload; else showAsPdfADownload"
          >
            <div class="col-9 my-0 py-0" *ngIf="!isFieldEmpty(item, field)">
              <p
                class="mb-2"
                [class]="field.cssClass"
                [innerHTML]="
                  field.translate
                    ? (castPreparedDataToString(
                        prepareDataForField(item, field)
                      ) | translate)
                    : prepareDataForField(item, field)
                "
              ></p>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #showAsDownload>
          <div class="col-9 my-0 py-0" *ngIf="!isFieldEmpty(item, field)">
            <a
              type="button"
              class="btn-sm btn-outline-black text-weight-600"
              [href]="prepareDataForField(item, field)"
              target="_blank"
            >
              {{ "my_doctor_download_pdf" | translate }}
            </a>
          </div>
        </ng-template>
        <ng-template #showAsPdfADownload>
          <div class="col-9 my-0 py-0" *ngIf="!isFieldEmpty(item, field)">
            <a
              type="button"
              class="btn-sm btn-outline-black text-weight-600"
              (click)="onPdfaButtonClick(prepareDataForField(item, field))"
            >
              {{ "Download PDF/A" | translate }}
            </a>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</div>
