import { ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';
import { MedrecordInputValue } from '../..';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ng-control-base',
  template: ``,
})
export class MedrecordNgControlComponentBase implements ControlValueAccessor {
  @Input() externalErrors: ValidationErrors = {};
  @Input() disabled = false;
  
  constructor(public control: NgControl) {
    if (control) {
      control.valueAccessor = this;
    }
  }
  
  protected _value: any = null;
  
  get value(): any {
    return this._value;
  }
  
  @Input()
  set value(value: any) {
    this._value = value;
    this.onChange(this._value);
  }
  
  get hasErrors(): boolean {
    return this.control?.touched && !!this.errors.length;
  }
  
  get errors(): any[] {
    return Object.values({
      ...this.control?.errors,
      ...this.externalErrors,
    });
  }
  
  // Control Value Accessor methods
  
  writeValue(value: MedrecordInputValue): void {
    this.value = value;
  }
  
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  
  onChange: any = (): any => null;
  
  onTouched: any = (): any => null;
}
