import { Action, createReducer, on } from '@ngrx/store';
import {
  loadCareprovidersSuccessAction,
  loadCareprovidersFailureAction,
  loadCareproviderSuccessAction,
  clearActiveCareproviderAction,
  loadResourcesSuccessAction,
  loadResourcesFailureAction,
  fetchCareproviderDataSuccessAction,
  loadLogsSuccessAction,
  clearResourcesAction,
} from './my-doctor-manager.actions';
import { Careprovider } from '../models/interfaces/careprovider.interface';
import { CareproviderResource } from '../models/interfaces/careprovider-resource.interface';

export const myDoctorManagerFeatureKey = 'my-doctor-manager';

export interface MyDoctorManagerState {
  error: any;
  careproviders: Careprovider[];
  count: number;
  activeCareprovider: Careprovider;
  resources: CareproviderResource[];
  fetchDataResponse: CareproviderResource[];
  logs: any[];
}

export const initialState: MyDoctorManagerState = {
  error: null,
  careproviders: [],
  count: 0,
  activeCareprovider: null,
  resources: [],
  fetchDataResponse: [],
  logs: [],
};

export const myDoctorManagerReducer = createReducer(
  initialState,
  on(loadCareprovidersSuccessAction, (state, { careproviders, count }) => ({ ...state, careproviders, count })),
  on(loadCareproviderSuccessAction, (state, { careprovider }) => ({ ...state, activeCareprovider: careprovider })),
  on(loadResourcesSuccessAction, (state, { resources }) => ({ ...state, resources })),
  on(fetchCareproviderDataSuccessAction, (state, { data }) => ({ ...state, fetchDataResponse: data.resources })),
  on(loadLogsSuccessAction, (state, { logs }) => ({ ...state, logs })),
  on(clearActiveCareproviderAction, (state) => ({ ...state, activeCareprovider: null })),
  on(clearResourcesAction, (state) => ({ ...state, resources: [] })),
  on(loadCareprovidersFailureAction, loadResourcesFailureAction, (state, { error }) => ({ ...state, error }))
);

export const reducer = (state: MyDoctorManagerState, action: Action): MyDoctorManagerState => {
  return myDoctorManagerReducer(state, action);
};
