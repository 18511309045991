import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {
  loadPositiveHealthQuestionnaire,
  loadPositiveHealthQuestionnaires,
} from '@app/medrecord/questionnaire-adapter/state-management/actions/questionnaire.actions';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { QuestionnairePageType } from '@app/questionnaire/questionnaire.enum';

@Injectable({ providedIn: 'root' })
export class PositiveHealthQuestionnaireResolver implements Resolve<void> {
  constructor(private store: Store) {}

  resolve(
    snapshot: ActivatedRouteSnapshot
  ): Observable<any> | Promise<any> | any {
    if (snapshot?.data?.type === QuestionnairePageType.pgScores) {
      return this.store.dispatch(
        loadPositiveHealthQuestionnaires({
          firstTaskIds: snapshot.queryParams.firstTaskIds?.split(','),
          secondTaskIds: snapshot.queryParams.secondTaskIds?.split(',') || [],
          userId: snapshot.paramMap.get('userId'),
        })
      );
    }
    return this.store.dispatch(loadPositiveHealthQuestionnaire());
  }
}
