import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const advanceDirectiveBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.consent,
  fieldGroups: [
    {
      name: ({ resource }) => resource.content?.type?.coding?.[0]?.display || 'my_doctor_directive',
      fields: [
        {
          label: 'my_doctor_declaration_date',
          fieldName: 'date',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LL') : ''),
        },
        {
          label: 'my_doctor_disease',
          fieldName: 'disorder',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_document',
          fieldName: 'document',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
        {
          name: 'my_doctor_representative',
          fieldAsSubgroup: true,
          fields: [
            {
              name: 'my_doctor_name',
              fieldAsSubgroup: true,
              fields: [
                {
                  label: 'my_doctor_identification',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.identifiers?.[0]?.value;
                  },
                },
                {
                  label: 'my_doctor_first_name',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.name?.firstName;
                  },
                },
                {
                  label: 'my_doctor_initials',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.name?.initials;
                  },
                },
                {
                  label: 'my_doctor_given_name',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.name?.givenName;
                  },
                },
                {
                  label: 'my_doctor_partner_last_name_prefix',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.name?.partnerLastNamePrefix;
                  },
                },
                {
                  label: 'my_doctor_partner_last_name',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.name?.partnerLastName;
                  },
                },
                {
                  label: 'my_doctor_last_name_prefix',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.name?.lastNamePrefix;
                  },
                },
                {
                  label: 'my_doctor_last_name',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.name?.lastName || relatedPerson?.content?.name?.family || null;
                  },
                },
                {
                  label: 'my_doctor_name_use',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.name?.assemblyOrder?.coding?.[0]?.display;
                  },
                },
              ],
            },
            {
              name: 'my_doctor_address',
              fieldAsSubgroup: true,
              fields: [
                {
                  label: 'my_doctor_street',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.addressList?.[0]?.streetName;
                  },
                },
                {
                  label: 'my_doctor_house_number',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.addressList?.[0]?.houseNumber;
                  },
                },
                {
                  label: 'my_doctor_postal_code',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.addressList?.[0]?.postalCode;
                  },
                },
                {
                  label: 'my_doctor_city',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.addressList?.[0]?.city;
                  },
                },
                {
                  label: 'my_doctor_country',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.addressList?.[0]?.landGbaCode?.coding?.[0]?.display;
                  },
                },
                {
                  label: 'my_doctor_address_type',
                  fieldName: 'representative',
                  translate: true,
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.addressList?.[0]?.use;
                  },
                },
              ],
            },
            {
              name: 'my_doctor_contact_information',
              fieldAsSubgroup: true,
              fields: [
                {
                  label: 'my_doctor_phone_number',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    const phone = relatedPerson?.content?.contactList?.find((t) => t.system === 'PHONE');
                    return phone?.value;
                  },
                },
                {
                  label: 'my_doctor_phone_type',
                  translate: true,
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    const phone = relatedPerson?.content?.contactList?.find((t) => t.system === 'PHONE');
                    return phone?.use ? 'common_contact_use_' + phone?.use?.toLowerCase() : '';
                  },
                },
                {
                  label: 'my_doctor_email',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    const email = relatedPerson?.content?.contactList?.find((t) => t.system === 'EMAIL');
                    return email?.value;
                  },
                },
                {
                  label: 'my_doctor_email_type',
                  translate: true,
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    const email = relatedPerson?.content?.contactList?.find((t) => t.system === 'EMAIL');
                    return email?.use ? 'common_contact_use_' + email?.use?.toLowerCase() : '';
                  },
                },
                {
                  label: 'my_doctor_date_of_birth',
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.birthDate
                      ? MedrecordMoment(relatedPerson?.content?.birthDate).format('LL')
                      : '';
                  },
                },
                {
                  label: 'my_doctor_gender',
                  translate: true,
                  fieldName: 'representative',
                  prepareData: (data, resources) => {
                    const relatedPerson = resources.find((r) => {
                      const [referenceType, referenceId] = data?.reference?.split('/');
                      return r.resourceType === referenceType && r.resourceId === referenceId;
                    });

                    return relatedPerson?.content?.gender
                      ? 'common_gender_' + relatedPerson?.content?.gender?.toLowerCase()
                      : '';
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});
