<form *ngIf="form"
      [formGroup]="form">

  <app-numeric-input
    *ngIf="!isPositiveHealth"
    [integerInputWidth]="integerInputWidth"
    [label]="'common_answer' | translate"
    [maxValue]="question.options?.maxValue"
    [minValue]="question.options?.minValue"
    [showFractions]="false"
    [unitsOfMeasure]="question.unitOfMeasure"
    [formControl]="form.controls['answer']"
  ></app-numeric-input>

  <app-slider-input
    *ngIf="isPositiveHealth"
    [label]="'common_answer' | translate"
    [maxValue]="question.options?.maxValue"
    [minValue]="question.options?.minValue"
    minLabel="Klopt niet"
    maxLabel="Klopt"
    [step]="1"
    [unitsOfMeasure]="question.unitOfMeasure"
    [formControl]="form.controls['answer']"
  ></app-slider-input>

</form>
