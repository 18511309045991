import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QuestionTypeBaseComponent } from '../../../base/question-type-base.component';
import { FormBuilder } from '@angular/forms';
import { QuestionType } from '../../../enums/question-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medrecord-boolean-question',
  templateUrl: './boolean-question.component.html',
  styleUrls: ['./boolean-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooleanQuestionComponent extends QuestionTypeBaseComponent {

  QuestionType = QuestionType;

  yesNoAnswers = [
    {
      label: this.translateService.instant('common_yes'),
      value: true
    },
    {
      label: this.translateService.instant('common_no'),
      value: false
    },
  ];

  constructor(
    protected fb: FormBuilder,
    private translateService: TranslateService
  ) {
    super(fb);
  }

  prepareAnswersForEmitting(currentAnswer: any) {
    const answer = currentAnswer?.answer;
    if (answer === undefined) {
      return answer;
    }

    return answer[0].value;
  }

  getCurrentAnswer() {
    if (this.currentAnswer !== undefined && this.currentAnswer !== null) {
      return {
        answer: this.yesNoAnswers
          .filter(({ value }) => value === this.currentAnswer)
      };
    }
  }

  getQuestion() {
    return {
      ...this.question,
      options: {
        ...this.question.options,
        answers: this.yesNoAnswers
      }
    };
  }

  getCurrentAnswerText(answerFromChoiceQuestion: any): string {
    if (!answerFromChoiceQuestion?.answer) {
      return '';
    }

    return answerFromChoiceQuestion.answer[0].label;
  }

}

