<!-- <ng-container *ngIf="permission$ | async as editPermission"> -->
<ng-container *ngIf="careprovider$ | async as careprovider">
  <medrecord-test-careprovider-modal
    [isOpen]="modalOpen"
    [transaction]="selectedTransaction"
    [dataServiceId]="selectedDataServiceId"
    (dialogClosed)="
      modalOpen = false;
      selectedTransaction = null;
      selectedDataServiceId = null
    "
    [showParametersModal]="false"
  >
  </medrecord-test-careprovider-modal>
  <medrecord-logs-modal
    [isOpen]="logsModalOpen"
    [careproviderId]="careprovider.id"
    (dialogClosed)="logsModalOpen = false"
  ></medrecord-logs-modal>
  <medrecord-list-modal
    *ngIf="careprovider.id === testCareproviderId"
    [isOpen]="listModalOpen"
    [status]="activeListStatus"
    [type]="activeListType"
    (dialogClosed)="listModalOpen = false"
  ></medrecord-list-modal>

  <div class="row mx-0">
    <div class="col-9 mb-3 d-flex align-items-center">
      <medrecord-back-icon-button
        tooltip="my_doctor_back_to_careproviders"
      ></medrecord-back-icon-button>
      <h3 class="text-weight-600 text-black mb-0 mr-2">
        {{ getDisplayName(careprovider) }}
      </h3>
      <medrecord-svg-medmij-verified
        [width]="22"
      ></medrecord-svg-medmij-verified>
    </div>
    <div class="col-3 mb-3 d-flex justify-content-end">
      <button
        class="button btn btn-outline-black font-weight-bold"
        type="button"
        (click)="getLogsAndOpenModal(careprovider.id)"
      >
        {{ "healthcare_providers_view_logs" | translate | titlecase }}
      </button>
    </div>
  </div>

  <div *ngIf="careprovider.id === testCareproviderId" class="row mx-0">
    <div class="col-12 mb-4 d-flex align-items-center">
      <button
        class="button btn btn-success font-weight-bold mr-3"
        type="button"
        (click)="loadList(fetchListType.whitelist)"
      >
        {{ "healthcare_providers_list_get_whitelist" | translate | titlecase }}
      </button>
      <button
        class="button btn btn-success font-weight-bold mr-3"
        type="button"
        (click)="loadList(fetchListType.dataservices)"
      >
        {{
          "healthcare_providers_list_get_dataservices" | translate | titlecase
        }}
      </button>
      <button
        class="button btn btn-success font-weight-bold"
        type="button"
        (click)="loadList(fetchListType.careproviders)"
      >
        {{
          "healthcare_providers_list_get_careproviders" | translate | titlecase
        }}
      </button>
    </div>
  </div>

  <ejs-grid
    #grid
    [rowHeight]="80"
    class="table-card"
    [dataSource]="transactions"
    [height]="transactions.length ? 'auto' : '50vh'"
  >
    <e-columns>
      <e-column
        [minWidth]="'70%'"
        [maxWidth]="'100%'"
        field="name"
        [headerText]="'common_column_name' | translate"
      >
        <ng-template #template let-data>
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <a
                class="nav-cell"
                [routerLink]="[data?.dataserviceId, data?.transaction?.id]"
              >
                {{ data?.transaction?.id | translate }}</a
              >
            </div>
            <div
              *ngIf="!isDataServiceSupported(data?.dataserviceId)"
              class="not-supported"
            >
              {{ "common_not_supported" | translate | titlecase }}
            </div>
          </div>
        </ng-template>
      </e-column>

      <e-column field="getData" [width]="300" textAlign="right" headerText="">
        <ng-template #template let-data>
          <button
            class="button btn btn-success font-weight-bold"
            (click)="
              getData(data.transaction, data?.dataserviceId, careprovider)
            "
            type="button"
          >
            {{ "my_doctor_get_data" | translate | titlecase }}
          </button>
        </ng-template>
      </e-column>
    </e-columns>
  </ejs-grid>
</ng-container>
<!-- </ng-container> -->
