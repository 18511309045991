import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { QuestionTypeBaseComponent } from '../../../base/question-type-base.component';
import { MedrecordMoment } from '@medrecord/services-datetime';

@Component({
  selector: 'medrecord-question-datetime',
  templateUrl: './question-date-time.component.html',
  styleUrls: ['./question-date-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionDateTimeComponent extends QuestionTypeBaseComponent {
  constructor(protected fb: FormBuilder) {
    super(fb);
  }

  getCurrentAnswerText(answer: any): string {
    if (answer?.date) {
      return MedrecordMoment(answer.date)
        .utcOffset('+00:00')
        .format('LLL');
    }

    return '';
  }

}
