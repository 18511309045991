import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { toZDBUser } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'zdb-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  @Input() userId: string;
  // FIXME: add User interface for User
  public user$: Observable<any>;
  public age: number;
  public city: string;
  public fullName: string;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    // TODO: add possibility to provide also a user object.
    // That way, for the patient and the caretaker we can save the data in the store
    //  and retrieve it from there to avoid a request each time we are on this page.
    if (this.userId) {
        this.user$ = this.userService.find(this.userId).pipe(
          map(user => toZDBUser(user)));
    }
  }

}
