<form *ngIf="form"
      [formGroup]="form">

  <app-numeric-input
    [comma]="':'"
    [fractionDigits]="2"
    [fractionInputWidth]="80"
    [integerInputWidth]="80"
    [label]="'common_answer' | translate"
    [maxFractionValue]="maxFractionsValue"
    [maxValue]="maxValue"
    [minValue]="minValue"
    [showFractions]="true"
    formControlName="answer"
  ></app-numeric-input>

</form>
