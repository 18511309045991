<div>
  <ejs-tab #bmdTabs (selected)="tabSelected($event)">
    <e-tabitems>
      <e-tabitem
        [id]="myDoctorRouteNames.MyDoctor.Search"
        [header]="{
          text: 'Search' | translate | titlecase,
          headerStyle: 'default'
        }"
      ></e-tabitem>

      <!-- Uncomment when Favorites tab is ready -->
      <!-- <e-tabitem
        [id]="myDoctorRouteNames.MyDoctor.Favourites"
        [header]="{
          text: 'Favourites' | translate | titlecase,
          headerStyle: 'default'
        }"
      ></e-tabitem> -->

    </e-tabitems>
  </ejs-tab>
  <div class="mt-4">
    <router-outlet></router-outlet>
  </div>
</div>
