import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Question } from '../interfaces/question.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';

@Component({
  selector: 'medrecord-question-type-base',
  template: ''
})
export class QuestionTypeBaseComponent implements OnChanges, OnDestroy {
  @Input() question: Question;
  @Input() isReadonly = true;

  @Output() answer = new EventEmitter<any>();

  form: FormGroup;

  protected _currentAnswer: any;

  protected unsubscribe$: Subject<void> = new Subject();

  constructor(protected fb: FormBuilder) {
  }

  @Input()
  set currentAnswer(currentAnswer: any) {
    this._currentAnswer = currentAnswer?.answer;
  }

  get currentAnswer(): any {
    return this._currentAnswer;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentAnswer && !changes.question) {
      return;
    }

    if (isEqual(changes.question.previousValue, changes.question.currentValue)) {
      return;
    }

    this.initForm();
    if (this.isReadonly) {
      this.form.disable();
    }

    this.connectAnswersEmitter();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm(): void {
    this.form = this.fb.group({
      answer: [this.currentAnswer || null]
    });
  }

  connectAnswersEmitter(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        filter(() => this.form.valid),
      ).subscribe(() => this.emitAnswers(this.form.value));
  }

  emitAnswers(formValue: any): void {
    const answer = this.prepareAnswersForEmitting(formValue);
    const text = this.getCurrentAnswerText(answer);

    return this.answer.emit({ text, answer });
  }

  prepareAnswersForEmitting(formValue: any): any {
    return formValue.answer;
  }

  getCurrentAnswerText(answer: any): string {
    if (typeof answer === 'string') {
      return answer;
    }

    return '';
  }
}
