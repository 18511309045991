<zdb-main-layout>
    <div class="zdb-card bg-white mb-4 zdb-shadow">
        <div class="card-header">
            <div class="float-lg-right mb-2">
                <button (click)="goBack()" class="btn btn-lg btn-secondary">
                    {{ "common_back" | translate }}
                </button>
            </div>
            <h2 class="card-title"> {{ "patients_network.archive_title" | translate }} </h2>
        </div>
    </div>
    <zdb-questionnaire-archive [userId]="userId" [showInProgress]="true"></zdb-questionnaire-archive>
    <zdb-questionnaire-archive class="col-md-6 px-0 px-md-3" [userId]="userId" [isPositiveHealth]="true"></zdb-questionnaire-archive>
</zdb-main-layout>
