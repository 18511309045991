import { Component } from '@angular/core';
import { questionnaireScope } from '@app/medrecord/questionnaire-adapter/constants/questionnaire-scope';
import {
  selectIsQuestionnaireTemplateLoaded,
  selectIsQuestionnaireTemplateLoading,
  selectQuestionnaireTemplate,
  selectQuestionnaireAnswers,
  selectQuestionnaireTemplateError,
} from '@app/medrecord/questionnaire-adapter/state-management/selectors/templates.selectors';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, first, tap, withLatestFrom } from 'rxjs/operators';
import { SaveModalType } from '../../../projects/questionnaires/src/lib/enums/save-modal-type.enum';
import { questionnaireLoaded } from '../../../projects/questionnaires/src/lib/state/questionnaires.actions';
import { mapResourceToQuestionnaire } from '../../../projects/questionnaires/src/lib/utils/hp-resource-to-questionnaire.util';
import { IZDBUser } from '@app/core/user/user.model';
import { HealthcareProviderResource } from '@app/medrecord/models/healthcare-provider-resource';

@Component({
  selector: 'zdb-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent {
  title: string;
  user$: Observable<IZDBUser>;
  questionnaireTask$: Observable<HealthcareProviderResource>;

  resource$: Observable<any> = this.store.pipe(
    select(selectQuestionnaireTemplate)
  );
  answers$: Observable<any> = this.store.pipe(
    select(selectQuestionnaireAnswers)
  );
  isLoading$: Observable<any> = this.store.pipe(
    select(selectIsQuestionnaireTemplateLoading)
  );
  isLoaded$: Observable<any> = this.store.pipe(
    select(selectIsQuestionnaireTemplateLoaded)
  );
  error$: Observable<any> = this.store.pipe(
    select(selectQuestionnaireTemplateError)
  );

  constructor(
    private translateService: TranslateService,
    private store: Store
  ) {
    this.resource$ = this.store.pipe(select(selectQuestionnaireTemplate));
    this.answers$ = this.store.pipe(select(selectQuestionnaireAnswers));
    this.isLoading$ = this.store.pipe(
      select(selectIsQuestionnaireTemplateLoading)
    );
    this.isLoaded$ = this.store.pipe(
      select(selectIsQuestionnaireTemplateLoaded)
    );
    this.error$ = this.store.pipe(select(selectQuestionnaireTemplateError));

    this.isLoaded$
      .pipe(
        withLatestFrom(this.resource$, this.answers$),
        filter(([isLoaded, resource]) => isLoaded && !!resource),
        first(),
        tap(([, resource, answers]) => {
          this.store.dispatch(
            questionnaireLoaded(
              mapResourceToQuestionnaire(
                {
                  ...resource,
                  id: resource.resourceId,
                },
                questionnaireScope,
                SaveModalType.default,
                this.translateService,
                answers
              )
            )
          );
        })
      )
      .subscribe(() => {}); // tslint:disable-line:no-empty
  }
}
