import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { IUser } from '@app/core/user/user.model';
import { RelatedUsersService } from '@app/network/services/related-users.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MapFieldType } from '@medrecord/ui';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'zdb-patients-network',
  templateUrl: './patients-network.component.html',
  styleUrls: ['./patients-network.component.scss'],
  providers: [ RelatedUsersService ]
})
export class PatientsNetworkComponent implements OnInit {
  users$: Observable<IUser[]>;
  totalUsers$: Observable<number>;
  form: FormGroup;
  readonly mapFieldType = MapFieldType;

  constructor(
    public relUsersService: RelatedUsersService,
    public store: Store,
    public translateService: TranslateService,
    public fb: FormBuilder) {

    this.form = fb.group({
      searchTerm: fb.control(null, [])
    });
    this.form.get('searchTerm').valueChanges
      .pipe(
        debounceTime(100),
      ).subscribe((term: string) => this.relUsersService.searchTerm = term);

  }

  ngOnInit(): void {
    this.users$ = this.relUsersService.users$;
    this.totalUsers$ = this.relUsersService.total$;
  }

}
