import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {QuestionnaireAdapterModule} from '@app/medrecord/questionnaire-adapter/questionnaire-adapter.module';
import {MedrecordUiModule} from '@medrecord/ui';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GaugeChartModule } from 'angular-gauge-chart';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './layouts/main/main.component';
import { SidenavComponent } from './layouts/components/sidenav/sidenav.component';
import { TopnavComponent } from './layouts/components/topnav/topnav.component';
import { CardComponent } from './layouts/components/card/card.component';
import { SidenavItemComponent } from './layouts/components/sidenav-item/sidenav-item.component';
import { MedrecordIconsModule } from '@medrecord/icons';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MedrecordCustomerMetadataModule } from '@medrecord/services-customer-metadata';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { MedrecordSocialLoginModule } from '@medrecord/services-social-login';
import { MedrecordStorageModule } from '@medrecord/tools-storage';
import { MedrecordToastToolModule } from '@medrecord/tools-toast';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { Store, StoreModule } from '@ngrx/store';

import { provideDefaultLang } from './medrecord/providers/provide-default-lang';
import { provideStorageKey } from './medrecord/providers/provide-storage-key';
import { provideGoogleAuthConfig } from './medrecord/providers/provide-google-auth-config';
import { createTranslateLoader } from './medrecord/factories/create-translation-loader.factory';

import { HelpComponent } from './help/help.component';
import { HelpItemComponent } from './help/help-item/help-item.component';
import { HeaderComponent } from './layouts/components/header/header.component';
import { PatientProfileComponent } from './patient-profile/patient-profile.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { AvatarComponent } from './avatar/avatar.component';
import { SelfcheckComponent } from './selfcheck/selfcheck.component';

import { CurrentUserProfileComponent } from './current-user-profile/current-user-profile.component';
import { CurrentUserEditComponent } from './current-user-edit/current-user-edit.component';

import { MedrecordFormsModule } from '@medrecord/components/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { UserFormComponent } from './user-form/user-form.component';
import { PatientEditComponent } from './patient-edit/patient-edit.component';
import { NetworkComponent } from './network/network.component';
import { AvatarInitialsComponent } from './avatar-initials/avatar-initials.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { MedrecordDatetimeModule } from '@medrecord/services-datetime';
import { QuestionnaireTaskScoresComponent } from './selfcheck/questionnaire-task-scores/questionnaire-task-scores.component';
import { GaugeChartComponent } from './gauge-chart/gauge-chart.component';
import { ChartsComponent } from './charts/charts.component';
import { QuestionnaireArchiveComponent } from './selfcheck/questionnaire-archive/questionnaire-archive.component';
import { UserQuestionnairesComponent } from './network/user-questionnaires/user-questionnaires.component';
import { PatientsNetworkComponent } from './network/patients-network/patients-network.component';
import { OrganizationsNetworkComponent } from './network/organizations-network/organizations-network.component';
import { APP_ENVIRONMENT, provideEnvironment, Language } from '@medrecord/core';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import {
  AuthManagerLibModule,
  AuthRouteParams,
  provideAuthorizationInterceptor,
  provideLogoutOnIdle,
  requestTokensAction
} from '@medrecord/managers-auth';
import { MedrecordNavigationLibModule } from '@medrecord/tools-navigation';
import { UsersManagerModule } from '@medrecord/managers-users';
import { QuestionnaireHeaderComponent } from './questionnaire/questionnaire-header/questionnaire-header.component';
import { PrintComponent } from './print/print.component';
import { SpiderwebGraphComponent } from './positive-health/spiderweb-graph/spiderweb-graph.component';
import { PositiveHealthComponent } from './positive-health/positive-health.component';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { LandingPageComponent } from './my-doctor/pages/landing-page/landing-page.component';
import { PositiveHealthAnswersTable } from './positive-health/positive-health-answers-table/positive-health-answers-table.component';
import { MyDoctorManagerModule } from '@medrecord/managers-my-doctor';
import { ShellComponent } from './my-doctor/containers/shell/shell.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainComponent,
    SidenavComponent,
    TopnavComponent,
    CardComponent,
    SidenavItemComponent,
    HelpComponent,
    HelpItemComponent,
    HeaderComponent,
    SelfcheckComponent,
    PatientProfileComponent,
    UserDetailsComponent,
    AvatarComponent,
    CurrentUserProfileComponent,
    UserFormComponent,
    CurrentUserEditComponent,
    PatientEditComponent,
    NetworkComponent,
    AvatarInitialsComponent,
    QuestionnaireComponent,
    QuestionnaireTaskScoresComponent,
    GaugeChartComponent,
    ChartsComponent,
    QuestionnaireArchiveComponent,
    UserQuestionnairesComponent,
    PatientsNetworkComponent,
    OrganizationsNetworkComponent,
    QuestionnaireHeaderComponent,
    PrintComponent,
    SpiderwebGraphComponent,
    PositiveHealthComponent,
    LandingPageComponent,
    PositiveHealthAnswersTable,
    ShellComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    CoreModule,
    AppRoutingModule,
    NgbModule,
    MedrecordIconsModule,
    HttpClientModule,
    MedrecordCustomerMetadataModule,
    MedrecordDatetimeModule,
    QuestionnaireAdapterModule,
    MedrecordSocialLoginModule.forRoot(),
    MedrecordStorageModule,
    MedrecordToastToolModule,
    AuthManagerLibModule.configure({
      setDefaultAccessTokenRules: true,
      setDefaultFormRules: true
    }),
    MedrecordNavigationLibModule,
    UsersManagerModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      enableSourceMaps: true,
      colorScheme: ['purple', 'teal', 'blue', 'gray', 'red', 'red', 'red']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, APP_ENVIRONMENT]
      }
    }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MedrecordUiModule,
    MedrecordFormsModule,
    FormsModule,
    ReactiveFormsModule,
    AlifeFileToBase64Module,
    GaugeChartModule,
    CheckBoxModule,
    MyDoctorManagerModule
  ],
  providers: [
    provideAuthorizationInterceptor(),
    provideDefaultLang(),
    provideStorageKey(),
    provideGoogleAuthConfig(),
    provideLogoutOnIdle(),
    provideEnvironment(environment),
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store) => {
        return () => {
          const code = (new URL(location.href)).searchParams.get(AuthRouteParams.Code);

          if (code) {
            store.dispatch(requestTokensAction({ code }));
          }
        };
      },
      deps: [Store],
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        let defaultLanguage: string = Language.NL;
        if (environment.defaultLanguage) {
            Object.values(Language).filter(v => v === environment.defaultLanguage)
                  .map(() => defaultLanguage = environment.defaultLanguage);
        }
        MedrecordMoment.locale(defaultLanguage);
    }
}
