import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const plannedCareActivityProcedureRequestBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.procedureRequest,
  fieldGroups: [
    {
      name: ({ resource }) => resource?.content?.operationType?.coding?.[0]?.display || 'my_doctor_procedure',
      fields: [
        {
          label: 'my_doctor_status',
          fieldName: 'status',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_start_date',
          fieldName: 'period',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_end_date',
          fieldName: 'period',
          prepareData: (data) => (data?.end ? MedrecordMoment(data?.end).format('LL') : ''),
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_type',
          fieldName: 'operationType',
          prepareData: (data) => [data?.coding?.[0]?.code, data?.coding?.[0]?.display].filter(Boolean).join(' - '),
        },
        {
          label: 'my_doctor_concern',
          fieldName: 'problem',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_organzation_name',
          fieldName: 'location',
          prepareData: (data) => data?.display,
        },
      ],
    },
  ],
});
