import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QuestionTypeBaseComponent } from '../../../base/question-type-base.component';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'medrecord-question-date',
  templateUrl: './question-date.component.html',
  styleUrls: ['./question-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionDateComponent extends QuestionTypeBaseComponent {
  constructor(protected fb: FormBuilder) {
    super(fb);
  }

}
