import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectQuestionnaireCopyright,
  selectQuestionnaireDescription,
  selectQuestionnaireDoctor,
  selectQuestionnaireDueDate,
  selectQuestionnaireExists,
  selectQuestionnaireOwnerLink,
  selectQuestionnaireOwnerName,
  selectQuestionnaireTags,
  selectQuestionnaireTitle,
} from '../../state/questionnaires.selectors';
import { map } from 'rxjs/operators';
import { textToHtml } from '../../utils/text-to-html.util';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';
import { ActivatedRoute } from '@angular/router';
import { positiveHealthQuestionnaireAlias } from '@app/medrecord/questionnaire-adapter/constants/positive-health-questionnaire-alias';

@Component({
  selector: 'medrecord-questionnaire-page',
  templateUrl: './questionnaire-page.component.html',
  styleUrls: ['./questionnaire-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnairePageComponent {
  public isPositiveHealth = false;

  public isReadonly = false;
  questionnaireExists$;
  title$;
  doctor$;
  dueDate$;
  tags$;
  description$;
  copyright$;
  ownerLink$;
  ownerName$;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {
    this.isPositiveHealth = this.activatedRoute.snapshot.data.isPositiveHealth;

    this.questionnaireExists$ = this.store.select(
      selectQuestionnaireExists,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );
    this.title$ = this.store.select(
      selectQuestionnaireTitle,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );
    this.doctor$ = this.store.select(
      selectQuestionnaireDoctor,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );
    this.dueDate$ = this.store.select(
      selectQuestionnaireDueDate,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );
    this.tags$ = this.store.select(
      selectQuestionnaireTags,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );
    this.description$ = this.store.select(
      selectQuestionnaireDescription,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );
    (this.copyright$ = this.store.select(
      selectQuestionnaireCopyright,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    )),
      map(textToHtml);
    this.ownerLink$ = this.store.select(
      selectQuestionnaireOwnerLink,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );
    this.ownerName$ = this.store.select(
      selectQuestionnaireOwnerName,
      !this.isPositiveHealth
        ? this.environment.interraiQuestionnaireId
        : positiveHealthQuestionnaireAlias
    );
  }

  getFormattedDate(date: Date): string {
    return MedrecordMoment(date).format('LLL');
  }

  openOwnerPage(e: any, link: string): void {
    e.preventDefault();
    window.open(link, '_blank');
  }
}
