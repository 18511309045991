import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const financeBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.coverage,
  fieldGroups: [
    {
      name: 'my_doctor_financial_information',
      fields: [
        {
          label: 'my_doctor_start_date',
          fieldName: 'period',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_end_date',
          fieldName: 'period',
          prepareData: (data) => (data?.end ? MedrecordMoment(data?.end).format('LL') : ''),
        },
        {
          label: 'my_doctor_insurance_type',
          fieldName: 'insuranceType',
          prepareData: (data) => data?.text || data?.coding?.[0]?.display || '',
        },
        {
          label: 'my_doctor_identification_number',
          fieldName: 'period',
          prepareData: (_data, allItems) => {
            return (
              (allItems.find((item) => item?.resourceType === ResourceType.organization) || {})?.content
                ?.identifiers?.[0]?.value || ''
            );
          },
        },
        {
          label: 'my_doctor_organisation_name',
          fieldName: 'organizationName',
          prepareData: (data) => data?.display || '',
        },
        {
          label: 'my_doctor_insured_number',
          fieldName: 'insuredNumber',
          prepareData: (data) => data || '',
        },
      ],
    },
    {
      name: 'my_doctor_address',
      fields: [
        {
          label: 'my_doctor_street',
          fieldName: 'period',
          prepareData: (_data, allItems) => {
            return (
              (allItems.find((item) => item?.resourceType === ResourceType.patient) || {})?.content?.addressList?.[0]
                ?.streetName || ''
            );
          },
        },
        {
          label: 'my_doctor_house_number',
          fieldName: 'period',
          prepareData: (_data, allItems) => {
            return (
              (allItems.find((item) => item?.resourceType === ResourceType.patient) || {})?.content?.addressList?.[0]
                ?.houseNumber || ''
            );
          },
        },
        {
          label: 'my_doctor_postal_code',
          fieldName: 'period',
          prepareData: (_data, allItems) => {
            return (
              (allItems.find((item) => item?.resourceType === ResourceType.patient) || {})?.content?.addressList?.[0]
                ?.postalCode || ''
            );
          },
        },
        {
          label: 'my_doctor_city',
          fieldName: 'period',
          prepareData: (_data, allItems) => {
            return (
              (allItems.find((item) => item?.resourceType === ResourceType.patient) || {})?.content?.addressList?.[0]
                ?.city || ''
            );
          },
        },
        {
          label: 'my_doctor_country',
          fieldName: 'period',
          prepareData: (_data, allItems) => {
            return (
              (allItems.find((item) => item?.resourceType === ResourceType.patient) || {})?.content?.addressList?.[0]
                ?.country || ''
            );
          },
        },
      ],
    },
    {
      name: 'my_doctor_contact_information',
      fields: [
        {
          label: 'my_doctor_phone_number',
          fieldName: 'period',
          prepareData: (_data, allItems) => {
            const phone = (
              allItems.find((item) => item?.resourceType === ResourceType.patient) || {}
            )?.content?.contactList?.find((t) => t.system === 'PHONE');
            return phone?.value;
          },
        },
        {
          label: 'my_doctor_phone_type',
          fieldName: 'period',
          prepareData: (_data, allItems) => {
            const phone = (
              allItems.find((item) => item?.resourceType === ResourceType.patient) || {}
            )?.content?.contactList?.find((t) => t.system === 'PHONE');
            return phone?.telecomType?.coding?.[0]?.display;
          },
        },
        {
          label: 'my_doctor_email',
          fieldName: 'period',
          prepareData: (_data, allItems) => {
            const email = (
              allItems.find((item) => item?.resourceType === ResourceType.patient) || {}
            )?.content?.contactList?.find((t) => t.system === 'EMAIL');
            return email?.value;
          },
        },
        {
          label: 'my_doctor_email_type',
          fieldName: 'period',
          translate: true,
          prepareData: (_data, allItems) => {
            const email = (
              allItems.find((item) => item?.resourceType === ResourceType.patient) || {}
            )?.content?.contactList?.find((t) => t.system === 'EMAIL');
            return email?.use ? 'common_contact_use_' + email?.use?.toLowerCase() : '';
          },
        },
      ],
    },
  ],
});
