import { Injectable } from '@angular/core';

import {
  CategoryGroup,
  Codes,
  GroupKeys,
  GroupedOutcome,
  OutcomeItem,
  isStoplightChartCode,
  isGuageChartCode,
  isDefaultDisplayCode
} from '@app/selfcheck/questionnaire-outomes/questionnaire-outcomes.type';
import { CodeDisplay } from '@app/selfcheck/questionnaire-outomes/questionnnaire-outomes.enum';
import {
  questionnaireCategories,
  unavailableCodes,
} from '@app/selfcheck/questionnaire-outomes/questionnaire-outcomes.data';
import { getSubquestions } from 'projects/questionnaires/src/lib/utils/question-answers.util';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireOutcomesService {
  groupOutcomes(
    items: OutcomeItem[],
    categories: CategoryGroup = questionnaireCategories
  ): GroupedOutcome[] {
    const groupedOutcomes = new Array<GroupedOutcome>();
    for (const key of Object.keys(categories)) {
      groupedOutcomes.push({
        label: key as GroupKeys,
        items: items
          .filter(
            (item) =>
              !unavailableCodes.includes(item.code as Codes) &&
              categories[key].entries.includes(item.code)
          )
          .sort((a, b) => {
            if (a.code < b.code) {
              return -1;
            }
            if (a.code > b.code) {
              return 1;
            }
            return 0;
          })
          .map((item) =>
            Object.assign(item, {
              displayType: this.displayTypeForCode(item.code),
            })
          ),
      });
    }
    return groupedOutcomes;
  }

  private displayTypeForCode(code: string): CodeDisplay {
    if (isStoplightChartCode(code)) {
      return CodeDisplay.stoplightChart;
    }
    if (isGuageChartCode(code)) {
      return CodeDisplay.gaugeChart;
    }
    if (isDefaultDisplayCode(code)) {
      return CodeDisplay.default;
    }
  }

  getExtraInfo(questionnaire) {
    const IMPORTANT_QUESTIONS = [
      // Format: questionId, displayType, excludeQuestionForValue
      // 'Section-F'
      ['siI1cbis', CodeDisplay.default, '0'],
      ['siI1dbis', CodeDisplay.default, '0'],
      ['siI1jbis', CodeDisplay.default, '0'],
      ['siI1kbis', CodeDisplay.default, '0'],
      ['siI1mbis', CodeDisplay.default, '0'],
      ['siI1lbis', CodeDisplay.default, '0'],
      ['siI1sbis', CodeDisplay.default, '0'],
      ['siI1tbis', CodeDisplay.default, '0'],
      ['siF4', CodeDisplay.default],
      // 'Section-C'
      ['siHH1', CodeDisplay.default],
      ['siP3', CodeDisplay.textDisplay],
      // 'Section-B'
      ['siD3a', CodeDisplay.ratingDisplay],
      // 'Section-H'
      ['siN1a', CodeDisplay.default],
      // 'Section-J'
      ['RAI', CodeDisplay.default],
      ['final', CodeDisplay.longTextDisplay],
    ];
    const subquestions = getSubquestions(questionnaire.questions);
    return IMPORTANT_QUESTIONS.filter(
      ([questionId, ,]) => subquestions[questionId]
    ).map(([questionId, displayType, excludeValue]) => ({
        title: subquestions[questionId]?.title,
        code: questionId,
        value:
          questionnaire.answers[questionId]?.answer[0]?.value ||
          questionnaire.answers[questionId]?.answer,
        answer: questionnaire.answers[questionId],
        displayType,
        excludeValue,
      }))
      .filter((details) => details.value !== details.excludeValue);
  }
}
