import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const patientBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.patient,
  fieldGroups: [
    {
      name: 'my_doctor_name',
      fields: [
        {
          label: 'my_doctor_identification',
          fieldName: 'identifiers',
          prepareData: (data) => data?.[0]?.value,
        },
        {
          label: 'my_doctor_first_name',
          fieldName: 'name',
          prepareData: (data) => data.firstName,
        },
        {
          label: 'my_doctor_initials',
          fieldName: 'name',
          prepareData: (data) => data.initials,
        },
        {
          label: 'my_doctor_given_name',
          fieldName: 'name',
          prepareData: (data) => data.givenName,
        },
        {
          label: 'my_doctor_partner_last_name_prefix',
          fieldName: 'name',
          prepareData: (data) => data.partnerLastNamePrefix,
        },
        {
          label: 'my_doctor_partner_last_name',
          fieldName: 'name',
          prepareData: (data) => data.partnerLastName,
        },
        {
          label: 'my_doctor_last_name_prefix',
          fieldName: 'name',
          prepareData: (data) => data.lastNamePrefix,
        },
        {
          label: 'my_doctor_last_name',
          fieldName: 'name',
          prepareData: (data) => data.lastName || data.family || null,
        },
        {
          label: 'my_doctor_name_use',
          fieldName: 'name',
          prepareData: (data) => data?.assemblyOrder?.coding?.[0]?.display,
        },
      ],
    },
    {
      name: 'my_doctor_address',
      fields: [
        {
          label: 'my_doctor_street',
          fieldName: 'addressList',
          prepareData: (data) => data?.[0]?.streetName,
        },
        {
          label: 'my_doctor_house_number',
          fieldName: 'addressList',
          prepareData: (data) => data?.[0]?.houseNumber,
        },
        {
          label: 'my_doctor_postal_code',
          fieldName: 'addressList',
          prepareData: (data) => data?.[0]?.postalCode,
        },
        {
          label: 'my_doctor_city',
          fieldName: 'addressList',
          prepareData: (data) => data?.[0]?.city,
        },
        {
          label: 'my_doctor_country',
          fieldName: 'addressList',
          prepareData: (data) => data?.[0]?.landGbaCode?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_address_type',
          fieldName: 'addressList',
          translate: true,
          prepareData: (data) => data?.[0]?.use,
        },
      ],
    },
    {
      name: 'my_doctor_contact_information',
      fields: [
        {
          label: 'my_doctor_phone_number',
          fieldName: 'contactList',
          prepareData: (data) => {
            const phone = data?.find((t) => t.system === 'PHONE');
            return phone?.value;
          },
        },
        {
          label: 'my_doctor_phone_type',
          fieldName: 'contactList',
          translate: true,
          prepareData: (data) => {
            const phone = data?.find((t) => t.system === 'PHONE');
            return phone?.use ? 'common_contact_use_' + phone?.use?.toLowerCase() : '';
          },
        },
        {
          label: 'my_doctor_email',
          fieldName: 'contactList',
          prepareData: (data) => {
            const email = data?.find((t) => t.system === 'EMAIL');
            return email?.value;
          },
        },
        {
          label: 'my_doctor_email_type',
          fieldName: 'contactList',
          translate: true,
          prepareData: (data) => {
            const email = data?.find((t) => t.system === 'EMAIL');
            return email?.use ? 'common_contact_use_' + email?.use?.toLowerCase() : '';
          },
        },
        {
          label: 'my_doctor_date_of_birth',
          fieldName: 'birthDate',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LL') : ''),
        },
        {
          label: 'my_doctor_gender',
          fieldName: 'gender',
          translate: true,
          prepareData: (data) => (data ? 'common_gender_' + data?.toLowerCase() : ''),
        },
        {
          label: 'my_doctor_multiple_indicator',
          fieldName: 'multipleBirth',
          translate: true,
          prepareData: (data) => (data ? 'common_yes' : 'common_no'),
        },
      ],
    },
  ],
});
