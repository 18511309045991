import { InjectionToken } from '@angular/core';

export enum MyDoctorRouteParams {
  id = 'id',
  careproviderId = 'careproviderId',
  serviceId = 'serviceId',
  transactionId = 'transactionId',
}

const getParamByKey = (key: MyDoctorRouteParams): string => ':' + key;

export const myDoctorRouteNames = {
  MyDoctor: {
    MijnDoktor: 'mijn-doktor',
    Entry: 'my-doctor',
    Search: 'search',
    Favourites: 'favourites',
    Careprovider: 'careprovider',
    Fetch: 'fetch',
    Service: getParamByKey(MyDoctorRouteParams.serviceId),
    Transaction: getParamByKey(MyDoctorRouteParams.transactionId),
    CareproviderId: getParamByKey(MyDoctorRouteParams.careproviderId),
  }
};

export type MyDoctorRouteNames = typeof myDoctorRouteNames;

export const MY_DOCTOR_ROUTE_NAMES = new InjectionToken<MyDoctorRouteNames>(
  'Route Names connected by `My Doctor` Shell library'
);

export const provideMyDoctorRouteNames = (entry = '') => [
  {
    provide: MY_DOCTOR_ROUTE_NAMES,
    useValue: { ...myDoctorRouteNames, Entry: entry },
  },
];