import { Component, Input, TemplateRef } from '@angular/core';
import { MedrecordInputType } from '../../types/medrecord-input.type';
import { MedrecordIcon } from '@medrecord/icons';
import { MedrecordInputNgControlComponentBase } from '../../base/medrecord-input-ng-control-component-base.component';
import { Colors } from '@medrecord/services-colors';

@Component({
  selector: 'medrecord-input',
  templateUrl: './medrecord-input.component.html',
  styleUrls: ['./medrecord-input.component.scss'],
})
export class MedrecordInputComponent extends MedrecordInputNgControlComponentBase {
  @Input() type: MedrecordInputType;
  @Input() iconsLeft: TemplateRef<MedrecordIcon>[] = [];
  @Input() iconsRight: TemplateRef<MedrecordIcon>[] = [];
  @Input() labelType: Colors = 'boulder';
  @Input() formDisabled = false;
}
