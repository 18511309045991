import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const medicalAidBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.deviceUseStatement,
  fieldGroups: [
    {
      name: ({ resource }) => resource.content?.productDescription?.display || 'my_doctor_device',
      fields: [
        {
          label: 'my_doctor_start_date',
          fieldName: 'period',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_end_date',
          fieldName: 'period',
          prepareData: (data) => (data?.end ? MedrecordMoment(data?.end).format('LL') : ''),
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_concern',
          fieldName: 'problem',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_anatomical_location',
          fieldName: 'anatomicalLocation',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_laterality',
          fieldName: 'laterality',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_organization_name',
          fieldName: 'provider',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_identification',
          fieldName: 'productDescription',
          prepareData: (data, resources) => {
            const device = resources.find((r) => {
              const [referenceType, referenceId] = data?.reference?.split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return device?.content?.carrierHrf;
          },
        },
        {
          label: 'my_doctor_type',
          fieldName: 'productDescription',
          prepareData: (data, resources) => {
            const device = resources.find((r) => {
              const [referenceType, referenceId] = data?.reference?.split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return device?.content?.productType?.coding?.[0]?.display;
          },
        },
      ],
    },
  ],
});
