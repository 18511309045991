<zdb-main-layout>
  <div class="row">
    <div class="col">
      <div class="card-deck">

        <zdb-card [ngStyle]="{ backgroundImage: 'url(assets/images/cards/home-todo.png)' }">
          <div class="card-header">
            <h2 class="selfcheck-title"> Krijg inzicht in je vitaliteit en gezondheid </h2>
          </div>
          <div class="card-footer ">
            <p class="card-text">
              <a
              class="btn btn-lg btn-primary-button"
              [routerLink]="'/zelfcheck'"
              >
                Doe de test
              </a>
            </p>
          </div>
        </zdb-card>

        <zdb-card
          [ngStyle]="{ backgroundImage: 'url(assets/images/cards/home-shopping.png)' }"
          class="card-small"
          >
          <div class="card-header">
            <h2 class="card-title">Wat is er in mijn buurt?</h2>
          </div>
          <div class="card-footer ">
            <p class="card-text">
              <a
                class="btn btn-lg btn-secondary"
                href="https://zodichtbij.nl/help-mij-op-weg/zoek-een-product-dienst/"
              >
              Ga naar Zo-Dichtbij
              </a>
            </p>
          </div>
        </zdb-card>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card-deck">
        <zdb-card [ngStyle]="{ backgroundImage: 'url(assets/images/cards/home-profile.png)' }">
          <div class="card-header">
            <h2 class="card-title">Vul je profiel aan</h2>
          </div>
          <div class="card-footer">
            <p class="card-text">
              <a
              class="btn btn-lg btn-secondary"
              [routerLink]="'/mijn-gegevens'"
              >
                Profiel aanvullen
              </a>
            </p>
          </div>
        </zdb-card>

        <zdb-card [ngStyle]="{ backgroundImage: 'url(assets/images/cards/home-map.png)' }">
          <div class="card-header">
            <h2 class="card-title">Help mij op weg</h2>
          </div>
          <div class="card-footer ">
            <p class="card-text">
              <a
                class="btn btn-lg btn-secondary"
                href="https://zodichtbij.nl/help-mij-op-weg/"
              >
              Hulp nodig?
              </a>
            </p>
          </div>
        </zdb-card>
      </div>
    </div>
  </div>
</zdb-main-layout>
