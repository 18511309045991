export const supportedDataServiceIds = [
  /** 2019.1 certified */
  '26',
  '28',
  // '30',
  // '31',
  // '35',
  // '36',
  // '37',
  // '43',
  // '45',

  /** 2020.1 certified */
  '48',
  '49',
  // PDF A
  'unknown-3',
  // '50',
  // '61',

  /** Questionnaire pilot */
  // '100000043',
  // '100000045',

  /** 2019.1 not certified */
  // '32', '33', '41',

  /** 2020.1 not certified */
  '51',

  // Test id for BgLZ service
  'bglz2020',

  // Test id for ggz service
  'ggz2020',
];
