import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FetchListType } from '@medrecord/managers-my-doctor';

@Component({
  selector: 'medrecord-list-modal',
  templateUrl: './list-modal.component.html',
  styleUrls: ['./list-modal.component.scss']
})
export class ListModalComponent {
  @Input() type: FetchListType;
  @Input() status: string;
  @Input() isOpen = false;
  @Output() dialogClosed = new EventEmitter();
}
