import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const nutritionAdviceBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.nutritionOrder,
  fieldGroups: [
    {
      name: ({ resource }) => resource?.content?.dietType || 'my_doctor_advice',
      fields: [
        {
          label: 'my_doctor_food_consistency',
          fieldName: 'foodConsistency',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
      ],
    },
  ],
});
