import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InputComponent} from './components/input/input.component';
import { MedrecordInputComponent } from './components/medrecord-input/medrecord-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SliderModule } from '@syncfusion/ej2-angular-inputs';
import { MedrecordIconsModule } from '@medrecord/icons';
import { MedrecordSelectComponent } from './components/medrecord-select/medrecord-select.component';
import { MedrecordLabelComponent } from './components/medrecord-label/medrecord-label.component';
import { MedrecordBricksComponent } from './components/medrecord-bricks/medrecord-bricks.component';
import { MedrecordCheckboxComponent } from './components/medrecord-checkbox/medrecord-checkbox.component';
import { MedrecordHintComponent } from './components/medrecord-hint/medrecord-hint.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MedrecordInputNgControlComponentBase } from './base/medrecord-input-ng-control-component-base.component';
import { MedrecordRepeatableFormComponent } from './components/medrecord-repeatable-form/medrecord-repeatable-form.component';
import { MedrecordDatepickerComponent } from './components/medrecord-datepicker/medrecord-datepicker.component';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { MedrecordAvatarUploadComponent } from './components/medrecord-avatar-upload/medrecord-avatar-upload.component';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import {NumericInputComponent} from './components/numeric-input/numeric-input.component';
import { SliderInputComponent } from './components/slider-input/slider-input.component';
import {RadioComponent} from './components/radio/radio.component';
import {ValidationService} from './services/validation.service';

const exportedComponents = [
  MedrecordInputComponent,
  MedrecordSelectComponent,
  MedrecordLabelComponent,
  MedrecordBricksComponent,
  MedrecordCheckboxComponent,
  MedrecordHintComponent,
  MedrecordRepeatableFormComponent,
  MedrecordInputNgControlComponentBase,
  MedrecordDatepickerComponent,
  MedrecordAvatarUploadComponent,
  RadioComponent,
  InputComponent,
  NumericInputComponent,
  SliderInputComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MedrecordIconsModule,
    TooltipModule.forRoot(),
    CalendarModule,
    AlifeFileToBase64Module,
    SliderModule
  ],
  declarations: [
    ...exportedComponents,
  ],
  exports: [
    ...exportedComponents
  ],
  providers: [ValidationService],
})
export class MedrecordFormsModule {
  static forRoot(): ModuleWithProviders<MedrecordFormsModule> {
    return {
      ngModule: MedrecordFormsModule,
      providers: [ValidationService],
    };
  }
}
