<form *ngIf="form"
      [formGroup]="form">
  <medrecord-datepicker
    [label]="'common_answer' | translate"
    [placeholder]="'common_select_date' | translate"
    [calendarViewDepth]="'Month'"
    [startCalendarView]="'Decade'"
    formControlName="answer"
  ></medrecord-datepicker>

</form>
