import {
  Algorithm,
  CategoryKey,
  CAP,
  Scale,
} from '@app/selfcheck/questionnaire-outomes/questionnnaire-outomes.enum';

export type Category =
  | CategoryKey.fysical
  | CategoryKey.cognitive
  | CategoryKey.mental_social
  | CategoryKey.self_determination
  | CategoryKey.stability;

const stoplightChartCodes = [
  CAP.cADD,
  CAP.cADL,
  CAP.cCARDIO,
  CAP.cCOGNIT,
  CAP.cCOMMUN,
  CAP.cDEHYD,
  CAP.cFALLS,
  CAP.cMOOD,
  CAP.cPACTIV,
  CAP.cPAIN,
  CAP.cSOCFUNC,
  CAP.cIADL,
  CAP.cURIN,
  Algorithm.aSRI,
] as const;
type StoplightChartCodes = typeof stoplightChartCodes[number];

export const isStoplightChartCode = (
  userInput: string
): userInput is StoplightChartCodes => {
  return (stoplightChartCodes as readonly string[]).includes(userInput);
};

const guageChartCodes = [
  Scale.sADLH,
  Scale.sDIVERT,
  Scale.sCHESS,
  Scale.sCOMM,
  Scale.sCPS,
  Scale.sCPS2,
  Scale.sIADLCH,
  Scale.siADLSF,
  Scale.sPAIN,
  Scale.sSRMOOD,
  Scale.sVPR,
  Algorithm.xFALLS,
] as const;
type GuageChartCodes = typeof stoplightChartCodes[number];

export const isGuageChartCode = (
  userInput: string
): userInput is GuageChartCodes => {
  return (guageChartCodes as readonly string[]).includes(userInput);
};

const defaultDisplayCodes = [Algorithm.aAXURG] as const;

type DefaultDisplayCodes = typeof stoplightChartCodes[number];
export const isDefaultDisplayCode = (
  userInput: string
): userInput is DefaultDisplayCodes => {
  return (defaultDisplayCodes as readonly string[]).includes(userInput);
};

const allCodes = [...stoplightChartCodes, ...guageChartCodes, ...defaultDisplayCodes];
export type Codes = typeof allCodes[number];

interface CodeGroup {
  entries: Array<Codes>;
}

type FilterKeys<T extends object, P extends unknown> = {
  [K in keyof T]: T[K] extends P ? K : never;
}[keyof T];

export type GroupKeys = FilterKeys<OutcomeItem, string>;
export type CategoryGroup = Record<Category, CodeGroup>;

export interface OutcomeItem {
  code: string;
  codeDescription: string;
  value: number;
  valueDescription: string;
  displayType: string;
}

export interface Score {
  name: string;
  scale: Array<string>;
}

export interface GroupedOutcome {
  label: string;
  items: OutcomeItem[];
}
