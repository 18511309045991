<ejs-dialog
  #dialogComponent
  [visible]="isOpen"
  [isModal]="true"
  (overlayClick)="dialogClosed.emit()"
  width="40%"
>
  <ng-template #header>
    <span>{{ transaction?.id | translate }}</span>
  </ng-template>

  <ng-template #content>
    <div class="row mx-0">
      <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="formSubmit()">
          <div class="row">
            <div class="col-12">
              <medrecord-input
                id="token"
                formControlName="token"
                [label]="'Token' | translate"
                [placeholder]="'Token' | translate"
              ></medrecord-input>
            </div>
            <div class="col-12">
              <medrecord-input
                id="userKey"
                formControlName="userKey"
                [label]="'User key' | translate"
                [placeholder]="'User key' | translate"
              ></medrecord-input>
            </div>
            <div class="col-12">
              <medrecord-input
                id="careProviderUrl"
                formControlName="careProviderUrl"
                [label]="'Careprovider URL' | translate"
                [placeholder]="'Careprovider URL' | translate"
              ></medrecord-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex align-items-center justify-content-end">
              <button
                (click)="dialogClosed.emit()"
                type="button"
                class="btn btn-outline-black mr-3"
              >
                {{ "common_cancel" | translate }}
              </button>
              <button class="btn btn-secondary">
                {{ "common_form_submit" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</ejs-dialog>

<doctor-gui-parameters-modal
  *ngIf="parametersModalVisible"
  [isOpen]="parametersModalVisible"
  [dataServiceId]="dataServiceId"
  [transaction]="transaction"
  (close)="parametersModalVisible = false"
  (submit)="submit($event)"
>
</doctor-gui-parameters-modal>
