import { Component, Inject } from '@angular/core';
import { logout, selectAccessToken } from '@medrecord/managers-auth';
import { Store } from '@ngrx/store';

import { selectRoles } from '@medrecord/managers-auth';
import { NavigationService } from 'src/app/core/navigation/navigation.service';
import { Role } from '@medrecord/managers-users';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/auth/auth.service';
import { APP_ENVIRONMENT } from '@medrecord/core';
import { ZDBEnvironment } from '@app/models/zdb-environment.interface';

@Component({
  selector: 'zdb-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
})
export class TopnavComponent {
  isNavbarCollapsed = true;

  roles$: Observable<Role[]> = this.store.select(selectRoles);
  authToken$: Observable<string> = this.store.select(selectAccessToken);

  constructor(
    private navigationService: NavigationService,
    private store: Store,
    private authService: AuthService,
    @Inject(APP_ENVIRONMENT) private environment: ZDBEnvironment
  ) {}

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  toggleSideNav(): void {
    this.navigationService.toggleSideNav();
  }

  goToAdmin(): void {
    this.authToken$.subscribe((bearerToken) =>
      // Generate temp token
      this.authService.getTempToken(bearerToken).subscribe((val: any) => {
        // If token exists, redirect to admin panel
        if (val.token) {
          window.location.href =
            `${this.environment.adminPanelUrl}?temp_token=${val.token}`.trim() +
            `&lang=${this.environment.defaultLanguage}&referral_url=${window.location.href}`.trim();
        }
      })
    );
  }

  logout() {
    this.store.dispatch(logout());
  }
}
