import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const concernBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.condition,
  fieldGroups: [
    {
      name: ({ resource }) => resource.content?.name?.coding?.[0]?.display || 'my_doctor_concern',
      fields: [
        {
          label: 'my_doctor_anatomical_location',
          fieldName: 'anatomicalLocation',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_laterality',
          fieldName: 'laterality',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_type',
          fieldName: 'type',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_name',
          fieldName: 'name',
          prepareData: (data) => [data?.coding?.[0]?.code, data?.coding?.[0]?.display].filter(Boolean).join(' - '),
        },
        {
          label: 'my_doctor_start_date',
          fieldName: 'problemPeriod',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_end_date',
          fieldName: 'problemPeriod',
          prepareData: (data) => (data?.end ? MedrecordMoment(data?.end).format('LL') : ''),
        },
        {
          label: 'my_doctor_status',
          fieldName: 'status',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_verification_status',
          fieldName: 'verificationStatus',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
      ],
    },
  ],
});
