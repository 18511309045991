<nav class="zdb-topnav navbar navbar-expand navbar-light zdb-shadow d-flex justify-content-between">
  <a class="navbar-brand" routerLink="/"
  >
    <img
      src="assets/images/logo.svg"
      width="60px"
      height="40px"
      alt="Zo-Dichtbij logo"
      class="d-inline-block align-center form-inline"
      loading="lazy"
    />
    <div class="d-none d-sm-flex flex-column">
      <strong>Zo-Dichtbij</strong>
      <span>Oost west, thuis best</span>
    </div>
  </a>

  <div class="d-flex">
    <!-- Navbar-->
    <ul class="navbar-nav ml-auto ml-md-0 p-1">
      <li class="nav-item dropdown dropdown-user no-caret" ngbDropdown placement="bottom-right" display="dynamic">
        <a class="nav-link dropdown-toggle" id="userDropdown" ngbDropdownToggle data-cy="userMenu" role="button" aria-haspopup="true" aria-expanded="false"><fa-icon [icon]='["fas", "user"]'></fa-icon></a>
        <div class="dropdown-menu dropdown-menu-right p-1" ngbDropdownMenu aria-labelledby="dropdownUser">
            <a type="button" *ngIf="(roles$ | async)?.includes('ADMIN')" class="dropdown-item" (click)="goToAdmin()" >Naar Administratie</a>
            <a class="dropdown-item" routerLink="/mijn-profiel">Mijn profiel</a>
            <button type="button" class="dropdown-item" (click)="logout()">{{ 'profile_logout' | translate }}</button>
        </div>
      </li>
    </ul>
    <button
      class="btn btn-link btn-lg rounded-circle"
      id="sidebarToggle"
      data-cy="topNavToggleSideNav"
      (click)="toggleSideNav()"
    >
      <fa-icon [icon]="['fas', 'bars']"></fa-icon></button
    >
  </div>
</nav>
