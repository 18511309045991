import { createAction, props } from '@ngrx/store';
import { IZDBUser } from '@app/core/user/user.model';

export const loadUserData = createAction('[Current user data] Load current user data');

export const loadUserDataSuccess = createAction(
  '[Current user data] Load current user data Success',
  props<{
    current_user: IZDBUser;
    patient: IZDBUser
  }>()
);

export const loadUserDataFailure = createAction(
  '[Current user data] Load current user data Failure',
  props<{ error: any }>()
);

export const loadUserDataNoProfile = createAction('[Current user data] User has no profile');

export const loadUserDataNoUser = createAction('[Current user data] No user logged in');

export const emptyUserData = createAction(
  '[Current user data] Empty current user data'
);
