import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zdb-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './card.component.html',
  styleUrls: ['card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() background!: string;
  @Input() backgroundImage!: string;
  @Input() color!: string;

  customClasses: string[] = [];
  customStyles: string[] = [];

  ngOnInit(): void {
    if (this.background) {
      this.customClasses.push(this.background);
    }
    if (this.backgroundImage) {
      this.customStyles.push(this.backgroundImage);
    }
    if (this.color) {
      this.customClasses.push(this.color);
    }
  }
}
