import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { loadQuestionnaire } from '@app/medrecord/questionnaire-adapter/state-management/actions/questionnaire.actions';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class QuestionnaireResolver implements Resolve<void> {
  constructor(private store: Store) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return this.store.dispatch(loadQuestionnaire({
      userId: route.params?.userId,
      taskId: route.params?.taskId
    }));
  }
}
