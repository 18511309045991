import { Component, Input, ViewChild } from '@angular/core';
import { MedrecordInputNgControlComponentBase } from '../../base/medrecord-input-ng-control-component-base.component';
import { MomentLocales } from '@medrecord/services-datetime';
import { MedrecordMoment } from '@medrecord/services-datetime';

export declare type CalendarView = 'Month' | 'Year' | 'Decade';

@Component({
  selector: 'medrecord-datepicker',
  templateUrl: './medrecord-datepicker.component.html',
  styleUrls: ['./medrecord-datepicker.component.scss']
})
export class MedrecordDatepickerComponent extends MedrecordInputNgControlComponentBase {

  @Input() dateFormat: MomentLocales = 'LL';
  @Input() firstDayOfWeek = 1;
  @Input() startCalendarView: CalendarView = 'Month';
  @Input() calendarViewDepth: CalendarView = 'Month';
  @Input() minDate: Date;
  @Input() maxDate: Date;

  @ViewChild('inputEl') inputEl;

  isCalendarVisible = false;

  toggleCalendar(): void {
    if (!this.control.disabled) {
      this.isCalendarVisible = !this.isCalendarVisible;
    }
  }

  showCalendar(event: Event): void {
    if (!this.control.disabled && (event?.target === this.inputEl?.input?.valueAccessor?._elementRef?.nativeElement)) {
      this.isCalendarVisible = true;
    }
  }

  hideCalendar(): void {
    if (!this.control.disabled) {
      this.isCalendarVisible = false;
    }
  }

  clearValue(): void {
    if (!this.control.disabled) {
      this.value = '';
      this.hideCalendar();
    }
  }

  handleDateSelect({ value: date }: any): void {
    this.value = MedrecordMoment(date).format(this.dateFormat);

    this.hideCalendar();
  }

}
