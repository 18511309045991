import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const familySituationBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.observation,
  fieldGroups: [
    {
      name: 'my_doctor_family_situation',
      fields: [
        {
          label: 'healthcare_providers_resource_maritalStatus',
          fieldName: 'id',
          translate: true,
          prepareData: (_data, allItems) => {
            const item = allItems.find(({ resourceType }) => resourceType === ResourceType.patient);
            return item?.content?.maritalStatus
              ? `my_doctor_marital_status_${item?.content?.maritalStatus.toLowerCase()}`
              : '';
          },
        },
        {
          label: 'my_doctor_family_composition',
          fieldName: 'familyComposition',
          prepareData: (data) => data?.text || data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_number_of_children',
          fieldName: 'numberOfChildren',
          prepareData: (data) => data?.value,
        },
        {
          label: 'my_doctor_number_of_children_at_home',
          fieldName: 'numberOfChildrenLivingAtHome',
          prepareData: (data) => data?.value,
        },
      ],
    },
  ],
});
