import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';
import {
  calculateValue,
  getFractionalPartOfNumberAsString,
  getFractionValue,
  getIntegerPartOfNumberAsString,
  getIntegerValue,
  getMaxIntegerValue,
  getMinIntegerValue,
  splitNumberToIntegerAndFractionStr,
  sumFloatNumbers
} from './numeric-input.component.functions';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';

const defaultIntegerInputWidth = 82;
const defaultFractionInputWidth = 133;

@Component({
  selector: 'app-numeric-input', // tslint:disable-line
  templateUrl: './numeric-input.component.html',
  styleUrls: ['./numeric-input.component.scss'],
})
export class NumericInputComponent implements ControlValueAccessor, OnChanges, AfterViewInit, OnDestroy {

  // Text options
  @Input() label: string;
  @Input() labelDescription: string;
  @Input() hint: string;
  @Input() unitsOfMeasure: string;
  @Input() comma = ',';

  // Behavior options
  @Input() showFractions: boolean;
  @Input() set fractionDigits(fractionDigits: number) {
    this.defaultFractionDigitsNumber = fractionDigits;
    this.fractionNumberStep = this.getDefaultFractionNumberStep();
    this.maxFractionValue = parseFloat('0.'.padEnd(this.defaultFractionDigitsNumber + 2, '9'));
    this.sum = sumFloatNumbers.bind(null, this.defaultFractionDigitsNumber);
    this.calcValue = calculateValue.bind(null, this.defaultFractionDigitsNumber);
  }

  @Input() minValue: number;
  @Input() maxValue: number;

  // State options
  @Input() disabled: boolean;
  @Input() externalValidationErrors: ValidationErrors | null;

  // Styling options
  @Input() integerInputWidth: number;
  @Input() fractionInputWidth: number;

  @ViewChild('integerInput') integerInput: ElementRef;
  @ViewChild('fractionInput') fractionInput: ElementRef;

  defaultFractionDigitsNumber = 2;
  integerNumberStep = 1;
  fractionNumberStep = this.getDefaultFractionNumberStep();

  @Input() minFractionValue = 0;
  @Input() maxFractionValue = parseFloat('0.'.padEnd(this.defaultFractionDigitsNumber + 2, '9'));

  sum = sumFloatNumbers.bind(null, this.defaultFractionDigitsNumber);
  calcValue = calculateValue.bind(null, this.defaultFractionDigitsNumber);

  customPatterns = {
    0: { pattern: new RegExp('-?') },
    9: { pattern: new RegExp('[0-9]') }
  };

  inputsInitialized$ = new BehaviorSubject<boolean>(false);

  integerValue: string;
  fractionValue: string;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _value;

  get value() {
    return this._value;
  }

  @Input()
  set value(value) {
    this._value = value;
    this.onChange(this._value);
  }

  get inputId() {
    return this.ngControl ? this.ngControl.name : null;
  }

  get hasUnitsOfMeasure(): boolean {
    return this.unitsOfMeasure !== null && this.unitsOfMeasure !== undefined && this.unitsOfMeasure.length > 0;
  }

  get minIntegerValue(): number {
    return getMinIntegerValue(this.minValue, this.hasFractions && this.minValue < 0);
  }

  get maxIntegerValue(): number {
    return getMaxIntegerValue(this.maxValue, this.hasFractions && this.maxValue > 0);
  }

  get errors(): ValidationErrors | null {
    return this.ngControl ? { ...this.ngControl.errors, ...this.externalValidationErrors } : null;
  }

  get hasFractions(): boolean {
    return this.showFractions && getFractionValue(this.fractionValue) !== 0;
  }

  get errorMessageWidth(): number {
    const intLength = this.integerInputWidth || defaultIntegerInputWidth;
    const fracLength = this.showFractions ? this.fractionInputWidth || defaultFractionInputWidth : 0;

    return intLength + fracLength;
  }

  // tslint:disable:no-empty
  onChange: any = (): any => {
  }

  onTouched: any = (): any => {
  }

  ngOnChanges(): void {
    if (!this.showFractions) {
      this.fractionValue = null;
    }

    this.value = this.calcValue(this.integerValue, this.fractionValue);
  }

  ngAfterViewInit(): void {
    if (this.integerInput) {
      this.inputsInitialized$.next(true);
    }
  }

  ngOnDestroy(): void {
    this.inputsInitialized$.complete();
  }

  incrementIntegerInput(event: Event): void {
    event.preventDefault();
    if (!this.integerInput) {
      return;
    }

    this.updateIntegerValue(
      this.sum(getIntegerValue(this.integerValue), this.integerNumberStep)
    );
  }

  decrementIntegerInput(event: Event): void {
    event.preventDefault();
    if (!this.integerInput) {
      return;
    }

    this.updateIntegerValue(
      this.sum(getIntegerValue(this.integerValue), -this.integerNumberStep)
    );
  }

  incrementFractionInput(event: Event): void {
    event.preventDefault();
    if (!this.fractionInput) {
      return;
    }

    this.updateFractionValue(
      this.sum(getFractionValue(this.fractionValue), this.fractionNumberStep)
    );
  }

  decrementFractionInput(event: Event): void {
    event.preventDefault();
    if (!this.fractionInput) {
      return;
    }

    this.updateFractionValue(
      this.sum(getFractionValue(this.fractionValue), -this.fractionNumberStep)
    );
  }

  onIntegerInput(value: string): void {
    this.onTouched();
    this.integerValue = value;
    const tempvalue = this.calcValue(this.integerValue, this.fractionValue);
    if (!isNaN(tempvalue)) { this.value = tempvalue; }
    if (this.minValue !== undefined) {
      if (this.value < this.minValue) { 
        this.value = this.minValue; 
        this.updateIntegerValueStr(getIntegerPartOfNumberAsString(this.value)); 
      } 
    }
  }

  onFractionInput(strValue: string): void {
    this.onTouched();

    this.fractionValue = strValue;

    this.value = this.calcValue(this.integerValue, this.fractionValue);
  }

  updateIntegerValue(value: number): void {
    if (!this.integerInput) {
      return;
    }

    let preparedValue = value;
    const calculatedValue = this.calcValue(value, this.fractionValue);

    if (calculatedValue < this.minValue) {
      preparedValue = this.minIntegerValue;
    }

    if (calculatedValue > this.maxValue) {
      preparedValue = this.maxIntegerValue;
    }

    const strValue = getIntegerPartOfNumberAsString(preparedValue);

    this.integerInput.nativeElement.value = strValue;
    this.onIntegerInput(strValue);
  }

  updateFractionValue(value: number): void {
    if (!this.fractionInput) {
      return;
    }

    let preparedValue = value;

    if (value < this.minFractionValue) {
      preparedValue = this.minFractionValue;
    }

    if (value > this.maxFractionValue) {
      preparedValue = this.maxFractionValue;
    }

    const strValue = getFractionalPartOfNumberAsString(preparedValue);

    this.fractionInput.nativeElement.value = strValue.padEnd(this.defaultFractionDigitsNumber, '0');
    this.onFractionInput(strValue);
  }

  updateIntegerValueStr(value: string): void {
    if (this.integerInput) {
      this.integerInput.nativeElement.value = value ? value : '';
    }
  }

  updateFractionValueStr(value: string): void {
    if (this.fractionInput) {
      this.fractionInput.nativeElement.value = value ? value.padEnd(this.defaultFractionDigitsNumber, '0') : '';
    }
  }

  private getDefaultFractionNumberStep() {
    return Math.pow(10, -this.defaultFractionDigitsNumber);
  }

  // Implementing ControlValueAccessor

  writeValue(value: number): void {
    const { integer, fraction } = splitNumberToIntegerAndFractionStr(value);

    this.integerValue = integer;
    this.fractionValue = fraction;

    this.value = value;

    this.inputsInitialized$.pipe(
      filter(initialized => initialized),
      first()
    ).subscribe(() => {
      this.updateIntegerValueStr(this.integerValue);
      this.updateFractionValueStr(this.fractionValue);
    });
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled) {
    this.disabled = disabled;
  }

}
