<form *ngIf="form"
      [formGroup]="form">

  <app-numeric-input
    [fractionDigits]="question.options?.maxDecimalPlaces"
    [fractionInputWidth]="fractionInputWidth"
    [integerInputWidth]="80"
    [label]="'common_answer' | translate"
    [maxValue]="question.options?.maxValue"
    [minValue]="question.options?.minValue"
    [showFractions]="true"
    [unitsOfMeasure]="question.unitOfMeasure"
    [formControl]="form.controls['answer']"
  ></app-numeric-input>

</form>
