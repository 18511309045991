import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const textResultBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.diagnosticReport,
  fieldGroups: [
    {
      name: 'my_doctor_text_result',
      fields: [
        {
          label: 'my_doctor_text_result_date',
          fieldName: 'dateTime',
          prepareData: (data) => (data?.date ? MedrecordMoment(data.date).format('LL') : ''),
        },
        {
          label: 'my_doctor_outcome',
          fieldName: 'report',
          prepareData: (data) => (data ? data.split('         ').join('<br><br>') : ''),
        },
        {
          label: 'my_doctor_status',
          fieldName: 'status',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_type',
          fieldName: 'code',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
      ],
    },
  ],
});
