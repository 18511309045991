import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QuestionTypeBaseComponent } from '../../../base/question-type-base.component';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'medrecord-string-question',
  templateUrl: './string-question.component.html',
  styleUrls: ['./string-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StringQuestionComponent extends QuestionTypeBaseComponent {

  constructor(protected fb: FormBuilder) {
    super(fb);
  }

}

