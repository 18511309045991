<ng-container *ngIf="{
  user: user$ | async,
  task: questionnaireTask$ | async
} as state">
  <div class="zdb-card bg-white mb-4 zdb-shadow" *ngIf="state.user">
    <div class="card-header">
      <div class="float-lg-right mb-2">
        <button (click)="goBack()" class="btn btn-lg btn-secondary">
          {{ 'common_back' | translate }}
        </button>
      </div>
      <div class="float-lg-right mr-2 mb-2">
        <button *ngIf="infoUrl" (click)="showInfo()" class="btn btn-lg btn-secondary">
          {{ 'common_explanation' | translate }}
        </button>
      </div>
      <zdb-print *ngIf="showPdf" printRoot="printRoot" [title]="title + ' ' + state.user.name.text + ', ' + formatDate(state.task.completed, 'dd-MM-yyyy')"></zdb-print>
      <h2 class="card-title" *ngIf="state.user">{{ title }} <span class="badge badge-primary">{{ state.user.name.text }}</span></h2>
      <h3 class="card-title" *ngIf="state.task">{{ state.task.completed | date: "dd-MM-yyyy" }}</h3>
    </div>
  </div>
</ng-container>
