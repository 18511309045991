export enum Resource {
  patient = 'patient',
  medication = 'medication',
  bloodPressure = 'blood-pressure',
  bodyHeight = 'body-height',
  bodyTemperature = 'body-temperature',
  livingSituation = 'living-situation',
  payer = 'payer',
  drugUse = 'drug-use',
  alcoholUse = 'alcohol-use',
  tobaccoUse = 'tobacco-use',
  contactPerson = 'contact-person',
  nutritionAdvice = 'nutrition-advice',
  treatmentDirective = 'treatment-directive',
  advanceDirective = 'advance-directive',
  functionalOrMentalStatus = 'func-or-mental-status',
  alert = 'alert',
  allergyIntolerance = 'allergy-intolerance',
  medicationUse = 'medication-use',
  medicationAgreement = 'medication-agreement',
  administrationAgreement = 'administration-agreement',
  vaccination = 'vaccination',
  laboratoryTestResult = 'laboratory-test-result',
  procedure = 'procedure',
  encounter = 'encounter',
  plannedCareActivityProcedureRequest = 'planned-care-activity-procedure-request',
  plannedCareActivityImmunizationRecommendation = 'planned-care-activity-immunization-recommendation',
  plannedCareActivityAppointment = 'planned-care-activity-appointment',
  plannedCareActivityDeviceRequest = 'planned-care-activity-device-request',
  concern = 'concern',
  healthProfessional = 'health-professional',
  medicalAid = 'medical-aid',
  bodyWeight = 'body-weight',

  bgzPatient2020 = 'bgz-patient-2020',
  bgzPayer2020 = 'bgz-payer-2020',
  bgzTreatmentDirective2020 = 'bgz-treatment-directive-2020',
  bgzAdvanceDirective2020 = 'bgz-advance-directive-2020',
  bgzContactPerson2020 = 'bgz-contact-person-2020',
  bgzFunctionalOrMentalStatus2020 = 'bgz-func-or-mental-status-2020',
  bgzConcern2020 = 'bgz-concern-2020',
  bgzLivingSituation2020 = 'bgz-living-situation-2020',
  bgzDrugUse2020 = 'bgz-drug-use-2020',
  bgzAlcoholUse2020 = 'bgz-alcohol-use-2020',
  bgzTobaccoUse2020 = 'bgz-tobacco-use-2020',
  bgzNutritionAdvice2020 = 'bgz-nutrition-advice-2020',
  bgzAlert2020 = 'bgz-alert-2020',
  bgzAllergyIntolerance2020 = 'bgz-allergy-intolerance-2020',
  bgzMedicationUse2020 = 'bgz-medication-use-2020',
  bgzMedicationAgreement2020 = 'bgz-medication-agreement-2020',
  bgzAdministrationAgreement2020 = 'bgz-administration-agreement-2020',
  bgzMedicalAid2020 = 'bgz-medical-aid-2020',
  bgzVaccination2020 = 'bgz-vaccination-2020',
  bgzBloodPressure2020 = 'bgz-blood-pressure-2020',
  bgzBodyWeight2020 = 'bgz-body-weight-2020',
  bgzBodyHeight2020 = 'bgz-body-height-2020',
  bgzLaboratoryTestResult2020 = 'bgz-laboratory-test-result-2020',
  bgzProcedure2020 = 'bgz-procedure-2020',
  bgzEncounter2020 = 'bgz-encounter-2020',
  bgzPlannedCareActivityProcedureRequest2020 = 'bgz-planned-care-activity-procedure-request-2020',
  bgzPlannedCareActivityImmunizationRecommendation2020 = 'bgz-planned-care-activity-immunization-recommendation-2020',
  bgzPlannedCareActivityAppointment2020 = 'bgz-planned-care-activity-appointment-2020',
  bgzPlannedCareActivityDeviceRequest2020 = 'bgz-planned-care-activity-device-request-2020',
  bgzHealthProfessional2020 = 'bgz-health-professional-2020',

  gpPractitioner = 'gp-practitioner',
  gpPatient = 'gp-patient',
  gpEpisode = 'gp-episode',
  gpAlert = 'gp-alert',
  gpCurrentMedication = 'gp-current-medication',
  gpMedicationIntolerance = 'gp-medication-intolerance',
  gpLaboratoryResults = 'gp-laboratory-results',
  gpSoap = 'gp-soap',

  gpPractitioner2020 = 'gp-practitioner-2020',
  gpPatient2020 = 'gp-patient-2020',
  gpEpisode2020 = 'gp-episode-2020',
  gpAlert2020 = 'gp-alert-2020',
  gpCurrentMedication2020 = 'gp-current-medication-2020',
  gpMedicationIntolerance2020 = 'gp-medication-intolerance-2020',
  gpLaboratoryResults2020 = 'gp-laboratory-results-2020',
  gpSoap2020 = 'gp-soap-2020',
  gpEncounter2020 = 'gp-encounter-2020',

  // PDF-A
  pdfBinaryId = 'pdf-pull-binary-id-2020',

  // PDF-A Metadata
  pdfMetadataDocref = 'pdf-pull-metadata-docref-2020',
  pdfMetadataManifest = 'pdf-pull-metadata-manifest-2020',
  pdfDocrefId = 'pdf-pull-metadata-docref-id-2020',

  // BgLZ
  bglzPatient2020 = 'bglz-patient-2020',
  bglzTreatmentDirective2020 = 'bglz-treatment-directive-2020',
  bglzContactPerson2020 = 'bglz-contact-person-2020',
  bglzProblem2020 = 'bglz-problem-2020',
  bglzAllergyIntolerance2020 = 'bglz-allergy-intolerance-2020',
  bglzLaboratoryTestResult2020 = 'bglz-laboratory-test-result-2020',
  bglzAdvanceDirective2020 = 'bglz-advance-directive-2020',
  bglzProcedure2020 = 'bglz-procedure-2020',
  bglzGoalsAndInterventions2020 = 'bglz-goals-and-interventions-2020',
  bglzCaresettingCareteam2020 = 'bglz-caresetting-careteam-2020',

  // BgGGZ
  ggzPatient2020 = 'ggz-patient-2020',
  ggzPayer2020 = 'ggz-payer-2020',
  ggzTreatmentDirective2020 = 'ggz-treatment-directive-2020',
  ggzAdvanceDirective2020 = 'ggz-advance-directive-2020',
  ggzLegalStatus2020 = 'ggz-legal-status-2020',
  ggzContactPerson2020 = 'ggz-contact-person-2020',
  ggzFuncOrMentalStatus2020 = 'ggz-func-or-mental-status-2020',
  ggzProblem2020 = 'ggz-problem-2020',
  ggzDrugUse2020 = 'ggz-drug-use-2020',
  ggzAlcoholUse2020 = 'ggz-alcohol-use-2020',
  ggzTobaccoUse2020 = 'ggz-tobacco-use-2020',
  ggzLivingSituation2020 = 'ggz-living-situation-2020',
  ggzFamilySituation2020 = 'ggz-family-situation-2020',
  ggzLanguageProficiency2020 = 'ggz-language-proficiency-2020',
  ggzParticipationInSociety2020 = 'ggz-participation-in-society-2020',
  ggzHelpFromOthers2020 = 'ggz-help-from-others-2020',
  ggzLaboratoryTestResult2020 = 'ggz-laboratory-test-result-2020',
  ggzGeneralMeasurement2020 = 'ggz-general-measurement-2020',
  ggzProcedure2020 = 'ggz-procedure-2020',
  ggzTextResult2020 = 'ggz-text-result-2020',
  ggzCareTeam2020 = 'ggz-care-team-2020',
}
