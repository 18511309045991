import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Careprovider,
  CareproviderLoadPayload,
  getCareproviders,
  getCareprovidersCount,
  loadCareprovidersAction,
} from '@medrecord/managers-my-doctor';
import { Store } from '@ngrx/store';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctor-gui-medsafe-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  careproviders$: Observable<Careprovider[]>;
  count$: Observable<number>;

  pageSettings: PageSettingsModel = { pageSize: 5, currentPage: 1 };
  // permission$: Observable<Permission> = this.store.select(getSelectedUserPermission);
  searchValue = '';
  searchTimeout: ReturnType<typeof setTimeout> = null;
  infoDialogOpen = false;
  portabilityModalOpen = false;
  selectedCareprovider: Careprovider;

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.loadCareproviders();
  }

  private loadCareproviders(): void {
    this.careproviders$ = this.store.select(getCareproviders);
    this.count$ = this.store.select(getCareprovidersCount);

    this.count$.subscribe((count) => {
      if (count) {
        this.pageSettings.pageCount = Math.ceil(count / this.pageSettings.pageSize);
        this.pageSettings.totalRecordsCount = count;
      }
    });

    const payload: CareproviderLoadPayload = {
      count: this.pageSettings.pageSize,
      startPage: this.pageSettings.currentPage - 1,
      search: this.searchValue,
    };
    this.store.dispatch(loadCareprovidersAction({ payload }));
  }

  updateSettingsModel({ settingsModel }): void {
    this.pageSettings = settingsModel;

    const payload: CareproviderLoadPayload = {
      count: this.pageSettings.pageSize,
      startPage: this.pageSettings.currentPage - 1,
      search: this.searchValue,
    };
    this.store.dispatch(loadCareprovidersAction({ payload }));
  }

  onSearch(): void {
    this.pageSettings.currentPage = 1;
    const payload: CareproviderLoadPayload = {
      count: this.pageSettings.pageSize,
      startPage: this.pageSettings.currentPage - 1,
      search: this.searchValue,
    };
    this.store.dispatch(loadCareprovidersAction({ payload }));
  }

  onSearchChange(value): void {
    this.searchValue = value;
  }

  onCareProviderSelect(careprovider): void {
    if (careprovider.column) {
      delete careprovider.column;
    }
    if (careprovider.index) {
      delete careprovider.index;
    }

    this.router.navigate(['..', 'careprovider', careprovider.id], { relativeTo: this.activatedRoute });
  }

  getDisplayName(careprovider: Careprovider): string {
    return careprovider?.displayName
      ? careprovider?.displayName
      : careprovider?.id
      ? careprovider?.id
      : careprovider?.email;
  }
  getEmail(careprovider: Careprovider): string {
    return careprovider?.email?.length ? careprovider?.email : careprovider?.id;
  }

  getPhone(careprovider: Careprovider): string {
    return careprovider?.phone?.length ? careprovider?.phone : null;
  }

  getAgb(careprovider: Careprovider): string {
    return careprovider?.agb?.length ? careprovider?.agb : null;
  }

  getSpeciality(careprovider: Careprovider): string {
    return careprovider?.speciality?.length ? careprovider?.speciality : null;
  }

  getAddress(careprovider: Careprovider, index: number = 0): string {
    if (!careprovider?.addresses?.length) {
      return null;
    }
    let address = careprovider.addresses[index]?.address
      ?.split('\r\n')
      .map((v) => v.trim())
      .join(', ');
    if (address?.length) {
      return address + (careprovider.addresses[index]?.country ? ', ' + careprovider.addresses[index]?.country : '');
    }

    address = `${careprovider.addresses[index].streetName || ''}${
      careprovider.addresses[index].streetName?.length ? ' ' : ''
    }${careprovider.addresses[index].streetNumber || ''}${
      careprovider.addresses[index].streetName?.length || careprovider.addresses[index].streetNumber?.length ? ', ' : ''
    }${careprovider.addresses[index].postalCode || ''}${careprovider.addresses[index].postalCode?.length ? ' ' : ''}${
      careprovider.addresses[index].city || ''
    }`;

    return address?.length
      ? address + (careprovider.addresses[index]?.country ? ', ' + careprovider.addresses[index]?.country : '')
      : null;
  }
}
