<rg-gauge-chart
  [canvasWidth]="canvasWidth"
  [needleValue]="needleValue"
  [centralLabel]="centralLabel"
  [options]="options"
  [name]="name"
  [nameFont]="20"
  [bottomLabel]="bottomLabel"
  [bottomLabelFont]="14"
></rg-gauge-chart>
