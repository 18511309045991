import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuestionnaireResolver } from '@app/medrecord/questionnaire-adapter/resolvers/questionnaire-resolver.service';
import { NewQuestionnaireResolver } from '@app/medrecord/questionnaire-adapter/resolvers/new-questionnaire-resolver.service';
import { CurrentUserGuard } from '@app/resolvers/current-user.guard';
import { QuestionnairePageComponent } from '../../projects/questionnaires/src/lib/containers/questionnaire-page/questionnaire-page.component';
import { PatientProfileComponent } from './patient-profile/patient-profile.component';
import { CurrentUserProfileComponent } from './current-user-profile/current-user-profile.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';
import { SelfcheckComponent } from './selfcheck/selfcheck.component';
import { zdbAuthRouteNames } from '@zdb/routes';
import { CurrentUserEditComponent } from '@app/current-user-edit/current-user-edit.component';
import { NetworkComponent } from '@app/network/network.component';
import { UserQuestionnairesComponent } from '@app/network/user-questionnaires/user-questionnaires.component';
import { PatientEditComponent } from '@app/patient-edit/patient-edit.component';
import { QuestionnaireComponent } from '@app/questionnaire/questionnaire.component';
import { QuestionnaireTaskScoresComponent } from '@app/selfcheck/questionnaire-task-scores/questionnaire-task-scores.component';
import { ReadonlyQuestionnairePageComponent } from 'projects/questionnaires/src/lib/containers/readonly-questionnaire-page/readonly-questionnaire-page.component';
import { provideAuthRouteNames } from '@medrecord/routes-auth';
import {
  generalRouteNames,
  provideGeneralRouteNames,
} from '@medrecord/routes-general';
import { provideMedsafeRouteNames } from '@medrecord/routes-medsafe';
import { AuthorizedGuard } from '@medrecord/managers-auth';
import { GetProfileGuard } from '@medrecord/managers-users';
import { QuestionnaireHeaderComponent } from './questionnaire/questionnaire-header/questionnaire-header.component';
import { QuestionnairePageType } from './questionnaire/questionnaire.enum';
import { PositiveHealthQuestionnaireResolver } from './medrecord/questionnaire-adapter/resolvers/positive-health-questionnaire-resolver.service';
import { PositiveHealthComponent } from './positive-health/positive-health.component';
import { LandingPageComponent } from './my-doctor/pages/landing-page/landing-page.component';
import { ShellComponent } from './my-doctor/containers/shell/shell.component';
import { MedmijAuthBackComponent } from '@medrecord/my-doctor';

export const ZDB_ROUTES: Routes = [
  {
    path: generalRouteNames.Auth.Entry,
    loadChildren: () =>
      import('@zdb/auth-view').then((m) => m.ZdbAuthViewModule),

    // children: AuthRoutes,
  },
  {
    path: zdbAuthRouteNames.onboarding,
    canActivate: [AuthorizedGuard],
    loadChildren: () =>
      import('@zdb/onboarding').then((m) => m.ZdbOnboardingModule),
  },
  {
    path: 'test-my-doctor',
    canActivate: [AuthorizedGuard],
    loadChildren: () =>
      import('@medrecord/my-doctor').then((m) => m.MyDoctorModule),
  },
  {
    path: 'mijn-doktor/auth-back',
    component: MedmijAuthBackComponent
  },
  {
    path: generalRouteNames.Base,
    canActivate: [AuthorizedGuard],
    children: [
      {
        path: generalRouteNames.Base,
        canActivate: [GetProfileGuard, CurrentUserGuard],
        children: [
          {
            path: generalRouteNames.Base,
            redirectTo: '/home',
            pathMatch: 'full',
          },
          { path: 'home', component: HomeComponent },
          { path: 'help', component: HelpComponent },
          {
            path: 'zelfcheck',
            children: [
              {
                path: '',
                component: SelfcheckComponent,
              },
              {
                path: 'start',
                component: QuestionnaireComponent,
                children: [
                  {
                    path: ``,
                    resolve: [NewQuestionnaireResolver],
                    component: QuestionnairePageComponent,
                  },
                  {
                    path: ``,
                    component: QuestionnaireHeaderComponent,
                    outlet: 'questionnaire-header',
                    data: { type: QuestionnairePageType.new },
                  },
                ],
              },
              {
                path: 'user/:userId/:taskId',
                component: QuestionnaireComponent,
                children: [
                  {
                    path: ``,
                    resolve: [QuestionnaireResolver],
                    component: ReadonlyQuestionnairePageComponent,
                  },
                  {
                    path: ``,
                    component: QuestionnaireHeaderComponent,
                    outlet: 'questionnaire-header',
                    data: { pdf: true, type: QuestionnairePageType.answers },
                  },
                ],
              },
              {
                path: 'user/:userId/:taskId/scores',
                component: QuestionnaireComponent,
                children: [
                  {
                    path: ``,
                    resolve: [QuestionnaireResolver],
                    component: QuestionnaireTaskScoresComponent,
                  },
                  {
                    path: ``,
                    component: QuestionnaireHeaderComponent,
                    outlet: 'questionnaire-header',
                    data: { pdf: true, info: 'zelfcheckResultInfoUrl', type: QuestionnairePageType.scores },
                  },
                ],
              },
              {
                path: 'positieve-gezondheid/:userId',
                component: QuestionnaireComponent,
                children: [
                  {
                    path: ``,
                    resolve: [PositiveHealthQuestionnaireResolver],
                    runGuardsAndResolvers: 'always',
                    component: QuestionnairePageComponent,
                    data: { isPositiveHealth: true },
                  },
                  {
                    path: ``,
                    component: QuestionnaireHeaderComponent,
                    outlet: 'questionnaire-header',
                    data: { type: QuestionnairePageType.pgNew },
                  },
                ],
              },
              {
                path: 'spinnenweb/:userId',
                component: QuestionnaireComponent,
                children: [
                  {
                    path: ``,
                    resolve: [PositiveHealthQuestionnaireResolver],
                    data: { type: QuestionnairePageType.pgScores },
                    component: PositiveHealthComponent,
                  },
                ],
              },
            ],
          },
          {
            path: 'mijn-netwerk',
            children: [
              { path: '', component: NetworkComponent, pathMatch: 'full' },
              { path: 'user/:userId', component: UserQuestionnairesComponent },
            ],
          },
          {
            path: 'mijn-gegevens',
            children: [
              {
                path: '',
                component: PatientProfileComponent,
                pathMatch: 'full',
              },
              {
                path: 'edit',
                component: PatientEditComponent,
              },
            ],
          },
          {
            path: 'mijn-profiel',
            children: [
              {
                path: '',
                component: CurrentUserProfileComponent,
                pathMatch: 'full',
              },
              {
                path: 'edit',
                component: CurrentUserEditComponent,
              },
            ]
          },
          {
            path: 'mijn-doktor',
            children: [
              { path: 'welcome', component: LandingPageComponent, pathMatch: 'full' },
              {
                path: '',
                component: ShellComponent,
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import('@medrecord/my-doctor').then((m) => m.MyDoctorModule),
                  },
                ]
              }
            ]
          },
        ]
      },
    ],
  },
  {
    path: generalRouteNames.Any,
    redirectTo: generalRouteNames.Base,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ZDB_ROUTES, {
      enableTracing: true,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  providers: [
    provideGeneralRouteNames(),
    provideMedsafeRouteNames(),
    provideAuthRouteNames(generalRouteNames.Auth.Entry),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
