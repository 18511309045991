<ejs-dialog #dialogComponent [visible]="isOpen" [isModal]="true" (overlayClick)="dialogClosed.emit()" width="60%">
  <ng-template #header>
    <span>{{ 'healthcare_providers_list' | translate }}</span>
  </ng-template>

  <ng-template #content>
    <div class="row mx-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12 d-flex">
            <span class="text-weight-600">{{ 'healthcare_providers_list_status_' + type | translate }}: &nbsp;</span>
            {{ status }}
          </div>

          <div class="col-12 d-flex justify-content-end mt-3">
            <button class="btn btn-secondary" (click)="dialogClosed.emit()">
              {{ 'common_ok' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
