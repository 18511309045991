<medrecord-input
  #input="ngModel"
  #inputEl
  [disabled]="true"
  [formDisabled]="disabled"
  (blur)="checkIfTouched()"
  [(ngModel)]="value"
  [hint]="hint"
  [externalErrors]="control?.errors"
  [iconsRight]="disabled ? [] : value ? [cross, calendar] : [calendar]"
  [label]="label"
  [placeholder]="placeholder"
  [type]="'text'"
  (click)="showCalendar($event)"
  class="d-block position-relative"
>
  <ejs-calendar *ngIf="isCalendarVisible && !disabled"
                [min]="minDate"
                [max]="maxDate"
                [showTodayButton]="false"
                [start]="startCalendarView"
                [depth]="calendarViewDepth"
                [firstDayOfWeek]="firstDayOfWeek"
                (change)="handleDateSelect($event)"
                class="d-block position-absolute"
  ></ejs-calendar>
</medrecord-input>

<ng-template #cross>
  <medrecord-svg-cross [width]="8"
                       [hoverable]="true"
                       (click)="clearValue()"
                       type="secondary"
  ></medrecord-svg-cross>
</ng-template>

<ng-template #calendar>
  <medrecord-svg-calendar
    [hoverable]="true"
    [width]="18"
    (click)="toggleCalendar()"
    type="secondary"
  ></medrecord-svg-calendar>
</ng-template>
